import { EventApi } from "@fullcalendar/core";
import { AppointmentContext } from "../../stores/Appointment/appointment.provider";
import useStore from "../../helpers/useStore";
import { UserContext } from "../../stores/User/User.provider";
import { TherapyDto } from "../../api-client";
  
const useCalendarValidations = () => {

  const { frequencyValidationForAppointment } = useStore(AppointmentContext);
  const { getUserById } = useStore(UserContext);

  const frequencyValidation = async (appointmentId: string, date: string): Promise<any> => {
      const result = await frequencyValidationForAppointment(appointmentId, date);
      console.log('frequencyValidationForAppointment:', result);
      
      return result;
    }

  // Check if the event is overlapping with other events in a day for a patient with different therapists
  const isPatientEventOverlapping = (start: Date, end: Date, events: EventApi[], draggedEvent: EventApi): boolean => {
    const startTime = new Date(start).getTime();
    const endTime = new Date(end).getTime();
    
    const patientTodayEvents = events.filter((e) => 
      e.extendedProps.type === "TimeSlot" &&
      e.extendedProps.patient?.id === parseInt(draggedEvent.extendedProps.patient?.id)
    );

    for (let i = 0; i < patientTodayEvents.length; i++) {
      let event = patientTodayEvents[i];
      if (event.id === draggedEvent.id) { //|| event.start?.getDate() !== start.getDate()
        continue;
      }
      
      if (draggedEvent.extendedProps.multipleTimeSlots) {
        const groupped = events.filter((e) => e.groupId === draggedEvent.groupId);
        if (groupped.some((g) => g.id === event.id))
          continue;
      }
      
      const eventStart = new Date(event.startStr).getTime();
      const eventEnd = new Date(event.endStr).getTime();
      
      if ((startTime < eventEnd && endTime > eventStart)) {
        return true;
      }
    }
    return false;
  };

  const hasTherapistCredentials = async (userId: string, therapy: string): Promise<boolean> => {
    return getUserById(userId)
      .then((user) => {
        if (user) {
          return (user.therapies as TherapyDto[])?.some((t) => t.abbreviation === therapy);
        }
        return false;
      })
      .catch((error) => {
        console.error('Error fetching user credentials:', error);
        return false;
      });
  };

  return {
    frequencyValidation,
    hasTherapistCredentials,
    isPatientEventOverlapping,
  };

};

export default useCalendarValidations;