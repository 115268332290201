/* eslint-disable react-hooks/exhaustive-deps */

import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuItem,
  Modal,
  Paper,
  Switch,
  Typography,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { observer, useObserver } from "mobx-react";
import Controls from "../atoms/Controls";
import EditTable, { validateFrequency, validateTherapy } from "../molecules/Editable";
import DoctorDialog from "./PatientDataEntryForm/Doctor";
import Form, { rxuseForm } from "./PatientDataEntryForm/Form";
import { format } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { DoctorContext } from "../stores/Doctor/doctor.provider";
import { MasterTablesContext } from "../stores/MasterTables/MasterTables.provider";
import useStore from "../helpers/useStore";
import AddIcon from "@mui/icons-material/Add";
import { PatientsContext } from "../stores/Patients/patients.provider";
import { DoubleTreatmentRxDto, NoteDto, PatientDto, RxDto } from "../api-client";
import { NotesContext } from "../stores/Notes/notes.provider";
import { UserContext } from "../stores/User/User.provider";
import Notes from "./ScheduleOverview/Notes/Notes";
import { MoreVert } from "@mui/icons-material";
import { RxStoreContext } from "../stores/Rx/rx.provider";
import React from "react";
import notificationStore from "../stores/Notification/notificationStore";
import Verordnung13 from "./PatientOverview/RxInfo/RxForms/Kasse/Verordnung13";
import ValidationCard, { formType } from "../molecules/ValidationCard";
import WarningDialog from "../atoms/WarningDialog";
import { TopLevelPaper } from "../themes/StyledComponents";
import FlatCard from "../atoms/FlatCard";

interface RxProps {
  isEditing?: boolean;
  selectedRow: any; // Adjust the type of selectedRow accordingly
  patientValues: any;
  masterData: any;
  handleData: (data: any) => void;
  setPatientData: (data: any) => void;
  readOnlyView?: boolean;
  openRx?: boolean;
  onCloseRx?: any;
  useDialog?: boolean;
}
interface DiagnoseGroup {
  a: string;
  b: string;
  c: string;
  d: string;
}

const RxPage: React.FC<RxProps> = observer(
  ({
    isEditing,
    selectedRow,
    patientValues,
    masterData,
    handleData,
    setPatientData,
    readOnlyView,
    openRx,
    onCloseRx,
    useDialog,
  }) => {
    const [therapytableData, settherapyTableData] = useState([]);
    const [selectedLANR, setSelectedLANR] = useState<string | null>(null);
    const [selectedBSNR, setSelectedBSNR] = useState<string | null>(null);
    const [selectedChipCard, setSelectedChipCard] = useState<string | null>(null);
    const [selectedPayer, setSelectedPayer] = useState(null);
    const [selectedDoctor, setSelectedDoctor] = useState(null);
    const DoctorStore = useStore(DoctorContext);
    const { doctorOptions } = DoctorStore;
    const masterTablesStore = useStore(MasterTablesContext);
    const { getICD, getTarifGroupIds } = masterTablesStore;
    const PatientsStore = useStore(PatientsContext);
    const { postPatients } = PatientsStore;
    // const NotesStore = useStore(NotesContext);
    // const { getRxNotes, postNotes ,updateNoteById} = NotesStore;
    const [rxNotes, setRxNotes] = useState<NoteDto[]>([]);
    const UserStore = useStore(UserContext);
    const RxStore = useStore(RxStoreContext);
    const { copyToContinous } = RxStore;
    const { user } = UserStore;
    // const [doctorOptions, setDoctorOptions] = useState([]);
    const [fetchedData, setFetchedData] = useState(null);
    const [showWarning, setShowWarning] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedDiagnoseGroup, setSelectedDiagnoseGroup] = useState<DiagnoseGroup | null>(null);

    const [isAddEditDialogOpen, setAddEditDialogOpen] = useState(false);
    const [menu, setMenu] = useState<null | HTMLElement>(null);
    const [showGenericView, setShowGenericView] = useState(false);
    const [unsavedChangesWarning, setShowunsavedChangesWarning] = useState(false);//To open dialog
    const [unsavedChanges, setUnsavedChanges] = useState(false); // Flag to track unsaved changes




    // const handleToggle = () => {
    //   setShowGenericView(!showGenericView);
    // };
    useEffect(() => {
      const rxType = patientValues?.rxs?.find((rx: any) => rx.rxNumber === selectedRow?.rxNumber);
      const therapyRxs = rxType?.therapyRxs;
      console.log("Frequenciesujjj:", rxType);
      const frequencies = new Set();
      therapyRxs?.forEach((therapy: any) => {
        console.log("Frequenciesujjj:nnn", therapy);
        const frequencyId = therapy?.frequency?.id || therapy.frequency; // Ensure frequency is not null or undefined
        if (frequencyId) {
          frequencies.add(frequencyId);
        }
      });

      console.log("Frequencies:", frequencies);

      const isKasse = rxType?.rxtype === "Kasse" || rxType?.rxtype?.id === "Kasse";
      const isSingleFrequency = frequencies.size === 1 || frequencies.size === 0;

      // Default to generic view if "Kasse" and frequency size is 1
      if (isKasse && isSingleFrequency) {
        setShowGenericView(true);
      } else {
        setShowGenericView(false); // Default to form view otherwise
      }

      // Show error if switching to generic view when conditions are not met
      if (!showGenericView && isKasse && !isSingleFrequency) {
        // notificationStore.showMessage("Therapiefrequenzwerte sind nicht eindeutig für die Generische Ansicht.", "error");
      }
    }, [selectedRow, patientValues]);

    const handleToggle = () => {
      if (unsavedChanges) {
        setShowunsavedChangesWarning(true);
      } else {
        const rxType = patientValues?.rxs?.find((rx: any) => rx.rxNumber === selectedRow?.rxNumber);
        const therapyRxs = rxType?.therapyRxs;

        const frequencies = new Set();
        therapyRxs?.forEach((therapy: any) => {
          const frequencyId = therapy?.frequency?.id || therapy?.frequency; // Ensure frequency is not null or undefined
          if (frequencyId) {
            frequencies.add(frequencyId);
          }
        });

        const isSingleFrequency = frequencies.size === 1;

        // Check if switching to generic view is allowed
        if (!isSingleFrequency && !showGenericView) {
          notificationStore.showMessage(
            "Therapiefrequenzwerte sind nicht eindeutig für die Generische Ansicht.",
            "error"
          );
          return;
        }

        setShowGenericView(!showGenericView);
      }
    };

    // Separate handler for the "More" button click
    const handleMoreButtonClick = (event: React.MouseEvent<HTMLElement>) => {
      setMenu(event.currentTarget);
      // Stop event propagation to prevent it from reaching the row click handler
      event.stopPropagation();
    };

    const handleMenuClose = () => {
      setMenu(null);
    };
    const handleCreateVirtualPrescription = async () => {
      try {
        // Make an API call to create a virtual prescription for the selected row
        const response = await copyToContinous(selectedRow.id);
        const updatedFormData = {
          ...patientValues,
          rxs: [...patientValues.rxs, response],
        };
        // setMasterPatientData(updatedFormData);
        // console.log("updateddgghhh", updatedFormData, updatedRxValues);
        handleData(updatedFormData);

        // After the API call is successful, fetch updated data and refresh the component
      } catch (error) {
        // Handle any errors that may occur during the API call
        console.error("Error creating virtual prescription:", error);
        // You can also display an error message to the user.
      }
    };

    // const handleOpenAddEditDialog = () => {
    //   setAddEditDialogOpen(true);
    // };
    const tarifGroupsMasterData = getTarifGroupIds();
    const handleOpenAddEditDialog = (lanr: string | null, bsnr: string | null) => {
      setSelectedLANR(lanr);
      setSelectedBSNR(bsnr);
      setAddEditDialogOpen(true);
    };

    const handleCloseWarning = () => {
      setShowWarning(false);
    };

    const handleCloseAddEditDialog = (data: any) => {
      // Handle doctor data, LANR, and BSNR passed back from DoctorDialog
      setAddEditDialogOpen(false);

      // Here you can perform further actions with the received data
      if (data && "number" in data) {
        const { number, clinicNumber, contactInfo, id } = data;

        // Update states or perform other operations based on the received data
        setSelectedLANR(number);
        setSelectedBSNR(clinicNumber);
        setSelectedDoctor(contactInfo?.lastName);
        setFormValues((prevFormValues: any) => ({
          ...prevFormValues,
          doctorNumber: number,
          practiceNumber: clinicNumber,
          doctorId: id,
        }));

        // For example, you can update doctor form values if needed
        // setDoctorFormValues(doctorData);
      }
    };

    // const fetchDoctorOptions = async () => {
    //   try {
    //     const response = await getAllDoctors();
    //     console.log("Resppnse", response);
    //     setDoctorOptions(response);
    //   } catch (error) {
    //     console.error("Error fetching doctor options:", error);
    //   }
    // };

    const handleDoctorChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedValue: any = event.target.value;
      setSelectedDoctor(selectedValue);
      // Autopopulate lanr and bsnr based on doctor selection
      const selectedDoctor: any = doctorOptions.find(
        (doctor: any) => doctor.contactInfo.lastName === selectedValue
      );

      if (selectedDoctor) {
        setFormValues((prevFormValues: any) => ({
          ...prevFormValues,
          doctorNumber: selectedDoctor.number,
          practiceNumber: selectedDoctor.clinicNumber,
        }));
        setSelectedLANR(selectedDoctor.number);
        setSelectedBSNR(selectedDoctor.clinicNumber);
      }

      // Filter doctor options based on selectedValue
      const filteredOptions: any = doctorOptions.filter(
        (doctor: any) => doctor.contactInfo.lastName === selectedValue
      );

      if (filteredOptions.length > 0) {
        // If filteredOptions has a value, set it as the fetchedData
        setFetchedData(filteredOptions[0]);
        // Update receiptForm with selected doctor
        // if (!isEditing) {
        setFormValues({
          ...formValues,
          doctorId: filteredOptions[0].id,
          practiceNumber: filteredOptions[0].clinicNumber,
          doctorNumber: filteredOptions[0].number,
        });
        // } else {
        //   setFormValues((prevFormValues: any) => ({
        //     ...prevFormValues,
        //     rxs: prevFormValues.rxs.map((rx: any) => ({
        //       ...rx,
        //       doctorId: filteredOptions[0].id,
        //       practiceNumber: filteredOptions[0].clinicNumber,
        //       doctorNumber: filteredOptions[0].number,
        //     })),
        //   }));
        // }
      } else {
        setFetchedData(null);
        setFormValues({
          ...formValues,
          doctorId: "",
          practiceNumber: "",
          doctorNumber: "",
        });
      }
    };

    // const handleDoctorChange = (selectedValue) => {
    //   setSelectedDoctor(selectedValue);
    //   fetchData(selectedValue);
    // };

    const treatmentColumns: any[] = [
      {
        field: "therapy",
        headerName: "Heilmittel",
        // width: 150,
        editable: true,
      },
      { field: "amount", headerName: "Menge", editable: true },

      {
        field: "frequency",
        headerName: "Frequency",
        // width: 150,
        editable: true,
      },
    ];
    const rxType = masterData
      .filter((obj: any) => "rxType" in obj) // Filter objects that have the insuranceStatus property
      .map((obj: any) => obj.rxType);
    const diagnoseGroups = masterData
      .filter((obj: any) => "diagnosisGroups" in obj)
      .map((obj: any) => obj.diagnosisGroups);
    const insurer = masterData
      .filter((obj: any) => "insurer" in obj)
      .map((obj: any) => obj.insurer);

    const account = masterData
      .filter((obj: any) => "account" in obj)
      .map((obj: any) => obj.account);
    // const doctors = masterData
    // .filter((obj: any) => "doctor" in obj)
    // .map((obj: any) => obj.doctor);
    const selectedInsurer = insurer[0]
      ? insurer[0].find(
          (insurer: any) => insurer.id === (patientValues?.payer || patientValues?.payer?.id)
        )
      : "";
    const initialFormValues = {
      // receiptId
      //  PatientId
      rxtype: "",
      firstName: patientValues?.firstName,
      lastName: patientValues?.lastName,
      dob: patientValues?.dob,
      accountId: "",
      employee: "",
      chipcard: "",
      tarifGroupId: patientValues?.tarifGroupId,
      // symptomCode: "",
      a: false,
      b: false,
      c: false,
      d: false,
      symptoms: "",
      Receiptpayer: patientValues?.payer,
      insuranceStatus: patientValues?.status,
      insureeNumber: patientValues?.insuranceNo,
      doctorId: "",
      signatureStatus: "",
      isPerpetual: false,
      unvirtualize: false,
      isCopaymentExempted: false,
      isAccident: false,
      isBVG: false,
      status: "",
      hasIndividualSymptoms: false,
      hygiene: false,
      isAutoAddReport: false,
      urgent: false,
      // homeVisit: false,
      therapyReport: false,
      // isHomeVisitFree: false,
      date: patientValues?.date,
      icdCodeEntries: [{ userText: "" }, { userText: "" }],
      practiceNumber: "",
      // number:"",
      doctorNumber: "",
      additionalNotes: "",
      diagnosegroup: "" || null,
      diagnosisText: "",
      isPerpetualApproved: false,
      therapyRxs: [],
      creationDate: "",
      visitType: "",
      problematic: false,
      changeRequested: false,
    };

    const insurerName = selectedInsurer?.name ?? "";

    const [formValues, setFormValues] = useState(initialFormValues);
    const [icdCodeEntries, setIcdCodeEntries] = useState(initialFormValues.icdCodeEntries);
    const rxvalidate = (fieldValues = formValues) => {
      let temp: any = { ...rxerrors };
      // if ("rxtype" in fieldValues)
      //   temp.rxtype = fieldValues.rxtype ? "" : "Pflichtfeld";
      // if ("accountId" in fieldValues)
      //   temp.accountId = fieldValues.accountId ? "" : "Pflichtfeld";
      // if ("chipcard" in fieldValues)
      //   temp.chipcard = fieldValues.chipcard ? "" : "Pflichtfeld";
      // if ("Receiptpayer" in fieldValues)
      //   temp.Receiptpayer = fieldValues.Receiptpayer ? "" : "Pflichtfeld";
      if ("tarifGroupId" in fieldValues)
        temp.tarifGroupId = fieldValues.tarifGroupId ? "" : "Pflichtfeld";
      // if ("practiceNumber" in fieldValues)
      //   temp.practiceNumber = fieldValues.practiceNumber ? "" : "Pflichtfeld";
      // if ("doctorNumber" in fieldValues)
      //   temp.doctorNumber = fieldValues.doctorNumber ? "" : "Pflichtfeld";
      // if ("doctorId" in fieldValues)
      //   temp.doctorId = fieldValues.doctorId ? "" : "Pflichtfeld";

      // if ('email' in fieldValues)
      //     temp.email = (/$^|.+@.+..+/).test(fieldValues.email) ? "" : "Email is not valid."

      rxsetErrors({
        ...temp,
      });

      if (fieldValues == formValues) return Object.values(temp).every((x) => x == "");
    };
    const { rxerrors, rxsetErrors } = rxuseForm(initialFormValues, true, rxvalidate);

    // Initialize with two input boxes
    useEffect(() => {
      const fetchData = async () => {
        if (isEditing && selectedRow.rxNumber) {
          try {
            let rxs: [] = [];
            let updatedFormValues = { ...formValues };
            if (selectedRow.rxNumber) {
              const selectedRxNumber = selectedRow.rxNumber; // Assuming the property name is `number` for rxNumber in the selected row
              const filteredRxs = patientValues?.rxs?.filter(
                (rx: any) => rx.rxNumber === selectedRxNumber
              );

              const formattedValues = { ...patientValues, rxs: filteredRxs };

              console.log(
                "56878",
                patientValues,
                formattedValues.rxs,
                selectedRxNumber,
                filteredRxs
              );
              rxs = formattedValues.rxs
                ? formattedValues.rxs.flatMap((rx: any) => {
                    const therapyRxs = rx.therapyRxs || [];
                    console.log("THERRTZZ", therapyRxs);
                    return therapyRxs?.map((therapy: any) => ({
                      id: therapy.id,
                      date: rx.date,
                      therapy: therapy?.therapy?.abbreviation || therapy.therapy,
                      amount: therapy.amount,
                      frequency: therapy.frequency?.text || therapy.frequency,
                      isDoubled: therapy.isDoubled,
                      doubledTreatmentIds: therapy.doubledTreatmentIds,
                      rxSeries: therapy.rxSeries,
                    }));
                  })
                : [];
              formattedValues.rxs = formattedValues.rxs
                ? formattedValues.rxs.map((rx: any) => {
                    let icdCodeEntries;

                    if (rx.icdCodeEntries && rx.icdCodeEntries.length > 0) {
                      if (rx.icdCodeEntries.length === 1) {
                        icdCodeEntries = [...rx.icdCodeEntries, { userText: "" }];
                      } else {
                        icdCodeEntries = rx.icdCodeEntries;
                      }
                    } else {
                      icdCodeEntries = [{ userText: "" }, { userText: "" }];
                    }

                    return {
                      ...rx,
                      firstName: formattedValues.firstName,
                      lastName: formattedValues.lastName,
                      dob: formattedValues.dob,
                      insuranceStatus:rx.insuranceStatus || formattedValues.status,
                      insureeNumber:rx.insureeNumber || formattedValues.insuranceNo,
                      Receiptpayer:
                        insurer[0].find((option: any) => option.id === rx.Receiptpayer)?.name ||
                        rx.payer?.name,
                      chipcard: rx.chipcard || rx.payer?.ik,
                      doctorId:
                        doctorOptions?.find((option: any) => option.id === rx.doctorId)?.contactInfo
                          .lastName || rx.doctor?.contactInfo?.lastName,
                      diagnosegroup:
                        diagnoseGroups[0].find((option: any) => option.id === rx.diagnosegroup)
                          ?.code || rx.diagnosisGroup?.code,
                      rxtype:
                        rxType[0].find((option: any) => option.id === rx.rxtype)?.text ||
                        rx.rxtype?.text,
                      accountId:
                        account[0].find((option: any) => option.id === rx.accountId)?.name ||
                        rx.account?.name,
                      icdCodeEntries: icdCodeEntries,
                    };
                  })
                : [];
              console.log("Formateed", formattedValues.rxs);
              updatedFormValues = {
                ...formValues,
                ...formattedValues.rxs[0], // Assuming only one item is filtered
                therapyRxs: rxs,
              };
              console.log("GZTUUU", formValues, updatedFormValues);

              // handleData({...patientValues,rxs:filteredRxs})
            }
            // else {
            //   console.log("ARB", formValues, selectedRow);
            //   const formattedValues = { ...patientValues, rxs: [selectedRow] };

            //   rxs = formattedValues.rxs
            //     ? formattedValues.rxs.map((rx: any) => ({
            //         id: rx.id,
            //         date: rx.date,
            //         number: rx.number,
            //         therapy: rx.therapy,
            //         frequency: rx.frequency,
            //         therapyId: rx.therapyId,
            //       }))
            //     : [];
            // }
            setFormValues(updatedFormValues);
            console.log("RDFF", rxs, formValues);
            settherapyTableData(rxs);
          } catch (error) {
            console.log("error", error);
          }
          // };

          // fetchData();
          // dummyApiFetch().then((data) => {
          //   setValues(data);
          // });
        }
        // Make API request to fetch patient data based on patient ID or any other identifier
        else {
          console.log("Patientzzuu", patientValues);
          const filteredRxs = patientValues?.rxs?.filter((rx: any) => rx.id === selectedRow.id);
          console.log("POOO", formValues, filteredRxs);
          const rxs = filteredRxs
            ? filteredRxs.flatMap((rx: any) => {
                const therapyRxs = rx.therapyRxs || [];
                console.log("THERAPPP", therapyRxs);
                return therapyRxs?.map((therapy: any) => ({
                  id: therapy.id || uuidv4(),
                  date: rx.date,
                  therapy: therapy.therapy,
                  amount: therapy.amount,
                  frequency: therapy.frequency || null,
                  isDoubled: therapy.isDoubled,
                  doubledTreatmentIds: therapy.doubledTreatmentIds,
                  rxSeries: therapy.rxSeries,
                }));
              })
            : [];

          console.log("Uiii", patientValues?.rxs, filteredRxs, selectedRow, rxs, formValues);
          settherapyTableData(rxs);
          const updatedRx = {
            ...formValues,
            ...filteredRxs[0],
            accountId:
              account[0].find((option: any) => option.id === filteredRxs[0]?.accountId)?.name ||
              filteredRxs[0]?.accountId,
            diagnosegroup:
              diagnoseGroups[0].find((option: any) => option.id === filteredRxs[0]?.diagnosegroup)
                ?.code || filteredRxs[0]?.diagnosisGroup?.code,
            rxtype:
              rxType[0].find((option: any) => option.id === filteredRxs[0]?.rxtype)?.text ||
              filteredRxs[0]?.rxtype?.text,
            doctorId:
              doctorOptions?.find((option: any) => option.id === filteredRxs[0]?.doctorId)
                ?.contactInfo.lastName || filteredRxs[0]?.doctorId,
            Receiptpayer:
              insurer[0].find((option: any) => option.id === filteredRxs[0]?.Receiptpayer)
                ?.name || insurerName,
            // icdCodeEntries:filteredRxs[0].icdCodeEntries,
            therapyRxs: rxs,
          };

          // // // const updatedFormValues = { ...formValues, rxs: updatedRxs };
          setFormValues(updatedRx);
          console.log("OPOKK", formValues, updatedRx, doctorOptions);
        }
      };
      fetchData();

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditing, patientValues?.rxs, selectedRow, showGenericView]);

    // useEffect(() => {
    //   fetchDoctorOptions();
    // }, []);
    // Call the function whenever isAddEditDialogOpen becomes true
    // useEffect(() => {
    //   if (isAddEditDialogOpen) {
    //     fetchDoctorOptions();
    //   }
    // }, [isAddEditDialogOpen]);
    // useEffect to log the updated formValues

    useEffect(() => {
      setIcdCodeEntries(formValues.icdCodeEntries);
      console.log("Updated icdCodeEntries:", formValues.icdCodeEntries);
    }, [formValues]);

    console.log("Fteched", formValues);

    const handleInputChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      index?: any,
      icdEntryId?: string
    ) => {
      console.log("LUUZZ", event);
      setUnsavedChanges(true);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { name, value, checked, type, id } = event.target;

      const inputValue = type === "checkbox" ? checked : value;
      console.log("input", inputValue, checked);
      console.log("Event:", event.target.name, event.target.value, index);
      if (name === "date" && formValues.isPerpetual && value) {
        setFormValues((prevValues: any) => ({
          ...prevValues,
          unvirtualize: true,
        }));
      }
      // if (name.startsWith("icdCodeEntries")) {
      //   setFormValues((prevValues: any) => ({
      //     ...prevValues,
      //     icdCodeEntries: prevValues.icdCodeEntries.map(
      //       (icdEntry: any, i: number) => {
      //         return i === index
      //           ? { id: icdEntryId || undefined, userText: inputValue }
      //           : { id: icdEntry?.id, userText: icdEntry?.userText || "" };
      //       }
      //     ),
      //   }));
      // }
      // Check if the input is for icdCodeEntries
    
      if (name.startsWith("icdCodeEntries")) {
        const inputElement = document.querySelector(`[name="${name}"]`) as HTMLInputElement | HTMLTextAreaElement;
        const cursorPosition = inputElement?.selectionStart || 0;
        let updatedValue = value;
        let newCursorPosition = cursorPosition; // Initialize new cursor position
      
        // Check if the length is at least 4 and if a period needs to be inserted
        if (value.length >= 4) {
          // Check if the period already exists at the correct position
          if (!value.includes(".")) {
            // Insert a period after the third character if it's not already there
            updatedValue = value.slice(0, 3) + "." + value.slice(3);
      
            // Adjust cursor position if a period was inserted before the original cursor position
            if (cursorPosition > 3) {
              newCursorPosition = cursorPosition + 1; // Move cursor one position forward
            }
          }
        }
      
        // Update the form values with the correctly formatted value
        setFormValues((prevValues: any) => ({
          ...prevValues,
          icdCodeEntries: prevValues.icdCodeEntries.map((icdEntry: any, i: number) => {
            return i === index
              ? { id: icdEntryId || undefined, userText: updatedValue }
              : { id: icdEntry?.id, userText: icdEntry?.userText || "" };
          }),
        }));
      
        // Restore cursor position after the update
        setTimeout(() => {
          if (inputElement) {
            inputElement.selectionStart = inputElement.selectionEnd = newCursorPosition;
          }
        }, 0);
      }
      
      
      
      
      
      
      
      
      
      if (name === "hasIndividualSymptoms") {
        // When the checkbox is selected, clear symptom codes and text
        // if (inputValue ) {
        setFormValues((prevValues: any) => ({
          ...prevValues,
          a: false, // Clear symptom code
          b: false,
          c: false,
          d: false,
          symptoms: "",
          hasIndividualSymptoms: inputValue, // Clear symptoms text
        }));
        // }
      } else if (
        (name === "a" || name === "b" || name === "c" || name === "d") &&
        formValues?.hasIndividualSymptoms
      ) {
        // If the checkbox is selected, and symptom code is changed, uncheck the checkbox
        setFormValues((prevValues: any) => ({
          ...prevValues,
          hasIndividualSymptoms: false,
          [name]: inputValue,
        }));
      } else {
        // Update other form fields based on their names
        setFormValues((prevValues: any) => ({
          ...prevValues,
          [name]: id ? id : inputValue,
        }));
      }

      // setFormValues((prevValues: any) => ({
      //   ...prevValues,
      //   rxs: prevValues.rxs.map((rx: any) => ({
      //     ...rx,
      //     [name]: id ? id : inputValue,
      //   })),
      // }));
      if (name === "doctorNumber") {
        // Autopopulate doctor and bsnr based on LANR selection
        const selectedDoctor: any = doctorOptions.find(
          (doctor: any) => doctor.number === Number(value)
        );
        setFetchedData(selectedDoctor);
        console.log("UZZHHHH", selectedDoctor);
        if (selectedDoctor) {
          setFormValues((prevFormValues: any) => ({
            ...prevFormValues,
            doctorId: selectedDoctor.id,
            practiceNumber: selectedDoctor.clinicNumber,
          }));
          setSelectedDoctor(selectedDoctor.contactInfo.lastName);
          setSelectedBSNR(selectedDoctor.clinicNumber);
        }
      }
      // if (name === "practiceNumber") {
      //   // Autopopulate doctor and bsnr based on LANR selection
      //   const selectedDoctor: any = doctorOptions.find(
      //     (doctor: any) => doctor.clinicNumber === Number(value)
      //   );
      //   setFetchedData(selectedDoctor);
      //   if (selectedDoctor) {
      //     setFormValues((prevFormValues: any) => ({
      //       ...prevFormValues,
      //       doctorId: selectedDoctor.id,
      //       doctorNumber: selectedDoctor.number,
      //     }));
      //     setSelectedDoctor(selectedDoctor.contactInfo.lastName);
      //     setSelectedLANR(selectedDoctor.number);
      //   }
      // }
      if (name === "Receiptpayer") {
        // Autopopulate doctor and bsnr based on LANR selection
        const selectedPayer: any = insurer[0].find(
          (option: any) => option.id === id || option.ik === value
        );
        console.log("UZZHHHH", selectedPayer);
        if (selectedPayer) {
          setFormValues((prevFormValues: any) => ({
            ...prevFormValues,
            chipcard: selectedPayer?.ik,
            tarifGroupId: selectedPayer?.tarifGroup?.id,
          }));
          setSelectedChipCard(selectedPayer.ik);
          console.log("selectedPayer", selectedPayer.name);
        }
      }

      if (name === "rxtype") {
        setFormValues((prevValues) => ({
          ...prevValues,
          diagnosegroup: null, // Clear diagnosegroup when rxtype changes
        }));
        filterDiagnoseGroups(value);
      }

      if (name === "chipcard") {
        const selectedPayer: any = insurer[0].find((option: any) => option.ik === value);
        console.log("UZZHHHH", selectedPayer);
        if (selectedPayer) {
          setFormValues((prevFormValues: any) => ({
            ...prevFormValues,
            Receiptpayer: selectedPayer.id,
            tarifGroupId: selectedPayer?.tarifGroup?.id,
          }));

          setSelectedPayer(selectedPayer.name);
          console.log("selectedPayer", selectedPayer.name);
        }
      }
      console.log("GTII", formValues);

      // else if (name === "doctor") {
      //   // Autopopulate doctor and bsnr based on LANR selection
      //   const selectedDoctor: any = doctorOptions.find(
      //     (doctor: any) => doctor.contactInfo.firstNames===value

      //   );
      //   if (selectedDoctor) {
      //     setFormValues((prevFormValues: any) => ({
      //       ...prevFormValues,
      //       lanr: selectedDoctor.number,
      //       bsnr: selectedDoctor.clinicNumber,
      //     }));
      //     setSelectedLANR(selectedDoctor.number);
      //   }
      //   console.log('DOCTOR',selectedDoctor,formValues,value,doctorOptions)
      // }
    };
    const handleAddInput = () => {
      setIcdCodeEntries([...icdCodeEntries, { userText: "" }]);

      // Update the formValues state as well to reflect the new entry
      setFormValues((prevValues: any) => ({
        ...prevValues,
        icdCodeEntries: [...prevValues.icdCodeEntries, { userText: "" }],
      }));
    };

    const handleICDCodeEntrySubmit = async (icdCode: any, index: number) => {
      try {
        const icdObject: any = await getICD(icdCode.userText);
        console.log("OOPP", icdObject);
        if (icdObject) {
          // If the API call is successful and returns an icdObject, update the icdCodeEntries state with the normCode
          setFormValues((prevValues: any) => {
            const updatedICDCodeEntries = prevValues.icdCodeEntries.map(
              (icdEntry: any, i: number) => {
                return i === index
                  ? { id: icdCode?.id || "", userText: icdCode.userText }
                  : icdEntry;
              }
            );

            const concatenatedDiagnosisText = prevValues.diagnosisText
              ? `${prevValues.diagnosisText} ${icdObject.text}` // Append the new icdObject.text to the existing diagnosisText
              : icdObject.text;
            return {
              ...prevValues,
              icdCodeEntries: updatedICDCodeEntries,
              diagnosisText: concatenatedDiagnosisText.trim(), // Set diagnosisText to the concatenated value
            };
          });
        }
      } catch (error) {
        console.error("Error fetching ICD object:", error);
      }
    };

    console.log("UZRRFCFASG", icdCodeEntries);

    useEffect(() => {
      if (formValues.diagnosegroup) {
        const diagnoseGroupIsId = diagnoseGroups[0].some(
          (group: any) => group.id === formValues.diagnosegroup
        );
        // Find the matching diagnose group based on the selected code
        const selectedDiagnoseGroup = diagnoseGroupIsId
          ? diagnoseGroups[0].find((group: any) => group.id === formValues.diagnosegroup)
          : diagnoseGroups[0].find((group: any) => group.code === formValues.diagnosegroup);
        console.log("formVa", formValues.diagnosegroup, selectedDiagnoseGroup, diagnoseGroups);

        if (selectedDiagnoseGroup) {
          // Generate symptoms based on selected checkboxes
          let selectedSymptoms = "";
          if (formValues.a) selectedSymptoms += selectedDiagnoseGroup.a + " ";
          if (formValues.b) selectedSymptoms += selectedDiagnoseGroup.b + " ";
          if (formValues.c) selectedSymptoms += selectedDiagnoseGroup.c + " ";
          if (formValues.d) selectedSymptoms += selectedDiagnoseGroup.d + " ";
          console.log("SELECTE", selectedSymptoms, selectedDiagnoseGroup);
          setSelectedDiagnoseGroup(selectedDiagnoseGroup);
          setFormValues((prevValues: any) => ({
            ...prevValues,
            a: selectedDiagnoseGroup.a ? prevValues.a : false,
            b: selectedDiagnoseGroup.b ? prevValues.b : false,
            c: selectedDiagnoseGroup.c ? prevValues.c : false,
            d: selectedDiagnoseGroup.d ? prevValues.d : false,
            symptoms: selectedSymptoms.trim(),
          }));
        }
      }
    }, [formValues.diagnosegroup, formValues.a, formValues.b, formValues.c, formValues.d]);

    const warningDoctorSignatureConditions = () => {
      return (
        (formValues.status === "vorhanden" || formValues.status === "beim Therapeut") &&
        formValues.signatureStatus === ""
      );
    };

    const handleSubmit = async (event?: any) => {
      if (event) {
        setLoading(false);
        event.preventDefault(); // Prevent default form submission behavior
        console.log("Event", event);
      }
      try {
        // Set loading to true to disable the form or show a loading indicator
        setLoading(true);
        if (warningDoctorSignatureConditions() && event) {
          // Show a warning/alert here
          setShowWarning(true);
        } else {
          console.log("HJJUU", formValues, therapytableData.length, formValues.therapyRxs);
          //       const newColumnSet = [...treatmentColumns];
          //       const newRowAdded = Math.abs(formValues.therapies.length - therapytableData.length);
          // console.log('Newro',newRowAdded)
          //       for (let i = 0; i < newRowAdded; i++) {
          //         handleAddColumnSet(newColumnSet);
          //       }

          if (rxvalidate()) {
            const updatedRxValues: any = {
              ...formValues,
              id: patientValues?.rxs.find((rx: any) => rx.id === selectedRow.id)?.id || "",
              rxNumber:
                patientValues?.rxs.find((rx: any) => rx.id === selectedRow.id)?.rxNumber || "",
              // date:
              //   patientValues.rxs.find((rx: any) => rx.id === selectedRow.id)
              //     ?.date || "",
              accountId:
                account[0].find((option: any) => option.name === formValues.accountId)?.id ||
                formValues.accountId,
              rxtype:
                rxType[0].find((option: any) => option.text === formValues.rxtype)?.id ||
                formValues.rxtype,
              diagnosegroup:
                diagnoseGroups[0].find((option: any) => option.code === formValues.diagnosegroup)
                  ?.id || formValues.diagnosegroup,
              doctorId:
                (doctorOptions as any).find(
                  (option: any) => option.contactInfo.lastName === formValues.doctorId
                )?.id || formValues.doctorId,
              Receiptpayer:
                insurer[0].find((option: any) => option.name === formValues.Receiptpayer)?.id ||
                formValues.Receiptpayer,
              therapyRxs: (formValues.therapyRxs || [])
              .filter((obj:any) => obj.therapy!== "")
                // .map((therapy: any) => ({
                //   ...therapy,
                //   id: therapy.therapyId || therapy.id,
                //   frequency: therapy.frequency || "",
                // }))
                //  .filter(obj => obj.therapy !== "")

                .map((therapy: any) => {
                  if (!(therapy.frequency) || !(therapy.therapy) || !therapy.amount) {
                    throw new Error(
                      "Bitte fügen Sie die Behandlung hinzu, um das Rezept zu speichern/bearbeiten (Therapie, Menge, Häufigkeit sind Pflichtfelder) und deaktivieren Sie auch die Zelle nach der Bearbeitung!"
                    );
                  }
                  const validTherapy=validateTherapy(therapy.therapy.abbreviation || therapy.therapy,masterData)
                  const validFrequency=validateFrequency(therapy.frequency.id || therapy.frequency,masterData)
                  if(!validTherapy || !validFrequency){
                    throw new Error("Bitte geben Sie eine gültige Therapie und Häufigkeit ein.")
                  }
                  return {
                    ...therapy,
                    id: therapy.therapyId || therapy.id,
                    frequency: therapy.frequency,
                    isDoubled: therapy.isDoubled,
                    doubledTreatmentIds: therapy.doubledTreatmentIds,
                  };
                })
            };
            console.log("AJJJJ", updatedRxValues, selectedRow.id);

            const updatedFormData = {
              ...patientValues,
              rxs: patientValues?.rxs.map((rx: any) =>
                rx.id === selectedRow.id ? updatedRxValues : rx
              ),
            };
            console.log("updateddgghhh", updatedFormData, updatedRxValues);

            //   rxTableData([updatedRxValues])
            // onTableDataChange(updatedFormData.rxs);

            const response = await postPatients(updatedFormData as unknown as PatientDto);
            setUnsavedChanges(false);
            const updatedPatientData = updatedFormData.rxs?.forEach((updatedRx: any) => {
              const correspondingRx = response.rxs.find((rx: any) => rx.id === updatedRx.id);
              if (correspondingRx) {
                updatedRx.icdCodeEntries = correspondingRx.icdCodeEntries;
              }
            });
            console.log("updatedPatientData", updatedPatientData, updatedFormData);
            handleData(updatedFormData);
            setFormValues(updatedRxValues);

            //   handleCloseDialog();
            //   const updatedTherapies = formValues.therapies;

            // const updatedRx = {
            //   ...selectedRow,
            //   therapies: updatedTherapies,
            // };

            // const updatedRxs = patientValues.rxs.map((rx: any) =>
            //   rx.id === updatedRx.id ? updatedRx : rx
            // );

            // const updatedPatientValues = {
            //   ...patientValues,
            //   rxs: updatedRxs,
            // };

            // handleData(updatedPatientValues);
            // handleCloseDialog();

            notificationStore.showMessage("Rezept erfolgreich gespeichert", "success");
          } else {
            notificationStore.showMessage(
              "Bitte füllen Sie alle erforderlichen Felder aus",
              "warning"
            );
          }
        }
      } catch (error: any) {
        console.log("Error adding rezept:", error);
        notificationStore.showMessage(
          error.message || "Fehler beim Speichern des Rezepts",
          "error",
          error
        );
      } finally {
        // Set loading back to false when the API call is complete
        setLoading(false);
      }
    };
    const handleContinueWarning = () => {
      setShowWarning(false); // Close the warning dialog
      handleSubmit(); // Call handleSubmit directly
    };

    const handletherapyTableDataChange = (data: DoubleTreatmentRxDto[]) => {
      console.log("datatttzz", data);

      // // Create a new array to hold the updated data
      const updatedData: any[] = [];
      data.forEach((item: any) => {
        // Divide the amount by 2 for the original item
        const updatedAmount = item.amount;

        // Update the original item with the new amount
        const updatedItem = { ...item, amount: updatedAmount, isDoubled: item.isDoubled };

        // Always add the original item to the updated data array
        updatedData.push(updatedItem);

        // If the item has isDoubled: true, create a new row without isDoubled: true and with the updated amount
        if (item.isDoubled) {
          const newId = uuidv4();
          // const newDuplicateItem = { ...item, id: newId, amount: updatedAmount, isDoubled: false };

          // Add the new ID to the doubleTreatmentIds array
          updatedItem.doubledTreatmentIds =
            updatedItem.doubledTreatmentIds && updatedItem.doubledTreatmentIds.length > 0
              ? updatedItem.doubledTreatmentIds
              : [newId];
        }
      });

      // data.forEach((item: any) => {
      //   // Divide the amount by 2 for both the original and duplicated rows
      //   const updatedAmount = (item.amount / 2).toString();

      //   // Create a new row with the updated amount
      //   const newItem = { ...item, amount: updatedAmount };

      //   // Always add the original item to the updated data array
      //   updatedData.push(newItem);

      //   // If the item has duplicate: true, create a new row without duplicate: true and with the updated amount
      //   if (item.isDoubled) {
      //     const newId = uuidv4();
      //     const newDuplicateItem = { ...item, id: newId, amount: updatedAmount };
      //     delete newDuplicateItem.isDoubled; // Remove the duplicate: true property
      //     updatedData.push(newDuplicateItem);
      //   }
      // });

      // setFormValues((prevData: any) => ({
      //   ...prevData,
      //   therapyRxs: updatedData,
      // }));
      setFormValues((prevData: any) => {
        const newFormValues = {
          ...prevData,
          therapyRxs: updatedData,
        };
        // Compare new form values with previous ones
        if (
          JSON.stringify(prevData.therapyRxs) !== JSON.stringify(updatedData) &&
          prevData.therapyRxs.length > 0 &&
          updatedData.length > 0
        ) {
          setUnsavedChanges(true);
        }

        return newFormValues;
      });
      console.log("jkkkkk", formValues);
      //   ...prevData,
      //   rxs: [
      //     {
      //       therapies: data,
      //     },
      //   ],
      // }));
    };

    console.log("FORM", formValues.symptoms);
    let formattedDob: any; // Initialize formattedDob to an empty string

    if (formValues?.dob) {
      const parsedDate: any = new Date(formValues.dob);
      if (!isNaN(parsedDate)) {
        // Check if the parsed date is valid
        formattedDob = format(parsedDate, "dd.MM.yyyy");
      }
    }

    //   const handleSubmit = async (event: any) => {
    //     event.preventDefault(); // Prevent default form submission behavior

    //     try {
    //       console.log("VALUEEESS", values);
    //       if (validate()) {
    //         const updatedValues = {
    //           ...values,
    //           payer: isEditing
    //             ? insurer[0].find((option: any) => option.insurer === values.payer)
    //                 ?.id
    //             : values.payer,
    //         };
    //         await postPatients(updatedValues as unknown as PatientDto);
    //         if (isEditing) {
    //           await fetchAndFormatPatientData(filteredValue);
    //         } else {
    //           setValues(initialFValues as any);
    //           setrxTableData([]);
    //         }
    //         alert("Patient is added successfully");
    //       }
    //     } catch (error) {
    //       console.log("Error adding patient:", error);
    //     }
    //   };

    // const handleSubmit = (e: any) => {
    //   e.preventDefault();
    //   if (validate()) {
    //     // employeeService.insertEmployee(values)
    //     resetForm();
    //   }
    // };
    console.log("SELECTEDROW", selectedRow);
    const handleCloseRx = () => {
      if (unsavedChanges) {
        // If there are unsaved changes, set a state to open the warning dialog
        setShowunsavedChangesWarning(true);
      } else {
        onCloseRx();
      }
    };

    const handleUnsavedChangesWarning = () => {
      if (showGenericView) {
        handleSubmit();
      } else {
        handleSubmit();
      }
    };

    const handleCloseUnsavedChangesWarning = () => {
      onCloseRx();
      // If user cancels and chooses not to continue, close the warning dialog
      setShowunsavedChangesWarning(false);
    };

    const [filteredDiagnoseGroups, setFilteredDiagnoseGroups] = useState([]);

    const filterDiagnoseGroups = (rxtype: any) => {
      const filtered = diagnoseGroups[0].filter((option: any) => option.RxType?.id === rxtype);
      setFilteredDiagnoseGroups(filtered);
    };

    useEffect(() => {
      filterDiagnoseGroups(formValues.rxtype);
    }, [formValues.rxtype]);

    const FormContent = (
      <Form>
        <Grid container xs = {12} spacing={4} direction="row" paddingTop={5} justifyContent="center" sx={{border:"0px solid green"}}>
          {showGenericView && (
            <Grid
              container
              spacing={2}
              sx={{ marginLeft: 1, border: "0px solid blue", padding: "0" }}
            >
              <Grid item xs={12} md={8} sx={{ border: "0px solid yellow" }}>
                {/* <Verordnung13
                  rxFormData={
                    patientValues?.rxs?.filter(
                      (rx: any) => rx.rxNumber === selectedRow?.rxNumber
                    )[0]
                  }
                  patientValues={patientValues}
                  handleData={handleData}
                  masterTablesData={masterData}
                  readOnlyView={readOnlyView}
                  ref={childRef}
                  setUnsavedChanges={setUnsavedChanges}
                  newRx={false}
                  // ref={saveGenericViewData}
                /> */}
                <Verordnung13
                rxFormData={
                  patientValues?.rxs?.filter(
                    (rx: any) => rx.rxNumber === selectedRow?.rxNumber
                  )[0]
                }
                patientValues={patientValues}
                handleData={handleData}
                masterTablesData={masterData}
                // setUnsavedChanges={setUnsavedChanges}
                newRx={false}
                openRx={openRx as boolean}
                onCloseRx={onCloseRx}
                // ref={saveGenericViewData}
              />

              </Grid>

              {!readOnlyView && (
                <Grid item xs={12} md={4} sx={{ border: "0px dotted black" }}>
                  <FlatCard title="Validierung" >
                  <ValidationCard
                    patientData={patientValues}
                    selectedRx={
                      patientValues?.rxs?.filter(
                        (rx: any) => rx.rxNumber === selectedRow?.rxNumber
                      )[0]
                    }
                    form = {formType.LONG}
                  />
                  </FlatCard>
                </Grid>
              )}
            </Grid>
          )}
          {!showGenericView && (
            <>
              <Grid
                item
                xs={12}
                md={5}
                container
                direction="column"
                spacing={2}
                sx={{ alignContent: "center" }}
              >
                <FlatCard title="Allgemeines">
                  <Controls.Select
                    name="rxtype"
                    label="Rezept-typ"
                    // id={1}
                    value={formValues?.rxtype}
                    onChange={handleInputChange}
                    options={rxType[0]?.map((option: any) => ({
                      label: option.text.toString(),
                      value: option.text.toString(),
                      id: option.id.toString(),
                    }))}
                    // required
                    error={rxerrors.rxtype}
                  />
                  <Controls.Select
                    name="accountId"
                    label="Mandant"
                    // id={1}
                    value={formValues?.accountId}
                    onChange={handleInputChange}
                    options={user?.location?.accounts.map((option: any) => ({
                      label: option.name.toString(),
                      value: option.name.toString(),
                      id: option.id.toString(),
                    }))}
                    // required
                    error={rxerrors.accountId}
                    // error={errors.departmentId}
                  />
                  {/* <Box width="300px"> */}
                  <Typography align="left" variant="h6">
                    Optionen
                  </Typography>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Controls.Checkbox
                      name="isPerpetualApproved"
                      label="Langfristgenehmigung"
                      value={formValues?.isPerpetualApproved}
                      onChange={handleInputChange}
                    />
                    <Controls.Checkbox
                      name="isCopaymentExempted"
                      label="Zuzahlungsfrei"
                      value={formValues?.isCopaymentExempted}
                      onChange={handleInputChange}
                    />
                    <Controls.Checkbox
                      name="isAccident"
                      label="Unfallfolgen"
                      value={formValues?.isAccident}
                      onChange={handleInputChange}
                    />
                    <Controls.Checkbox
                      name="isBVG"
                      label="BVG"
                      value={formValues?.isBVG}
                      onChange={handleInputChange}
                    />
                    {/* <Button
                  onClick={() => {
                    const updatedIsPerpetual = !formValues.unvirtualize;

                    const event: any = {
                      target: {
                        name: "unvirtualize",
                        type: "checkbox",
                        checked: updatedIsPerpetual,
                      },
                    };

                    handleInputChange(event);
                  }}
                  sx={{ width: "200px" }}
                >
                  {formValues.unvirtualize
                    ? "Dauerrezept"
                    : "Dauerrezept anlegen"}
                </Button> */}
                  </div>
                  {/* </Box> */}

                  <Controls.Select
                    name="status"
                    label="Rezeptverbleib"
                    value={formValues?.status}
                    onChange={handleInputChange}
                    options={[
                      {
                        label: "noch nicht erstellt",
                        value: "noch nicht erstellt",
                      },
                      { label: "beim Patient", value: "beim Patient" },
                      { label: "beim Therapeut", value: "beim Therapeut" },
                      { label: "vorhanden", value: "vorhanden" },
                    ]}
                    // error={errors.departmentId}
                  />
                </FlatCard>
                <FlatCard title="Versicherung">
                  <Controls.Select
                    name="Receiptpayer"
                    label="Kostenträger"
                    id={1}
                    value={selectedPayer || formValues.Receiptpayer}
                    onChange={handleInputChange}
                    options={insurer[0]?.map((option: any) => ({
                      label: option?.ik.toString(),
                      value: option?.name.toString(),
                      id: option.id.toString(),
                    }))}
                    // required
                    // error={rxerrors.Receiptpayer}
                  />
                  <Controls.Select
                    name="chipcard"
                    label="IK der Chipkarte"
                    value={selectedChipCard || formValues?.chipcard}
                    onChange={handleInputChange}
                    // id={1}
                    options={insurer[0]?.map((insurer: any) => ({
                      label: insurer.name.toString(),
                      value: insurer.ik.toString(),
                      // id: insurer.id.toString(),
                    }))}
                    // required
                    // error={rxerrors.chipcard}
                    // error={errors.fullName}
                  />
                  <Controls.Select
                    name="tarifGroupId"
                    label="Tarif Group"
                    value={formValues?.tarifGroupId}
                    onChange={handleInputChange}
                    options={tarifGroupsMasterData.map((value: any) => ({
                      label: value.toString(),
                      value: value.toString(),
                    }))}
                    required
                    error={rxerrors.tarifGroupId}
                  />
                  <Controls.Input
                    name="insureeNumber"
                    label="Versichertennummer"
                    value={formValues?.insureeNumber}
                    onChange={handleInputChange}
                    // error={errors.fullName}
                  />

                  <Controls.Select
                    name="insuranceStatus"
                    label="Status"
                    value={formValues?.insuranceStatus}
                    onChange={handleInputChange}
                    options={[
                      { label: "10000", value: "10000" },
                      { label: "30000", value: "30000" },
                      { label: "50000", value: "50000" },
                    ]}
                    // error={errors.departmentId}
                  />
                </FlatCard>
                <FlatCard title="Arzt">
                  <Controls.Select
                    name="doctorNumber"
                    label="LANR"
                    value={selectedLANR || formValues?.doctorNumber}
                    onChange={handleInputChange}
                    options={doctorOptions?.map((doctor: any) => ({
                      label: doctor.number.toString(),
                      value: doctor.number.toString(),
                    }))}
                    // required
                    error={rxerrors.doctorNumber}
                    // error={errors.fullName}
                  />
                  <Button
                    onClick={() => handleOpenAddEditDialog(selectedLANR, selectedBSNR)}
                    sx={{ padding: 0, width: "auto" }} // Adjust the width and other styles as needed
                  >
                    <AddIcon />
                    Arzt hinzufügen / bearbeiten
                  </Button>
                  {isAddEditDialogOpen && (
                    <DoctorDialog
                      openAddEditDialog={isAddEditDialogOpen}
                      handleCloseAddEditDialog={handleCloseAddEditDialog}
                      doctorOptions={doctorOptions}
                      selectedLANR={formValues.doctorNumber}
                      selectedBSNR={formValues.practiceNumber}
                    />
                  )}
                  <Controls.Select
                    name="doctor"
                    label="Arzt"
                    id={1}
                    value={selectedDoctor || formValues.doctorId}
                    onChange={handleDoctorChange}
                    options={doctorOptions?.map((doctor: any) => ({
                      label: doctor.contactInfo.lastName.toString(),
                      value: doctor.contactInfo.lastName.toString(),
                      id: doctor.id.toString(),
                    }))}
                    // required
                    error={rxerrors.doctor}
                  />
                  <Controls.Input
                    name="practiceNumber"
                    label="BSNR"
                    value={selectedBSNR || formValues?.practiceNumber}
                    onChange={handleInputChange}
                    // required
                    error={rxerrors.practiceNumber}
                  />
                  <Controls.RadioGroup
                    label={
                      <Typography align="left" variant="h6">
                        Arztunterschrift vorhanden
                      </Typography>
                    }
                    name="signatureStatus"
                    value={formValues?.signatureStatus}
                    options={[
                      {
                        id: "nicht kontrolliert",
                        label: "nicht kontrolliert",
                      },
                      { id: "vorhanden", label: "vorhanden" },
                      { id: "fehlend", label: "fehlend" },
                    ]}
                    onChange={handleInputChange}
                  />
                </FlatCard>

                <FlatCard title="Datum">
                  <Controls.DatePicker
                    name="date"
                    label="Rezeptdatum"
                    value={formValues?.date}
                    onChange={handleInputChange}
                    formatDate="dd.MM.yy"
                  />
                </FlatCard>
              </Grid>

              <Grid item xs={12} md={5}>
                <Grid container direction="column" spacing={2} style={{ alignContent: "center" }}>
                  <FlatCard title="Diagnose">
                    {icdCodeEntries.map((icdEntry: any, index: any) => (
                      <div key={`icd-${index}`}>
                        <Controls.Input
                          label={`ICD ${index + 1}`}
                          name={`icdCodeEntries-${index}`}
                          value={icdEntry.userText}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            console.log("Eventssaa", e, index);
                            handleInputChange(e, index, icdEntry?.id);
                          }}
                          onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                            console.log("KEY", e.key);
                            if (e.key === "Tab" || e.key === "Enter") {
                              e.preventDefault();
                              // handleICDCodeEntrySubmit(formValues.icdCodeEntries[index], index);
                              handleICDCodeEntrySubmit(icdEntry, index);
                            }
                          }}
                          // error={errors.email}
                        />
                      </div>
                    ))}
                    <Button onClick={handleAddInput} style={{ padding: 0 }}>
                      <AddIcon />
                      <Typography color="white">ICD Hinzufügen</Typography>
                    </Button>

                    <Controls.TextArea
                      name="diagnosisText"
                      rows={4}
                      placeholder="Textdiagnose"
                      value={formValues?.diagnosisText}
                      onChange={handleInputChange}
                    />
                  </FlatCard>
                  <FlatCard title="Leitsymptomatik">
                    <Grid item xs={6}>
                      <Controls.Select
                        name="diagnosegroup"
                        label="Diagnosegruppe"
                        // id={1}
                        value={formValues?.diagnosegroup}
                        onChange={handleInputChange}
                        options={filteredDiagnoseGroups?.map((option: any) => ({
                          label: option.code.toString(),
                          value: option.code.toString(),
                          id: option.id.toString(),
                        }))}
                        // error={errors.departmentId}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Controls.Checkbox
                        name="a"
                        label="a"
                        value={formValues?.a}
                        onChange={handleInputChange}
                        disabled={!selectedDiagnoseGroup?.a}
                      />
                      <Controls.Checkbox
                        name="b"
                        label="b"
                        value={formValues?.b}
                        onChange={handleInputChange}
                        disabled={!selectedDiagnoseGroup?.b}
                      />
                      <Controls.Checkbox
                        name="c"
                        label="c"
                        value={formValues?.c}
                        onChange={handleInputChange}
                        disabled={!selectedDiagnoseGroup?.c}
                      />
                      <Controls.Checkbox
                        name="d"
                        label="d"
                        value={formValues?.d}
                        onChange={handleInputChange}
                        disabled={!selectedDiagnoseGroup?.d}
                      />
                    </Grid>

                    <Controls.Checkbox
                      name="hasIndividualSymptoms"
                      label="Patientenindividuelle Leitsymptomatik"
                      value={formValues?.hasIndividualSymptoms}
                      onChange={handleInputChange}
                    />

                    <Controls.TextArea
                      name="symptoms"
                      rows={4}
                      placeholder="Patientenindividuelle Leitsymptomatik als
              Freitext angeben"
                      value={formValues?.symptoms}
                      onChange={handleInputChange}
                    />
                  </FlatCard>
                  <FlatCard title="Behandlungen">
                  <EditTable
                    initialColumns={treatmentColumns}
                    initialRows={therapytableData}
                    onTableDataChange={handletherapyTableDataChange}
                    isSingleRxPage={true}
                    masterTablesData={masterData}
                    // parseInput={parseInput}
                  />
                </FlatCard>
                  <FlatCard title="Anforderungen">
                  <Controls.RadioGroup
                    label=""
                    name="visitType"
                    value={formValues?.visitType}
                    options={[
                      { id: "Praxis", label: "Praxis" },
                      { id: "Haus", label: "Haus" },
                      { id: "Heim", label: "Heim" },
                    ]}
                    onChange={handleInputChange}
                  />
                  <Controls.Checkbox
                    name="problematic"
                    label="auf Kontrolle"
                    value={formValues?.problematic}
                    onChange={handleInputChange}
                  />
                  <Controls.Checkbox
                    name="changeRequested"
                    label="auf zum Ändern"
                    value={formValues?.changeRequested}
                    onChange={handleInputChange}
                  />
                  {/* <Controls.Checkbox
              name="booked"
              label="abgerechnet"
              value={formValues?.booked}
              onChange={handleInputChange}
            /> */}

                  <Controls.Checkbox
                    name="therapyReport"
                    label="Therapiebericht"
                    value={formValues?.therapyReport}
                    onChange={handleInputChange}
                  />
                  {/* <Controls.Checkbox
                name="homeVisit"
                label="Haus-/Heimbesuch"
                value={formValues?.homeVisit}
                onChange={handleInputChange}
              /> */}
                  {/* <Controls.Checkbox
                name="isHomeVisitFree"
                label="Heimbesuch zuzahlungsfrei"
                value={formValues?.isHomeVisitFree}
                onChange={handleInputChange}
              /> */}
                  <Controls.Checkbox
                    name="urgent"
                    label="Dringlicher Behandlungsbedarf"
                    value={formValues?.urgent}
                    onChange={handleInputChange}
                  />
                  {/* <Controls.Checkbox
                name="isAutoAddReport"
                label="Berichtsposition automatisch hinzufügen"
                value={formValues?.isAutoAddReport}
                onChange={handleInputChange}
              /> */}
                  <Controls.Checkbox
                    name="hygiene"
                    label="Hygieneposition automatisch hinzufügen"
                    value={formValues?.hygiene}
                    onChange={handleInputChange}
                  />
                  {/* </Box> */}
                  <Typography color="red" align="left">
                    ggf. Therapieziele/weitere med. Befunde und hinweise
                  </Typography>
                  <Controls.TextArea
                    name="additionalNotes"
                    rows={4}
                    placeholder=""
                    value={formValues?.additionalNotes}
                    onChange={handleInputChange}
                  />
                  </FlatCard>
                  {/* <FlatCard title="Notizen">
                  <Notes notes={rxNotes} setNotes={updateNote} maxVisibleNotes={rxNotes.length} />
                </FlatCard> */}

                </Grid>
                {!useDialog && (
                  <Box
                    sx={{
                      border: "10px",
                      display: "flex",
                      gap: "8px",
                      padding: "40px",
                    }}
                  >
                    {/* <Controls.Button type="submit" >Submit</Controls.Button>              
        <Controls.Button onClick={resetForm}>Reset</Controls.Button> */}
                    <Button type="submit" disabled={loading}>
                      Speichern
                    </Button>
                    {/* <Button
          // onClick={resetForm}
          >
            Reset
          </Button> */}
                  </Box>
                )}
              </Grid>
            </>
          )}
        </Grid>
        <Modal
          open={loading}
          aria-labelledby="loading-modal-title"
          aria-describedby="loading-modal-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <CircularProgress size={60} />
          </div>
        </Modal>
        {unsavedChangesWarning && (
          <WarningDialog
            open={true} // Ensure the warning dialog is open
            onClose={handleCloseUnsavedChangesWarning} // Function to close the dialog
            onContinue={handleUnsavedChangesWarning} // Function to continue despite unsaved changes
            title="Änderungen speichern?"
            content="Du hast einige Änderungen gemacht. Möchtest du diese speichern, bevor du schließt?"
            isConfirmSave
          />
        )}
      </Form>
    );
    return useObserver(() => {
      return useDialog && openRx ? (
        <Dialog open={openRx} onClose={handleCloseRx} maxWidth="xl" fullWidth>
          <DialogTitle>
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
              <>Rezept bearbeiten</>
              <Box display={"flex"} alignItems={"center"}>
                <Switch
                  checked={showGenericView}
                  onChange={handleToggle}
                  inputProps={{ "aria-label": "toggle generic/form view" }}
                />
                <Typography>{showGenericView ? "Musteransicht" : "Allgemeine Ansicht"}</Typography>
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent>{FormContent}</DialogContent>
          <DialogActions>
            <>
              <Button variant="text" onClick={handleCloseRx}>
                Abbrechen
              </Button>
              <Button
                onClick={ handleSubmit}
                disabled={loading}
                variant="contained"
              >
                Speichern
              </Button>
            </>
          </DialogActions>
        </Dialog>
      ) : (
        FormContent
      );
    });
  }
);
export default RxPage;
