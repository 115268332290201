import React from "react";
import { Timeline, TimelineEvent } from "react-event-timeline";
import { MobiliAvatar } from "../../../atoms/MobiliAvatar";
import { GetHistoryDto } from "../../../api-client";
import moment from "moment";
import useStore from "../../../helpers/useStore";
import { UserContext } from "../../../stores/User/User.provider";
import { toJS } from "mobx";

interface PatientHistoryTimelineRowProps {
  history: GetHistoryDto;
}
export default function PatientHistoryTimelineRow({ history }: PatientHistoryTimelineRowProps) {
  const UserStore = useStore(UserContext);
  const { user, getRoles, listPermissions, apiInfo, permissionMap } = UserStore;

  const viewHistoryUserPermission = permissionMap.has("view-history-user");
  return (
    <Timeline>
      <TimelineEvent
        title={history.translations.action || history.action}
        subtitle={history.label}
        createdAt={moment(history.timestamp).format("DD.MM.YYYY HH:mm:ss")}
        icon={
          <div>
            {viewHistoryUserPermission || user?.id == history.userId ? (
              <MobiliAvatar user={history.user} />
            ) : (
              <MobiliAvatar />
            )}
          </div>
        }
      >
        {history.message}
      </TimelineEvent>
    </Timeline>
  );
}
