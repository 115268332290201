import { RxDtoProcessingStatusEnum } from "../../../../api-client";
import { RxStatusColors } from "../../../../themes/mobiliTheme";

export const getStatusColor = (status: RxDtoProcessingStatusEnum) => {
    switch (status) {
      case RxDtoProcessingStatusEnum.InBehandlung:
        return RxStatusColors.BEING_TREATED;
      case RxDtoProcessingStatusEnum.Abgeschlossen:
        return RxStatusColors.FINISHED;
      case RxDtoProcessingStatusEnum.Abgerechnet:
        return RxStatusColors.BOOKED;
      case RxDtoProcessingStatusEnum.Abgebrochen:
        return RxStatusColors.INTERRUPTED;
      case RxDtoProcessingStatusEnum.ZurKontrolle:
        return RxStatusColors.HAS_ISSUES;
    }
  };