/* eslint-disable no-template-curly-in-string */
import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Badge,
  Card,
  CardContent,
  Chip,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import useStore from "../../helpers/useStore";
import { differenceInMinutes, differenceInDays, format } from "date-fns";

import {generateColor} from "../../helpers/generateColor";
// import Overview from "../Overview";

import { PatientsContext } from "../../stores/Patients/patients.provider";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import {
  CalendarToday,
  PanToolAlt,
  Smartphone,
} from "@mui/icons-material";
import { attentionColors, mobiliTheme } from "../../themes/mobiliTheme";
import { visitTypeMapping } from "../../helpers/visitTypeMapping";
import moment from "moment";
import { AppointmentContext } from "../../stores/Appointment/appointment.provider";
import PatientLayout from "../PatientLayout/PatientLayout";
import { TherapyChip } from "../../atoms/Chip";
import { MobiliAvatar } from "../../atoms/MobiliAvatar";
import { UserDto } from "../../api-client";

interface CancelledAppointmentProps {
  cancelledAppointments: any;
}

const callStatusOptions = [
  { label: "Nicht benötigt", status: "nicht benötigt" },
  { label: "Benötigt", status: "benötigt" },
  { label: "Versucht", status: "versucht" },
  { label: "Erledigt", status: "erledigt" },
];

const rxNumberColorMap: { [key: string]: string } = {};

const getColorForRxNumber = (rxNumber: string) => {
  // Check if the color for this Rx number is already assigned
  if (rxNumberColorMap[rxNumber]) {
    return rxNumberColorMap[rxNumber];
  }

  // If not assigned, generate a new color using the generateColor function
  const newColor = generateColor(rxNumber);
  rxNumberColorMap[rxNumber] = newColor;
  console.log("NEWCOLO", newColor);
  return newColor;
};

const TherapyCard = ({ timeSlot }: { timeSlot: any }) => {
  // Get the color based on the Rx number
  const rxNumber = timeSlot?.therapyRx?.rx?.rxNumber || "";
  const visitType = visitTypeMapping[timeSlot?.therapyRx?.rx?.visitType];
  const rxNumberColor = getColorForRxNumber(rxNumber);
  return (
    <Card
      sx={{
        width: "200px",
        height: "21px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingTop: "20px",
          width: "100%",
        }}
      >
        <Typography
          variant="caption"
          sx={{
            textAlign: "left",
            padding: "4px",
            color: rxNumberColor,
            fontWeight: "bold", // Set the font weight to bold
            fontSize: "17px", // Set the font size (you can adjust this value)
          }}
        >
          {timeSlot?.therapyRx?.rx?.rxNumber}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            textAlign: "left",
            paddingTop: "2px",
            flex: 1,
            fontWeight: "bold",
            fontSize: "13px",
          }}
        >
          {visitType ? `(${visitType})` : " "} {timeSlot?.therapyRx?.therapy?.abbreviation}
        </Typography>
        {timeSlot?.user?.id && (
          <Avatar
            sx={{
              width: 30,
              height: 20,
              backgroundColor: "secondary.main", // Replace with your desired background color
              padding: "3px",
              fontSize: "14px",
            }}
          >
            {timeSlot?.user?.nickName}
          </Avatar>
        )}
      </CardContent>
    </Card>
    // </Tooltip>
  );
};
const differenceInWeeks = (originalDate: Date, appointmentStart: Date | null) => {
  const cancelledAppointmentDate = new Date(originalDate);
  const appointmentStartDate = appointmentStart ? new Date(appointmentStart) : null;

  let daysDifference = 0;

  if (cancelledAppointmentDate && appointmentStartDate) {
    daysDifference = differenceInDays(cancelledAppointmentDate, appointmentStartDate);
  }

  return { weeks: daysDifference / 7, days: daysDifference }; // Return as a floating-point number
};
// const getEndForRxNumber = (
//  gapInDays
// ) => {
//   const appointment = scheduledAppointments?.find((item: any) => {
//     return (
//       item.rxNumber === rxNumber &&
//       item.start &&
//       item.start < cancelledAppointment
//     );
//   });

//   let maxDifferenceInWeeks;

//   if (appointment) {
//     maxDifferenceInWeeks = differenceInWeeks(
//       cancelledAppointment,
//       appointment?.start
//     );

//     console.log("APPOINTMENTdfsffd", appointment,cancelledAppointment,maxDifferenceInWeeks);
//   }
//   //To check with David if that rxNumber doensot have any previous appointment scheduled
//   return maxDifferenceInWeeks || {weeks:0,days:0};
// };

const backgroundColourCheck = (differenceInWeeksValue: number) => {
  let backgroundColor = "";
  if (differenceInWeeksValue <= 1) {
    backgroundColor = attentionColors.customInfoPrimary;
  } else if (differenceInWeeksValue <= 2) {
    backgroundColor = attentionColors.lowPriority; // Add your color for <= 2 weeks
  } else if (differenceInWeeksValue <= 3) {
    backgroundColor = attentionColors.highPriority; // Add your color for <= 3 weeks
  } else {
    backgroundColor = attentionColors.immediateAttention; // Add your color for > 3 weeks
  }
  console.log("backgroundColourCheck", backgroundColor, differenceInWeeksValue);
  return backgroundColor;
};

const AppointmentView = ({
  appointment,
  cancelledAppointment,
}: // countColor
{
  appointment: any;
  cancelledAppointment: any;
  firstCancelled?: any;
  lastCancelled?: any;
  // countColor?: string;
}) => {
  const { timeSlots } = appointment.appointment;

  const backgroundColor = backgroundColourCheck(appointment.gapInDays / 7);

  // Calculate the height based on the number of therapyRx items
  const cardHeight = `${timeSlots?.length * 30 + 20}px`; // Adjust the height calculation as needed

  return (
    <div style={{ position: "relative" }}>
      {/* { additionalAppointmentsCount && ( */}
      <>
        {/* Left Badge */}
        {appointment?.firstLastCancelled?.firstMatchesCancelled && (
          <Badge
            badgeContent={"E"}
            color="primary"
            sx={{
              position: "absolute",
              top: "50%",
              left: "-20%",

              transform: "translate(-100%, -50%)",
            }}
          />
        )}

        {/* Right Badge */}
        {appointment?.firstLastCancelled?.lastMatchesCancelled && (
          <Badge
            badgeContent={"L"}
            color="primary"
            sx={{
              position: "absolute",
              top: "50%",
              right: "1%",

              transform: "translate(-100%, -50%)",
            }}
          />
        )}
      </>
      {/* )} */}

      {/* Avatar */}
      <Avatar
        sx={{ backgroundColor: backgroundColor, width: "30px", height: "30px", fontSize: "12px" }}
      >
        {appointment.gapInDays ? appointment.gapInDays : 0}
      </Avatar>
    </div>
  );
};
// const AppointmentCard= ({
//   appointment,
//   scheduledAppointments,
//   cancelledAppointment,
//   additionalAppointmentsCount
//   // countColor
// }: {
//   appointment: any;
//   scheduledAppointments: any;
//   cancelledAppointment: any;
//   additionalAppointmentsCount?: number;
//   // countColor?: string;
// }) => {
//   const { timeSlots } = appointment.appointment;

//   // // Determine the background color based on the difference in weeks
//   // const differenceInWeeksValue = getEndForRxNumber(
//   //   appointment.appointment.timeSlots[0].therapyRx?.rx?.rxNumber,
//   //   scheduledAppointments,
//   //   cancelledAppointment
//   // );

// const backgroundColor = backgroundColourCheck(differenceInWeeksValue.weeks);

//   // Calculate the height based on the number of therapyRx items
//   const cardHeight = `${timeSlots?.length * 30 + 20}px`; // Adjust the height calculation as needed

//   return (
//     <div style={{ position: 'relative' }}>
//       {additionalAppointmentsCount && additionalAppointmentsCount>1 &&
//     <Badge badgeContent={additionalAppointmentsCount} color="primary" style={{ position: 'absolute', top: 0, left: 0 }}/>}

//       <Card
//         sx={{

//           height: cardHeight,
//           backgroundColor: backgroundColor,
//           borderRadius: "10px !important",
//           position: "relative", // Add relative positioning to the card
//         }}
//       >
//         <CardContent>
//           <Grid container spacing={1}>
//             {timeSlots?.map((timeSlot: any, index: any) => (
//               <Grid item xs={12} key={index}>
//                 <TherapyCard timeSlot={timeSlot} />
//               </Grid>
//             ))}
//           </Grid>
//         </CardContent>

//         <Divider
//           sx={{
//             backgroundColor: "dark",
//             height: "2px",
//             marginTop: "8px",
//             marginBottom: "8px",
//           }}
//         />
//       </Card>
//       <div style={{ position: 'relative' }}>
//       {/* { additionalAppointmentsCount && ( */}
//         <>
//     {/* Left Badge */}{appointment?.firstLastCancelled?.firstMatchesCancelled &&
//     <Badge badgeContent={"E"} color="primary" sx={{ position: 'absolute',  top: '50%',
//     left:"-5%",

//               transform: 'translate(-100%, -50%)', }}/>}

//     {/* Right Badge */}
//     {appointment?.firstLastCancelled?.lastMatchesCancelled &&
//     <Badge badgeContent={"L"} color="primary" sx={{ position: 'absolute', top: '50%',right:"50%",

//              transform: 'translate(-100%, -50%)' }}/>}
//     </>
//     {/* )} */}

//     {/* Avatar */}
//     <Avatar sx={{ backgroundColor: backgroundColor,width:"30px",height:"30px",fontSize:"12px" }}>
//     {  differenceInWeeksValue.days?
//   differenceInWeeksValue.days
//   : <React.Fragment></React.Fragment>}
//     </Avatar>

//   </div>
//     </div>
//   );
// };
// const getMaxAdditionalAppointments = async (additionalAppointments:any, scheduledAppointments:any, cancelledAppointment:any) => {
//   let maxDifferenceColor:any; // Default color if no additional appointments

//   await Promise.all(additionalAppointments.map(async (additionalAppointment:any) => {
//     const differenceInWeeksValue = await getEndForRxNumber(
//       additionalAppointment?.appointments?.timeSlots[0]?.therapyRx?.rx?.rxNumber,
//       scheduledAppointments,
//       cancelledAppointment.originalDate
//     );

//     maxDifferenceColor = backgroundColourCheck(differenceInWeeksValue);
//   }));

//   console.log('MANSJDSJ', maxDifferenceColor);
//   return maxDifferenceColor;
// };

const UnScheduled = observer(({ cancelledAppointments }: CancelledAppointmentProps) => {
  const navigate = useNavigate(); // Hook to access the navigate function
  // const [open, setOpen] = useState(false);
  const [patientData, setPatientData] = useState(0);
  const PatientStore = useStore(PatientsContext);
  const appointmentStore = useStore(AppointmentContext);
  const { updateCancelledAppointment } = appointmentStore;
  const {
    setOpen,
    isOpen,
    setFilteredPatient,
    setSelectedValue,
    filteredPatient,
    getSelectedPatientparams,
    constructSearchPath,
  } = PatientStore;
  const [callStatusMap, setCallStatusMap] = useState<{ [key: string]: string }>({});
  const [selectedAppointmentId, setSelectedAppointmentId] = useState("");

  const [openTooltipId, setOpenTooltipId] = useState(null);
  // State to manage popover open/close for each chip
  const [anchorEls, setAnchorEls] = useState<{ [key: string]: HTMLElement | null }>({});

  const handleChipClick = (id: any, event: React.MouseEvent<HTMLElement>) => {
    setOpenTooltipId(openTooltipId === id ? null : id);
    setAnchorEls({ ...anchorEls, [id]: event.currentTarget });
  };

  const handlePopoverClose = () => {
    setAnchorEls({});
  };
  const open = (id: string) => Boolean(anchorEls[id]);
  const id = (id: string) => (open(id) ? `popover-${id}` : undefined);

  // Update call status map when cancelledAppointments prop changes
  useEffect(() => {
    const updatedCallStatusMap: any = {};
    cancelledAppointments?.cancelledAppointments?.forEach((cancelledAppointment: any) => {
      if (!cancelledAppointment.callStatus) {
        updatedCallStatusMap[cancelledAppointment.id] = "nicht benötigt"; // Default status if not set
      } else {
        updatedCallStatusMap[cancelledAppointment.id] = cancelledAppointment.callStatus;
      }
    });
    setCallStatusMap(updatedCallStatusMap);
  }, []);

  const handlePatientData = (patient: any) => {
    setOpen(true);
    setSelectedValue(
      `${patient.lastName},${patient.firstName} (${moment(patient.dob).format("DD.MM.yyyy")})-${
        patient.id
      }`
    );
    setFilteredPatient(patient.id);
    // setMasterPatientData(patient);
    setPatientData(patient);
    const params = getSelectedPatientparams(patient.id);
    let url;
    if (params) {
      url = constructSearchPath({ ...params, selectedSubTab: 2 });
    } else {
      url = constructSearchPath({ pId: patient.id, selectedSubTab: 2 });
    }

    navigate(url);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any, id: string) => {
    setAnchorEl(event.currentTarget);
    setSelectedAppointmentId(id); // Store the ID of the selected appointment
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Update call status for a specific cancelled appointment
  const handleCallStatusSelect = async (status: string) => {
    try {
      await updateCancelledAppointment(selectedAppointmentId, {
        callStatus: status,
      });
      setCallStatusMap((prevState) => ({
        ...prevState,
        [selectedAppointmentId]: status,
      }));
      handleClose();
    } catch (error) {
      // Handle error
      console.error("Error updating cancelled appointment:", error);
    }
  };

  const handlePlanning = (patientId: string) => {
    navigate("/termin?pid=" + patientId);
  };

  useEffect(() => {
    const selectedPatientParams = getSelectedPatientparams(filteredPatient);
    if (selectedPatientParams && isOpen) {
      const urlPath = constructSearchPath(selectedPatientParams);
      navigate(urlPath);
    } else if (isOpen) {
      const urlPath = constructSearchPath({ pId: filteredPatient });
      navigate(urlPath);
    }
  }, [isOpen, navigate]);
  const [isHovered, setIsHovered] = useState(false);
  const renderAppointmentTable = () => {
    const uniquePatients: any = {};
    const calculateColor = (originalDate: any) => {
      const differenceMinutes = differenceInMinutes(new Date(originalDate), new Date());
      if (differenceMinutes <= 0) {
        return attentionColors.immediateAttention;
      } else if (differenceMinutes <= 120) {
        return attentionColors.highPriority;
      } else if (differenceMinutes <= 240) {
        return attentionColors.mediumPriority;
      } else if (differenceMinutes <= 1440) {
        return attentionColors.lowPriority;
      } else {
        return attentionColors.customInfoPrimary;
      }
    };

    const callStatusOptions = [
      { label: "Nicht benötigt", status: "nicht benötigt" },
      { label: "Benötigt", status: "benötigt" },
      { label: "Versucht", status: "versucht" },
      { label: "Erledigt", status: "erledigt" },
    ];
    // Get status color based on the callStatusMap
    const getStatusColor = (status: string, id?: string) => {
      const callStatus = id ? callStatusMap[id] : status;
      switch (callStatus) {
        case "nicht benötigt":
          return mobiliTheme.palette.primary.main;
        case "benötigt":
          return mobiliTheme.palette.secondary.main;
        case "versucht":
          return mobiliTheme.palette.warning.main;
        case "erledigt":
          return mobiliTheme.palette.success.main;
        default:
          return "";
      }
    };
    // Define a function to generate a consistent color for a given string
    const stringToColor = (str: string) => {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      const c = (hash & 0x00ffffff).toString(16).toUpperCase();
      const hexColor = "#" + "00000".substring(0, 6 - c.length) + c;

      // Convert hex color to RGB
      const hexToRgb = (hex: any) => hex.match(/\w\w/g).map((str: any) => parseInt(str, 16));
      const [r, g, b] = hexToRgb(hexColor);

      // Adjust brightness to generate a lighter shade
      const factor = 0.7; // Adjust this factor to control lightness, 1 for original color
      const lightR = Math.min(255, Math.round(r + (255 - r) * factor));
      const lightG = Math.min(255, Math.round(g + (255 - g) * factor));
      const lightB = Math.min(255, Math.round(b + (255 - b) * factor));

      // Convert RGB back to hex
      const rgbToHex = (r: any, g: any, b: any) =>
        "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
      return rgbToHex(lightR, lightG, lightB);
    };

    return (
      <TableContainer sx={{ maxHeight: "500px", minHeight: "200px", backgroundColor: mobiliTheme.cardBackground }}>
        <Table sx={{ tableLayout: "fixed" }}>
          <TableHead
            sx={{
              position: "sticky",
              zIndex: "1",
              top: 0,
              backgroundColor: mobiliTheme.cardBackground
            }}
          >
            <TableRow sx={{backgroundColor: mobiliTheme.cardBackground}}>
              <TableCell sx={{ width: "3%", padding: "1px" ,backgroundColor: mobiliTheme.cardBackground}}>Patientenname</TableCell>
              <TableCell sx={{ width: "2%", padding: "1px" }}>Datum</TableCell>
              <TableCell sx={{ width: "2%", padding: "1px" }}>Time</TableCell>
              <TableCell sx={{ width: "2%", padding: "2px" }}>Heilmittel</TableCell>
              <TableCell sx={{ width: "1%", padding: "2px" }}>Visit</TableCell>
              <TableCell sx={{ width: "2.5%", padding: "1px" }}>PT</TableCell>
              <TableCell sx={{ width: "1%", padding: "2px" }}>Gap | E-L</TableCell>
              <TableCell sx={{ width: "1%", padding: "2px" }}>Status-Aufruf</TableCell>
              <TableCell sx={{ width: "3%", padding: "5px" }}>Aktionen</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ overflowY: "auto" }}>
            {cancelledAppointments?.cancelledAppointments?.map((cancelledAppointment: any) => {
              const patientId: string = cancelledAppointment.patient.id;
              // Filter cancelled appointments for the current patient
              const patientCancelledAppointments =
                cancelledAppointments.cancelledAppointments?.filter(
                  (a: any) => a.patient.id === patientId
                );
              const additionalAppointments = cancelledAppointments.cancelledAppointments?.filter(
                (a: any) => a.patient.id === patientId && a.id !== cancelledAppointment.id
              );
              // Sort the filtered appointments by original date
              patientCancelledAppointments.sort((a: any, b: any) => {
                return new Date(a.originalDate).getTime() - new Date(b.originalDate).getTime();
              });

              // Check if the selected appointment is the first or last cancelled
              const isFirstCancelled =
                patientCancelledAppointments[0].id === cancelledAppointment.id;
              const isLastCancelled =
                patientCancelledAppointments[patientCancelledAppointments.length - 1].id ===
                cancelledAppointment.id;

              if (!uniquePatients[patientId]) {
                uniquePatients[patientId] = true;
                console.log(
                  "cancelledAppointmentretett",

                  cancelledAppointment.id
                );
                return (
                  <React.Fragment key={cancelledAppointment.id}>
                    <TableRow>
                      {/* PATIENT CELL */}
                      <TableCell sx={{ width: "3%", padding: "1px", position: "relative" }}>
                        <Badge
                          badgeContent={additionalAppointments.length}
                          color="primary"
                          sx={{ position: "absolute", top: "10px", right: "10px" }}
                        />
                        {/* <div style={{ position: 'relative' }}> */}

                        {/* PATIENT NAME CHIP */}
                        <Chip
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            position: "relative", // Ensure the Chip is positioned relative
                          }}
                          onClick={(event) => handleChipClick(cancelledAppointment.id, event)}
                          label={
                            <Typography
                              sx={{
                                wordWrap: "break-word",
                                fontSize: "12px",
                              }}
                            >
                              {`${cancelledAppointment.patient.firstName} ${cancelledAppointment.patient.lastName}`}
                            </Typography>
                          }
                        />
                        {/* </div> */}
                        {openTooltipId === cancelledAppointment.id && (
                          <>
                            {openTooltipId === cancelledAppointment.id &&
                              additionalAppointments.length > 0 && (
                                <Popover
                                  id={id(cancelledAppointment.id)}
                                  open={
                                    open(cancelledAppointment.id) &&
                                    openTooltipId === cancelledAppointment.id &&
                                    additionalAppointments.length > 0
                                  }
                                  anchorEl={anchorEls[cancelledAppointment.id]}
                                  onClose={handlePopoverClose}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                  }}
                                  slotProps={{
                                    paper: {
                                      style: {
                                        width: "31%",
                                        border: "5px solid #ccc",
                                        borderRadius: "10px",
                                      },
                                    },
                                  }}
                                >
                                  {cancelledAppointments.cancelledAppointments
                                    ?.filter(
                                      (a: any) =>
                                        a.patient.id === patientId &&
                                        a.id !== cancelledAppointment.id
                                    )
                                    .map((additionalAppointment: any, index: any) => (
                                      <TableRow
                                        key={additionalAppointment.id}
                                        sx={{
                                          background: "white",
                                          borderRadius: "10px",
                                          border: "9px solid #ccc",
                                        }}
                                      >
                                        <TableCell
                                          sx={{
                                            color: calculateColor(
                                              additionalAppointment.originalDate
                                            ),
                                            fontWeight: "bold",
                                            width: "2%",
                                            padding: "1px",
                                          }}
                                        >
                                          <Tooltip
                                            title={`${format(
                                              new Date(additionalAppointment.originalDate),
                                              "dd.MM.yyyy"
                                            )}  ${format(
                                              new Date(additionalAppointment.originalDate),
                                              "HH:MM:SS"
                                            )}`}
                                          >
                                            <Chip
                                              sx={{
                                                height: "auto",
                                                // "& .MuiChip-label": {
                                                display: "block",
                                                whiteSpace: "normal",
                                                width: "auto",
                                                cursor: "pointer",
                                                color: calculateColor(
                                                  additionalAppointment.originalDate
                                                ),
                                                fontWeight: "bold",
                                                // },
                                              }}
                                              label={format(
                                                new Date(additionalAppointment.originalDate),
                                                "dd.MM"
                                              )}
                                            />
                                          </Tooltip>
                                        </TableCell>
                                        {/* Time */}
                                        <TableCell
                                          sx={{
                                            color: calculateColor(
                                              additionalAppointment.originalDate
                                            ),
                                            fontWeight: "bold",
                                            width: "2%",
                                            padding: "1px",
                                          }}
                                        >
                                          <Tooltip
                                            title={`${format(
                                              new Date(additionalAppointment.originalDate),
                                              "dd.MM.yyyy"
                                            )}  ${format(
                                              new Date(additionalAppointment.originalDate),
                                              "HH:MM:SS"
                                            )}`}
                                          >
                                            <Chip
                                              sx={{
                                                height: "auto",
                                                // "& .MuiChip-label": {
                                                display: "block",
                                                whiteSpace: "normal",
                                                width: "auto",
                                                cursor: "pointer",
                                                color: calculateColor(
                                                  additionalAppointment.originalDate
                                                ),
                                                fontWeight: "bold",
                                                // },
                                              }}
                                              label={format(
                                                new Date(additionalAppointment.originalDate),
                                                "HH:MM"
                                              )}
                                            />
                                          </Tooltip>
                                        </TableCell>
                                        {/* Therapy */}

                                        <TableCell sx={{ width: "5%", padding: "2px" }}>
                                          <Tooltip
                                            title={additionalAppointment.appointment?.timeSlots
                                              .map((timeSlot: any) =>
                                                timeSlot.therapyRx.therapy.id.concat(
                                                  "   ",
                                                  timeSlot.therapyRx.rx.rxNumber
                                                )
                                              )
                                              .join(", ")}
                                          >
                                            <div
                                              style={{
                                                display: "grid",
                                                gridTemplateColumns:
                                                  "repeat(auto-fill, minmax(40px, 1fr))",
                                                gap: "0px",
                                                flexWrap: "wrap",
                                              }}
                                            >
                                              {additionalAppointment.appointment?.timeSlots.map(
                                                (timeSlot: any, index: any) => {
                                                  const therapyColors: { [key: string]: string } =
                                                    {};
                                                  const therapyId = timeSlot.therapyRx.therapy.id;
                                                  // Generate or retrieve color for this therapy ID
                                                  const backgroundColor =
                                                    therapyColors[therapyId] ||
                                                    (therapyColors[therapyId] =
                                                      stringToColor(therapyId));
                                                  return (
                                                    <Chip
                                                      key={timeSlot.id}
                                                      sx={{
                                                        gridColumn: index % 2 === 0 ? "1" : "2",
                                                        gridRow: index < 2 ? "1" : "2",
                                                        fontSize: "10px",
                                                        backgroundColor: backgroundColor, // Assigning color based on therapy ID
                                                      }}
                                                      label={therapyId}
                                                    />
                                                  );
                                                }
                                              )}
                                            </div>
                                          </Tooltip>
                                        </TableCell>
                                        <TableCell sx={{ width: "2%", padding: "1px" }}>
                                          <Tooltip
                                            // title={cancelledAppointment.appointment?.timeSlots
                                            //   .map(
                                            //     (timeSlot: any) =>
                                            //       timeSlot.therapyRx?.rx?.visitType ||
                                            //       "Praxis"
                                            //   )
                                            //   .join(", ")}
                                            title={
                                              additionalAppointment.appointment?.timeSlots[0]
                                                ?.therapyRx?.rx?.visitType || "Praxis"
                                            }
                                          >
                                            <div
                                              style={{
                                                display: "grid",
                                                gridTemplateColumns:
                                                  "repeat(auto-fill, minmax(40px, 1fr))",
                                                gap: "0px",
                                                flexWrap: "wrap",
                                              }}
                                            >
                                              <Chip
                                                // key={timeSlot.id}
                                                sx={{
                                                  fontSize: "10px",
                                                }}
                                                label={
                                                  visitTypeMapping[
                                                    additionalAppointment.appointment?.timeSlots[0]
                                                      ?.therapyRx?.rx?.visitType
                                                  ] || "PX"
                                                }
                                              />

                                              {/* {cancelledAppointment.appointment?.timeSlots.map(
                                  (timeSlot: any, index: any) => (
                                    <Chip
                                      key={timeSlot.id}
                                      style={{
                                        gridColumn: index % 2 === 0 ? "1" : "2",
                                        gridRow: index < 2 ? "1" : "2",
                                        fontSize: "10px",
                                      }}
                                      label={
                                        visitTypeMapping[
                                          timeSlot?.therapyRx?.rx?.visitType
                                        ] || "PX"
                                      }
                                    />
                                  )
                                )} */}
                                            </div>
                                          </Tooltip>
                                        </TableCell>
                                        {/* User */}
                                        <TableCell sx={{ width: "6%", padding: "1px" }}>
                                          <Tooltip
                                            title={additionalAppointment?.users
                                              .map((user: any) =>
                                                user
                                                  ? user?.firstName.concat("   ", user?.lastName)
                                                  : "-"
                                              )
                                              .join(", ")}
                                          >
                                            <div
                                              style={{
                                                display: "grid",
                                                gridTemplateColumns:
                                                  "repeat(auto-fill, minmax(40px, 1fr))",
                                                gap: "0px",
                                                flexWrap: "wrap",
                                              }}
                                            >
                                              {additionalAppointment?.users.map(
                                                (user: any, index: any) => {
                                                  const userColors: { [key: string]: string } = {};
                                                  const userId = user
                                                    ? user?.nickName ||
                                                      user?.firstName.charAt(0) +
                                                        "" +
                                                        user?.lastName.charAt(0)
                                                    : "-";
                                                  // Generate or retrieve color for this therapy ID
                                                  const backgroundColor =
                                                    userColors[userId] ||
                                                    (userColors[userId] = stringToColor(userId));
                                                  return (
                                                    <Chip
                                                      key={user.id}
                                                      sx={{
                                                        gridColumn: index % 2 === 0 ? "1" : "2",
                                                        gridRow: index < 2 ? "1" : "2",
                                                        fontSize: "10px",
                                                        backgroundColor: backgroundColor, // Assigning color based on therapy ID
                                                      }}
                                                      label={userId ? userId : "-"}
                                                    />
                                                  );
                                                }
                                              )}
                                            </div>
                                          </Tooltip>
                                        </TableCell>
                                        <TableCell sx={{ width: "1%", padding: "18px" }}>
                                          <div
                                            onMouseOver={() => setIsHovered(true)}
                                            onMouseLeave={() => setIsHovered(false)}
                                          >
                                            <AppointmentView
                                              appointment={additionalAppointment}
                                              cancelledAppointment={
                                                additionalAppointment.originalDate
                                              }
                                            />
                                          </div>
                                        </TableCell>
                                        {/* Status */}
                                        <TableCell sx={{ width: "1%", padding: "2px" }}>
                                          <Tooltip
                                            title={
                                              <Typography>
                                                Status:{" "}
                                                {additionalAppointment.callStatus ||
                                                  "nicht benötigt"}
                                                <br />
                                                Mobil: {additionalAppointment.patient.mobile}
                                                <br />
                                                Telefon1:{" "}
                                                {additionalAppointment.patient.phone1 || "-"}
                                                <br />
                                                Telefon2:{" "}
                                                {additionalAppointment.patient.phone2 || "-"}
                                                <br />
                                              </Typography>
                                            }
                                          >
                                            <IconButton
                                              onClick={(event) =>
                                                handleClick(event, additionalAppointment.id)
                                              }
                                            >
                                              <Smartphone
                                                sx={{
                                                  color: getStatusColor(
                                                    additionalAppointment.callStatus ||
                                                      "nicht benötigt",
                                                    additionalAppointment.id
                                                  ),
                                                }}
                                              />
                                            </IconButton>
                                          </Tooltip>

                                          <Menu
                                            anchorEl={anchorEl}
                                            open={Boolean(anchorEl)}
                                            onClose={handleClose}
                                          >
                                            {callStatusOptions.map((option) => (
                                              <MenuItem
                                                key={option.status}
                                                onClick={() =>
                                                  handleCallStatusSelect(option.status)
                                                }
                                                sx={{ color: getStatusColor(option.status) }}
                                              >
                                                {option.label}
                                              </MenuItem>
                                            ))}
                                          </Menu>
                                        </TableCell>
                                        <TableCell sx={{ width: "7%", padding: "2px" }}></TableCell>
                                      </TableRow>
                                    ))}
                                </Popover>
                              )}
                          </>
                        )}
                      </TableCell>

                      {/* DATE CELL */}
                      <TableCell
                        sx={{
                          color: calculateColor(cancelledAppointment.originalDate),
                          fontWeight: "bold",
                          width: "2%",
                          padding: "1px",
                        }}
                      >
                        <Tooltip
                          title={`${format(
                            new Date(cancelledAppointment.originalDate),
                            "dd.MM.yyyy"
                          )}  ${format(new Date(cancelledAppointment.originalDate), "HH:MM:SS")}`}
                        >
                          <Chip
                            sx={{
                              height: "auto",
                              // "& .MuiChip-label": {
                              display: "block",
                              whiteSpace: "normal",
                              width: "auto",
                              color: calculateColor(cancelledAppointment.originalDate),
                              fontWeight: "bold",
                              // },
                            }}
                            label={format(new Date(cancelledAppointment.originalDate), "dd.MM")}
                          />
                        </Tooltip>
                      </TableCell>

                      {/* TIME CELL */}
                      <TableCell
                        sx={{
                          color: calculateColor(cancelledAppointment.originalDate),
                          fontWeight: "bold",
                          width: "2%",
                          padding: "1px",
                        }}
                      >
                        <Tooltip
                          title={`${format(
                            new Date(cancelledAppointment.originalDate),
                            "dd.MM.yyyy"
                          )}  ${format(new Date(cancelledAppointment.originalDate), "HH:MM:SS")}`}
                        >
                          <Chip
                            sx={{
                              height: "auto",
                              // "& .MuiChip-label": {
                              display: "block",
                              whiteSpace: "normal",
                              width: "auto",
                              color: calculateColor(cancelledAppointment.originalDate),
                              fontWeight: "bold",
                              // },
                            }}
                            label={format(new Date(cancelledAppointment.originalDate), "HH:MM")}
                          />
                        </Tooltip>
                      </TableCell>

                      {/* THERAPY CELL */}
                      <TableCell sx={{ width: "2%", padding: "2px" }}>
                        <Tooltip
                          title={cancelledAppointment.appointment?.timeSlots
                            .map((timeSlot: any) =>
                              timeSlot.therapyRx.therapy.id.concat(
                                "   ",
                                timeSlot.therapyRx.rx.rxNumber
                              )
                            )
                            .join(", ")}
                        >
                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns: "repeat(auto-fill, minmax(40px, 1fr))",
                              gap: "0px",
                              flexWrap: "wrap",
                            }}
                          >
                            {cancelledAppointment.appointment?.timeSlots.map(
                              (timeSlot: any, index: any) => {
                                const therapyName = timeSlot.therapyRx.therapy.abbreviation;
                                // Generate or retrieve color for this therapy ID
                                return (
                                  <TherapyChip
                                    therapyName={therapyName}
                                    key={timeSlot.id}
                                    sx={{
                                      gridColumn: index % 2 === 0 ? "1" : "2",
                                      gridRow: index < 2 ? "1" : "2",
                                      fontSize: "10px",
                                    }}
                                  />
                                );
                              }
                            )}
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell sx={{ width: "1%", padding: "1px" }}>
                        <Tooltip
                          // title={cancelledAppointment.appointment?.timeSlots
                          //   .map(
                          //     (timeSlot: any) =>
                          //       timeSlot.therapyRx?.rx?.visitType ||
                          //       "Praxis"
                          //   )
                          //   .join(", ")}
                          title={
                            cancelledAppointment.appointment?.timeSlots[0]?.therapyRx?.rx
                              ?.visitType || "Praxis"
                          }
                        >
                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns: "repeat(auto-fill, minmax(40px, 1fr))",
                              gap: "0px",
                              flexWrap: "wrap",
                            }}
                          >
                            <Chip
                              // key={timeSlot.id}
                              sx={{
                                fontSize: "10px",
                              }}
                              label={
                                visitTypeMapping[
                                  cancelledAppointment.appointment?.timeSlots[0]?.therapyRx?.rx
                                    ?.visitType
                                ] || "PX"
                              }
                            />

                            {/* {cancelledAppointment.appointment?.timeSlots.map(
                                  (timeSlot: any, index: any) => (
                                    <Chip
                                      key={timeSlot.id}
                                      style={{
                                        gridColumn: index % 2 === 0 ? "1" : "2",
                                        gridRow: index < 2 ? "1" : "2",
                                        fontSize: "10px",
                                      }}
                                      label={
                                        visitTypeMapping[
                                          timeSlot?.therapyRx?.rx?.visitType
                                        ] || "PX"
                                      }
                                    />
                                  )
                                )} */}
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell sx={{ width: "2%", padding: "1px" }}>
                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns: "repeat(auto-fill, minmax(40px, 1fr))",
                              gap: "0px",
                              flexWrap: "wrap",
                            }}
                          >
                            {cancelledAppointment?.users.map((user: UserDto, index: any) => {
                              // const userColors: { [key: string]: string } = {};
                              // const userId = user
                              //   ? user?.nickName ||
                              //     user?.firstName.charAt(0) + "" + user?.lastName.charAt(0)
                              //   : "-";
                              // // Generate or retrieve color for this therapy ID
                              // const backgroundColor =
                              //   userColors[userId] || (userColors[userId] = stringToColor(userId));

                              return (

                                <MobiliAvatar user={user} sx={{height:30,width:30, fontSize:"0.8rem"}} isTherapist/>
                                // userId && (
                                //   <Chip
                                //     key={user.id}
                                //     sx={{
                                //       gridColumn: index % 2 === 0 ? "1" : "2",
                                //       gridRow: index < 2 ? "1" : "2",
                                //       fontSize: "10px",
                                //       backgroundColor: backgroundColor, // Assigning color based on therapy ID
                                //     }}
                                //     label={userId || "-"}
                                //   />
                                );
                              
                            })}
                          </div>
                      </TableCell>

                      <TableCell sx={{ width: "1%", padding: "2px" }}>
                        <AppointmentView
                          appointment={cancelledAppointment}
                          cancelledAppointment={cancelledAppointment.originalDate}
                        />
                        {/* </div> */}
                        {/* </Tooltip> */}
                      </TableCell>
                      <TableCell sx={{ width: "1%", padding: "2px" }}>
                        <Tooltip
                          title={
                            <Typography>
                              Status: {cancelledAppointment.callStatus || "nicht benötigt"}
                              <br />
                              Mobil: {cancelledAppointment.patient.mobile}
                              <br />
                              Telefon1: {cancelledAppointment.patient.phone1 || "-"}
                              <br />
                              Telefon2: {cancelledAppointment.patient.phone2 || "-"}
                              <br />
                            </Typography>
                          }
                        >
                          <IconButton
                            onClick={(event) => handleClick(event, cancelledAppointment.id)}
                          >
                            <Smartphone
                              sx={{
                                color: getStatusColor(
                                  cancelledAppointment.callStatus || "nicht benötigt",
                                  cancelledAppointment.id
                                ),
                              }}
                              onClick={(event) => handleClick(event, cancelledAppointment.id)}
                            />
                          </IconButton>
                        </Tooltip>

                        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                          {callStatusOptions.map((option) => (
                            <MenuItem
                              key={option.status}
                              onClick={() => handleCallStatusSelect(option.status)}
                              sx={{ color: getStatusColor(option.status) }}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </Menu>
                      </TableCell>
                      <TableCell sx={{ width: "3%", padding: "0px" }}>
                        <div style={{ display: "flex", gap: "1px" }}>
                          <Chip
                            sx={{
                              height: "auto",
                              // "& .MuiChip-label": {
                              display: "block",
                              whiteSpace: "normal",
                              width: "40px",
                              cursor: "pointer",
                              // },
                            }}
                            icon={
                              <>
                                <PanToolAlt
                                  sx={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-70%, -30%)",
                                    zIndex: 1,
                                  }}
                                />
                                <CalendarToday
                                  sx={{
                                    position: "relative",
                                    zIndex: 0,
                                    width: "40px",
                                    height: "100%",
                                  }}
                                />
                              </>
                            }
                            onClick={() => handlePlanning(patientId)}
                          />
                          <Chip
                            sx={{
                              height: "auto",
                              // "& .MuiChip-label": {
                              display: "block",
                              whiteSpace: "normal",
                              width: "40px",
                              cursor: "pointer",
                              // },
                            }}
                            icon={
                              <>
                                <Typography
                                  sx={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-60%, -30%)",
                                    zIndex: 1,
                                  }}
                                >
                                  AI
                                </Typography>
                                <CalendarToday
                                  sx={{
                                    position: "relative",
                                    zIndex: 0,
                                    width: "40px",
                                    height: "100%",
                                  }}
                                />
                              </>
                            }
                            onClick={() => handlePatientData(cancelledAppointment.patient)}
                            // sx={{ cursor: 'pointer', marginRight: 1 }}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              }

              return null;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {renderAppointmentTable()}
      </Grid>

      {isOpen && <PatientLayout />}
    </Grid>
  );
});

export default UnScheduled;
