import { createTheme } from "@mui/material/styles";
import { blue } from "@mui/material/colors";
import { deDE } from '@mui/x-date-pickers/locales';

declare module "@mui/material/styles" {
  interface Theme {
    topLevelBackground: string;
    topLevelPadding: string;
    mainHeader: string;
    cardBackground: string;
    lowLevelContainerBackground: string;
  }

  // allow configuration using `createTheme`
  interface ThemeOptions {
    topLevelBackground?: string;
    topLevelPadding?: string;
    mainHeader?: string;
    cardBackground?: string;
    lowLevelContainerBackground?: string;
  }
}

// const blue1 = "#B7C6CD";
// const blue2 = "#87A2AD";
// const blue3 = "#3F6A7C";
// const blue4 = "#05445B";

const blue1 = "#b2dbef";
const blue2 = "#6fa4bf";
const blue3 = "#367b9a";
const blue4 = "#06465D";

const generalBackground = "white"; //"#ECF2F5";

const TAB_HEIGHT = "0px";
// export const PATIENT_TAB_HEIGHT = TAB_HEIGHT;

const ELEVATION = 5;

const lowLevelBorderRadius = "6px";

// palette
export let mobiliTheme = createTheme({
  topLevelBackground: "#9EB1C0",
  topLevelPadding: "12px",
  mainHeader: "#003059",
  cardBackground: "#D9DFE2",
  lowLevelContainerBackground: "#F2F2F2",
  palette: {
    primary: {
      main: "#fff",
      // light: blue1,
      // dark: blue3,
      //contrastText: "black",
    },
    secondary: {
      main: "#9C1A35",
    },
    success: {
      main: "#6FBF99",
    },
    info: {
      main: "#4361FF",
    },
    warning: {
      main: "#FFE80B",
    },
    error: {
      main: "#FF0000",
    },
  },
}, deDE);

export const mainAppGridStyle = {
  background: `linear-gradient(to top, ${mobiliTheme.mainHeader}, ${mobiliTheme.topLevelBackground})` /* Blue to light blue */,
};

export const RxStatusColors = {
  BEING_TREATED : "#D943FF",
  FINISHED : "#6FBF99",
  BOOKED : "#4361FF",
  INTERRUPTED : "#FFE80B",
  HAS_ISSUES : "#FF0000",
};
export const attentionColors = {
  immediateAttention: "#ab003c",
  highPriority: mobiliTheme.palette.error.main,
  mediumPriority: "#ff5722",
  lowPriority: "#ffc107",
  customInfoPrimary: mobiliTheme.palette.info.main,
  // Add other colors as needed
};

// component overrides
mobiliTheme = createTheme(mobiliTheme, {
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          border: `1px solid ${mobiliTheme.palette.primary.main}`,
          borderRadius: "6px",
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          textAlign: "left",
          backgroundColor: "white",
          color: mobiliTheme.palette.primary.contrastText,
          padding: "5px",
        },
        title: {
          fontSize: "1.25em", // Adjust to your desired size
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          backgroundColor: mobiliTheme.cardBackground,
          // color: mobiliTheme.palette.primary.contrastText,
          // padding:"4px",
          // paddingBottom: "4px",
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          display: "flex",
          justifyContent: "flex-end",
          backgroundColor: mobiliTheme.cardBackground,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: "0px",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: mobiliTheme.palette.secondary.main,
            color: mobiliTheme.palette.secondary.contrastText,
          },
          backgroundColor: mobiliTheme.palette.primary.main,
          borderTopLeftRadius: "10px", // Top-left corner rounded
          borderTopRightRadius: "10px", // Top-right corner rounded
          borderBottomLeftRadius: "0px", // Bottom-left corner square
          borderBottomRightRadius: "0px", // Bottom-right corner square
          textTransform: "none",
          minHeight: TAB_HEIGHT,
          marginRight: "5px",
          paddingTop: "8px",
          paddingBottom: "8px",
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: ELEVATION,
      },
      styleOverrides: {
        root: {
          //   backgroundColor: mobiliTheme.palette.primary.light,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        // elevation: ELEVATION,
        variant: "contained",
      },
      styleOverrides: {
        root: {
          '&:hover': { // Defines hover state styles
              backgroundColor: mobiliTheme.palette.secondary.light, // Light background on hover
              color: mobiliTheme.palette.secondary.contrastText, // Dark text on hover
            },
          // Applying conditional styling based on the variant
          "&.MuiButton-text": {
            // This specifically targets buttons with the 'text' variant
            color: "black",
            '&:hover': { // Defines hover state styles
              backgroundColor: mobiliTheme.palette.secondary.light, // Light background on hover
              color: mobiliTheme.palette.secondary.contrastText, // Dark text on hover
            },            
          },
        },
      },
      // styleOverrides: {
      //   root: {
      //     backgroundColor: mobiliTheme.palette.primary.main,
      //     color:mobiliTheme.palette.primary.contrastText,
      //   },
      // },
    },

    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: mobiliTheme.palette.secondary.main,
          color: "white",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background:
            process.env.REACT_APP_INSTANCE_NAME != "production"
              ?  `linear-gradient(to left, ${mobiliTheme.palette.secondary.main}, ${mobiliTheme.mainHeader})`
              : mobiliTheme.mainHeader,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          padding: "0px", // Adjust this value as needed
          backgroundColor: "white",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: mobiliTheme.cardBackground,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          backgroundColor: mobiliTheme.cardBackground,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          backgroundColor: mobiliTheme.cardBackground,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        colorPrimary: {
          // This targets the specific state where colorPrimary is applied
          color: "green", // Replace '#yourColor' with the color you want
        },
        root: {
          // Targeting the root style for all InputLabels
          "&.Mui-focused": {
            color: mobiliTheme.palette.secondary.main, // Change 'red' to whatever color you want
          },
          "&.MuiFormLabel-filled": {
            color: mobiliTheme.mainHeader, // Change 'green' to whatever color you need when the label is filled
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: "white", // Background color for text boxes
          // border: '2px solid black', // Border for text boxes
          borderRadius: lowLevelBorderRadius, // Border radius for text boxes
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          backgroundColor: mobiliTheme.lowLevelContainerBackground, // Background color for autocomplete
          // border: '2px solid black', // Border for autocomplete
          borderRadius: lowLevelBorderRadius, // Border radius for autocomplete
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          // backgroundColor: 'white', // Background color for menu items
          // border: '1px solid #ccc', // Border for menu items
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          "&.Mui-checked": {
            color: mobiliTheme.palette.secondary.light, // Color for checked radio buttons
          },
          backgroundColor: "white", // Background color for radio buttons
          "&:hover": {
            backgroundColor: "rgba(0, 123, 255, 0.08)", // Background color on hover
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          margin: "0 8px", // Custom margin for radio group labels
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: 'black', // Use secondary color for unchecked state
          '&.Mui-checked': {
            color: mobiliTheme.palette.secondary.light, // Use secondary color for checked state
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: 'black', // Use secondary color for labels
          '&.Mui-focused': {
            color: mobiliTheme.mainHeader, // Use secondary color for focused labels
          },
          fontSize: "1rem", // Adjust to your desired size
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          width: "unset",
          // backgroundColor: '#f0f0f0', // Custom background color
          // color: '#333', // Custom text color
          // '&.MuiDataGrid-columnHeader--sortable': {
          //   cursor: 'pointer',
          //   '&:hover': {
          //     backgroundColor: '#e0e0e0', // Background color on hover
          //   },
          //   '&.MuiDataGrid-columnHeader--sorted': {
          //     backgroundColor: '#d0d0d0', // Background color when sorted
          //   },
          // },
        },
        columnHeaderTitle: {
          // fontWeight: 'bold', // Custom font weight
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          color: `${mobiliTheme.palette.secondary.main}`, // Use secondary color instead of primary
        },
        thumb: {
          color: `${mobiliTheme.palette.secondary.main}`, // Thumb color to secondary
        },
        track: {
          color: `${mobiliTheme.palette.secondary.main}`, // Track color to secondary
        },
        rail: {
          color: `${mobiliTheme.palette.secondary.main}`, // Rail color to secondary
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          backgroundColor: mobiliTheme.lowLevelContainerBackground, // Set the custom background color for the table container
          borderRadius: "6px", 
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          margin: "4px", // Add margin to create space between cells
          fontSize: "0.7rem", // Set custom font size for this table
          padding: "4px", // Adjust padding if necessary
          backgroundColor:  "transparent", // Light gray background to mimic a chip
          // borderRadius: "16px", // Rounded corners like a chip
        },
        head: {
          fontSize: "0.85rem", // Font size for header cells
          fontWeight: "bold", // Make header cells bold
          backgroundColor: "transparent", // Set background color for header
          borderBottom: "1px solid #999", // Custom border for header
          borderRadius: "0px", // Rounded corners like a chip
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        body1: {
          fontSize: "0.875rem", // Customize the typography for table content
        },
      },
    },
    // MuiTableContainer: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: mobiliTheme.lowLevelContainerBackground, // Set the custom background color for the table container
    //     },
    //   },
    // },
    // MuiTableCell: {
    //   styleOverrides: {
    //     root: {
    //       fontSize: "0.7rem", // Set custom font size for this table
    //       padding: "1px", // Adjust padding if necessary          
    //       backgroundColor: "white", // Light gray background to mimic a chip
    //       borderRadius: "16px", // Rounded corners like a chip          
    //       border: "0px solid #ccc", // Optional: subtle border for the chip effect
    //     },
    //     head: {
    //       fontSize: "0.85rem", // Font size for header cells
    //       fontWeight: "bold", // Make header cells bold
    //       backgroundColor: mobiliTheme.lowLevelContainerBackground, // Set background color for header
    //       color: "#333", // Custom text color for header cells
    //       borderBottom: "0px solid #999", // Custom border for header
    //     },
    //   },
    // },

  },
});

// typography

mobiliTheme = createTheme(mobiliTheme, {
  typography: {
    // h5: {
    //   backgroundColor: mobiliTheme.palette.primary.light,
    // },
    // h6: {
    //   backgroundColor: mobiliTheme.palette.primary.light,
    // },
  },
});
// densify:
mobiliTheme = createTheme(mobiliTheme, {
  //const theme = createTheme({
  components: {
    MuiButton: {
      defaultProps: {
        size: "small",
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: mobiliTheme.lowLevelContainerBackground, // This is your off color, adjust as needed
          "&.Mui-checked": {
            color: mobiliTheme.palette.secondary.main, // This is your on color, adjust as needed
          },
          "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: mobiliTheme.palette.secondary.light, // Track color when checked
          },
        },
        track: {
          backgroundColor: "#767577", // Default track color
        },
      },
    },
    MuiFilledInput: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiFormControl: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: "small",
      },
    },
    MuiInputBase: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiInputLabel: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiListItem: {
      defaultProps: {
        dense: true,
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiFab: {
      defaultProps: {
        size: "small",
      },
    },
    MuiTable: {
      defaultProps: {
        size: "small",
      },
    },
    MuiTextField: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiToolbar: {
      defaultProps: {
        variant: "dense",
      },
    },
  },
});

mobiliTheme.spacing(2);

console.log("mobiliTheme.typography", mobiliTheme.typography);

const therapyGroupColors = {
  KG: "#6FBF99",
  ML: "#B4BF6F",
  MT: "#6F8FBF",
  KGN: "#8B6FBF",
  KGG: "#F2CBD3",
};

export const therapyColors = {
  BAN: therapyGroupColors.ML,
  // "BER": therapyGroupColors.,
  // "BERXL": therapyGroupColors.,
  BGM: therapyGroupColors.KG,
  // "D1": therapyGroupColors.,
  EIS: therapyGroupColors.KG,
  ET: therapyGroupColors.KG,
  HL: therapyGroupColors.KG,
  HR: therapyGroupColors.KG,
  KG: therapyGroupColors.KG,
  KGG: therapyGroupColors.KGG,
  "KG Muko": therapyGroupColors.KG,
  "KG Muko Tele": therapyGroupColors.KG,
  "KGN BO": therapyGroupColors.KGN,
  "KGN BO Tele": therapyGroupColors.KGN,
  "KGN KI BO Tele": therapyGroupColors.KGN,
  "KGN KI BO": therapyGroupColors.KGN,
  "KGN KI VOJ": therapyGroupColors.KGN,
  "KGN PNF": therapyGroupColors.KGN,
  "KGN VOJ": therapyGroupColors.KGN,
  "KG Tele": therapyGroupColors.KG,
  "KMT/MA": therapyGroupColors.KG,
  ML3: therapyGroupColors.ML,
  ML4: therapyGroupColors.ML,
  ML6: therapyGroupColors.ML,
  MT: therapyGroupColors.MT,
  "MT Tele": therapyGroupColors.MT,
  PA: therapyGroupColors.KG,
  // "PBE": therapyGroupColors.,
  SCH: therapyGroupColors.KG,
  "SM/PM/CM": therapyGroupColors.KG,
  ÜBB: therapyGroupColors.KG,
};
