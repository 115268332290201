// WebSocketProvider.js
import { createContext, ReactNode, useEffect } from 'react';
import WebSocketStore from './WebSocket';

interface WebSocketProviderProps {
  children: ReactNode;
}

export const WebSocketContext = createContext<WebSocketStore | null>(null);
WebSocketContext.displayName = 'WebSocketContext';

export const WebSocketProvider = ({ children }: WebSocketProviderProps) => {
  const store = new WebSocketStore();

  useEffect(() => {
    store.initializeWebSocket(); // Call the initialization method only once when component mounts
  }, []); // Empty dependency array ensures this effect runs only once

  return (
    <WebSocketContext.Provider value={store}>
      {children}
    </WebSocketContext.Provider>
  );
};
