import React, { useState } from "react";
import moment from 'moment'
import GroupCheckBox from './GroupCheckBox'
import CalendarDatePicker from './CalendarDatePicker'
import FullCalendar from "@fullcalendar/react";
import Button from "../../atoms/Button";
import { Box, FormControlLabel, IconButton, Switch, Tooltip } from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import RefreshIcon from '@mui/icons-material/Refresh';
import TodayIcon from '@mui/icons-material/Today';
import Controls from "../../atoms/Controls";
import { UserDto } from "../../api-client";
import { mobiliTheme } from "../../themes/mobiliTheme";

interface HeaderToolbarProps {
  calendarRef: React.RefObject<FullCalendar>
  onDatesSet: (date: Date) => void;
  onOutdated: () => void;
  onContinuousViewChange: (continuous: boolean) => void;
  isContinuous: boolean;
  date: string;
  onGroupChecked: (checked: boolean) => void;
  groupCheck: boolean;
  onDateChange: (date: string) => void;
  users: UserDto[];
  onUserChange: (userId: string) => void;
}

export const HeaderToolbar:React.FC<HeaderToolbarProps> = ({ 
  isContinuous, 
  calendarRef, 
  groupCheck, 
  date, 
  users, 
  onDateChange, 
  onOutdated, 
  onDatesSet, 
  onGroupChecked, 
  onContinuousViewChange, 
  onUserChange
  }) => {

  const [continuousView, setContinuousView] = useState<boolean>(isContinuous);
  const [selectedDate, setSelectedDate] = useState<string>(date);
  const [showHeaderTitle, setShowHeaderTitle] = useState<boolean>(true);

  const getSelectedDate = (): Date => {
    let calendarApi = calendarRef.current?.getApi()!;
    return calendarApi?.getDate();
  };

  const onContinuousToggleChange = (checked: boolean) => {
    setContinuousView(checked);
    return onContinuousViewChange(checked);
  };

  const onNextDate = () => {
    let calendarApi = calendarRef.current?.getApi()!;
    calendarApi.next();
    let date = getSelectedDate();
    if (date) setSelectedDate(moment(date).format("YYYY-MM-DD"));
    return onDatesSet(date);
  }

  const onPreviousDate = () => {
    let calendarApi = calendarRef.current?.getApi()!;
    calendarApi.prev();
    let date = getSelectedDate();
    if (date) setSelectedDate(moment(date).format("YYYY-MM-DD"));
    return onDatesSet(date);
  }

  const onGoToday = () => {
    let calendarApi = calendarRef.current?.getApi()!;
    calendarApi.today();
    let date = getSelectedDate();
    if (date) setSelectedDate(moment(date).format("YYYY-MM-DD"));
    return onDatesSet(date);
  }

  const onDatePickerChange = (date: string) => {
    setSelectedDate(date);
    return onDateChange(date);
  }

  const onUserSelected = (userId: string) => {
    userId ? setShowHeaderTitle(false) : setShowHeaderTitle(true);
    onUserChange(userId);
  }

  return (
    <div className="calendar-header">
      {/* <ViewToggleButton onChange={setResource}/> */}
      <Box display="flex" gap={1} height={40}>
        <Button onClick={onPreviousDate}><NavigateBeforeIcon/></Button>
        <Button onClick={onNextDate}><NavigateNextIcon/></Button>
        <Button onClick={onOutdated}><RefreshIcon/></Button>
        <Button onClick={onGoToday} color={date === moment().format("YYYY-MM-DD") ? "primary" : "info"}><TodayIcon/></Button>
        <FormControlLabel control={
          <Switch checked={isContinuous} onChange={(event) => onContinuousToggleChange(event.target.checked)} />
        } label="Dauertermin" />
      </Box>
      {showHeaderTitle && 
      <h2>{moment(selectedDate).format(isContinuous ? "dddd" : "dddd, D. MMM YYYY")}</h2>}
      {!continuousView && 
      <Box display="flex" alignItems='center'>
        <GroupCheckBox onChecked={onGroupChecked} checked={groupCheck} />
        <CalendarDatePicker date={date} onChange={onDatePickerChange} />
      </Box>}
      {continuousView && 
        <Controls.Select
          name="user"
          label="Therapeut"
          onChange={(event: any) => onUserSelected(event.target.value)}
          options={users.map((user: UserDto) => ({
            label: user.firstName + " " + user.lastName,
            value: user.id,
          }))}
          placeholder="Therapeut auswählen"
          showOptionLabel
          style={{width: 300}}
        />}
    </div>
  )
}
