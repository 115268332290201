import React from 'react';
import { Card, CardContent, Typography, Grid, CardHeader, Tooltip, Avatar, IconButton } from '@mui/material';
import { NoteDto } from '../../../api-client';

interface NotesViewProps {
  notes: NoteDto[];
}

const NotesView: React.FC<NotesViewProps> = ({ notes }) => {
  return (
    <Grid container spacing={2}>
    {notes.map((note, index) => (
      <Grid item xs={12} key={note.id || index}>
        <Card
          style={{
            border: '0px solid #007bff',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <CardHeader
            avatar={
              <Tooltip title={note.updatedByUser ? note.updatedByUser.lastName : note.createdByUser?.lastName}>
                <Avatar sx={{ width: 40, height: 35 }}>
                  {note.updatedByUser ? note.updatedByUser.nickName : note.createdByUser?.nickName}
                </Avatar>
              </Tooltip>
            }
            title=""
            style={{ paddingBottom: 0 }} // Adjust padding
          />
          <CardContent style={{ flex: 1, paddingTop: 0 }}>
            <Tooltip title={note.text || 'Keine Notiz'}>
              <Typography
                variant="body2"
                color="textSecondary"
                style={{
                  fontWeight: 'bold',
                  whiteSpace: 'pre-wrap', // Ensure that the full note is visible
                }}
              >
                {note.text || 'Keine Notiz'}
              </Typography>
            </Tooltip>
          </CardContent>
        </Card>
      </Grid>
    ))}
  </Grid>
  );
};

export default NotesView;