import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Paper, Divider, Table, TableCell, TableRow, TableBody } from '@mui/material';
import { observer } from 'mobx-react';
import moment from 'moment';
import FlatCard from '../../../../../atoms/FlatCard';
import ValidationCardRxInfoPage from '../../../../../molecules/ValidationCardRxInfoPage';
import ValidationCard, { formType } from '../../../../../molecules/ValidationCard';
import Controls from '../../../../../atoms/Controls';
import { NoteDto, RxDtoProcessingStatusEnum } from '../../../../../api-client';
import { RxStoreContext } from '../../../../../stores/Rx/rx.provider';
import useStore from '../../../../../helpers/useStore';
import { getStatusColor } from '../statusColor';
import { NotesContext } from '../../../../../stores/Notes/notes.provider';
import { UserContext } from '../../../../../stores/User/User.provider';
import Notes from '../../../../ScheduleOverview/Notes/Notes';

interface PrivateViewProps {
  selectedRow: any; 
  patientData: any;
}

const PrivateView: React.FC<PrivateViewProps> = observer(({ selectedRow, patientData }) => {
  const RxStore=useStore(RxStoreContext);
  const {updateprocessingStatus}=RxStore;
  const updateRxProcessingStatus=async(event:any)=>{
    const response=await updateprocessingStatus(selectedRow.id,event?.target.value)
  }
  const UserStore=useStore(UserContext);
  const {user}=UserStore;
  const NotesStore=useStore(NotesContext);
  const {getRxNotes,updateNoteById,postNotes,fetchNotes}=NotesStore;
  const [rxNotes, setRxNotes] = useState<NoteDto[]>([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        if(fetchNotes){
        const response = await getRxNotes(selectedRow.id);

        setRxNotes(response);
        }
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchData();
  }, [selectedRow.id,fetchNotes]);
  const updateNote = async (index: number, updatedCard: NoteDto) => {
    updatedCard.rxId = selectedRow.id;
    if(updatedCard.id){
      await updateNoteById(updatedCard.id,user?.id as string,updatedCard)
   }
   else{
    await postNotes(user?.id as string, updatedCard);
   }
    const updatedNotes = [...rxNotes];
    updatedNotes[index] = updatedCard;
    setRxNotes(updatedNotes);
  };

  return (
    <Paper
      elevation={3}
      sx={{ margin: "2px" }}
      //   style={{
      //     width: '1191px',
      //     height: '1684px',
      //     padding: '20px',
      //     margin: 'auto',
      //     position: 'relative',
      //   }}
    >
      <Grid container direction="row" spacing={2} sx={{ height: "100%" }}>
        {/* Right Side: Prescription (Rx) Information */}
        <Grid item xs={12}>
          <FlatCard title={""}>
            <Grid direction="column" sx={{ height: "100%", background: "#eeeeee" }}>
              <Grid item xs={12}>
                <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
                  {/* <Typography variant="body2" sx={{ fontSize: "0.8rem" }}> */}
                  <Typography variant="body2" sx={{ fontSize: "1.3rem", fontWeight: "bold" }}>
                    {`${selectedRow?.doctor?.contactInfo?.salutation || "Dr."} ${
                      selectedRow?.doctor?.contactInfo?.firstNames || ""
                    } ${selectedRow?.doctor?.contactInfo?.lastName || ""}`}{" "}
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: "0.8rem" }}>
                    {`Address: ${selectedRow?.doctor?.contactInfo?.addressLine1 || ""}`}

                    {`${selectedRow?.doctor?.contactInfo?.addressLine2 || ""}`}
                    <br />
                    {`Tel: ${selectedRow?.doctor?.contactInfo?.phone1 || ""}`}
                    <br />
                    {`LANR: ${selectedRow?.doctor?.number || ""}`}
                    <br />
                    {`BSNR: ${selectedRow?.doctor?.clinicNumber || ""}`}
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: "1rem", fontWeight: "bold" }}>
                    {" "}
                    {`${selectedRow?.doctor?.contactInfo?.city || ""}`}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={12} sx={{ margin: "4px" }}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="h6">Rp.</Typography>
                  <Typography variant="body2" sx={{ fontSize: "1rem", fontWeight: "bold" }}>
                    {" "}
                    {`${selectedRow?.doctor?.contactInfo?.city || ""}`}
                  </Typography>
                  <Typography variant="body1" sx={{ display: "flex", justifyContent: "flex-end" }}>
                    {selectedRow.date
                      ? moment(selectedRow.date, "YYYY-MM-DD", true).isValid()
                        ? moment(selectedRow.date).format("DD.MM.YYYY")
                        : selectedRow.date
                      : "-"}
                  </Typography>
                </Box>
              </Grid>

              <Grid item container xs={12} spacing={2}>
                <Grid item xs={6}>
                  <FlatCard title={""}>
                    <Table>
                      <TableBody>
                        {selectedRow.therapyRxs?.map((therapyRx: any, index: number) => (
                          <TableRow key={index}>
                            {/* Render the therapy information */}
                            <TableCell sx={{ fontFamily: "-moz-initial" }}>
                              {therapyRx?.amount} x {therapyRx?.therapy?.abbreviation}
                            </TableCell>

                            {/* Conditionally render additional notes in the first row only */}
                            {index === 0 && selectedRow?.additionalNotes && (
                              <TableCell colSpan={2} sx={{ whiteSpace: "normal" }}>
                                <Typography variant="body1" sx={{ fontFamily: "-moz-initial" }}>
                                  {selectedRow.additionalNotes}
                                </Typography>
                              </TableCell>
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </FlatCard>
                </Grid>

                <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Box sx={{ textAlign: "left" }}>
                    <Typography variant="body1" sx={{ fontFamily: "-moz-initial" }}>
                      für:
                    </Typography>
                    <Typography variant="body1" sx={{ fontFamily: "-moz-initial" }}>{`${
                      patientData?.salutation || ""
                    } ${patientData?.firstName} ${patientData?.lastName}`}</Typography>
                    <Typography variant="body1" sx={{ fontFamily: "-moz-initial" }}>{`${
                      patientData?.street || "-"
                    } ${patientData?.city || ""}`}</Typography>
                    <Typography variant="body1" sx={{ fontFamily: "-moz-initial" }}>{`${
                      patientData?.zip || ""
                    } ${patientData?.country || ""}`}</Typography>
                  </Box>
                </Grid>
              </Grid>

              <Grid item xs={12} sx={{ margin: "4px" }}>
                <Box textAlign="left">
                  <Typography variant="body1" sx={{ fontFamily: "-moz-initial" }}>
                    Diagnose: (
                    {(selectedRow || [])?.icdCodeEntries
                      ?.map((icd: any) => icd.userText)
                      .join(", ") || "-"}
                    )
                  </Typography>

                  <Typography variant="body1" sx={{ fontFamily: "-moz-initial" }}>
                    {selectedRow?.diagnosisText || "-"}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} spacing={2} sx={{ margin: "4px" }}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  sx={{ height: "100%" }}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "0.8rem", textAlign: "left", fontFamily: "cursive" }}
                  >
                    {`${selectedRow?.doctor?.contactInfo?.salutation || "Dr."} ${
                      selectedRow?.doctor?.contactInfo?.firstNames || ""
                    } ${selectedRow?.doctor?.contactInfo?.lastName || ""}`}
                    <br />
                    {`Address: ${selectedRow?.doctor?.contactInfo?.addressLine1 || ""}`}
                    {`${selectedRow?.doctor?.contactInfo?.addressLine2 || ""}`}
                    <br />
                    {`Tel: ${selectedRow?.doctor?.contactInfo?.phone1 || ""}`}
                    <br />
                    {`LANR: ${selectedRow?.doctor?.number || ""}`}
                    <br />
                    {`BSNR: ${selectedRow?.doctor?.clinicNumber || ""}`}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </FlatCard>
        </Grid>
        <Grid item xs={12} md={6} sx={{ border: "0px dashed green" }}>
          
        <FlatCard
            title="Notizen"
            sx={{
              paddingTop: "5px",
              marginBottom: "8px",
              textAlign: "left",
            }}
          >
            <Notes notes={rxNotes} setNotes={updateNote} maxVisibleNotes={3} />
          </FlatCard></Grid>
     
        {/* Left Side: Validation Card */}
        <Grid item xs={12} md={6} sx={{ border: "0px dashed green" }}>
          <Grid>
        <FlatCard title="Allgemein" sx={{ marginBottom: "2px" }}>
            <Grid container spacing={0} justifyContent="space-between">
              {/* Third Row */}
              <Grid item xs={5}>
                <Typography textAlign="left" style={{ fontSize: "0.8rem" }}>
                  Rx Location:
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Controls.Chip
                  label={selectedRow.visitType || "-"}
                  sx={{ width: "100%", fontSize: "0.7rem" }}
                />
              </Grid>
              <Grid item xs={12}>
                <Controls.Select
                  name="processingStatus"
                  label="Status"
                  onChange={updateRxProcessingStatus}
                  value={selectedRow?.processingStatus || RxDtoProcessingStatusEnum.InBehandlung}
                  options={Object.values(RxDtoProcessingStatusEnum).map((value) => ({
                    value: value,
                    label: value,
                  }))}
                  style={{
                    color: getStatusColor(
                      selectedRow?.processingStatus || RxDtoProcessingStatusEnum.InBehandlung
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </FlatCard></Grid>
          <Grid>
          {selectedRow && (
            <FlatCard title="Validierung" sx={{ marginBottom: "8px" }}>
              <ValidationCard
                patientData={patientData}
                selectedRx={selectedRow}
                form={formType.SHORT}
              />
            </FlatCard>
          )}</Grid>
        </Grid>
        </Grid>
    
    
    </Paper>
  );
});

export default PrivateView;
