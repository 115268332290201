// import React from 'react';
// import { Tooltip } from '@mui/material';

// interface ValidationTooltipProps {
//   validity: string | undefined;
//   message: string;
//   children: React.ReactNode;
// }

// const ValidationTooltip: React.FC<ValidationTooltipProps> = ({ validity, message, children }) => {
//   return (
//     <Tooltip
//       title={message}
//       open={validity === 'Valid' || validity === 'Not Valid' || validity === "Partially Valid"}
//       arrow 
//     >
//       <div>{children}</div>
//     </Tooltip>
//   );
// };

// export default ValidationTooltip;

import React from 'react';
import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { styled } from '@mui/material/styles';

interface ValidationTooltipProps {
  validity: string | undefined;
  message: string;
  placement?: TooltipProps['placement'];
  children: React.ReactNode;
  sx?: object;
}

interface ColoredTooltipProps extends TooltipProps {
  validity?: string;
}

const ColoredTooltip = styled(({ className, ...props }: ColoredTooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, validity }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor:
      validity === 'Valid' ? '#82FF87' :
      validity === 'Not Valid' ? '#FF0000' :
      validity === 'Partially Valid' ? '#FFE80B' :
      theme.palette.background.paper,
    color: theme.palette.common.black,
    fontSize: 10,
  },
}));

const ValidationTooltip: React.FC<ValidationTooltipProps> = ({ validity, message, placement, sx, children }) => {
  return (
    <ColoredTooltip
      title={message}
      open={validity === 'Valid' || validity === 'Not Valid' || validity === "Partially Valid"}
      arrow
      validity={validity}
      placement={placement}
      sx={sx}
    >
      <div>{children}</div>
    </ColoredTooltip>
  );
};

export default ValidationTooltip;
