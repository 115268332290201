import React, { Children, ReactElement, ReactNode, useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {
  Box,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  createTheme,
} from "@mui/material";
import { AddressDto, ContactInfoDTO, PatientDto } from "../../api-client";
import TicketForm from "../Ticketing/TicketEditCreate/ticketForm";
import { MoreVert } from "@mui/icons-material";
import PatientPage from "../PatientDataEntryForm/PatientPage";
import { mobiliTheme } from "../../themes/mobiliTheme";
import { FlexListContainer, LabelItem, ValueItem } from "../../molecules/FlexList";
import { observer } from "mobx-react";
interface PatientOverviewProps {
  patientData: PatientDto;
  onRowClick: (row: any) => void; // Callback for row click
  setPatientData: (row: any) => void;
  handleData: (row: any) => void;
  masterData?: any;
  updatePatientData?: any;
}
const formatDate = (date: any) => {
  const inputDate = new Date(date);

  const day = String(inputDate.getUTCDate()).padStart(2, "0");
  const year = inputDate.getUTCFullYear();

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = months[inputDate.getUTCMonth()];

  const formattedDate = `${day} ${month} ${year}`;
  return formattedDate;
};

interface ContactToolTipProps {
  contactInfo: ContactInfoDTO;
  children: ReactElement; // Add this line to specify children prop type
}
const ContactToolTip: React.FC<ContactToolTipProps> = ({ contactInfo, children }) => {
  return (
    <Tooltip
      title={
        <Box>
          {contactInfo.salutation && <Typography>{contactInfo.salutation}</Typography>}
          {contactInfo.firstNames && <Typography>{contactInfo.firstNames}</Typography>}
          {contactInfo.lastName && <Typography>{contactInfo.lastName}</Typography>}
          {contactInfo.mobile && <Typography>Mobil: {contactInfo.mobile}</Typography>}
          {contactInfo.phone1 && <Typography>Tel. : {contactInfo.phone1}</Typography>}
          {contactInfo.fax && <Typography>Fax: {contactInfo.fax}</Typography>}          
          {contactInfo.addressLine1 && <Typography>Adresse: {contactInfo.addressLine1}</Typography>}
          {contactInfo.addressLine2 && <Typography>{contactInfo.addressLine2}</Typography>}
          {contactInfo.city && <Typography>Ort: {contactInfo.city}</Typography>}
          {contactInfo.zip && <Typography>PLZ: {contactInfo.zip}</Typography>}
          {contactInfo.email && <Typography>Mail: {contactInfo.email}</Typography>}
        </Box>
      }
    >
      {children}
    </Tooltip>
  );
};

const FlexListAddresses: React.FC<{ addresses: AddressDto[] | undefined }> = ({
  addresses,
}: {
  addresses: AddressDto[] | undefined;
}) => {
  if (!addresses) return <Typography>Keine Adressen vorhanden</Typography>;

  const primary = addresses.find((address) => address.isPrimary);
  if (!primary) return <Typography>Keine Hauptadresse vorhand</Typography>;

  const otherAddresses = addresses.filter(
    (address) => !address.isPrimary && (address.isAlternativeBilling || address.isVisit)
  );

  return (
    <>
      {primary.contactInfo?.mobile && (
        <>
          <LabelItem label="Mobil" />
          <ValueItem value={`${primary.contactInfo?.mobile}`} />
        </>
      )}
      {primary.contactInfo?.phone1 && (
        <>
          <LabelItem label="Tel. 1" />
          <ValueItem value={`${primary.contactInfo?.phone1}`} />
        </>
      )}
      {/* {primary.contactInfo?.phone2 && (
        <>
          <LabelItem label="Tel1" />
          <ValueItem value={`${primary.contactInfo?.phone2}`} />
        </>
      )} */}
      {primary.contactInfo?.email && (
        <>
          <LabelItem label="Mail" />
          <ValueItem value={`${primary.contactInfo?.email}`} />
        </>
      )}
      <LabelItem label="Kontakt für" />
      <ValueItem
        value={`Hauptkontakt ${primary.isVisit ? "Behandlung" : ""} ${
          primary.isAlternativeBilling ? "Rechnung" : ""
        }`}
      />
      <LabelItem label="Adresse" />
      <ValueItem  value={
              <ContactToolTip contactInfo={primary.contactInfo!}>
                <Box>{primary.contactInfo?.addressLine1} {primary.contactInfo?.city}</Box>
              </ContactToolTip>
            } />

      {otherAddresses.map((address) => (
        <>
          <LabelItem label="alt. Kontakt für" />
          <ValueItem
            value={`${address.isVisit ? "Behandlung" : ""} ${
              address.isAlternativeBilling ? "Rechnung" : ""
            }`}
          />

          <LabelItem label="alt. Adresse" />
          <ValueItem
            value={
              <ContactToolTip contactInfo={address.contactInfo!}>
                <Box>{address.contactInfo?.addressLine1} {address.contactInfo?.city}</Box>
              </ContactToolTip>
            }
          />
        </>
      ))}
    </>
  );
};

const PatientOverview: React.FC<PatientOverviewProps> = observer(({
  patientData,
  onRowClick,
  setPatientData,
  handleData,
  masterData,
  updatePatientData,
}) => {
  const [menu, setMenu] = useState(null);
  const [openPatientEdit, setOpenPatientEdit] = useState<any>(false);
  const [refresh, setRefresh] = useState(false);
  const [openTicket, setOpenTicket] = useState<any>(false);

  const handleMenuOpen = (event: any) => {
    setMenu(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenu(null);
  };

  const handlePatientClose = () => {
    setOpenPatientEdit(false);
  };
  const handleCloseTicketForm = () => {
    setOpenTicket(false);
  };

  console.log("PatientData", patientData);
  return (
    <>
      <Card>
        <CardHeader
          title={`${patientData?.lastName}, ${patientData?.firstName}: ${patientData?.id}`}
          action={
            <div>
              <IconButton
                aria-label="more"
                aria-controls="menu"
                aria-haspopup="true"
                onClick={handleMenuOpen}
              >
                <MoreVert />
              </IconButton>
              <Menu id="menu" anchorEl={menu} open={Boolean(menu)} onClose={handleMenuClose}>
                <MenuItem
                  onClick={() => {
                    setOpenTicket(!openTicket);
                    handleMenuClose();
                  }}
                >
                  Ticket erstellen
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    setOpenPatientEdit(!openPatientEdit);
                    handleMenuClose();
                  }}
                >
                  Bearbeiten
                </MenuItem>
              </Menu>
            </div>
          }
        />
        {openTicket && (
          <TicketForm
            openTicket={openTicket}
            onCloseTicketForm={handleCloseTicketForm}
            data={{ patientId: patientData.id, patient: patientData }}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        )}
        {openPatientEdit && (
          //   <Dialog
          //   open={openPatientEdit}
          //   onClose={handlePatientClose}
          //   aria-labelledby="edit-patient-dialog"
          //   fullScreen
          // >
          //   <DialogContent>
          <PatientPage
            isEditing={true}
            filteredValue={{
              id: patientData.id,
            }}
            updatedPatientData={updatePatientData} // Pass the callback function
            useDialog={true}
            openPatientDialog={openPatientEdit}
            onClosePatientDialog={handlePatientClose}
          />
          //   </DialogContent>
          //   <DialogActions>
          //     <Button onClick={handlePatientClose}>Close</Button>
          //   </DialogActions>
          // </Dialog>
        )}
        <CardContent sx={{ padding: 1 }}>
          <FlexListContainer>
            <LabelItem label="Name" />
            <ValueItem
              value={`${patientData?.salutation || ""} ${patientData?.firstName} ${
                patientData?.lastName
              }`}
            />
            <LabelItem label="Geburtsdatum" />
            <ValueItem value={`${formatDate(patientData?.dob) || "-"}`} />
            <LabelItem label="Status" />
            <ValueItem value={`${patientData?.status || "-"}`} />
            <LabelItem label="Kostenträger" />
            <ValueItem value={`${(patientData?.payer as any)?.name || "-"}`} />
            <LabelItem label="Tarif Group" />
            <ValueItem value={`${patientData?.tarifGroupId || "-"}`} />
            <LabelItem label="Zuzahlung" />
            <ValueItem
              // coPayment flag indicates that the patient is exempted from copayment
              value={patientData?.coPayment ? "befreit" : "pflichtig"}
            />
            <FlexListAddresses addresses={patientData?.addresses} />
          </FlexListContainer>
        </CardContent>
      </Card>
    </>
  );
});

export default PatientOverview;
