import { Autocomplete, Grid, Input, InputAdornment, TextField } from "@mui/material";
import React, { useState } from "react";
import Controls from "../../atoms/Controls";
import { Search } from "@mui/icons-material";

interface HistoryFilterOption {
  label: string;
  value: string;
}

interface InputProps {
  name: string;
  value: string;
  onChange: (value: string) => void;
}

interface InputSelectProps extends InputProps {
  options: HistoryFilterOption[];
}

interface HistoryFilterProps {
  selectFilters?: InputSelectProps[];
  dateFilters?: InputProps[];
  searchFilter?: InputProps;
}

export default function HistoryFilters({
  selectFilters,
  dateFilters,
  searchFilter,
}: HistoryFilterProps) {
  return (
    <Grid container spacing={2}>
      {selectFilters?.map((filter) => (
        <Grid item xs={12} sm={6} md={2} key={filter.name}>
          <Autocomplete
            sx={{ width: "100%" }}
            options={filter.options || []}
            getOptionLabel={(option: HistoryFilterOption) => option.label || ""}
            value={filter.options.find((option) => option.value === filter.value) || null}
            onChange={(_, newValue) => {
              filter.onChange(newValue ? newValue.value : "");
            }}
            renderInput={(params) => (
              <TextField {...params} label={filter.name} InputLabelProps={{ shrink: true }} />
            )}
          />
        </Grid>
      ))}
      {dateFilters?.map((filter) => (
        <Grid item xs={12} sm={6} md={2} key={filter.name}>
          <Controls.DatePicker
            name={filter.name}
            label={filter.name}
            value={filter.value}
            onChange={(e: any) => filter.onChange(e.target.value)}
            floatingLabel={true}
          />
        </Grid>
      ))}
      {searchFilter ? (
        <Grid item xs={12} md={4}>
          <Input
            type="text"
            placeholder={searchFilter?.name}
            value={searchFilter?.value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const searchVal = e.target.value;

              searchFilter?.onChange(searchVal);
            }}
            sx={{ border: "2px solid #ccc", width: "100%", height: "50px", marginTop: 1 }}
            startAdornment={
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            }
          />
        </Grid>
      ) : null}
    </Grid>
  );
}
