import React, { createRef, useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, {
  DateClickArg,
  EventDragStartArg,
  EventDragStopArg,
  EventReceiveArg,
  EventResizeDoneArg,
} from "@fullcalendar/interaction";
import resourceDayGridPlugin from "@fullcalendar/resource-daygrid";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import {
  AppointmentDto,
  ContinuousAppointmentDto,
  ContinuousEventTimeSlotDto,
  EventTimeSlotDto,
  LeaveOfAbsenceDto,
  PatientAvailabilityDto,
  PatientDto,
  TimeSlotDto,
  UserDto,
} from "../../api-client";
import {
  DateSelectArg,
  DateSpanApi,
  EventAddArg,
  EventApi,
  EventChangeArg,
  EventClickArg,
  EventContentArg,
  EventDropArg,
  EventHoveringArg,
  EventMountArg,
  EventRemoveArg,
} from "@fullcalendar/core";
import moment from "moment";
import {mapTherapyToColor} from "../../helpers/generateColor";
import { Event, Resource, PatientExt, TherapyExt, TimeSlotExt, TherapyFrequencyExt } from "./Calendar.type";
import "./Calendar.css";
import GroupCheckBox from "./GroupCheckBox";
import { Backdrop, CircularProgress, Snackbar, Tooltip, Grid, Chip } from "@mui/material";
import CalendarDatePicker from "./CalendarDatePicker";
import { TimeSlotDialog } from "../../components/TerminPlan/TimeSlotDialog";
import UserPopover from "./UserPopover";
import { ResourceLabelContentArg, ResourceLabelMountArg } from "@fullcalendar/resource";
import AddEventModal from "./AddEventModal";
import { mobiliTheme, therapyColors } from "../../themes/mobiliTheme";
import ContextMenu, { MousePosition } from "./ContextMenu";
import useStore from "../../helpers/useStore";
import { AppointmentContext } from "../../stores/Appointment/appointment.provider";
import { CancelAppointmentDialog } from "../CancelAppointmentDialog";
import { HeaderToolbar } from "./HeaderToolbar";
import notificationStore from "../../stores/Notification/notificationStore";
import WarningDialog from "../../atoms/WarningDialog";
import { TopLevelPaper } from "../../themes/StyledComponents";
import { EventImpl } from "@fullcalendar/core/internal";
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import AppointmentNotes from "./AppointmentNotes";
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import { observer } from "mobx-react";
import useCalendarValidations from "./Calendar.validations";
import ScheduleIcon from '@mui/icons-material/Schedule';
import AppointmentEditCard from "../../components/ScheduleOverview/Appointments/AppointmentEditCard";

interface CalendarProps {
  users?: UserDto[];
  appointments?: AppointmentDto[];
  goneFishings?: TimeSlotDto[];
  leaveOfAbsences?: LeaveOfAbsenceDto[];
  eventTimeslots?: EventTimeSlotDto[];
  continuousEventTimeSlots?: ContinuousEventTimeSlotDto[];
  continuousAppointments?: ContinuousAppointmentDto[];
  lunchBreaks?: TimeSlotDto[];
  travelTimes?: TimeSlotDto[];

  patientAvailabilities?: PatientAvailabilityDto[];
  patientUnavailableTypes?: TimeSlotDto[];
  onDatesSet: (date: Date) => void;
  onCreateEvent?: (event: Event) => void;
  onChangeEvent?: (event: Event, relatedEvents?: Event[]) => void;
  onEventReceive?: (event: EventReceiveArg) => void;
  onRemoveEvent?: (id: string) => void;
  onRemoveContinuousEvent?: (id: string) => void;
  onOutdated: () => void;
  onContinuousViewChange: (continuous: boolean) => void;
  isContinuous?: boolean;
  isLoading?: boolean;
  onMoveEventDate?: (event: Event) => void;
  isShelfEvents: boolean;
  onAddShelfEvent?: (event: Event) => void;
  onEventContentClick?: (event: TimeSlotExt) => void;
  onCloseNotesDialog?: () => void;
}

const Calendar = observer(({
  users,
  appointments,
  goneFishings,
  leaveOfAbsences,
  eventTimeslots,
  continuousEventTimeSlots,
  lunchBreaks,
  travelTimes,
  patientAvailabilities,
  patientUnavailableTypes,
  continuousAppointments,
  isContinuous = false,
  isLoading = false,
  isShelfEvents = false,
  onDatesSet,
  onCreateEvent,
  onChangeEvent,
  onEventReceive,
  onRemoveEvent,
  onRemoveContinuousEvent,
  onOutdated,
  onContinuousViewChange,
  onMoveEventDate,
  onAddShelfEvent,
  onEventContentClick,
  onCloseNotesDialog
}: CalendarProps) => {
  const AppointmentStore = useStore(AppointmentContext);
  const { setAttended, cancelContinuousAppointment, adjustOutsideAppointments,warningDialogMessage,setWarningDialogMessage } = AppointmentStore;
  const [isCancellationDialogOpen, setCancellationDialogOpen] = useState(false);
  const [isContinuousCancelationWarningOpen, setContinuousCancelationWarningOpen] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState<AppointmentDto | null>(null); // Track the selected appointment for the dialog
  const [selectedContinuousAppointment, setSelectedContinuousAppointment] =
    useState<ContinuousAppointmentDto | null>(null); // Track the selected continuous appointment for the warning
  const [selectedPatientId, setselectedPatientId] = useState<number | null>(null); // Track the selected appointment for the dialog
  const [currentEvents, setCurrentEvents] = useState<EventApi[]>([]);
  const [resources, setResources] = useState<Resource[]>([]);
  const [events, setEvents] = useState<Event[]>([]);
  const calendarRef = createRef<FullCalendar>();
  const [openEventModal, setOpenEventModal] = useState(false);
  const [event, setEvent] = useState<Event>();
  const [groupCheck, setGroupCheck] = useState<boolean>(true);
  const [message, setMessage] = useState<string>("");
  const [openMessage, setOpenMessage] = useState<boolean>(false);
  const [date, setDate] = useState<string>(() => {
    return localStorage.getItem("lastViewedDate") || moment(new Date()).format("YYYY-MM-DD");
  });
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [clickedTS, setModalTS] = useState<any>();
  console.log("isContinuous for initial Calendar state", isContinuous);
  const [continuousView, setContinuousView] = useState<boolean>(isContinuous);
  const [isContextMenuOpen, setIsContextMenuOpen] = useState<boolean>(false);
  const [contextMenuEvent, setContextMenuEvent] = useState<Event>();
  const [mousePosition, setMousePosition] = useState<MousePosition>({ mouseX: null, mouseY: null });
  const [selectedUser, setSelectedUser] = useState<UserDto>();
  const [handleEventChangeForResizing, setHandleEventChangeForResizing] = useState(true);
  const [showNotesDialog, setShowNotesDialog] = useState<boolean>(false);
  const { frequencyValidation, hasTherapistCredentials, isPatientEventOverlapping } = useCalendarValidations();
  const [patientAvailability, setPatientAvailability ] = useState<PatientAvailabilityDto>();
  const [isAppointmentEditOpen, setIsAppointmentEditOpen] = useState<boolean>(false);
  const [dataRefreshFlag, setDataRefreshFlag] = useState<boolean>(false);

  const slotMinTime = "08:00:00";
  const slotMaxTime = "21:00:00";
  const heatTreaments = ["PA", "HL"];

  let eventGuid = 0;

  const createEventId = () => {
    return String(eventGuid++);
  };

  const handleDateSelect = (selectInfo: DateSelectArg) => {
    // if (shelfEvents?.length! > 0)
    //   return;

    let calendarApi = selectInfo.view.calendar;
    const event: Event = {
      id: createEventId(),
      title: "",
      start: selectInfo.startStr,
      end: selectInfo.endStr,
      resourceId: selectInfo.resource?.id,
      allDay: selectInfo.allDay,
    };
    calendarApi.addEvent(event);
    console.log("event added to calendar", event);
  };

  const handleEventClick = (clickInfo: EventClickArg) => {
    // Remove 'selected' class from all events
    document.querySelectorAll('.fc-event.selected').forEach((eventEl) => {
      eventEl.classList.remove('selected');
    });

    // Add 'selected' class to the clicked event
    const clickedEventEl = clickInfo.el;
    if (clickedEventEl) {
      clickedEventEl.classList.add('selected');
    }
  };

  const handleEvents = (events: EventApi[]) => {
    // console.log('handleEvents', events);
    setCurrentEvents(events);
  };

  const getSelectedDate = (): Date => {
    let calendarApi = calendarRef.current?.getApi()!;
    return calendarApi?.getDate();
  };

  const callbackDatesSet = () => {
    let date = getSelectedDate();
    if (date) setDate(moment(date).format("YYYY-MM-DD"));
    return onDatesSet(date);
  };

  const handleEventAdd = (eventAddInfo: EventAddArg) => {
    console.log("eventAddInfo", eventAddInfo.event.toPlainObject());

    if (eventAddInfo.event.extendedProps.type === "TimeSlot") {
      // Don't allow timeslots to be added independently
      return;
    }
    
    const event: Event = {
      id: undefined, // this is a new event
      title: eventAddInfo.event.title,
      start: eventAddInfo.event.start?.toString()!,
      end: eventAddInfo.event.end?.toString()!,
      resourceId: eventAddInfo.event._def.resourceIds?.toString()!,
      allDay: eventAddInfo.event.allDay,
      timeSlot: {
        start: eventAddInfo.event.start?.toString()!,
        end: eventAddInfo.event.end?.toString()!,
      },
    };
    console.log("add event modal for:", event);
    setEvent(event);
    setOpenEventModal(true);

    // return onCreateEvent && onCreateEvent(event!);
  };

  const handleEventChange = (eventChangeInfo: EventChangeArg) => {
    if (handleEventChangeForResizing) {
      // handle event change only for single timeslots here, multiple timeslots are handled in handleEventDrop
      if (eventChangeInfo.event.extendedProps.multipleTimeSlots === true) {
        return;
      }
      console.log("eventChangeInfo", eventChangeInfo.event.toPlainObject());

      const event: Event = {
        id: eventChangeInfo.event.id,
        title: eventChangeInfo.event.title,
        start: eventChangeInfo.event.start?.toISOString()!,
        end: eventChangeInfo.event.end?.toISOString()!,
        resourceId: eventChangeInfo.event._def.resourceIds?.toString()!,
        allDay: eventChangeInfo.event.allDay,
        appointmentId: eventChangeInfo.event.extendedProps.appointmentId,
        type: eventChangeInfo.event.extendedProps.type,
      };

      const abbreviation = eventChangeInfo.event.extendedProps.timeSlot?.therapy?.abbreviation;
      const userId = eventChangeInfo.event.getResources()[0]?.id;

      // Check if the event is within working hours
      if (isEventOutsideOfWorkingHours(eventChangeInfo.event)) {
        if (window.confirm("Der Termin liegt außerhalb der Arbeitszeit, möchten Sie Überstunden ändern oder reparieren?")) {
          // api call to repair overtimes
          repairOvertimes(eventChangeInfo.event, userId);
        }
      }
      
      if (userId && abbreviation)
        hasTherapistCredentials(userId!, abbreviation)
        .then((result: boolean) => {
          if (!result) {
            notificationStore.showMessage("Therapeut hat kein Heilmittel für diese Therapie: " + abbreviation, "warning");
            return eventChangeInfo.revert();
          }
          else {
            console.log("event is going to be updated in the database:", event);
            return onChangeEvent && onChangeEvent(event);
          }
        });
    } else {
      setHandleEventChangeForResizing(true);
    }
  };

  const handleEventRemove = (eventRemoveInfo: EventRemoveArg) => {
    console.log("eventRemoveInfo", eventRemoveInfo);
    // return onRemoveEvent && onRemoveEvent(parseInt(eventRemoveInfo.event.id));
  };

  const handleEventReceive = async (eventReceiveInfo: EventReceiveArg) => {
    // Recieve external events
    console.log("eventReceiveInfo", eventReceiveInfo.event.toPlainObject());
    const originalEvent = eventReceiveInfo.event;
    const appointmentId = originalEvent.id.toString();
    const date = moment(originalEvent.start).format("YYYY-MM-DD");
    const userId = originalEvent.getResources()[0]?.id;
    const abbreviation = originalEvent.extendedProps.timeSlots?.[0]?.therapyRx?.therapy?.abbreviation;

    if (originalEvent.extendedProps.type === "TimeSlot") {
      // Don't allow timeslots as external events
      return;
    }

    try {
      const frequency = !isContinuous && await frequencyValidation(appointmentId, date);
      const credentials = await hasTherapistCredentials(userId, abbreviation);
      if (frequency && frequency.validity === "Invalid") {
        notificationStore.showMessage(frequency.message, "warning");
        originalEvent.remove(); // Remove the event if frequency validation fails
      } else if (!credentials) {
        notificationStore.showMessage("Therapeut hat kein Heilmittel für diese Therapie: " + abbreviation, "warning");
        originalEvent.remove(); // Remove the event if therapist has no credentials
      }
      else {
        if (onEventReceive) {
          onEventReceive(eventReceiveInfo);
        }
      }
    } catch (error) {
      console.error("Error during frequency validation:", error);
      originalEvent.remove(); // Optionally remove the event on error
    }
  };  

  const eventDragStart = (eventDragStartArg: EventDragStartArg) => {
    console.log("eventDragInfo", eventDragStartArg.event.toPlainObject());
    if (eventDragStartArg.event.extendedProps.multipleTimeSlots) {
      console.log("multipleTimeSlots");
      setMessage("Umzug Termin mit mehreren TimeSlots!");
      setOpenMessage(true);

      // // make grouped events draggable together
      // if (eventDragStartArg.event.groupId !== undefined)
      //   setGroupCheck(true);
    }
  };

  const eventDragStop = (eventDragStopArg: EventDragStopArg) => {
    console.log("eventDragInfo", eventDragStopArg.event.toPlainObject());
  };

  const handleEventDrop = (eventDropInfo: EventDropArg) => {
    // handle event drop only for multiple timeslots!
    if (eventDropInfo.event.extendedProps.multipleTimeSlots === false) {
      return;
    }
    console.log("event:", eventDropInfo.event.toPlainObject());

    // main event that is dragged
    const event: Event = {
      id: eventDropInfo.event.id,
      title: eventDropInfo.event.title,
      start: eventDropInfo.event.start?.toISOString()!,
      end: eventDropInfo.event.end?.toISOString()!,
      resourceId: eventDropInfo.event._def.resourceIds?.toString()!,
      allDay: eventDropInfo.event.allDay,
      appointmentId: eventDropInfo.event.extendedProps.appointmentId,
      type: eventDropInfo.event.extendedProps.type,
    };

    let isOutsideWorkingHours = false;

     // Check if the event is within working hours
     if (isEventOutsideOfWorkingHours(eventDropInfo.event)) {
      isOutsideWorkingHours = true;
    }

    const abbreviation = eventDropInfo.event.extendedProps.timeSlot?.therapy?.abbreviation;
    const userId = eventDropInfo.event.getResources()[0]?.id;

    if (userId && abbreviation)
      hasTherapistCredentials(userId, abbreviation)
      .then((result: boolean) => {
        if (!result) {
          notificationStore.showMessage("Therapeut hat kein Heilmittel für diese Therapie: " + abbreviation, "warning");
          return eventDropInfo.revert();
        }
      });

    const relatedEvents = eventDropInfo.relatedEvents.map((related) => {
      console.log("related event:", related.toPlainObject());
      const relevent: Event = {
        id: related.id,
        title: related.title,
        start: related.start?.toISOString()!,
        end: related.end?.toISOString()!,
        resourceId: related._def.resourceIds?.toString()!,
        allDay: related.allDay,
        appointmentId: related.extendedProps.appointmentId,
        type: related.extendedProps.type,
      };
      return relevent;
    });

    for (const related of eventDropInfo.relatedEvents) {
      const abbreviation = related.extendedProps.timeSlot?.therapy?.abbreviation;
      const userId = related.getResources()[0]?.id;

      // Check if the related event is within working hours
      if (isEventOutsideOfWorkingHours(related)) {
        isOutsideWorkingHours = true;
        // Break the loop if the event is outside working hours
        break;
      }

      if (userId && abbreviation)
        hasTherapistCredentials(userId, abbreviation)
          .then((result: boolean) => {
            if (!result) {
              notificationStore.showMessage("Therapeut hat kein Heilmittel für diese Therapie: " + abbreviation, "warning");
              return eventDropInfo.revert();
            }
          });
    };

    if (isOutsideWorkingHours) {
      if (window.confirm("Der Termin liegt außerhalb der Arbeitszeit, möchten Sie Überstunden ändern oder reparieren?"
)) {
        // api call to repair overtimes
        repairOvertimes(eventDropInfo.event, userId);
      }
    }

    console.log("event is going to be updated in the database:", event);
    return onChangeEvent && onChangeEvent(event, relatedEvents);
  };

  const isEventOutsideOfWorkingHours = (event: EventImpl): boolean => {
    const user = users?.find((u) => u.id === event._def.resourceIds?.toString());
    const workingHours = user?.weeklyWorkDays?.find((w) => w.dayOfWeek === event.start?.getDay() && w.isBreak === false);
  
    if (!workingHours) {
      return false;
    }
  
    const eventStartTime = moment(event.start).format("HH:mm");
    const eventEndTime = moment(event.end).format("HH:mm");
  
    const workingStartTime = moment(workingHours.startTime).format("HH:mm");
    const workingEndTime = moment(workingHours.endTime).format("HH:mm");
  
    const isOutside = moment(eventStartTime, "HH:mm").isBefore(moment(workingStartTime, "HH:mm")) ||
                      moment(eventEndTime, "HH:mm").isAfter(moment(workingEndTime, "HH:mm"));

    console.log("event times:", eventStartTime, eventEndTime, "is outside of working hours:", workingStartTime, workingEndTime);
    return isOutside;
  };

  const repairOvertimes = async (event: EventImpl, userId: string) : Promise<any> => {
    const user = users?.find((u) => u.id === userId);
    const workingHours = user?.weeklyWorkDays?.find((w) => w.dayOfWeek === event.start?.getDay());
    const start = event.start?.toISOString()!;
    const end = event.end?.toISOString()!;
    console.log("repairing overtimes for event", start, end);
    
    const result = await adjustOutsideAppointments(userId, start, end);
    console.log("repairing overtimes for event", result);
  }
  
  const handleCloseMessage = (event: any, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMessage(false);
  };

  // const handleEventModalClose = (selectedTimeSlot?: TimeSlotDto) => {

  //   if (event) {
  //     const ts: TimeSlotExt = {
  //       id: selectedTimeSlot?.id,
  //       start: event.start,
  //       end: event.end,
  //     }
  //     event.id = selectedTimeSlot?.appointmentId;
  //     event.timeSlot = ts;
  //     console.log('event is going to be added to the database:', event);
  //     setOpenEventModal(false);
  //     return onCreateEvent && onCreateEvent(event!);
  //   }

  // };

  const handleCreateEvent = (event: Event) => {
    console.log("event is going to be added to the database:", event);
    setOpenEventModal(false);
    return onCreateEvent && onCreateEvent(event);
  };

  const handleEventModalClose = () => {
    // remove the event from the calendar
    let calendarApi = calendarRef.current?.getApi()!;
    calendarApi.getEventById("0")?.remove();

    setOpenEventModal(false);
  };

  const onDateChange = (date: string) => {
    calendarRef.current?.getApi()?.gotoDate(date);
  };

  const handleCancelAppointment = (timeSlot: TimeSlotExt) => {
    console.log("Canceling appointment", timeSlot);
    // grab the appointment for this timeslot:
    const appointment = appointments?.find((a) => a.timeSlots?.some((ts) => ts.id === timeSlot.id));
    if (!appointment) {
      notificationStore.showMessage("Appointment not found for timeslot", "error");
      return;
    }
    setSelectedAppointment(appointment);
    setselectedPatientId(timeSlot.patient?.id!);
    setIsContextMenuOpen(false);
    setCancellationDialogOpen(true);
  };

  const handleCancelContinuousAppointment = (timeSlot: TimeSlotExt) => {
    console.log("Canceling continuous appointment", timeSlot);
    const appointment = continuousAppointments?.find((a) =>
      a.timeSlots?.some((ts) => ts.id === timeSlot.id)
    );
    if (!appointment) {
      notificationStore.showMessage("Appointment not found for timeslot", "error");
      return;
    }
    setSelectedContinuousAppointment(appointment);
    setIsContextMenuOpen(false);
    setContinuousCancelationWarningOpen(true);
  };

  const onCancelContinuousAppointmentConfirm = async () => {
    await cancelContinuousAppointment(selectedContinuousAppointment?.id!);
    setContinuousCancelationWarningOpen(false);
    onOutdated();
  };

  const handleCloseCancellation = (actionPerformed: boolean) => {
    setCancellationDialogOpen(false);
    if (actionPerformed) {
      onOutdated();
    }
  };

  const handleAttended = (timeSlot: TimeSlotExt) => {
    const appointment = appointments?.find((a) => a.timeSlots?.some((ts) => ts.id === timeSlot.id));
    console.log("Marking as attended", appointment);
    setAttended(appointment?.id!, !appointment?.attended!).then(() => {
      onOutdated();
      setIsContextMenuOpen(false);
    });
  };
  const handleCloseWarning=()=>{
    setWarningDialogMessage("")
  }

  const handleEditAppointment = (timeSlot: TimeSlotExt) => {
    const appointment = appointments?.find((a) => a.timeSlots?.some((ts) => ts.id === timeSlot.id));
    if (!appointment) {
      notificationStore.showMessage("Appointment not found for timeslot", "error");
      return;
    }
    console.log("Editing appointment", appointment);    
    setSelectedAppointment(appointment);
    setselectedPatientId(timeSlot.patient?.id!);
    setIsContextMenuOpen(false);
    setIsAppointmentEditOpen(true); 
  }

  useEffect(() => {
    // RESOURCES //////////////////////////////////////////////
    const resources = (selectedUser ? [selectedUser] : users)?.map((user, index) => {
      const selectedDate = getSelectedDate();
      const selectedDayIndex: number = getSelectedDate().getDay();

      const validWeeklyWorkDays = user.weeklyWorkDays?.filter((f) => {
        const validityStart = f.workPlan?.validityStart ? new Date(f.workPlan.validityStart) : null;
        const validityEnd = f.workPlan?.validityEnd ? new Date(f.workPlan.validityEnd) : null;

        return (
          f.dayOfWeek === selectedDayIndex &&
          (!validityStart || validityStart <= selectedDate) &&
          (!validityEnd || validityEnd >= selectedDate)
        );
      });

      const businessHours =
        validWeeklyWorkDays &&
        validWeeklyWorkDays.map((day) => {
          return {
            daysOfWeek: [day.dayOfWeek],
            startTime: moment(day.startTime).format("HH:mm"),
            endTime: moment(day.endTime).format("HH:mm"),
          };
        });

      let resource: Resource = {
        id: user.id!,
        title: user.nickName!,
        // eventColor: generateColor(user.nickName!),
        businessHours: businessHours,
        extendedProps: {
          link: user.id,
        },
      };
      return resource;
    });

    // add new resource for Patient Availability
    const patientAvailability = patientAvailabilities?.find((pa) => pa.dayOfWeek === getSelectedDate().getDay());    
    if (patientAvailability || (patientUnavailableTypes && patientUnavailableTypes.length > 0)) {
      console.log("adding patient availability to resources", patientAvailability);
      setPatientAvailability(patientAvailability);
      const newResource: Resource = {
        id: "0",  // id 0 is reserved for patient availability
        title: "PA",
        businessHours: [
          {
            daysOfWeek: [patientAvailability?.dayOfWeek],
            startTime: patientAvailability?.startTime ? moment(patientAvailability?.startTime).format("HH:mm") : slotMinTime,
            endTime: patientAvailability?.endTime ? moment(patientAvailability?.endTime).format("HH:mm"): slotMaxTime,
          },
        ],
      };
      resources?.push(newResource);
      console.log("newResource for patient availability", newResource);
    }

    setResources(resources!);

    // EVENTS //////////////////////////////////////////////
    const events: Event[] = [];

      // continuous appointments as events
    if (isContinuous) {
      const continuous = continuousAppointments
        ?.map((appointment: ContinuousAppointmentDto) => {
          let patient = appointment.timeSlots![0].therapyRx?.rx?.patient as PatientExt;
          let multipleTimeSlots = appointment.timeSlots!.length > 1;
          let evts = appointment.timeSlots?.map((timeSlot) => {
            const therapy = timeSlot.therapyRx?.therapy as TherapyExt;
            let event: Event = {
              id: timeSlot.id?.toString(), // should be a unique id
              title: patient.lastName,
              start: timeSlot.start!,
              end: timeSlot.end!,
              patient:patient,
              // startTime, endTime and daysOfWeek are used for recurring events
              startTime: moment(timeSlot.start!).format("HH:mm:ss"),
              endTime: moment(timeSlot.end!).format("HH:mm:ss"),
              daysOfWeek: [moment(timeSlot.start!).day().toString()],
              resourceId: timeSlot.user?.id,
              groupId: appointment.id?.toString(),
              appointmentId: appointment.id?.toString(),
              type: "TimeSlot",
              multipleTimeSlots,
              timeSlot: {
                id: timeSlot.id,
                patient: patient,
                therapy: therapy,
                user: timeSlot.user?.lastName!,
                visitType: timeSlot.therapyRx?.rx?.visitType,
                therapyRx: timeSlot.therapyRx,
                appointmentId: appointment.id?.toString(),
              },
              frequency: appointment.frequency as TherapyFrequencyExt,
              color: mapTherapyToColor(therapy.abbreviation),
              display: heatTreatmentBackground(therapy),
              containsHeatTreatment: heatTreaments.includes(therapy.abbreviation!),
            };
            return event;
          });

          // group contains heat treatments, note all other events
          if (evts?.some((event) => event.display === "background")) {
            console.log("grouping heat treatments");
            evts?.forEach((event) => {
              if (event.display !== "background")
                event.containsHeatTreatment = true;
            });
          }
          
          return evts!;
        })
        .flat();

      if (continuous) events.push(...continuous);
        
      const continuousEventTimeSlot = continuousEventTimeSlots?.map((eventTimeslot, index) => {
        let ets: Event = {
          id: eventTimeslot.id?.toString(),
          title: eventTimeslot.title!,
          start: eventTimeslot.start!,
          end: eventTimeslot.end!,
          startTime: moment(eventTimeslot.start!).format("HH:mm:ss"),
          endTime: moment(eventTimeslot.end!).format("HH:mm:ss"),
          daysOfWeek: [moment(eventTimeslot.start!).day().toString()],
          resourceId: eventTimeslot.user?.id,
          type: "ContinuousEventTimeSlot",
          editable: true,
          resourceEditable: true,
          resizable: true,
          color: mobiliTheme.palette.secondary.main,
        };
        return ets;
      });
      events.push(...continuousEventTimeSlot!);

      if (patientAvailability && patientAvailability.startTime && patientAvailability.endTime) {
        const paEvent: Event = {
          id: "0",  // id 0 is reserved for patient availability
          title: patientAvailability.patient?.lastName!,
          start: patientAvailability.startTime,
          end: patientAvailability.endTime,
          startTime: moment(patientAvailability.startTime!).format("HH:mm:ss"),
          endTime: moment(patientAvailability.endTime!).format("HH:mm:ss"),
          daysOfWeek: [patientAvailability.dayOfWeek?.toString()!],
          resourceId: "0",
          type: "PatientAvailability",
          color: mobiliTheme.palette.info.main,
          resourceEditable: false,
          editable: false,
          classNames: ["striped-event"],
        };
        events.push(paEvent);
      }
      
    } else {
      console.log("processing appointments into events");
      // timeslots as events
      const timeslots = appointments
        ?.map((appointment, index) => {
          let patient = appointment.timeSlots![0].therapyRx?.rx?.patient as PatientExt;
          let multipleTimeSlots = appointment.timeSlots!.length > 1;
          let evts = appointment.timeSlots?.map((timeSlot) => {
            const therapy = timeSlot.therapyRx?.therapy as TherapyExt;
            let event: Event = {
              id: timeSlot.id?.toString(), // should be a unique id
              title: patient.lastName,
              start: timeSlot.start!,
              end: timeSlot.end!,
              resourceId: timeSlot.user?.id,
              groupId: groupCheck ? appointment.id?.toString() + timeSlot.user?.id! : "",
              appointmentId: appointment.id?.toString(),
              type: "TimeSlot",
              multipleTimeSlots,
              patient:patient,

              timeSlot: {
                id: timeSlot.id,
                patient: patient,
                therapy: therapy,
                user: timeSlot.user?.lastName!,
                visitType: timeSlot.therapyRx?.rx?.visitType,
                therapyRx: timeSlot.therapyRx,
                appointmentId: appointment.id?.toString(),
                treatmentIndex: timeSlot.treatmentIndex,
              },
              frequency: appointment.frequency as TherapyFrequencyExt,
              color: appointment.attended
                ? mobiliTheme.palette.success.main
                : mapTherapyToColor(therapy.abbreviation),

              classNames: appointment.attended?["striped-event"]:[],

              attended: appointment.attended,
              editable: !appointment.attended,
              resizable: false,
              resourceEditable: !appointment.attended,
              display: heatTreatmentBackground(therapy),
              hasNotes: appointment.notes?.length! > 0,
              
              containsHeatTreatment: heatTreaments.includes(therapy.abbreviation!),
            };
            return event;
          });

          // group contains heat treatments, note all other events
          if (evts?.some((event) => event.display === "background")) {
            console.log("grouping heat treatments");
            evts?.forEach((event) => {
              if (event.display !== "background")
                event.containsHeatTreatment = true;
            });
          }

          return evts!;
        })
        .flat();
      
      // add new event for Patient Availability from patient_availability table
      if (patientAvailability) {
        const paEvent: Event = {
          id: "0",  // id 0 is reserved for patient availability
          title: patientAvailability.patient?.lastName!,
          start: patientAvailability.startTime ? patientAvailability.startTime : slotMinTime,
          end: patientAvailability.endTime ? patientAvailability.endTime : slotMaxTime,
          startTime: patientAvailability.startTime ? moment(patientAvailability.startTime).format("HH:mm:ss") : slotMinTime,
          endTime: patientAvailability.endTime ? moment(patientAvailability.endTime).format("HH:mm:ss") : slotMaxTime,
          daysOfWeek: [patientAvailability.dayOfWeek?.toString()!],
          resourceId: "0",
          type: "PatientAvailability",
          color: mobiliTheme.palette.info.main,
          resourceEditable: false,
          editable: false,
          classNames: ["striped-event"],
        };
        timeslots?.push(paEvent);
      }

      // add PatientUnavailable types from time_slots table
      if (patientUnavailableTypes) {
        const patientUnavailableEvents = patientUnavailableTypes.map((patientUnavailable, index) => {
          let pu: Event = {
            id: patientUnavailable.id?.toString(),
            title: patientUnavailable.patient?.lastName!,
            start: patientUnavailable.start!,
            end: patientUnavailable.end!,
            resourceId: "0",
            type: "PatientUnavailable",
            editable: true,
            resourceEditable: false,
            resizable: true,
            color: mobiliTheme.palette.error.main,
            classNames: ["striped-event"],
          };
          return pu;
        });
        timeslots?.push(...patientUnavailableEvents);
      }

      events.push(...timeslots!);

      // goneFishings as events
      const goneFishingsEvents = goneFishings?.map((goneFishing, index) => {
        let gfs: Event = {
          id: goneFishing.id?.toString(),
          title: goneFishing.user?.lastName!,
          start: goneFishing.start!,
          end: goneFishing.end!,
          resourceId: goneFishing?.user?.id,
          color: "black",
          type: "GoneFishing",
          editable: false,
          resizable: false,
          resourceEditable: false,
        };
        return gfs;
      });
      events.push(...goneFishingsEvents!);

      const leaveOfAbsencesEvents = leaveOfAbsences?.map((leaveOfAbsence, index) => {
        let loa: Event = {
          id: leaveOfAbsence.id?.toString(),
          title: leaveOfAbsence.loAType!,
          start: moment(leaveOfAbsence.start!).toISOString(),
          end: moment(leaveOfAbsence.end!).toISOString(),
          resourceId: leaveOfAbsence.user?.id,
          type: "LeaveOfAbsence",
          editable: true,
          resourceEditable: false,
          resizable: true,
          classNames: ["striped-event"],
          color: mobiliTheme.palette.info.main,
        };
        return loa;
      });
      events.push(...leaveOfAbsencesEvents!);

      const eventTimeslotsEvents = eventTimeslots?.map((eventTimeslot, index) => {
        let ets: Event = {
          id: eventTimeslot.id?.toString(),
          title: eventTimeslot.title!,
          start: eventTimeslot.start!,
          end: eventTimeslot.end!,
          resourceId: eventTimeslot.user?.id,
          type: "EventTimeSlot",
          editable: true,
          resourceEditable: true,
          resizable: true,
          color: mobiliTheme.palette.secondary.main,
        };
        return ets;
      });
      events.push(...eventTimeslotsEvents!);
      const lunchBreakEvents = lunchBreaks?.map((lunchBreak, index) => {
        let ets: Event = {
          id: lunchBreak.id?.toString(),
          title: "Pause",
          start: lunchBreak.start!,
          end: lunchBreak.end!,
          resourceId: lunchBreak.user?.id,
          type: "LunchBreak",
          editable: true,
          resourceEditable: true,
          resizable: true,
          color: mobiliTheme.palette.info.light,
        };
        return ets;
      });      
      events.push(...lunchBreakEvents!);

      const travelTimeEvents = travelTimes?.map((travelTime, index) => {
        let ets: Event = {
          id: travelTime.id?.toString(),
          title: "Fahrzeit",
          start: travelTime.start!,
          end: travelTime.end!,
          resourceId: travelTime.user?.id,
          type: "TravelTime",
          editable: true,
          resourceEditable: true,
          resizable: true,
          color: mobiliTheme.palette.info.dark,
        };
        return ets;
      }
      );
      events.push(...travelTimeEvents!);
    }
    
    setEvents(events!);
    console.log("events", events!);
  }, [
    appointments,
    continuousAppointments,
    goneFishings,
    leaveOfAbsences,
    eventTimeslots,
    continuousEventTimeSlots,
    users,
    groupCheck,
  ]);

  useEffect(() => {
    localStorage.setItem("lastViewedDate", date);
    return onDatesSet(new Date(date));
  }, [date]);

  const heatTreatmentBackground = (therapy: TherapyExt): string => {
    enum display {
      block = "block",
      background = "background",
      none = "none",
    }
    if (heatTreaments.includes(therapy.abbreviation!)) {
      return display.background;
    } else {
      return display.block;
    }
  };

  const renderEventContent = (eventContent: EventContentArg) => {    
    const timeSlot = eventContent.event.extendedProps.timeSlot as TimeSlotExt;
    const containsHeatTreatment = eventContent.event.extendedProps.containsHeatTreatment;
    const hasNotes = eventContent.event.extendedProps.hasNotes;
    const isBackgroundTreatment = heatTreaments.includes(timeSlot?.therapy?.abbreviation!);
    const type = eventContent.event.extendedProps.type;

    if (type === "TimeSlot") {
      if (isBackgroundTreatment) {
        return (
          <>
            <b>{moment(eventContent.event.startStr).format("HH:mm")} - {moment(eventContent.event.endStr).format("HH:mm")}</b> <br />
            <i>{eventContent.event.title}</i> <br />
            <span>{timeSlot?.therapy?.abbreviation}</span> <br />
          </>
        );
      } else {
        return (
          <>
            <b>{eventContent.timeText}</b>
            <span style={{float:"right"}}>
              {containsHeatTreatment && 
                <Tooltip title="Warmpackung inklusive">
                  <LocalFireDepartmentIcon fontSize={"small"} style={{ color: mobiliTheme.palette.secondary.main }}/>
                </Tooltip>
              }
              {hasNotes && 
                <Tooltip title="Notizen">
                  <span onClick={(e) => onNoteIconClick(e, timeSlot)}>
                    <NoteAltOutlinedIcon fontSize={"small"} sx={{cursor: "pointer"}}/>
                  </span>
                </Tooltip>
              }
              {timeSlot?.therapyRx?.rx?.isPerpetual &&
                <Tooltip title="Dauertermin">
                  <AllInclusiveIcon fontSize={"small"}/>
                </Tooltip>
              }
              {timeSlot?.treatmentIndex && 
                <span style={{position: "absolute", bottom: "2px", right: "2px"}}>
                  <Chip label={timeSlot?.treatmentIndex} size="small" sx={{fontSize: "10px", height:"15px" }} />
                </span>
              }
            </span> 
            <br />
            <i>{eventContent.event.title}</i> <br />
            <span>{timeSlot?.therapy?.abbreviation}</span> <br />
            <span>{timeSlot?.visitType}</span>
          </>
        );
      }
    }
    else {
      return (
        <>
          <b>{eventContent.timeText}</b>
          <br />
          <i>{eventContent.event.title}</i> <br />
        </>
      );
    }
  };

  const resourceLabelContent = (resource: ResourceLabelContentArg) => {
    if (resource.resource.id === "0")
      return <ScheduleIcon/>; 

    return <UserPopover resource={resource} onConfirm={onOutdated} />;
  };

  const handleContextMenuClose = () => {
    setIsContextMenuOpen(false);
    setMousePosition({ mouseX: null, mouseY: null });
  };

  const handleEventDidMount = (arg: EventMountArg) => {
    arg.el.addEventListener("click", (e) => {
      if (arg.event.extendedProps.type === "TimeSlot")
        {
          // e.preventDefault();
          // setModalTS(arg.event.extendedProps.timeSlot as TimeSlotExt);
          // onShowPatient(arg.event.extendedProps.timeSlot as TimeSlotExt);
          setModalTS(arg.event.extendedProps.timeSlot as TimeSlotExt);
          return onEventContentClick && onEventContentClick(arg.event.extendedProps.timeSlot as TimeSlotExt);
        }
    });

    arg.el.addEventListener("contextmenu", (e) => {
      e.preventDefault(); // Prevent default behavior (e.g., opening a context menu)

      // Check if the click has clientX and clientY properties (to ensure it's a valid click event)
      if (e.clientX && e.clientY) {
        setMousePosition({
          mouseX: e.clientX - 2,
          mouseY: e.clientY - 4,
        });

        // Create an event object to pass to the menu
        const event: Event = {
          id: arg.event.id,
          title: arg.event.title,
          start: arg.event.start?.toISOString()!,
          end: arg.event.end?.toISOString()!,
          resourceId: arg.event._def.resourceIds?.toString()!,
          allDay: arg.event.allDay,
          appointmentId: arg.event.extendedProps.appointmentId,
          type: arg.event.extendedProps.type,
          timeSlot: arg.event.extendedProps.timeSlot as TimeSlotExt,
          multipleTimeSlots: arg.event.extendedProps.multipleTimeSlots,
          groupId: arg.event.groupId,
          attended: arg.event.extendedProps.attended,
          frequency: arg.event.extendedProps.frequency,
        };

        // Set states to control the opening of the menu
        setIsContextMenuOpen(true);
        setModalTS(arg.event.extendedProps.timeSlot);
        setContextMenuEvent(event);
      }
    });
  };

  const handleRemoveGoneFishing = (event: Event) => {
    if (window.confirm(`Are you sure you want to delete the event '${event.title}'`)) {
      calendarRef.current?.getApi()?.getEventById(event.id!)?.remove();
      const timeslotId = event.id!;
      setIsContextMenuOpen(false);
      return onRemoveEvent && onRemoveEvent(timeslotId);
    }
  };

  const handleRemoveEventTimeSlot = (event: Event) => {
    if (window.confirm(`Are you sure you want to delete the event '${event.title}'`)) {
      calendarRef.current?.getApi()?.getEventById(event.id!)?.remove();
      const timeslotId = event.id!;
      setIsContextMenuOpen(false);
      return onRemoveEvent && onRemoveEvent(timeslotId);
    }
  };

  const handleRemoveLeaveOfAbsence = (event: Event) => {
    if (window.confirm(`Are you sure you want to delete the event '${event.title}'`)) {
      calendarRef.current?.getApi()?.getEventById(event.id!)?.remove();
      const timeslotId = event.id!;
      setIsContextMenuOpen(false);
      return onRemoveEvent && onRemoveEvent(timeslotId);
    }
  };

  const handleRemoveContinuousEventTimeSlot = (event: Event) => {
    if (window.confirm(`Are you sure you want to delete the event '${event.title}'`)) {
      calendarRef.current?.getApi()?.getEventById(event.id!)?.remove();
      const timeslotId = event.id!;
      setIsContextMenuOpen(false);
      return onRemoveContinuousEvent && onRemoveContinuousEvent(timeslotId);
    }
  };

  const handleMoveEvent = (event: Event) => {
    console.log("Moving event from calendar", event);
    setIsContextMenuOpen(false);
    calendarRef.current
      ?.getApi()
      ?.getEvents()
      .forEach((e) => {
        if (e.groupId === event.groupId) {
          e.remove();
        }
      });
    console.log("Termin aus dem kalender entfernt");
    return onMoveEventDate && onMoveEventDate(event);
  };

  const handleDateClick = (clickInfo: DateClickArg) => {
    console.log("date click", clickInfo);
    if (isShelfEvents) {
      if (clickInfo.jsEvent.clientX && clickInfo.jsEvent.clientY) {
        setMousePosition({
          mouseX: clickInfo.jsEvent.clientX - 2,
          mouseY: clickInfo.jsEvent.clientY - 4,
        });
        const event: Event = {
          type: "ShelfEvent",
          title: undefined!,
          start: clickInfo.dateStr,
          resourceId: clickInfo.resource?.id,
        };
        setIsContextMenuOpen(true);
        setContextMenuEvent(event);
        // return onAddShelfEvent && onAddShelfEvent(event);
      }
    }
  };

  const handleEventMouseEnter = ({ event, el, jsEvent }: EventHoveringArg) => {
    const ts = event.extendedProps.timeSlot as TimeSlotDto;
    const patient = event.extendedProps.patient as PatientDto;

    let displayStr = "";
    if (patient) {
      displayStr =
        displayStr +
        `${patient.salutation ? patient.salutation : ""} ${patient.lastName}, ${patient.firstName}`;
    }
    if (ts) {
      displayStr = displayStr + `\nRezept: ${ts.therapyRx?.rx?.rxNumber}`;
    }
    console.log("event mouse enter", event.extendedProps, ts, patient);
    console.log("tooltip", displayStr);
    el.setAttribute("title", displayStr);
  };
  const handleAddShefEvent = (event: Event) => {
    console.log("Adding shelf event", event);
    setIsContextMenuOpen(false);
    return onAddShelfEvent && onAddShelfEvent(event);
  };

  const onGroupChecked = (checked: boolean) => {
    setGroupCheck(checked);
    onOutdated();
  };

  const onUserChange = (userId: string) => {
    if (userId) {
      // user weekly view
      const user = users?.find((u) => u.id === userId);
      if (!user) return;
      setSelectedUser(user);
      changeCalendarView("resourceTimeGridWeek");
    } else {
      // general daily view
      setSelectedUser(undefined);
      changeCalendarView("resourceTimeGridDay");
    }
  };

  const changeCalendarView = (view: string) => {
    let calendarApi = calendarRef.current?.getApi();
    calendarApi?.changeView(view);
    if (view === "resourceTimeGridWeek") {
      calendarApi?.view?.calendar.setOption("dayHeaderFormat", { weekday: 'long' });
    }
  }

  const handleContinuousViewChange = (continuous: boolean) => {
    changeCalendarView("resourceTimeGridDay");
    setSelectedUser(undefined);
    return onContinuousViewChange(continuous);
  };

  const handleEventResize = (info: EventResizeDoneArg) => {
    const event = events.find((e) => e.id === info.event.id);
    if (event && !event.resizable) {
      info.revert();
      setHandleEventChangeForResizing(false);
    } else {
      // Handle the resize action if the event is resizable
      console.log("Event resized:", info.event);
    }
  };

  const onNoteIconClick = (event: any, timeSlot: TimeSlotExt) => {
    event.stopPropagation();
    setModalTS(timeSlot);
    setShowNotesDialog(true);
  }

  const onCloseNotesDialogEvent = () => {
    setShowNotesDialog(false);
    return onCloseNotesDialog && onCloseNotesDialog();
  }

  const eventAllow = (dropInfo: DateSpanApi, draggedEvent: EventApi | null): boolean => {
    
    const start = moment(dropInfo.start).toDate();
    const end = moment(dropInfo.end).toDate();
    
    // patient events overlapping validation
    const isOverlapping = isPatientEventOverlapping(start, end, currentEvents, draggedEvent!);
    if (isOverlapping) {
      console.log("eventAllow", "Patient event is overlapping");
      // notificationStore.showMessage("Patienten-Termin überschneidet sich mit einem anderen Termin", "warning");
      return false;
    }

    if (dropInfo.resource?.id === "0") {
      console.log("eventAllow", "add events to PA column is not allowed");
      return false;
    }
    
    if (patientAvailability && parseInt(draggedEvent?.extendedProps.patient?.id) === patientAvailability.patient?.id) {
      const paStart = moment(patientAvailability.startTime).format("HH:mm");
      const paEnd = moment(patientAvailability.endTime).format("HH:mm");
      const eventStart = moment(start).format("HH:mm");
      const eventEnd = moment(end).format("HH:mm");

      if (moment(eventStart, "HH:mm").isBetween(moment(paStart, "HH:mm"), moment(paEnd, "HH:mm")) ||
          moment(eventEnd, "HH:mm").isBetween(moment(paStart, "HH:mm"), moment(paEnd, "HH:mm"))) {
        console.log("eventAllow", "Patient is not available between " + paStart + " and " + paEnd);
        notificationStore.showMessage("Patient ist nicht verfügbar zwischen " + paStart + " und " + paEnd, "warning");
        return false;
      }
    }

    for (const patientAvailability of patientUnavailableTypes || []) {
      const paStart = moment(patientAvailability.start);
      const paEnd = moment(patientAvailability.end);
      const eventStart = moment(start);
      const eventEnd = moment(end);

      if (paStart.isBefore(eventEnd) && paEnd.isAfter(eventStart)) {
        console.log("eventAllow", "Patient is not available between " + paStart + " and " + paEnd);
        notificationStore.showMessage("Patient ist nicht verfügbar zwischen " + paStart.format("HH:mm") + " und " + paEnd.format("HH:mm"), "warning");
        return false;
      }
    };

    return true;
  }

  const handleEventOverlap = (stillEvent: EventApi, movingEvent: EventApi | null): boolean => {
    console.log("handleEventOverlap", 
      stillEvent.toPlainObject(), 
      movingEvent?.toPlainObject());

    if (stillEvent.display === "background" || movingEvent?.display === "background") {
      return true;
    }

    console.log("handleEventOverlap", "Appointment cannot overlap");
    // notificationStore.showMessage("Termin kann nicht überlappen", "warning");
    return false;
  };

  const handleSelectable = (selectInfo: DateSpanApi) => {
    if (isShelfEvents) {
      return false;
    }
    if (selectInfo.resource?.id === "0") {
      return false;
    }
    return true;
  }

  const handleResourceLabelDidMount = (arg: ResourceLabelMountArg) => {
    if (arg.resource.id === "0") {
      arg.el.style.backgroundColor = mobiliTheme.palette.secondary.light;
    }
  }

  const handleCloseEditAppointment = (actionPerformed: boolean) => {
    setIsAppointmentEditOpen(false);
    if (actionPerformed) {
      onOutdated();
    }
  }

  const handleCloseNotesDialog = () => {
    onOutdated();
  }

  return (
    <>
      <Grid container direction={"column"} sx={{ border: "0px dotted black", height: "100%" }}>
        <Grid item sx={{ border: "0px solid blue", display: "flex" }}>
          <HeaderToolbar
            date={date}
            calendarRef={calendarRef}
            isContinuous={isContinuous}
            groupCheck={groupCheck}
            onDatesSet={onDatesSet}
            onDateChange={onDateChange}
            onOutdated={onOutdated}
            onContinuousViewChange={handleContinuousViewChange}
            onGroupChecked={onGroupChecked}
            users={users!}
            onUserChange={(userId) => onUserChange(userId)}
          />
        </Grid>
        {/* <Grid item sx={{ border: "0px dotted lightgreen", flexGrow: 1 }}> */}
          <TopLevelPaper sx={{  flexGrow: 1 }}>
            <FullCalendar
              height="100%"
              ref={calendarRef}
              locale={"de"}
              initialDate={new Date(date)}
              schedulerLicenseKey="0067980715-fcs-1697454050"
              plugins={[
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin,
                resourceDayGridPlugin,
                resourceTimeGridPlugin,
                resourceTimelinePlugin,
              ]}
              headerToolbar={false}
              titleFormat={
                isContinuous
                  ? { weekday: "long", separator: " bis " }
                  : { year: "numeric", month: "long", day: "numeric", weekday: "long" }
              }
              buttonText={{
                today: "Heute",
                month: "Monat",
                year: "Jahr",
                week: "Woche",
                day: "Tag",
                list: "Liste",
              }}
              initialView="resourceTimeGridDay"
              views={{
                timeGridWeek: {
                  dayHeaderFormat: { weekday: "long" },
                },
              }}
              slotDuration={"00:05"}
              slotLabelFormat={{
                hour: "2-digit", //2-digit, numeric
                minute: "2-digit", //2-digit, numeric
                meridiem: false, //lowercase, short, narrow, false (display of AM/PM)
                hour12: false, //true, false
              }}
              eventTimeFormat={{
                hour: "2-digit", //2-digit, numeric
                minute: "2-digit", //2-digit, numeric
                meridiem: false, //lowercase, short, narrow, false (display of AM/PM)
                hour12: false, //true, false
              }}
              slotMinTime={slotMinTime}
              slotMaxTime={slotMaxTime}
              firstDay={1}
              defaultTimedEventDuration={"00:30:00"}
              editable={true}
              eventResizableFromStart={false}
              selectable={true}
              selectAllow={handleSelectable} // Use selectAllow to control the selectability
              selectMirror={true}
              dayMaxEvents={true}
              droppable={true}
              nowIndicator={true}
              allDaySlot={false}
              resources={resources}
              events={events}
              eventInteractive={true} // will cause all events to be focusable/tabbable.
              datesSet={callbackDatesSet}
              // loading={onLoading}
              select={handleDateSelect}
              eventContent={renderEventContent} // custom render function
              eventClick={handleEventClick}
              eventsSet={handleEvents} // called after events are initialized/added/changed/removed
              // you can update a remote database when these fire:
              eventAdd={handleEventAdd}
              eventChange={handleEventChange} // handle the changes for the event that was directly dragged
              eventRemove={handleEventRemove}
              eventDrop={handleEventDrop} // handle the changes for related events (grouped events) that are dragged along with the main event
              eventReceive={handleEventReceive}
              eventDragStart={eventDragStart}
              eventDragStop={eventDragStop}
              resourceLabelContent={resourceLabelContent}
              eventDidMount={handleEventDidMount}
              dateClick={handleDateClick}
              eventMouseEnter={handleEventMouseEnter}
              eventResize={handleEventResize}
              eventAllow={eventAllow}
              eventOverlap={handleEventOverlap}
              resourceLabelDidMount={handleResourceLabelDidMount}
            />
          </TopLevelPaper>
        {/* </Grid> */}
      </Grid>
      {warningDialogMessage!=="" && (
          <WarningDialog
            open={true} // Ensure the warning dialog is open
            onClose={handleCloseWarning} // Function to close the dialog
            title="Bearbeitungsstatus aktualisiert"
            content={warningDialogMessage}
            
          />
        )}
      {/* dialogues, models, menues */}
      <AddEventModal
        event={event}
        open={openEventModal}
        onConfirm={handleCreateEvent}
        onClose={handleEventModalClose}
        continuous={isContinuous}
      />
      <Backdrop open={isLoading} style={{ zIndex: 1300, color: mobiliTheme.palette.primary.light }}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar
        open={openMessage}
        autoHideDuration={6000}
        message={message}
        onClose={handleCloseMessage}
      />
      <ContextMenu
        open={isContextMenuOpen}
        event={contextMenuEvent!}
        timeSlot={clickedTS}
        continuous={isContinuous}
        mouseX={mousePosition.mouseX}
        mouseY={mousePosition.mouseY}
        onClose={handleContextMenuClose}
        onRemoveGoneFishing={handleRemoveGoneFishing}
        onRemoveEventTimeSlot={handleRemoveEventTimeSlot}
        onRemoveContinuousEventTimeSlot={handleRemoveContinuousEventTimeSlot}
        onRemoveLeaveOfAbsence={handleRemoveLeaveOfAbsence}
        onMoveEvent={handleMoveEvent}
        onAddShelfEvent={handleAddShefEvent}
        onCancelAppointment={handleCancelAppointment}
        onCancelContinuousAppointment={handleCancelContinuousAppointment}
        onToggleAttended={handleAttended}
        onEditAppointment={handleEditAppointment}
        onCloseNotesDialog={handleCloseNotesDialog}
      />
      {showNotesDialog && 
        <AppointmentNotes appointmentId={clickedTS?.appointmentId} showDialog={showNotesDialog} onClose={onCloseNotesDialogEvent}/>
      }
      <TimeSlotDialog open={isModalOpen} setOpen={setModalOpen} timeSlot={clickedTS} />
      {isCancellationDialogOpen && (
        <CancelAppointmentDialog
          patientId={selectedPatientId as number}
          isOpen={isCancellationDialogOpen}
          onClose={handleCloseCancellation}
          appointment={selectedAppointment as AppointmentDto}
        />
      )}
      {isContinuousCancelationWarningOpen && (
        <WarningDialog
          title="Stornieren"
          content="Sind Sie sicher, dass Sie den Dauert Termin stornieren möchten?"
          open={isContinuousCancelationWarningOpen}
          onClose={() => setContinuousCancelationWarningOpen(false)}
          onContinue={onCancelContinuousAppointmentConfirm}
        />
      )}
      {isAppointmentEditOpen &&
      <AppointmentEditCard
        open={isAppointmentEditOpen}
        appointment={selectedAppointment as AppointmentDto}
        onClose={handleCloseEditAppointment}
        patientId={selectedPatientId!}
        dataRefreshFlag={dataRefreshFlag}
        setDataRefreshFlag={setDataRefreshFlag}
      />}
    </>
  );
});

export default Calendar;
