/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import Form, { useForm } from "./Form";
import Controls from "../../atoms/Controls";
import {
  Box,
  IconButton,
  Button,
  Grid,
  Modal,
  CircularProgress,
  Tab,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  TextField,
  Typography,
} from "@mui/material";
import EditTable from "../../molecules/Editable";
import { v4 as uuidv4 } from "uuid";
import ReceiptDataEntryDialog from "./Receipt";
import { AddressDto, ContactInfoDTO, PatientDto } from "../../api-client";
import useStore from "../../helpers/useStore";
import { PatientsContext } from "../../stores/Patients/patients.provider";
import { MasterTablesContext } from "../../stores/MasterTables/MasterTables.provider";
import { useObserver } from "mobx-react";
import { DoctorContext } from "../../stores/Doctor/doctor.provider";
// import { notifySuccess } from "../../atoms/Notify";
import { Delete, Edit } from "@mui/icons-material";
import { RxStoreContext } from "../../stores/Rx/rx.provider";
import notificationStore from "../../stores/Notification/notificationStore";
import { handleParseInput } from "../../helpers/parseInput";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { commonTabStyle } from "../PatientOverview/tabStyles";
import WarningDialog from "../../atoms/WarningDialog";
import { TopLevelPaper } from "../../themes/StyledComponents";
import FlatCard from "../../atoms/FlatCard";
import { renderIntoDocument } from "react-dom/test-utils";
import PatientAddress, { PatientAddressRef } from "./PatientAddress";

const cardStyles = {
  card1: {
    // backgroundColor: "#ffcccc",
    margin: "10px",
    height: "100px",
  },
};

const FlatCardGridItem = ({ children }: any) => (
  <Grid item xs={12} md={6}>
    {" "}
    {/*  md={4} lg={3} xl={2}*/}
    {children}
  </Grid>
);

interface SelectedRow {
  therapy?: string;
  id: any;
  therapyId?: any;
  frequency?: any;
  // Add other properties if applicable
}

interface PatientPageProps {
  isEditing?: boolean;
  filteredValue?: any;
  updatedPatientData?: (data: any) => void;
  setActiveTab?: any;
  useDialog?: boolean;
  openPatientDialog?: boolean;
  onClosePatientDialog?: any;
}

type Optional<T> = {
  [P in keyof T]?: T[P];
};

export interface AddressDtoValidation extends Omit<AddressDto, "contactInfo"> {
  id: string;
  contactInfo: Optional<ContactInfoDTO>;
}
// Define a type for updatedValues
type UpdatedValuesType = {
  rxs: any[]; // Replace 'any[]' with the actual type of 'rxs'
  // Add other properties as needed
  addresses: AddressDto[];
};

const PatientPage: React.FC<PatientPageProps> = ({
  isEditing,
  filteredValue,
  updatedPatientData,
  setActiveTab, // Receive the callback prop
  useDialog,
  openPatientDialog,
  onClosePatientDialog,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<SelectedRow | null>(null);
  const PatientsStore = useStore(PatientsContext);
  const { postPatients, searchPatient } = PatientsStore;
  const RxStore = useStore(RxStoreContext);
  const { deleteRx } = RxStore;
  const [selectedOption, setSelectedOption] = useState(""); // Initialize with an empty string
  const [loading, setLoading] = useState(false);
  const DoctorStore = useStore(DoctorContext);
  // Initialize updatedValues with the expected type
  const [updatedValues, setUpdatedValues] = useState<UpdatedValuesType | null>(null);
  const { getAllDoctors } = DoctorStore;
  const [addressesFormValidationErrors, setAddressesFormValidationErrors] =
    useState<(AddressDtoValidation | null)[]>();

  const patientAddressRef = useRef<PatientAddressRef>(null);

  const fetchDoctorOptions = async () => {
    try {
      const response = await getAllDoctors();
      console.log("Resppnse", response);
      // setDoctorOptions(response);
    } catch (error) {
      console.error("Error fetching doctor options:", error);
    }
  };
  useEffect(() => {
    fetchDoctorOptions();
  }, []);

  const [rxtableData, setrxTableData] = useState<any>([]);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleDeleteRx = async (selectedRx: any) => {
    console.log("Selected Rx:", selectedRx);

    try {
      let response;
      if (selectedRx.rxNumber) {
        response = await deleteRx(selectedRx.id);
        if (response) {
          const updatedRxs = values.rxs.filter((rx: any) => rx.id !== selectedRx.id);
          setValues((prevValues: any) => ({
            ...prevValues,
            rxs: updatedRxs,
          }));
          setrxTableData(updatedRxs);
        }
      } else {
        // If no rxNumber, remove the row from the table without deleting from the backend
        const updatedRxs = values.rxs.filter((rx: any) => rx.id !== selectedRx.id);
        setValues((prevValues: any) => ({
          ...prevValues,
          rxs: updatedRxs,
        }));
        setrxTableData(updatedRxs);
      }
    } catch (error) {
      console.error("Error deleting row:", error);
    }
  };
  const handleRowDoubleClick = (row: any) => {
    console.log("Row", row);
    setSelectedRow(row);
    if (values.rxs.length == 0) {
      setValues((prevValues: any) => ({
        ...prevValues,
        rxs: [row],
      }));
    }
    setOpenDialog(true);
  };
  const [showFields, setShowFields] = useState(false);

  const handleToggleFields = (event: any) => {
    console.log("Event.target", event.target, values.alternativeBillingLastname);
    setShowFields(event.target.value);
  };
  //Add PatientDto to check with David for id and dob
  const initialFValues: PatientDto = {
    salutation: "",
    lastName: "",
    firstName: "",
    dob: "",
    status: "",
    payer: "",
    tarifGroupId: "",
    insuranceNo: "",
    coPayment: false,
    street: "",
    country: "",
    zip: "",
    city: "",
    letterOfAddress: "",
    phone1: "",
    phone2: "",
    mobile: "",
    email: "",
    smsDesired: false,
    // info: "",
    // note: "",
    // personType: "",
    notes: [],
    rxs: [],
    alternativeBillingFirstnames: "",
    alternativeBillingLastname: "",
    alternativeBillingTel: "",
    alternativeBillingFax: "",
    alternativeBillingMobile: "",
    alternativeBillingAddressline1: "",
    alternativeBillingAddressline2: "",
    alternativeBillingCity: "",
    alternativeBillingZip: "",
    alternativeBillingEmail: "",
    treatmentBusinessName: "",
    treatmentTel: "",
    treatmentFax: "",
    treatmentMobile: "",
    treatmentAddressline1: "",
    treatmentAddressline2: "",
    treatmentCity: "",
    treatmentZip: "",
    treatmentEmail: "",
    addresses: [],
    // Add more fields as needed
  };

  const fetchAndFormatPatientData = async (filteredValue: any) => {
    try {
      const response = await searchPatient(filteredValue);
      if (!response) {
        throw new Error(`No data found for patient`);
      }

      const formattedValues = {
        ...response,
        payer: response.payer?.name,
        smsDesired: response?.smsDesired,
        rxs: response.rxs?.filter((rx: any) => rx.deletedAt == null),
        addresses: response?.addresses,
        // Set other properties from the response object as needed
      };
      // Set values to formattedValues
      setValues(formattedValues);

      // Set updatedValues to formattedValues to trigger the effect
      setUpdatedValues(formattedValues);
      // const rxs = response.rxs.map((rx: any) => ({
      //   rxNumber: rx.rxNumber,
      //   id: rx.id,
      //   date: rx.date,
      //   number: rx.therapies[0]?.amount,
      //   therapy: rx.therapies[0]?.therapy?.abbreviation,
      //   therapyId: rx.therapies[0]?.id,
      //   frequency:rx.therapies[0]?.frequency.text

      // }));

      // const rxs:any = {
      //   rxNumber: rx.rxNumber,
      //   id: rx.id,
      //   date: rx.date,
      //   number: rx.number,
      //   therapy: rx.therapy,
      //   therapyId: rx.therapyId,
      //   frequency: rx.frequency,
      // };

      // rx.therapies.forEach((therapy:any, index:any) => {
      //   if (index === 0) {
      //     // For the first therapy, use the existing fields
      //     rxs.number = therapy.number;
      //     rxs.therapy = therapy.therapy;
      //     rxs.frequency = therapy.frequency;
      //   } else {
      //     // For subsequent therapies, add index to the field names
      //     rxs[`number${index}`] = therapy.number;
      //     rxs[`therapy${index}`] = therapy.therapy;
      //     rxs[`frequency${index}`] = therapy.frequency;
      //   }
      // });
      // setValues(response);
      // const rxs = response.rxs.map((rx:any) => ({
      //   id:rx.id,
      //   date: rx.date,
      //   number: rx.therapies[0].number,
      //   therapy: rx.therapies[0].therapy.abbreviation
      // }));
      console.log("UIII", values, formattedValues);

      // handleAddColumnSet()
      // handlerxTableDataChange(response.rxs);
      // setrxTableData(rxs);
    } catch (error) {
      console.log("error", error);
    }
  };
  // Use a useEffect to watch for changes in updatedValues
  useEffect(() => {
    if (updatedValues !== null && "rxs" in updatedValues) {
      // Call handlerxTableDataChange with the updated values
      console.log("UPKJAJKKSK", updatedValues);
      setrxTableData(updatedValues.rxs);
      handlerxTableDataChange(updatedValues?.rxs);
      setUpdatedValues(null); // Reset updatedValues
    }
  }, [updatedValues]);

  useEffect(() => {
    if (isEditing && filteredValue) {
      const fetchData = async () => {
        fetchAndFormatPatientData(filteredValue);
      };

      fetchData();
      // setShowFields(true);

      // dummyApiFetch().then((data) => {
      //   setValues(data);
      // });
    }
    // Make API request to fetch patient data based on patient ID or any other identifier
    else {
      setValues(initialFValues);
    }
  }, [isEditing]); // Before it was [isEditing, filteredValue]

  const validateAddressObj = (address: AddressDto): AddressDtoValidation => {
    let validationObj: AddressDtoValidation = {
      ...address,
      id: address.id!,
      contactInfo: {},
    };

    let contactInfoValidation: Optional<ContactInfoDTO> = {};
    //Meldeadresse check
    if (address.isPrimary && !address.contactInfo?.mobile && !address.contactInfo?.phone1) {
      contactInfoValidation["mobile"] = "telefonnummer ist erforderlich";
    }

    //Rechnungsaddresse
    if (address.isAlternativeBilling) {
      contactInfoValidation["addressLine1"] = address?.contactInfo?.addressLine1
        ? ""
        : "Pflichtfeld";
      contactInfoValidation["zip"] = address?.contactInfo?.zip ? "" : "Pflichtfeld";
      contactInfoValidation["city"] = address?.contactInfo?.city ? "" : "Pflichtfeld";

      //Full name
      if (!address.isPrimary) {
        contactInfoValidation["salutation"] = address?.contactInfo?.salutation ? "" : "Pflichtfeld";
        contactInfoValidation["lastName"] = address?.contactInfo?.lastName ? "" : "Pflichtfeld";
        contactInfoValidation["firstNames"] = address?.contactInfo?.firstNames ? "" : "Pflichtfeld";
      }
    }

    //Anfahrtsaddresse
    if (address.isVisit && !(address?.contactInfo?.zip && address.contactInfo?.addressLine1)) {
      contactInfoValidation["zip"] = address.contactInfo?.zip ? "" : "Pflichtfeld";
      contactInfoValidation["addressLine1"] = address.contactInfo?.addressLine1
        ? ""
        : "Pflichtfeld";
    }

    if (address.isInstitution && !address?.contactInfo?.businessName?.trim()) {
      contactInfoValidation["businessName"] = address.contactInfo?.businessName
        ? ""
        : "Pflichtfeld";
    }

    validationObj.contactInfo = contactInfoValidation;

    return validationObj;
  };
  const getAddressesValidationErrors = (
    addresses: AddressDto[]
  ): (AddressDtoValidation | null)[] => {
    let addressesErrors: (AddressDtoValidation | null)[] = [];
    for (let i = 0; i < addresses.length; i++) {
      let address = addresses[i];
      let errorObj = validateAddressObj(address);
      //Check if all values in the contactInfo in errorObj object are empty strings
      const isValidContactInfo = Object.values(errorObj.contactInfo).every((x) => x === "");
      // if (!isValidContactInfo) {
      //   addressesErrors.push(errorObj);
      // } else {
      //   addressesErrors.push(null);
      // }
      addressesErrors.push(errorObj);
    }

    return addressesErrors;
  };
  const validate = (fieldValues = values) => {
    let temp: any = { ...errors };
    if ("firstName" in fieldValues) temp.firstName = fieldValues.firstName ? "" : "Pflichtfeld";
    if ("lastName" in fieldValues) temp.lastName = fieldValues.lastName ? "" : "Pflichtfeld";
    if ("dob" in fieldValues) temp.dob = fieldValues.dob ? "" : "Pflichtfeld";
    // if ("payer" in fieldValues)
    //   temp.payer = fieldValues.payer ? "" : "Pflichtfeld";
    if ("tarifGroupId" in fieldValues)
      temp.tarifGroupId = fieldValues.tarifGroupId ? "" : "Pflichtfeld";
    // if ("insuranceNo" in fieldValues)
    //   temp.insuranceNo = fieldValues.insuranceNo
    //     ? ""
    //     : "This field is required.";
    if ("coPayment" in fieldValues) {
      temp.coPayment = fieldValues.coPayment !== null ? "" : "Pflichtfeld";
    }

    // if ("personType" in fieldValues)
    // temp.personType = fieldValues.personType ? "" : "This field is required.";

    // if ('email' in fieldValues)
    //     temp.email = (/$^|.+@.+..+/).test(fieldValues.email) ? "" : "Email is not valid."

    // if ("mobile" in fieldValues || "phone1" in fieldValues || "phone2" in fieldValues) {
    //   temp.contact =
    //     fieldValues.mobile || fieldValues.phone1 || fieldValues.phone2 ? (
    //       ""
    //     ) : (
    //       <Typography sx={{ fontSize: "10px" }}>
    //         "Mindestens ein Kontakt (Mobiltelefon, Tel. 1, Tel. 2) ist erforderlich."
    //       </Typography>
    //     );
    // }

    setErrors({
      ...temp,
    });

    //Address validation
    const addressesErrors = getAddressesValidationErrors(values?.addresses);
    for (let i = 0; i < addressesErrors.length; i++) {
      let addressErrorObj = addressesErrors[i];
      if (addressErrorObj?.contactInfo) {
        if (!Object.values(addressErrorObj.contactInfo).every((x) => x === "")) {
          return false;
        }
      }
    }

    // Check if all values in the temp object are empty strings (valid)
    const isValid = Object.values(temp).every((x) => x === "");

    return isValid;
    // if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };
  const [unsavedChangesWarning, setShowunsavedChangesWarning] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const handleClosePatient = () => {
    if (unsavedChanges) {
      // If there are unsaved changes, set a state to open the warning dialog
      setShowunsavedChangesWarning(true);
    } else {
      onClosePatientDialog();
    }
  };

  const handleUnsavedChangesWarning = () => {
    handleSubmit();
    setShowunsavedChangesWarning(false);
  };

  const handleCloseUnsavedChangesWarning = () => {
    onClosePatientDialog();
    // If user cancels and chooses not to continue, close the warning dialog
    setShowunsavedChangesWarning(false);
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(
    initialFValues,
    true,
    validate,
    setUnsavedChanges
  );
  useEffect(() => {
    if (values?.addresses?.length) {
      let addressesErrors = getAddressesValidationErrors(values.addresses);

      setAddressesFormValidationErrors(addressesErrors);
      // setAddressesFormValidationErrors((prevFormValues: any) => ({
      //   ...prevFormValues,
      //   ...addressesErrors,
      // }));
    }
  }, [values?.addresses]);

  // if (values?.addresses.length) {
  //   values.addresses = values.addresses.sort((a: AddressDto, b: AddressDto) => {
  //     return a.id!.localeCompare(b.id!); // Compare ids lexicographically
  //   });
  // }

  const handlePayerChange = (e: any) => {
    const { name, value, id } = e.target;
    console.log("TArget", e.target, name);
    if (name === "payer") {
      // Autopopulate doctor and bsnr based on LANR selection
      const selectedPayer: any = insurer[0].find((option: any) => option.id === id);
      console.log("UZZHHHH", selectedPayer);
      if (selectedPayer) {
        setValues((prevFormValues: any) => ({
          ...prevFormValues,
          tarifGroupId: selectedPayer?.tarifGroup.id,
        }));
      }
      handleInputChange(e);
    }
  };

  const handleSubmit = async (event?: any) => {
    if (event) {
      event.preventDefault(); // Prevent default form submission behavior
    }
    // console.log("PostData", updatedValues);

    try {
      setLoading(true);
      const validatee = validate();
      console.log("AHJSJSJJSKAKka", validatee);
      if (validate()) {
        const updatedValues = {
          ...values,
          payer:
            insurer[0].find((option: any) => option.name === values.payer)?.id || values.payer,
        };
        const response = await postPatients(updatedValues as unknown as PatientDto);
        setUnsavedChanges(false);
        if (response) {
          if (updatedPatientData) {
            updatedPatientData(response as unknown as PatientDto);
          }
          console.log("VALUEEESS", values, updatedValues, filteredValue, response);
          if (isEditing) {
            //TO CHECK why it was added
            await fetchAndFormatPatientData(filteredValue);
            onClosePatientDialog();
          } else {
            setActiveTab(response);
            // Update the state to activate the newly added patient's tab
            setValues(initialFValues as any);
            setrxTableData([]);
          }
          // alert("Patient is added successfully");
        }
      } else {
        notificationStore.showMessage("Bitte füllen Sie alle erforderlichen Felder aus", "warning");
      }
    } catch (error) {
      console.log("Error adding patient:", error);
      notificationStore.showMessage("Fehler beim Hinzufügen des Patienten", "error", error);
    } finally {
      // Set loading back to false when the API call is complete
      setLoading(false);
    }
  };

  // const handleSubmit = (e: any) => {
  //   e.preventDefault();
  //   if (validate()) {
  //     // employeeService.insertEmployee(values)
  //     resetForm();
  //   }
  // };

  const handleCheckboxChange = (event: any) => {
    const { name, value } = event.target;
    setValues((prevValues: any) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  const [typedTherapy, setTypedTherapy] = useState("");
  const [parsedData, setParsedData] = useState({});

  const initialColumns: any[] = [
    // { field: "RezNum", headerName: "RezNum", width: 150 },
    // {
    //   field: "date",
    //   headerName: "Rezept Date",
    //   width: 150,
    //   // type: "date",
    // },
    {
      field: "Actions",
      width: 70,
      renderCell: (params: any) => (
        <>
          <IconButton onClick={() => handleRowDoubleClick(params.row)}>
            <Edit />
          </IconButton>
          <IconButton onClick={() => handleDeleteRx(params.row)}>
            <Delete />
          </IconButton>
        </>
      ),
    },
    { field: "creationDate", headerName: "Creation" },
    { field: "visitType", headerName: "Location", editable: true },
    { field: "therapy", headerName: "Heilmittel", editable: true },
    { field: "amount", headerName: "Menge", editable: true },
    { field: "frequency", headerName: "Frequency", editable: true },
  ];
  const [columnSets, setColumnSets] = useState<any[]>([]);
  const handleAddColumnSet = () => {
    const newColumnSet = generateColumnSet(columnSets.length + 1);
    setColumnSets((prevColumnSets) => [...prevColumnSets, newColumnSet]);
  };

  // Function to generate a new column set
  const generateColumnSet = (columnIndex: any) => [
    {
      field: `therapy${columnIndex}`,
      headerName: `Heilmittel ${columnIndex}`,
      // width: 150,
      editable: true,
    },
    {
      field: `amount${columnIndex}`,
      headerName: `Menge ${columnIndex}`,
      // width: 150,
      editable: true,
    },
    {
      field: `frequency${columnIndex}`,
      headerName: `Frequency ${columnIndex}`,
      // width: 150,
      editable: true,
    },
  ];

  // const handleAddColumnSet = () => {
  //   const columnIndex = columnSets.length + 1;

  //   const newColumnSet = [
  //     {
  //       field: `therapy${columnIndex}`,
  //       headerName: `Heilmittel ${columnIndex}`,
  //       width: 150,
  //       editable: true,
  //     },
  //     {
  //       field: `number${columnIndex}`,
  //       headerName: `Menge ${columnIndex}`,
  //       width: 150,
  //       editable: true,
  //     },
  //     {
  //       field: `frequency${columnIndex}`,
  //       headerName: `Frequency ${columnIndex}`,
  //       width: 150,
  //       editable: true,
  //     },
  //   ];

  //   setColumnSets((prevColumnSets) => [...prevColumnSets, newColumnSet]);
  // };
  const visitTypeMapping: { [key: string]: string } = {
    hm: "Heim",
    hb: "Haus",
    px: "Praxis",
    // Add more mappings as needed
  };

  const handlerxTableDataChange = (data: any) => {
    console.log("Data", data, values);

    const updatedRxs = data.map((rx: any) => {
      if (rx.visitType && visitTypeMapping.hasOwnProperty(rx.visitType)) {
        rx.visitType = visitTypeMapping[rx.visitType];
      }

      const existingRxIndex = values.rxs.findIndex((existingRx: any) => existingRx.id === rx.id);

      // const newRowAdded = Math.abs(
      //   rx.therapies?.length - columnSets.length - 1
      // );
      // console.log('ZUi',newRowAdded,rx.therapies?.length,columnSets.length)

      // // Add new column sets if needed
      // const newColumnSets = Array.from({ length: newRowAdded }, (_, index) =>
      //   generateColumnSet(columnSets.length + index + 1)
      // );
      // setColumnSets((prevColumnSets) => [...prevColumnSets, ...newColumnSets]);

      const therapyRxs: any[] = [];

      if (rx.therapyRxs) {
        console.log("ZUUJJJSJJSJ", rx, rxtableData);
        // const rxs = {
        //   rxNumber: rx.rxNumber,
        //   id: rx.id,
        //   date: rx.date,
        //   number: rx.therapies[0]?.amount,
        //   therapy: rx.therapies[0]?.therapy?.abbreviation,
        //   therapyId: rx.therapies[0]?.id,
        //   frequency:rx.therapies[0]?.frequency

        // };
        // setrxTableData([rxs] as any)
        const rxs: any = {
          rxNumber: rx.rxNumber,
          id: rx.id,
          creationDate: rx.creationDate,
          visitType: rx.visitType,
          // date: rx.date,
          amount: rx.amount,
          therapy: rx.therapy,
          therapyId: rx.therapyId,
          frequency: rx.frequency,
          isDoubled: rx.isDoubled,
          doubledTreatmentIds: rx.doubledTreatmentIds,
          rxSeries: rx.rxSeries,
        };
        //   number: rx.therapies[0]?.amount,
        //   therapy: rx.therapies[0]?.therapy?.abbreviation,
        //   therapyId: rx.therapies[0]?.id,
        //   frequency:rx.therapies[0]?.frequency

        rx.therapyRxs.forEach((therapy: any, index: any) => {
          if (index === 0) {
            // For the first therapy, use the existing fields
            rxs.therapyId = therapy.id;
            rxs.amount = therapy.amount;
            rxs.therapy = therapy.therapy?.abbreviation || therapy.therapy;
            rxs.frequency = therapy.frequency?.text || therapy.frequency;
            rxs.isDoubled = therapy.isDoubled;
            rxs.doubledTreatmentIds = therapy.doubledTreatmentIds;
            rxs.rxSeries = therapy.rxSeries;
          } else {
            // For subsequent therapies, add index to the field names
            rxs[`therapyId${index}`] = therapy.id;
            rxs[`amount${index}`] = therapy.amount;
            rxs[`therapy${index}`] = therapy.therapy?.abbreviation || therapy.therapy;
            rxs[`frequency${index}`] = therapy.frequency?.text || therapy.frequency;
            rxs[`isDoubled${index}`] = therapy.isDoubled;
            rxs[`doubledTreatmentIds${index}`] = therapy.doubledTreatmentIds;
            rxs[`rxSeries${index}`] = therapy.rxSeries;
          }
        });
        // rxs.location=rx.location
        console.log("Rx112", rxtableData);
        // Set the rxTableData with the newly constructed rxs array
        // setrxTableData((prevRxTableData:any) => {
        //   const updatedRxTableData = [...prevRxTableData, rxs];
        //   console.log('Updated Rx Table Data:', updatedRxTableData);
        //   return updatedRxTableData;
        // });
        setrxTableData((prevRxTableData: any) => {
          const updatedRxTableData = [...prevRxTableData];
          const existingIndex = updatedRxTableData.findIndex((item) => item.id === rxs.id);

          if (existingIndex !== -1) {
            updatedRxTableData[existingIndex] = rxs;
          } else {
            updatedRxTableData.push(rxs);
          }

          console.log("Updated Rx Table Data:", updatedRxTableData);
          return updatedRxTableData;
        });
        // setrxTableData([...rxtableData, rxs] as any);
        console.log("Rx", rxtableData, rxs);
        return rx;
      }
      console.log("Thzuuuu");
      for (const key in rx) {
        if (rx.hasOwnProperty(key) && key.startsWith("therapy") && !key.startsWith("therapyId")) {
          const index = key.slice(key.indexOf("y") + 1);
          const therapyNumber = rx[`amount${index}`] || "";
          const therapy = rx[`therapy${index}`] || "";
          const frequency = rx[`frequency${index}`] || "";
          const therapyId = rx[`therapyId${index}`] || "";

          // Check if therapy data exists before adding
          if (therapyNumber || therapy || frequency) {
            const therapyObj = {
              id: therapyId ? therapyId : uuidv4(),
              amount: therapyNumber,
              therapy: { id: therapy, abbreviation: therapy },
              frequency: { id: frequency, text: frequency },
              isDoubled: rx[`isDoubled${index}`] || false,
              doubledTreatmentIds: rx[`doubledTreatmentIds${index}`] || [],
              rxSeries: rx[`rxSeries${index}`],
            };
            console.log("Therapyiiii", therapyObj);
            therapyRxs.push(therapyObj);
          }
        }
      }
      console.log("JKKK", therapyRxs);
      // Update existing rx if found, otherwise create a new one
      if (existingRxIndex !== -1) {
        console.log("ABN", therapyRxs);
        return {
          ...values.rxs[existingRxIndex],
          visitType: rx.visitType,
          therapyRxs: therapyRxs,
        };
      } else {
        console.log("RXFGG", rx);
        return {
          rxNumber: rx.rxNumber,
          id: rx.id,
          visitType: rx.visitType,
          creationDate: rx.creationDate,
          // date: rx.date,
          therapyRxs: therapyRxs,
        };
      }
    });

    console.log("UPDFT", updatedRxs, rxtableData);
    const maxTherapies = Math.max(
      ...updatedRxs.map((rx: any) => (rx.therapyRxs ? rx.therapyRxs.length : 0))
    );

    // Calculate the number of new column sets to add
    const newColumnSetsCount = Math.max(0, maxTherapies - columnSets.length - 1);

    // Add new column sets if needed
    const newColumnSets = Array.from({ length: newColumnSetsCount }, (_, index) =>
      generateColumnSet(columnSets.length + index + 1)
    );
    setColumnSets((prevColumnSets) => [...prevColumnSets, ...newColumnSets]);
    // Check if any field other than 'id' has a value
    const haveRxs = (objects: any) => {
      for (const obj of objects) {
        let allValuesAreEmpty = true;

        for (const key in obj) {
          if (
            key !== "id" &&
            obj[key] !== undefined &&
            (!Array.isArray(obj[key]) || obj[key].length !== 0)
          ) {
            allValuesAreEmpty = false;
            break; // Exit the inner loop as soon as a non-empty value is found.
          }
        }

        if (allValuesAreEmpty) {
          return false; // Return false if all values are empty except 'id'.
        }
      }

      return true; // Return true if any object has at least one non-empty value.
    };

    setValues((prevValues: any) => ({
      ...prevValues,
      rxs: haveRxs(updatedRxs) ? updatedRxs : [],
    }));
  };

  // const handlerxTableDataChange = (data: any) => {
  //   console.log('Data', data);
  //   const updatedRxs = data.map((rx: any) => {

  //     const therapies: any[] = [];

  //     for (const key in rx) {
  //       const existingRxIndex = values.rxs.findIndex((existingRx: any) => existingRx.id === rx.id);
  //       if (rx.hasOwnProperty(key) && key.startsWith('number')) {
  //         const index = key.slice(6);
  //       const therapyNumber = rx[key];
  //       const therapy = rx[`therapy${index}`] || '';
  //       const frequency = rx[`frequency${index}`] || '';

  //       // Check if therapy data exists before adding
  //       if (therapyNumber || therapy || frequency) {
  //         const therapyObj = {
  //           therapyId: uuidv4(),
  //           number: therapyNumber,
  //           therapy: therapy,
  //           frequency: frequency,
  //         };
  //         therapies.push(therapyObj);
  //       }
  //     }}

  //     console.log('Data1', therapies);

  //     return {
  //       rxNumber: rx.rxNumber,
  //       id: rx.id,
  //       date: rx.date,
  //       therapies: therapies,
  //     };

  //   });

  // console.log('UPDFT',updatedRxs)
  //   setValues((prevValues: any) => ({
  //     ...prevValues,
  //     rxs: updatedRxs?updatedRxs:prevValues.rxs,
  //   }));
  // };

  // const handlerxTableDataChange = (data: any) => {
  //   console.log('Data',data)
  //   const updatedRxs = data.map((rx: any) => {
  //     const existingRxIndex = values.rxs.findIndex((existingRx: any) => existingRx.id === rx.id);

  //     if (existingRxIndex !== -1) {
  //       return {
  //         ...values.rxs[existingRxIndex],
  //         therapies: values.rxs[existingRxIndex].therapies.map((therapy: any,index: number) => {
  //           console.log('ARTZZZ',rx.therapyId,therapy.therapyId,rx,therapy,index)
  //           if (therapy.therapyId) {

  //             return {
  //               ...therapy,
  //               number: rx[`number${index}`] || rx.number,
  //             therapy: rx[`therapy${index}`] || rx.therapy,
  //             frequency: rx[`frequency${index}`] || rx.frequency,
  //             };
  //           }
  //           return therapy;
  //         }),
  //       };
  //     }

  //     const therapies: any[] = [];

  //     for (const key in rx) {
  //       if (rx.hasOwnProperty(key) && key.startsWith('number')) {
  //         const index = key.slice(6);
  //         const therapyObj = {
  //           therapyId: uuidv4(),
  //           number: rx[key],
  //           therapy: rx[`therapy${index}`] || '',
  //           frequency: rx[`frequency${index}`] || '',
  //         };
  //         therapies.push(therapyObj);
  //       }
  //     }
  //     console.log('Data1',therapies)
  //     return {
  //       rxNumber: rx.rxNumber,
  //       id: rx.id,
  //       date: rx.date,
  //       therapies: therapies,
  //     };
  //   });

  //   setValues((prevValues: any) => ({
  //     ...prevValues,
  //     rxs: updatedRxs,
  //   }));
  // };

  // const parseInput = (input:string) => {
  //   const parts:any = input.split(/\s+/);
  //   const result:any = {};
  //   let therapyIndex = 0;
  //   let mengeIndex = 0;

  //   for (let i = 0; i < parts.length; i++) {
  //     if (isNaN(parts[i])) {
  //       if (parts[i].includes('HB') || parts[i].includes('HM')) {
  //         result['location'] = parts[i];
  //       } else {
  //         const therapyKey = `therapy${therapyIndex === 0 ? '' : therapyIndex}`;
  //         result[therapyKey] = parts[i];
  //         therapyIndex++;
  //       }
  //     } else {
  //       const mengeKey = `number${mengeIndex === 0 ? '' : mengeIndex}`;
  //       result[mengeKey] = parts[i];
  //       mengeIndex++;
  //     }
  //   }

  //   return result;
  // };

  console.log("FinalValues", values, values?.smsDesired, rxtableData);
  const initialRows: any[] = [
    {
      id: 1,
      // RezNum: "1",
      Heilmittel: "KG",
      Menge: "3",
      rezeptDate: "26.05.2023",
    },
    {
      id: 2,
      // RezNum: "2",
      Heilmittel: "KGZ",
      Menge: "5",
      rezeptDate: "27.05.2023",
    },
    // Additional rows...
  ];
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };
  const handleTableDataChange = (data: any[]) => {
    setValues(data);
  };

  const masterTablesStore = useStore(MasterTablesContext);
  const { masterTablesData, getTarifGroupIds } = masterTablesStore;

  const insuranceStatus = masterTablesData
    .filter((obj: any) => "insuranceStatus" in obj) // Filter objects that have the insuranceStatus property
    .map((obj: any) => obj.insuranceStatus);
  const insurer = masterTablesData
    .filter((obj: any) => "insurer" in obj)
    .map((obj: any) => obj.insurer);
  const tarifGroupsMasterData = getTarifGroupIds();

  const [value, setValue] = React.useState("1");

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const handleAddressChange = (addresses: AddressDto[]) => {
    if (values) {
      // console.log("Adderesses on change", addresses);

      setValues((prevFormValues: any) => ({
        ...prevFormValues,
        addresses,
      }));
    }
  };
  const handleAddAddress = () => {
    let haveExistingAddresses = values?.addresses.length ? true : false;
    let newAddress: AddressDto = {
      id: "",
      isPrimary: !haveExistingAddresses,
      isAlternativeBilling: false,
      isVisit: false,
    };
    let addressArr = [newAddress];
    if (haveExistingAddresses) addressArr = [...values?.addresses!, ...addressArr];
    handleAddressChange(addressArr);
  };

  const FormContent = (
    <Form onSubmit={handleSubmit}>
      <Card>
        <CardHeader title={values.id ? `Patient (${values.id})` : "Neuer Patient"} />
        <CardContent>
          <Grid container spacing={2} justifyContent="center" alignItems="stretch">
            <FlatCardGridItem>
              <FlatCard title="Name und Persönliche Daten" fullHeight>
                <Controls.Select
                  name="salutation"
                  label="Anrede"
                  value={values?.salutation}
                  onChange={handleInputChange}
                  options={[
                    { label: "No Title", value: null },
                    { label: "Frau", value: "Frau" },
                    { label: "Herr", value: "Herr" },
                    { label: "Frau Dr.", value: "Frau Dr." },
                    { label: "Herr Dr.", value: "Herr Dr." },
                    { label: "Frau Dr. med.", value: "Frau Dr. med." },
                    { label: "Herr Dr. med.", value: "Herr Dr. med." },
                    { label: "Frau Prof. Dr.", value: "Frau Prof. Dr." },
                    { label: "Herr Prof. Dr.", value: "Herr Prof. Dr." },
                    { label: "Frau Prof. Dr. med.", value: "Frau Prof. Dr. med." },
                    { label: "Herr Prof. Dr. med.", value: "Herr Prof. Dr. med." },
                  ]}
                  onBlur={true}
                />

                <Controls.Input
                  name="lastName"
                  label="Nachname"
                  value={values?.lastName}
                  onChange={handleInputChange}
                  required
                  error={errors.lastName}
                />
                <Controls.Input
                  name="firstName"
                  label="Vorname"
                  value={values?.firstName}
                  onChange={handleInputChange}
                  required
                  error={errors.firstName}
                />
                <Controls.DatePicker
                  name="dob"
                  label="Geburtsdatum"
                  value={values?.dob}
                  required
                  onChange={handleInputChange}
                  error={errors.dob}
                />
                <Controls.Input
                  label="Briefliche Anrede"
                  name="letterOfAddress"
                  value={
                    values?.letterOfAddress || `${values?.salutation || ""} ${values?.lastName}`
                  }
                  onChange={handleInputChange}

                  // error={errors.email}
                />
              </FlatCard>
            </FlatCardGridItem>
            <FlatCardGridItem>
              <FlatCard title="Versichertendaten" fullHeight>
                <Controls.Select
                  name="status"
                  label="Status"
                  value={values?.status}
                  onChange={handleInputChange}
                  options={[
                    { label: "10000", value: "10000" },
                    { label: "30000", value: "30000" },
                    { label: "50000", value: "50000" },
                  ]}
                  disabled={
                    selectedOption === "Privat versicherter Patient" ||
                    selectedOption === "BG-Patient"
                  } // Update this line
                />
                <Controls.Select
                  name="payer"
                  label="Kostenträger"
                  id={1}
                  value={values?.payer}
                  onChange={handlePayerChange}
                  options={insurer[0]?.map((option: any) => ({
                    label: option.ik.toString(),
                    value: option.name.toString(),
                    id: option.id,
                  }))}
                  // required
                  // error={errors.payer}
                />
                <Controls.Select
                  name="tarifGroupId"
                  label="Tarif Group"
                  id={1}
                  value={values?.tarifGroupId}
                  onChange={handleInputChange}
                  options={tarifGroupsMasterData?.map((value: any) => ({
                    label: value.toString(),
                    value: value.toString(),
                  }))}
                  required
                  error={errors.tarifGroupId}
                />
                <Controls.Input
                  name="insuranceNo"
                  label="Versichertennummer"
                  value={values?.insuranceNo}
                  onChange={handleInputChange}
                  // error={errors.insuranceNo}
                  // required
                />
                {/* {selectedOption === "Gesetzlich versicherter Patient" && ( */}
                <Controls.Checkbox
                  name="coPayment"
                  label="Zuzahlungsfrei"
                  value={values?.coPayment}
                  onChange={handleInputChange}
                  required
                  error={errors.coPayment}
                />
              </FlatCard>
            </FlatCardGridItem>
            <FlatCardGridItem>
              {!isEditing && (
                <FlatCard title="Rezepte" fullHeight>
                  <Box style={{ width: "100%", overflowX: "auto" }}>
                    <EditTable
                      initialColumns={initialColumns}
                      initialRows={rxtableData}
                      columnSets={columnSets}
                      setSelectedRow={setSelectedRow}
                      setOpenDialog={setOpenDialog}
                      onTableDataChange={handlerxTableDataChange}
                      parseInput={handleParseInput}
                      handleAddColumnSet={handleAddColumnSet}
                      masterTablesData={masterTablesData}
                    />
                  </Box>
                </FlatCard>
              )}
            </FlatCardGridItem>
          </Grid>

          <FlatCard
            title={
              <div>
                <label>Adressen</label>
                <Button
                  variant="contained"
                  style={{ marginLeft: "2em" }}
                  onClick={handleAddAddress}
                >
                  Adresse hinzufügen
                </Button>
              </div>
            }
            // fullHeight
            sxContent={{
              padding: 0,
              margin: 0,
              width: "100%",
              backgroundColor: "none",
              border: "none",
            }}
          >
            <PatientAddress
              ref={patientAddressRef}
              patientId={filteredValue?.id}
              addresses={values?.addresses}
              onChange={(addresses: AddressDto[]) => {
                handleAddressChange(addresses);
              }}
              addressesFormValidationErrors={addressesFormValidationErrors}
            />
          </FlatCard>

          {/* Opens a dialog Box for receipt data entry */}
          {openDialog && (
            <ReceiptDataEntryDialog
              isEditing={isEditing}
              openDialog={openDialog}
              handleCloseDialog={handleCloseDialog}
              selectedRow={selectedRow}
              patientValues={values}
              masterData={masterTablesData}
              handleData={setValues}
              handleAddColumnSet={handleAddColumnSet}
              onTableDataChange={handlerxTableDataChange}
              rxTableData={setrxTableData}
              parseInput={handleParseInput}
              patientForm
            />
          )}
        </CardContent>
        <CardActions>
          {!useDialog && (
            <Button type="submit" disabled={loading}>
              Erstellen
            </Button>
          )}
        </CardActions>
      </Card>
      <Modal
        open={loading}
        aria-labelledby="loading-modal-title"
        aria-describedby="loading-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div>
          <CircularProgress size={60} />
        </div>
      </Modal>
      {unsavedChangesWarning && (
        <WarningDialog
          open={true} // Ensure the warning dialog is open
          onClose={handleCloseUnsavedChangesWarning} // Function to close the dialog
          onContinue={handleUnsavedChangesWarning} // Function to continue despite unsaved changes
          title="Änderungen speichern?"
          content="Du hast einige Änderungen gemacht. Möchtest du diese speichern, bevor du schließt?"
          isConfirmSave
        />
      )}
    </Form>
  );

  return useObserver(() => {
    return useDialog && openPatientDialog ? (
      <Grid container xs={12}>
        <TopLevelPaper>
          <Dialog
            PaperComponent={TopLevelPaper}
            open={openPatientDialog}
            onClose={handleClosePatient}
            maxWidth="xl"
            fullScreen
          >
            {/* <DialogTitle>
          Patient {values.id ? `(${values.id})` : ""}
        </DialogTitle> */}
            <DialogContent>{FormContent}</DialogContent>
            <DialogActions>
              <Button variant="text" onClick={handleClosePatient}>
                Abbrechen
              </Button>
              <Button onClick={handleSubmit} disabled={loading} variant="contained">
                Speichern
              </Button>
            </DialogActions>
          </Dialog>
        </TopLevelPaper>
      </Grid>
    ) : (
      <Grid container xs={12} sx={{ justifyContent: "center" }}>
        <Grid container xs={12} xl={7.5}>
          {FormContent}
        </Grid>
      </Grid>
    );
  });
};
export default PatientPage;
