import { action, makeAutoObservable } from "mobx";
import AuthStore from "../Auth/authStore";
import notificationStore from "../Notification/notificationStore";
import {
  ActivityDto,
  FindAllTicketsQueryFilters,
  TicketDto,
  TicketFilterDto,
  TicketsApi,
} from "../../api-client";

export default class TicketsStore {
  public apiClient: TicketsApi;
  public authStore: AuthStore;
  public lastTicketsUpdate: Date | null = null;

  public constructor(apiClient: TicketsApi, authStore: AuthStore) {
    makeAutoObservable(this);
    this.apiClient = apiClient;
    this.authStore = authStore;
  }

  @action
  public createTicket = async (ticketsDto: TicketDto) => {
    try {
      const { data } = (await this.apiClient.createTicket(ticketsDto)) as any;
      if (data) {
        notificationStore.showMessage("Ticket erfolgreich erstellt!", "success");
      }
      this.lastTicketsUpdate = new Date();
      return data || [];
    } catch (error: any) {
      console.error("Error creating ticket:", error);
      // Check if the error response contains a message
      const errorMessage = error.response?.data?.message || "Ticketerstellung fehlgeschlagen";

      notificationStore.showMessage(errorMessage, "error", error);
    }
  };

  @action getTicketById = async (id: number) => {
    try {
      const { data } = (await this.apiClient.getTicketById(id)) as any;
      return data || [];
    } catch (error: any) {
      console.error("Error getting ticket by id:", error);
      // Check if the error response contains a message
      const errorMessage = error.response?.data?.message || "Ticket nicht gefunden";

      notificationStore.showMessage(errorMessage, "error", error);
    }
  };
  @action getTicketsByPatientId = async (patientId: number) => {
    try {
      const { data } = (await this.apiClient.getPatientTickets(patientId)) as any;
      return data || [];
    } catch (error: any) {
      console.error("Error getting ticket by id:", error);
      // Check if the error response contains a message
      const errorMessage = error.response?.data?.message || "Ticket nicht gefunden";

      notificationStore.showMessage(errorMessage, "error", error);
    }
  };

  @action getTicketsByAppointmentId = async (appointmentId: string) => {
    try {
      const { data } = (await this.apiClient.getAppointmentTickets(appointmentId)) as any;
      return data || [];
    } catch (error: any) {
      console.error("Error getting ticket by id:", error);
      // Check if the error response contains a message
      const errorMessage = error.response?.data?.message || "Ticket nicht gefunden";

      notificationStore.showMessage(errorMessage, "error", error);
    }
  };

  @action getTicketFilters = async () => {
    try {
      const response = await this.apiClient.getTicketFilters();
      const data = response.data as TicketFilterDto[];
      return data || [];
    } catch (error: any) {
      console.error("Error getting ticket filters:", error);
      // Check if the error response contains a message
      // const errorMessage = error.response?.data?.message || "Ticket nicht gefunden";

      // notificationStore.showMessage(errorMessage, "error", error);
    }
  };

  @action updateTicket = async (id: number, ticketsDto: TicketDto) => {
    try {
      const { data } = (await this.apiClient.updateTickets(id, ticketsDto)) as any;
      if (data) {
        notificationStore.showMessage("Ticket erfolgreich aktualisiert!", "success");
      }
      this.lastTicketsUpdate = new Date();

      return data || [];
    } catch (error: any) {
      console.error("Error updating ticket:", error);
      // Check if the error response contains a message
      const errorMessage =
        error.response?.data?.message || "Die Ticketaktualisierung ist fehlgeschlagen";

      notificationStore.showMessage(errorMessage, "error", error);
    }
  };

  @action
  public getTickets = async ({
    limit,
    page,
    ticketStatus,
    createdByUserId,
    assignedToUserId,
  }: FindAllTicketsQueryFilters) => {
    try {
      const { data } = (await this.apiClient.getAllTickets(
        limit,
        page,
        ticketStatus,
        createdByUserId,
        assignedToUserId
      )) as any;
      return data || [];
    } catch (error: any) {
      if (error?.name === "AbortError") {
        console.log("Request aborted");
      } else {
        throw error; // Re-throw other errors for handling elsewhere
      }
    }
  };

  @action
  public postorUpdateComments = async (id: number, activityDto: ActivityDto) => {
    try {
      const { data } = (await this.apiClient.postorUpateComment(id, activityDto)) as any;
      if (data) {
        notificationStore.showMessage("Kommentar erfolgreich hinzugefügt!", "success");
      }
      this.lastTicketsUpdate = new Date();
      return data || [];
    } catch (error: any) {
      console.error("Error adding comment:", error);
      // Check if the error response contains a message
      const errorMessage = error.response?.data?.message || "Kommentar hinzufügen fehlgeschlagen";

      notificationStore.showMessage(errorMessage, "error", error);
    }
  };

  @action
  public deleteComment = async (id: string) => {
    try {
      const { data } = (await this.apiClient.deleteComment(id)) as any;
      if (data) {
        notificationStore.showMessage("Kommentar erfolgreich gelöscht!", "success");
      }
      this.lastTicketsUpdate = new Date();
      return data || [];
    } catch (error: any) {
      console.error("Error deleting comment:", error);
      // Check if the error response contains a message
      const errorMessage = error.response?.data?.message || "Kommentar löschen fehlgeschlagen";

      notificationStore.showMessage(errorMessage, "error", error);
    }
  };
}
