import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Box,
  Divider,
  Grid,
  Tooltip,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Badge,
  DialogContent,
  Dialog,
  Button,
  DialogActions,
  Popover,
  Modal,
  CircularProgress,
  Switch,
  FormControlLabel,
  DialogTitle,
} from "@mui/material";
import { observer } from "mobx-react";
import React, { useContext, useEffect, useState } from "react";
import { mobiliTheme } from "../../../themes/mobiliTheme";
import { visitTypeMapping } from "../../../helpers/visitTypeMapping";
import { formatDateUI, formatTime } from "../../../helpers/dateFormat";
import Controls from "../../../atoms/Controls";
import {
  MoreVert,
  Cancel,
  CheckCircle,
  Note,
  Error,
  NoteAlt,
  ManageHistory,
  Edit,
  Add,
  HelpRounded,
} from "@mui/icons-material";
import notificationStore from "../../../stores/Notification/notificationStore";
import { AppointmentContext } from "../../../stores/Appointment/appointment.provider";
import {
  AppointmentDto,
  ContinuousAppointmentDto,
  NoteDto,
  PatientAvailabilityDto,
  TimeSlotDto,
} from "../../../api-client";
import useStore from "../../../helpers/useStore";
import { NotesContext } from "../../../stores/Notes/notes.provider";
import { UserContext } from "../../../stores/User/User.provider";
import Notes from "../../ScheduleOverview/Notes/Notes";
import { CancelAppointmentDialog } from "../../../molecules/CancelAppointmentDialog";
import TicketForm from "../../Ticketing/TicketEditCreate/ticketForm";
import { backgroundColourCheck, getColorForRxNumber } from "../../../helpers/statusColor";
import ConfirmationDialog from "./RxSeries/ConfirmationDialog";
import DialogBoxAppointment from "../../PrintAppointment/printRxDialogBox";
import { TerminViewConstants } from "../TerminView";
import { isVisible } from "@testing-library/user-event/dist/utils";
import { DeleteEvent } from "../../ScheduleOverview/UnscheduledAppointments";
import { SmallChip, TherapyChip } from "../../../atoms/Chip";
import { MobiliAvatar } from "../../../atoms/MobiliAvatar";
import AppointmentEditCard from "../../ScheduleOverview/Appointments/AppointmentEditCard";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import NotesView from "../../ScheduleOverview/Notes/NotesView";
import WarningDialog from "../../../atoms/WarningDialog";

interface TerminViewProps {
  appointments: AppointmentDto[] | ContinuousAppointmentDto[];
  patientId: any;
  dataRefreshFlag: any;
  setDataRefreshFlag: any;
  appointmentsLoading?: boolean;
  isView: TerminViewConstants;
  blockedData?: TimeSlotDto[];
  setblockedData?: any;
  patientAvailabilityData?: PatientAvailabilityDto[];
  setPatientAvailability?: any;
  isContinuous?: boolean;
  setUnScheduledSelectedAppointmentId?: any;
  setDeleteEvent?: any;
}
const popoverStyle = {
  width: "30%",
  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
  position: "relative",
};
const TherapyCardView = ({
  timeSlot,
  appointment,
  index,
  totalSlots,
}: {
  timeSlot: TimeSlotDto;
  appointment: any;
  index: number;
  totalSlots: number;
}) => {
  // Get the color based on the Rx number
  const rxNumber = timeSlot?.therapyRx?.rx?.rxNumber || "";
  const rxNumberColor = getColorForRxNumber(rxNumber);
  // const visitType = visitTypeMapping[timeSlot?.therapyRx?.rx?.visitType!];
  // Determine the background color based on conditions

  type AttendedStatus = "future" | "unattended" | "attended" | "unscheduled";

  let attendedStatus: AttendedStatus = !timeSlot.start
    ? "unscheduled"
    : timeSlot.attended
    ? "attended"
    : new Date(timeSlot.start) > new Date()
    ? "future"
    : "unattended";

  return (
    <Grid container direction="column">
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={3}>
          {/* Therapy details */}
          <Tooltip title={rxNumber}>
            <Controls.Chip
              label={rxNumber}
              sx={{ backgroundColor: rxNumberColor, fontSize: "0.6em", direction: "rtl" }}
              variant="outlined"
            />
          </Tooltip>
        </Grid>
        <Grid item xs={2}>
          {timeSlot.treatmentIndex && (
            <Tooltip title={timeSlot.treatmentIndex}>
              <SmallChip label={timeSlot.treatmentIndex} />
            </Tooltip>
          )}
        </Grid>

        <Grid item xs={2}>
          <TherapyChip
            therapyName={(timeSlot?.therapyRx?.therapy as any).abbreviation}
            sx={{ fontSize: "0.5em" }}
          />
        </Grid>
        <Grid item xs={1}>
          {/* Avatar */}
          {timeSlot?.user?.id && (
            <MobiliAvatar
              user={timeSlot?.user}
              isTherapist
              sx={{ width: 25, height: 25, fontSize: "0.8em" }}
            />
          )}
        </Grid>
        <Grid item xs={2}>
          {timeSlot.visitFee && (
            <Tooltip title={timeSlot.visitFee.abbreviation}>
              <SmallChip label={timeSlot.visitFee.abbreviation} />
            </Tooltip>
          )}
        </Grid>
        <Grid item xs={1}>
          {timeSlot.therapyRx?.rx?.isPerpetual && (
            <Tooltip title="">
              <AllInclusiveIcon fontSize={"small"} />
            </Tooltip>
          )}
        </Grid>
        {/* We can Add visit type back in if requested:  */}
        {/* {visitType && (
        <Grid item>
          <Tooltip title={visitType}>
            <Controls.Chip label={visitType} color="primary" variant="outlined" />
          </Tooltip>
        </Grid>
      )} */}

        <Grid item xs={1}>
          {attendedStatus == "attended" && (
            <CheckCircle
              sx={{
                width: 20,
                height: 20,
                color: mobiliTheme.palette.success.main, // Replace with your desired background color
                margin: 0, // Add some space between the avatar and chip
              }}
            />
          )}
          {(attendedStatus == "unattended" || attendedStatus == "future") && (
            <HelpRounded
              sx={{
                width: 20,
                height: 20,
                color: attendedStatus == "unattended" ? mobiliTheme.palette.warning.main : "white", // Replace with your desired background color
                margin: 0, // Add some space between the avatar and chip
              }}
            />
          )}
        </Grid>
      </Grid>
      {appointment.reason && !appointment.end && index === totalSlots - 1 && (
        <Grid item xs={12}>
          <Typography sx={{ fontSize: "12px", color: "grey.600", padding: "1px" }}>
            * {appointment.reason}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
// Function to format the gap between appointments
const formatGap = (endDate: Date, nextStartDate: Date) => {
  if (endDate && nextStartDate) {
    // Strip time from dates
    const endDateOnly = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
    const nextStartDateOnly = new Date(
      nextStartDate.getFullYear(),
      nextStartDate.getMonth(),
      nextStartDate.getDate()
    );

    const diffTime = Math.abs(nextStartDateOnly.getTime() - endDateOnly.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  } else {
    return null; // or return some default value or handle error appropriately
  }
};

export const AppointmentCardView = observer(({
  appointment,
  onAddNotes,
  notes,
  appointments,
  patientId,
  dataRefreshFlag,
  setDataRefreshFlag,
  gapInDays,
  isContinous = false,
  isView,
  setUnScheduledSelectedAppointmentId,
  setDeleteEvent,
}: {
  appointment: any;
  onAddNotes?: any;
  notes?: NoteDto[];
  appointments: any;
  patientId: any;
  dataRefreshFlag: any;
  setDataRefreshFlag: any;
  gapInDays?: number | null;
  isContinous?: boolean;
  isView?: TerminViewConstants; //This is made optional for the PatientAdress component
  setUnScheduledSelectedAppointmentId?: any;
  setDeleteEvent?: any;
}) => {
  const { timeSlots } = appointment;
  const AppointmentStore = useStore(AppointmentContext);
  const { setAttended, copyAppointment } = AppointmentStore;
  const [notesViewAnchorEl, setNotesViewAnchorEl] = useState<HTMLElement | null>(null);

  const handleOpenNotesPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setNotesViewAnchorEl(event.currentTarget);
  };

  const handleCloseNotesPopover = () => {
    setNotesViewAnchorEl(null);
  };

  const [openTicket, setOpenTicket] = useState<any>(false);
  const [ticketData, setTicketData] = useState<any>(null);

  const [isCancellationDialogOpen, setCancellationDialogOpen] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState<AppointmentDto | null>(null); // Track the selected appointment for the dialog
  const [isSuggestionDialogOpen, setsuggestionDialogOpen] = useState(false);
  const handleCloseCancellation = (actionPerformed: boolean) => {
    setCancellationDialogOpen(false);
    if (actionPerformed) setDataRefreshFlag(new Date().getTime());
  };

  const handleOpenCancellationDialog = (appointmentId: any) => {
    // Create an object with appointmentId and appointmentStart
    const selectedAppointment = appointments.find((app: any) => app.id === appointmentId);
    if (!selectedAppointment) {
      console.error("Appointment not found for id:", appointmentId);
      // use the snackbar to show an error message
      notificationStore.showMessage("Termin nicht gefunden: " + appointmentId, "error");
      return;
    }
    setSelectedAppointment(selectedAppointment);
    setCancellationDialogOpen(true);
  };
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false); // State for the edit dialog

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleEditDialogOpen = () => {
    setEditDialogOpen(true);
    handleMenuClose();
  };

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
    handleMenuClose();
  };

  // const [menu, setMenu] = useState<{ [key: string]: HTMLElement | null }>({});

  // const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>, appointmentId: any) => {
  //   setMenu({ ...menu, [appointmentId]: event.currentTarget });
  // };

  // const handleMenuClose = (appointmentId: any) => {
  //   setMenu({ ...menu, [appointmentId]: null });
  //   const handleOpenCancellationDialog = (appointmentId: any) => {
  //     // Create an object with appointmentId and appointmentStart
  //     const selectedAppointment = appointments.find((app: any) => app.id === appointmentId);
  //     if (!selectedAppointment) {
  //       console.error("Appointment not found for id:", appointmentId);
  //       // use the snackbar to show an error message
  //       notificationStore.showMessage("Termin nicht gefunden: " + appointmentId, "error");
  //       return;
  //     }
  //     setSelectedAppointment(selectedAppointment);
  //     setCancellationDialogOpen(true);
  //   };

  //   const handleAttended = async (appointmentId: any, isAttended: boolean) => {
  //     try {
  //       // Call your API or setAttended function with the appointmentId and isAttended value
  //       await setAttended(appointmentId, isAttended);
  //       setDataRefreshFlag(isAttended);
  //     } catch (error) {
  //       console.error("Error marking as attended/unattended:", error);
  //     }
  //   };
  // };

  const openTicketForm = (appointment: any) => {
    // Iterate through each appointment
    // appointments.forEach((appointment) => {

    // Initialize an array to store RX IDs and associated user and therapy data
    const rxData: any[] = [];
    let patient: any;

    // Iterate through each time slot in the appointment
    appointment.timeSlots.forEach((timeSlot: any) => {
      // Extract the patient ID from the first digit of the RX number
      const patientId = timeSlot?.therapyRx?.rx.rxNumber.split("-")[0];

      // Extract the RX ID, user data, and therapy ID from the time slot
      const rxNumber = timeSlot.therapyRx?.rx.rxNumber;
      const user = timeSlot.user;
      const therapyId = timeSlot.therapyRx.therapy;

      // Extract patient data
      patient = {
        patientId: patientId,
        patientData: timeSlot.therapyRx.rx.patient,
      };

      // Construct the data object for each time slot and push it to rxData array
      rxData.push({
        therapyRx: {
          rx: {
            rxNumber: rxNumber,
          },
          therapy: therapyId,
        },
        user: user,
      });
    });

    // Assuming `patient` is defined elsewhere
    setTicketData({
      appointmentId: appointment.id,
      appointment: { timeSlots: rxData },
      patientId: patient.patientId,
      patient: patient.patientData,
    });

    // });
    setOpenTicket(!openTicket);
  };
  const handleCloseTicketForm = () => {
    setOpenTicket(false);
  };

  // Calculate the height based on the number of therapyRx items
  const cardHeight = `${timeSlots.length * 30 + 20}px`; // Adjust the height calculation as needed

  const currentDate: any = new Date();
  const today = new Date().setHours(0, 0, 0, 0);
  const startDate = appointment.start ? new Date(appointment.start).setHours(0, 0, 0, 0) : null;
  const handleCopy = async (appointmentId: string, isContinuous: boolean) => {
    try {
      // Call your API or backfill function with the appointment object
      console.log("Copying appointment:", appointmentId);
      if (!appointmentId) {
        notificationStore.showMessage("No appointment ID found for copying appointment:", "error");
        return;
      }
      const response: any = await copyAppointment(appointmentId, isContinuous);
      if (!response) return;

      notificationStore.showMessage("" + response.backfilled + " termine erzeugt", "success");
      setDataRefreshFlag(appointmentId);
    } catch (error) {
      console.error("Error backfilling appointment:", error);
    }
  };
  const isToday = startDate === today;

  return (
    <Box sx={{ margin: "0px", padding: "0px", border: "0px dotted red" }}>
      {/* Icon Button for adding notes as a tag */}

      <Box
        sx={{
          position: "relative",
          border: isToday
            ? `2px solid ${mobiliTheme.palette.secondary.main}`
            : `1px solid ${mobiliTheme.mainHeader}`,
          p: 0,
          m: 0,
          padding: "0px",
          paddingBottom: "4px",
          margin: "0px",
          backgroundColor: mobiliTheme.lowLevelContainerBackground,
          borderRadius: "0.4em",
        }}
      >
        {notes && notes.length > 0 && (
          <Badge
            // badgeContent={notes.length}
            color="secondary"
            sx={{
              position: "absolute",
              top: "0%",
              left: "-3%",

              transform: "translate(-50%, -50%)",
              cursor: "pointer",
            }}
            onClick={(event: any) => handleOpenNotesPopover(event)}
          >
            <NoteAlt />
          </Badge>
        )}
        {/* First grid in one row */}
        <Grid container>
          <Grid item xs={6} display="flex" justifyContent="space-between">
            {/* Date on the left side */}
            <Typography
              sx={{
                fontSize: "0.75em",
                fontWeight: "bold",
                padding: "0px",
                textDecoration:
                  appointment.end === null && appointment.originalDate ? "line-through" : "none",
                textDecorationThickness: "2px",
              }}
            >
              {appointment.start && !appointment.unschedule ? formatDateUI(appointment.start) : ""}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-start",
            }}
          >
            {/* Start and end times on the right side */}
            {!appointment.unschedule && (
              <Typography
                noWrap
                sx={{
                  fontSize: "0.75em",
                  fontWeight: "bold",
                  textDecoration: "underline",
                }}
              >
                {!appointment.start && "nicht geplant"}
                {appointment.start ? formatTime(appointment?.start as any) : " "}
                {"-"}
                {appointment.end ? formatTime(appointment.end as any) : ""}
              </Typography>
            )}

            <Box sx={{ borderRadius: "8px", background: "transparent" }}>
              {isContinous && (
                <ContinuousAppointmentMenu
                  appointment={appointment}
                  handleOpenCancellationDialog={handleOpenCancellationDialog}
                  dataRefreshFlag={dataRefreshFlag}
                  setDataRefreshFlag={setDataRefreshFlag}
                  openTicketForm={openTicketForm}
                  patientId={patientId}
                  handleCopy={handleCopy}
                  onAddNotes={onAddNotes}
                />
              )}
              {!isContinous &&
                (isView === TerminViewConstants.TERMIN_VIEW ||
                  isView === TerminViewConstants.RX_VIEW) && (
                  <AppointmentMenu
                    appointment={appointment}
                    handleOpenCancellationDialog={handleOpenCancellationDialog}
                    dataRefreshFlag={dataRefreshFlag}
                    setDataRefreshFlag={setDataRefreshFlag}
                    openTicketForm={openTicketForm}
                    patientId={patientId}
                    handleCopy={handleCopy}
                    onAddNotes={onAddNotes}
                  />
                )}
            </Box>
            <Box>
              {/* This is for unscheduled appointments */}
              {(isView === TerminViewConstants.UNSCHEDULED_TERMIN ||
                isView === TerminViewConstants.UNSCHEDULED_CONT_TERMIN) && (
                <>
                  <IconButton
                    aria-label="more"
                    aria-controls={`menu-${appointment.id}`}
                    aria-haspopup="true"
                    onClick={(event) => handleMenuOpen(event)}
                  >
                    <MoreVert />
                  </IconButton>
                  <Menu
                    id={`menu-${appointment.id}`}
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={handleEditDialogOpen}>
                      <Edit />
                      Bearbeiten
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setUnScheduledSelectedAppointmentId(appointment.id as string);
                        setDeleteEvent({
                          selectedAppointment: appointment.id as string,
                          isContinuous:
                            isView === TerminViewConstants.UNSCHEDULED_CONT_TERMIN ? true : false,
                          isDeleteDialogOpen: true,
                        });
                      }}
                    >
                      <Tooltip title="löschen">
                        <>
                          <Cancel className="cancel-icon" />
                          löschen
                        </>
                      </Tooltip>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleCopy(
                          appointment.id!,
                          isView === TerminViewConstants.UNSCHEDULED_TERMIN ? false : true
                        ); // flag set to true for continuous appointments
                        handleMenuClose();
                      }}
                    >
                      <Add /> Neuer Termin
                    </MenuItem>
                    {isView !== TerminViewConstants.UNSCHEDULED_CONT_TERMIN && (
                      <MenuItem
                        onClick={(event: any) => {
                          onAddNotes(event);
                          handleMenuClose();
                        }}
                      >
                        <Edit />
                        Notizen hinzufügen/bearbeiten
                      </MenuItem>
                    )}
                  </Menu>
                </>
              )}
              {/* This is for PatientAdress apointment view*/}
              {!isView && (
                <>
                  <IconButton
                    aria-label="more"
                    aria-controls={`menu-${appointment.id}`}
                    aria-haspopup="true"
                    onClick={(event) => handleMenuOpen(event)}
                  >
                    <MoreVert />
                  </IconButton>
                  <Menu
                    id={`menu-${appointment.id}`}
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={handleEditDialogOpen}>
                      <Edit />
                      Bearbeiten
                    </MenuItem>
                  </Menu>
                </>
              )}
              {editDialogOpen && (
                <AppointmentEditCard
                  open={editDialogOpen}
                  onClose={handleEditDialogClose}
                  appointment={appointment}
                  patientId={patientId}
                  dataRefreshFlag={dataRefreshFlag}
                  setDataRefreshFlag={setDataRefreshFlag}
                  isContinous={
                    isView === TerminViewConstants.UNSCHEDULED_CONT_TERMIN ? true : false
                  }
                />
              )}
            </Box>
          </Grid>

          {/* Render the WarningDialog conditionally */}
          {selectedAppointment && isCancellationDialogOpen && (
            <CancelAppointmentDialog
              patientId={patientId}
              isOpen={isCancellationDialogOpen}
              onClose={handleCloseCancellation}
              appointment={selectedAppointment}
            />
          )}

          {openTicket && (
            <TicketForm
              openTicket={openTicket}
              onCloseTicketForm={handleCloseTicketForm}
              data={ticketData}
            />
          )}
        </Grid>

        {/* Second grid in another row */}
        <Grid container>
          {timeSlots
          .sort((a: any, b: any) => a.therapyRx.order - b.therapyRx.order)
          .map((timeSlot: any, index: any) => (
            <Grid item xs={12} key={index}>
              <TherapyCardView
                timeSlot={timeSlot}
                appointment={appointment}
                index={index}
                totalSlots={timeSlots.length}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      {(gapInDays || gapInDays === 0) && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <Divider style={{ flex: 1 }} />
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body1" component="p" style={{ marginRight: "8px" }}>
              {gapInDays} Tage
            </Typography>
            <Avatar
              sx={{
                width: 25,
                height: 25,
                backgroundColor: backgroundColourCheck(gapInDays / 7),
                marginRight: 1,
              }}
            >
              <React.Fragment></React.Fragment>
            </Avatar>
          </div>
          <Divider style={{ flex: 1 }} />
        </div>
      )}
      <Popover
        open={Boolean(notesViewAnchorEl)}
        anchorEl={notesViewAnchorEl}
        onClose={handleCloseNotesPopover}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <Card sx={{ width: 500, maxHeight: 500, overflow: "auto" }}>
          <CardHeader title="Notizen"/>
          <CardContent>
            {notes && notes.length > 0 ? (
              <NotesView notes={notes} />
            ) : (
              <Typography>No notes available</Typography>
            )}
          </CardContent>
        </Card>
      </Popover>
    </Box>
  );
});

const TerminView: React.FC<TerminViewProps> = observer(
  ({
    appointments,
    patientId,
    dataRefreshFlag,
    setDataRefreshFlag,
    appointmentsLoading,
    isView,
    blockedData,
    setblockedData,
    patientAvailabilityData,
    setPatientAvailability,
    isContinuous = false,
    setUnScheduledSelectedAppointmentId,
    setDeleteEvent,
  }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [includeCancelled, setIncludeCancelled] = useState(false);

    const [selectedAppointmentId, setSelectedAppointmentId] = useState<string | null>(null);
    const UserStore = useStore(UserContext);
    const { user } = UserStore;

    const NotesStore = useStore(NotesContext);
    const [showNotesDialog, setShowNotesDialog] = useState(false);
    const { postNotes, getNotesForAppointments, fetchNotes, updateNoteById } = NotesStore;
    const [appoitmentNotes, setAppointmentNotes] = useState<NoteDto[]>([]);
    const AppointmentStore = useStore(AppointmentContext);
    const { swapAppointments, canSwapAppointments } = AppointmentStore;
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [canSwap, setCanSwap] = useState([]);
    const [showDialogBoxAppointment, setShowDialogBoxAppointment] = useState<boolean>(false); // for Termine ausdrucken button
    // Function to show the Notes component
    const handleAddNotes = (event: any, appointmentId: string) => {
      setAnchorEl(event.currentTarget);
      setSelectedAppointmentId(appointmentId);
      setShowNotesDialog(showNotesDialog);
    };

    const handleCloseNotesDialog = () => {
      setAnchorEl(null);
      setSelectedAppointmentId(null);
      setShowNotesDialog(!showNotesDialog);
    };

    useEffect(() => {
      const fetchData = async () => {
        try {
          if (
            appointments.length > 0 &&
            isView !== TerminViewConstants.CONTINOUS_TERMIN &&
            isView !== TerminViewConstants.UNSCHEDULED_CONT_TERMIN
          ) {
            // Extract the appointment IDs from the appointments array
            let appointmentIds: any = [];
            appointmentIds = appointments.map((appointment) => appointment.id) || [];

            // Fetch notes for the given appointment IDs
            const response = await getNotesForAppointments(appointmentIds);

            // Update the state with the fetched notes
            setAppointmentNotes(response || []);
            // setFetchNotes(!fetchNotes);
          }
        } catch (error) {
          console.log("Error fetching notes:", error);
        }
      };
      fetchData();
    }, [appointments.length, fetchNotes]);

    const updateNote = async (index: number, updatedCard: NoteDto) => {
      try {
        if (selectedAppointmentId) {
          let response;
          updatedCard.appointmentId = selectedAppointmentId;
          if (updatedCard.id) {
            response = await updateNoteById(updatedCard.id, user?.id as string, updatedCard);
          } else {
            response = await postNotes(user?.id as string, updatedCard);
          }
          if (response) {
            // Check if the note already exists in the current state
            const existingNoteIndex = appoitmentNotes.findIndex((note) => note.id === response.id);

            let updatedNotes;
            if (existingNoteIndex >= 0) {
              // Update the existing note
              updatedNotes = appoitmentNotes.map((note, idx) =>
                idx === existingNoteIndex ? response : note
              );
            } else {
              // Add the new note
              updatedNotes = [...appoitmentNotes, response];
            }

            // Update the state with the modified notes array
            setAppointmentNotes(updatedNotes);
            // setFetchNotes(true)
          } else {
            console.log("No selectedAppointmentId available.");
          }
        }
      } catch (error) {
        notificationStore.showMessage("Error while posting notes!!!", "error");
      }
    };
    const getValidStartDate = (appointment: any, index: number): Date => {
      if (index >= filteredAppointments.length - 1) {
        return appointment.start; // Return the start date of the appointment if it's the last one
      }
      return !appointment.end
        ? getValidStartDate(filteredAppointments[index + 1], index + 1)
        : appointment.start;
    };

    // const filteredAppointments = includeCancelled
    // ? appointments.filter((appointment: any) => appointment.originalDate || appointment.end)
    // : (isView === TerminViewConstants.SERIES_VIEW ||
    //     isView === TerminViewConstants.UNSCHEDULED_TERMIN ||
    //     isView === TerminViewConstants.UNSCHEDULED_CONT_TERMIN
    //   ? appointments
    //   : appointments.filter((appointment: any) => appointment.end));
    const filteredAppointments =
      includeCancelled ||
      isView === TerminViewConstants.SERIES_VIEW ||
      isView === TerminViewConstants.UNSCHEDULED_TERMIN ||
      isView === TerminViewConstants.UNSCHEDULED_CONT_TERMIN
        ? appointments
        : appointments.filter((appointment: any) => appointment.end);

    const handleSwapAppointments = async () => {
      console.log(
        "AJSJSJJSJSJJSJS",
        canSwap.every((result: any) => !result.canSwap),
        canSwap
      );
      // if(canSwap.every((result:any) => !result.canSwap)){
      //   notificationStore.showMessage("Termine können nicht verschoben werden, da kein anderer Termin geplant ist.","error")
      //   return;
      // }
      let uniqueIds: any;
      uniqueIds = new Set(); // Using a Set to automatically handle uniqueness

      filteredAppointments.forEach((appointment) => {
        appointment?.timeSlots?.forEach((timeSlot: any) => {
          if (timeSlot.therapyRx && timeSlot.therapyRx.rxSeries && timeSlot.therapyRx.rxSeries.id) {
            uniqueIds.add(timeSlot.therapyRx.rxSeries.id);
          }
        });
      });

      const response = await swapAppointments(Array.from(uniqueIds));
      if (response) {
        if (response.some((result: any) => result.perpetualAppointments.length > 0)) {
          setOpenConfirmation(!openConfirmation);
        }
        setDataRefreshFlag(true);
      }
    };
    // show a dialog box for printing the appointments
    const handleShowAppointment = () => {
      setShowDialogBoxAppointment(true);
    };

    const handleHideAppointment = () => {
      setShowDialogBoxAppointment(false);
    };

    const closeConfirmation = () => {
      setOpenConfirmation(false);
    };

    useEffect(() => {
      const fetchData = async () => {
        if (isView === TerminViewConstants.SERIES_VIEW && appointments.length > 0) {
          try {
            let uniqueIds: any;
            uniqueIds = new Set(); // Using a Set to automatically handle uniqueness
            console.log("ASPPDPD", appointments);
            appointments.forEach((appointment) => {
              appointment?.timeSlots?.forEach((timeSlot: any) => {
                if (
                  timeSlot.therapyRx &&
                  timeSlot.therapyRx.rxSeries &&
                  timeSlot.therapyRx.rxSeries.id
                ) {
                  uniqueIds.add(timeSlot.therapyRx.rxSeries.id);
                }
              });
            });
            const results = await canSwapAppointments(Array.from(uniqueIds));
            setCanSwap(results);
          } catch (error) {
            console.error("Error fetching data:", error);
            // Handle error (e.g., show error message)
          }
        }
      };

      fetchData();
    }, [dataRefreshFlag, isView === TerminViewConstants.SERIES_VIEW, appointments]);

    return (
      <>
        {/* <CardHeader
      title="Termine"
      action={ */}
        <FormControlLabel
          sx={{ width: "80", justifyContent: "end" }}
          control={
            <>
              {isView === TerminViewConstants.RX_VIEW && (
                <Switch
                  checked={includeCancelled || false}
                  onChange={(e) => {
                    setIncludeCancelled(e.target.checked);
                  }}
                />
              )}
              {isView === TerminViewConstants.SERIES_VIEW && (
                <Button
                  onClick={handleSwapAppointments}
                  variant="contained"
                  color="primary"
                  disabled={appointments.length === 0}
                >
                  neu ordnen
                </Button>
              )}
              <ConfirmationDialog open={openConfirmation} onClose={closeConfirmation} />
            </>
          }
          label={
            isView === TerminViewConstants.RX_VIEW ? (
              <Typography sx={{ fontSize: "0.7em" }}>Storn.</Typography>
            ) : (
              ""
            )
          }
        />
        {/* }
    /> */}
        <>
          {appointments.length > 0 && isView === TerminViewConstants.TERMIN_VIEW && (
            <Grid container justifyContent="flex-end">
              <Grid
                item
                xs={"auto"}
                alignSelf="flex-end"
                sx={{ padding: "4px", border: "0px red solid" }}
              >
                <Button variant="contained" color="primary" onClick={handleShowAppointment}>
                  Drucken
                </Button>
                {
                  <DialogBoxAppointment
                    id={patientId}
                    openDialog={showDialogBoxAppointment}
                    handleOnClose={handleHideAppointment}
                    single={false}
                    tarifRx={null}
                    rxDate={null}
                  />
                }
              </Grid>
            </Grid>
          )}
          {appointmentsLoading ? (
            <CircularProgress size={60} />
          ) : !appointmentsLoading && filteredAppointments?.length > 0 ? (
            filteredAppointments?.map((appointment: any, index: number) => {
              return (
                <React.Fragment key={index}>
                  <AppointmentCardView
                    appointment={appointment}
                    appointments={filteredAppointments}
                    notes={appoitmentNotes.filter((note) => note.appointmentId === appointment.id)}
                    onAddNotes={(event: any) => handleAddNotes(event, appointment.id! as any)}
                    patientId={patientId}
                    dataRefreshFlag={dataRefreshFlag}
                    setDataRefreshFlag={setDataRefreshFlag}
                    gapInDays={
                      filteredAppointments[index + 1] && filteredAppointments[index]?.end
                        ? formatGap(
                            new Date(getValidStartDate(appointment, index)),
                            new Date(getValidStartDate(filteredAppointments[index + 1], index + 1))
                          )
                        : null
                    }
                    isContinous={isContinuous}
                    isView={isView}
                    setUnScheduledSelectedAppointmentId={setUnScheduledSelectedAppointmentId}
                    setDeleteEvent={setDeleteEvent}
                  />
                  {/* {appointments[index + 1] && !appointments[index]?.originalDate && ( // Check if there's a next appointment
         <><Typography variant="body1" component="p">
                  {formatGap(
                    new Date(getValidStartDate(appointment, index)),
                    new Date(getValidStartDate(appointments[index + 1], index + 1))
                  )}{" "}
                  days
                </Typography>
             
              </>
       
        
          
            )} */}

                  {/* <Popover
                    open={Boolean(
                      anchorEl && selectedAppointmentId === appointment.id
                    )}
                    anchorEl={anchorEl}
                    onClose={handleCloseNotesDialog}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    PaperProps={{
                      sx: popoverStyle,
                    }}
                  > */}
                  <Dialog
                    open={Boolean(anchorEl && selectedAppointmentId === appointment.id)}
                    onClose={handleCloseNotesDialog}
                  >
                    <DialogTitle>Notizen</DialogTitle>
                    <DialogContent>
                      {/* <Card style={{ backgroundColor: "rgba(5, 68, 91, 0.5)" }}> */}
                        <Notes
                          notes={appoitmentNotes.filter(
                            (note) => note.appointmentId === appointment.id
                          )}
                          setNotes={updateNote}
                          maxVisibleNotes={appoitmentNotes.filter(
                            (note) => note.appointmentId === appointment.id
                          ).length}
                        />
                      {/* </Card> */}
                    </DialogContent>
                    <DialogActions>
                      {" "}
                      <Button onClick={handleCloseNotesDialog}>Stornieren</Button>
                    </DialogActions>{" "}
                  </Dialog>
                </React.Fragment>
              );
            })
          ) : (
            <Typography variant="body1" component="p" sx={{}}>
              Keine Termine
            </Typography>
          )}
        </>
      </>
    );
  }
);

interface MenuInterface {
  appointment: AppointmentDto;
  handleOpenCancellationDialog: (appointmentId: any) => void;
  setDataRefreshFlag: any; // function to set the data refresh flag
  dataRefreshFlag: boolean;
  openTicketForm: (appointment: AppointmentDto) => void;
  patientId: number;
  handleCopy: (appointmentId: string, isContinous: boolean) => any;
  onAddNotes?: any;
}

const AppointmentMenu = observer(({
  appointment,
  handleOpenCancellationDialog,
  setDataRefreshFlag,
  dataRefreshFlag,
  openTicketForm,
  patientId,
  handleCopy,
  onAddNotes,
}: MenuInterface) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false); // State for the edit dialog
  const AppointmentStore = useStore(AppointmentContext);
  const { setAttended,warningDialogMessage,setWarningDialogMessage } = AppointmentStore;

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAttended = async (appointmentId: any, isAttended: boolean) => {
    try {
      // Call your API or setAttended function with the appointmentId and isAttended value
      await setAttended(appointmentId, isAttended);
      setDataRefreshFlag(isAttended);
    } catch (error) {
      console.error("Error marking as attended/unattended:", error);
    }
  };
  const handleEditDialogOpen = () => {
    setEditDialogOpen(true);
    handleMenuClose();
  };

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
    handleMenuClose();
  };

  const handleCloseWarning=()=>{
    setWarningDialogMessage("")
  }

  const currentDate: any = new Date();
  return (
    <>
      {warningDialogMessage!=="" && (
          <WarningDialog
            open={true} // Ensure the warning dialog is open
            onClose={handleCloseWarning} // Function to close the dialog
            title="Bearbeitungsstatus aktualisiert"
            content={warningDialogMessage}
            
          />
        )}
      <IconButton
        sx={{ padding: "0px", margin: "0px" }}
        aria-label="more"
        aria-controls={`menu-${appointment.id}`}
        aria-haspopup="true"
        onClick={(event) => handleMenuOpen(event)}
      >
        <MoreVert fontSize="small" />
      </IconButton>
      <Menu
        id={`menu-${appointment.id}`}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleEditDialogOpen}>
          <Edit />
          Bearbeiten
        </MenuItem>
        {!appointment.attended && appointment.start && appointment.end && (
          <MenuItem onClick={() => handleOpenCancellationDialog(appointment.id)}>
            <Cancel className="cancel-icon" />
            stornieren
          </MenuItem>
        )}
        {appointment.attended && (
          <MenuItem onClick={() => handleAttended(appointment.id, false)}>
            <CheckCircle color="success" sx={{ padding: "2px" }} />
            Wahrgenommen
          </MenuItem>
        )}
        {appointment.start &&
          new Date(new Date(appointment.start).getTime() - 20 * 60 * 1000) < currentDate &&
          !appointment.attended && (
            <MenuItem onClick={() => handleAttended(appointment.id, true)}>
              <Error color="info" />
              Noch nicht wahrgenommen
            </MenuItem>
          )}
        <MenuItem
          onClick={() => {
            openTicketForm(appointment);
            handleMenuClose();
          }}
        >
          <Note /> Ticket erstellen
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleCopy(appointment.id!, false); // flag set to true for continuous appointments
            handleMenuClose();
          }}
        >
          <Add /> Neuer Termin
        </MenuItem>
        <MenuItem
          onClick={(event: any) => {
            onAddNotes(event);
            handleMenuClose();
          }}
        >
          <Edit />
          Notizen hinzufügen/bearbeiten
        </MenuItem>
      </Menu>
      {editDialogOpen && (
        <AppointmentEditCard
          open={editDialogOpen}
          onClose={handleEditDialogClose}
          appointment={appointment}
          patientId={patientId}
          dataRefreshFlag={dataRefreshFlag}
          setDataRefreshFlag={setDataRefreshFlag}
        />
      )}
    </>
  );
});

const ContinuousAppointmentMenu = ({
  appointment,
  setDataRefreshFlag,
  dataRefreshFlag,
  patientId,
  handleCopy,
  onAddNotes,
}: MenuInterface) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const AppointmentStore = useStore(AppointmentContext);
  const { backfillContinuousAppointment, cancelContinuousAppointment } = AppointmentStore;
  const [editDialogOpen, setEditDialogOpen] = useState(false); // State for the edit dialog

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleBackfill = async (appointment: AppointmentDto) => {
    try {
      // Call your API or backfill function with the appointment object
      console.log("Backfilling appointment:", appointment);
      if (!appointment.id) {
        notificationStore.showMessage(
          "No appointment ID found for backfilling appointment:",
          "error"
        );
        return;
      }
      const response: any = await backfillContinuousAppointment(appointment.id);
      if (!response) return;

      if (response.backfilled === 0) {
        notificationStore.showMessage("Keine termine notwendig", "warning");
        return;
      } else {
        notificationStore.showMessage("" + response.backfilled + " termine erzeugt", "success");
        setDataRefreshFlag(appointment);
      }
    } catch (error) {
      console.error("Error backfilling appointment:", error);
    }
  };

  const handleCancel = async (appointment: AppointmentDto) => {
    try {
      // Call your API or backfill function with the appointment object
      console.log("Cancelling appointment:", appointment);
      if (!appointment.id) {
        notificationStore.showMessage(
          "No appointment ID found for cancelling appointment:",
          "error"
        );
        return;
      }
      const response: any = await cancelContinuousAppointment(appointment.id);
      if (response) notificationStore.showMessage("Termin storniert", "success");
      setDataRefreshFlag(appointment);
    } catch (error) {
      console.error("Error backfilling appointment:", error);
    }
  };

  const handleEditDialogOpen = () => {
    setEditDialogOpen(true);
    handleMenuClose();
  };
  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
    handleMenuClose();
  };

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls={`menu-${appointment.id}`}
        aria-haspopup="true"
        onClick={(event) => handleMenuOpen(event)}
      >
        <MoreVert />
      </IconButton>

      <Menu
        id={`menu-${appointment.id}`}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleEditDialogOpen}>
          <Edit />
          Bearbeiten
        </MenuItem>
        <MenuItem onClick={() => handleCancel(appointment)}>
          <Cancel className="cancel-icon" />
          stornieren
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleBackfill(appointment);
            handleMenuClose();
          }}
        >
          <ManageHistory /> Züruckplanen
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleCopy(appointment.id!, true); // flag set to true for continuous appointments
            handleMenuClose();
          }}
        >
          <Add /> Neuer Termin
        </MenuItem>
        {/* <MenuItem onClick={onAddNotes}>
          <Edit />
          Notizen hinzufügen/bearbeiten
        </MenuItem> */}
      </Menu>
      {editDialogOpen && (
        <AppointmentEditCard
          open={editDialogOpen}
          onClose={handleEditDialogClose}
          appointment={appointment}
          patientId={patientId}
          dataRefreshFlag={dataRefreshFlag}
          setDataRefreshFlag={setDataRefreshFlag}
          isContinous={true}
        />
      )}
    </>
  );
};

export default TerminView;
