/* eslint-disable react-hooks/exhaustive-deps */
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  Menu,
  MenuItem,
  Modal,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { observer, useObserver } from "mobx-react";
import React, { useState, useEffect } from "react";
import useStore from "../../../helpers/useStore";
import { UserContext } from "../../../stores/User/User.provider";
import formatDate from "../../../helpers/dateFormat";
import { Add, MoreVert, Search } from "@mui/icons-material";
import DataTable from "../../../molecules/DataTable";
import { format } from "date-fns";
import { MasterTablesContext } from "../../../stores/MasterTables/MasterTables.provider";
import { TopLevelPaper } from "../../../themes/StyledComponents";

interface UsersProps {
  onSelectUser: (user: any) => void; // Replace 'any' with the actual type of your user object
  selectedLocation: string;
  setSelectedLocation: (locationId: string) => void;
}
interface UserLocationFilter {
  selectedOption: string;
  options: { label: string; value: string }[];
  onOptionChange: (optionValue: string) => void;
}
interface UserDataTableHeaderTopProps {
  includeDeleted?: boolean;
  onIncludeDeletedChange?: (includeDeleted: boolean) => void;
  filters?: UserLocationFilter[];
  onAddUserClick: (user: any) => void;
  onSearchUser: (searchedVal: string) => void;
}
const UserDataTableHeaderTop = ({
  includeDeleted,
  onIncludeDeletedChange,
  filters,
  onAddUserClick,
  onSearchUser,
}: UserDataTableHeaderTopProps) => {
  const [searched, setSearched] = useState<string>("");

  return (
    <Box
      sx={{
        border: "3px solid #ccc",
        display: "flex",
        justifyContent: "flex-end",
        gap: "50px",
        height: "90px",
      }}
    >
      {/* Dropdown for the selection tab */}

      {filters?.map((filter) => (
        <Autocomplete
          sx={{ width: "200px", height: "50px" }}
          options={filter.options || []}
          getOptionLabel={(option: any) => option.label || ""}
          value={
            filter.options?.find((option: any) => option.value === filter.selectedOption) || null
          }
          onChange={(_, newValue) => {
            filter.onOptionChange(newValue ? newValue.value : "");
          }}
          renderInput={(params) => (
            <TextField {...params} label="Standort" InputLabelProps={{ shrink: true }} />
          )}
        />
      ))}

      {
        // additionalProperty?.onRowDoubleClick && (
        //   <Box
        //     sx={{
        //       paddingTop: 2,
        //       display: "flex",
        //       alignItems: "center",
        //       justifyContent: "space-evenly",
        //       gap: "50px",
        //       height: "65px",
        //       position: "relative",
        //     }}
        //   >
        //     {/* <Tooltip title="6 KG HB 2x or 6 KG HB 8 KGG 1-2 x or 6 KG 2x"> */}
        //     <TextField
        //       size="medium"
        //       type="text"
        //       label="Schnelltext"
        //       sx={{ padding: 0, width: "200px" }} // Adjust the width as needed
        //       value={inputValue} // Assuming inputValue is the state variable holding the value of the TextField
        //       InputLabelProps={{ style: { fontWeight: "bold" } }}
        //       onChange={(e) => setInputValue(e.target.value)} // Assuming setInputValue is the function to update the inputValue state
        //       onKeyDown={(e) => {
        //         if (e.key === "Enter") {
        //           e.preventDefault(); // Prevent the default Enter key behavior
        //           setIsQuickAdd((prev) => !prev); // Set isBoxOpen to true
        //         }
        //       }}
        //     />
        //     {/* </Tooltip> */}
        //     <Button
        //       onClick={() => {
        //         additionalProperty.onRowDoubleClick && additionalProperty.onRowDoubleClick();
        //       }}
        //       sx={{ padding: 1, width: "140px" }} // Adjust the width and other styles as needed
        //     >
        //       <Add />
        //       Neu Rezept
        //     </Button>
        //   </Box>
        // )
      }

      {/* Checkbox for including Softdeleted data */}
      <FormControlLabel
        control={
          <Switch
            checked={includeDeleted || false}
            onChange={(e) => onIncludeDeletedChange && onIncludeDeletedChange(e.target.checked)}
          />
        }
        label="Gelöschte Einträge anzeigen"
      />
      {
        <>
          <Button
            onClick={() => onAddUserClick(true)}
            sx={{ padding: 0, width: "100px" }} // Adjust the width and other styles as needed
          >
            <Add />
            Neu
          </Button>
          <Input
            type="text"
            placeholder="Suchen"
            value={searched}
            onChange={(e) => {
              const searchVal = e.target.value;
              setSearched(searchVal);
              onSearchUser(searchVal);
            }}
            sx={{ border: "2px solid #ccc" }}
            startAdornment={<Search />}
          />
        </>
      }
      {/* {onAddClick && ( */}
      {/* <Box sx={{ position: "absolute", top: 10, right: 10 }}> */}

      {/* </Box> */}
      {/* )} */}
    </Box>
  );
};

const Users = observer(({ onSelectUser, selectedLocation, setSelectedLocation }: UsersProps) => {
  const UserStore = useStore(UserContext);
  const [userData, setUserData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true); // New state for loading
  const [additionalProperty, setAdditionalProperty] = useState<boolean>(false);
  const { getUserBy, user, activateOrDeactivateUser } = UserStore;
  const [menu, setMenu] = useState<null | HTMLElement>(null);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [includeDeleted, setIncludeDeleted] = useState<boolean>(false);
  const MasterTableStore = useStore(MasterTablesContext);
  const { masterTablesData } = MasterTableStore;
  // Introduce a new state variable for the selected location
  //  const [selectedLocation, setSelectedLocation] = useState<string>("")

  const locationsOptions = masterTablesData
    .filter((obj: any) => "location" in obj)
    .map((obj: any) => obj.location);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenu(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenu(null);
  };

  const handleEditClick = (selectedUser: any) => {
    console.log("selectedUser", selectedUser);
    if (selectedUser) {
      console.log("Edit clicked for user:", selectedUser);
      onSelectUser(selectedUser);
      handleMenuClose();
    }
  };
  // const handleDeleteClick = (selectedUser: any) => {
  //   // Handle delete action here
  //   // Call the API to delete the user
  //   console.log("Delete clicked for user:", user);
  //   handleMenuClose();
  // };
  const handleActivateDeactivateClick = async (selectedUser: any) => {
    // Call the API to deactivate or activate the user based on the current state
    try {
      if (selectedUser.deletedAt) {
        // User is currently deactivated, activate the user
        await activateOrDeactivateUser(selectedUser.id, false);
        console.log("User activated:", selectedUser);
      } else {
        // User is currently active, deactivate the user (soft delete)
        await activateOrDeactivateUser(selectedUser.id, true);
        console.log("User deactivated:", selectedUser);
      }

      fetchData(selectedLocation);
    } catch (error) {
      console.error("Error during delete:", error);
    } finally {
      handleMenuClose();
    }
  };
  // Separate handler for the "More" button click
  const handleMoreButtonClick = (event: React.MouseEvent<HTMLElement>, row: any) => {
    console.log("ROWHJFJJF", row);
    setSelectedUser(row);
    // setSelectedRow(row);
    setMenu(event.currentTarget);
    // Stop event propagation to prevent it from reaching the row click handler
    event.stopPropagation();
  };

  const fetchData = async (locationId: string) => {
    console.log("userfecthData", user);
    try {
      setLoading(true); // Set loading to true when starting to fetch data
      const response = await getUserBy(locationId);
      const updatedData = (response || []).map((obj: any) => {
        const rolesData = (obj.roles || []).map((role: any) => role.name).join(", ");
        const parsedDate: any = new Date(obj.createdAt);
        // if (!isNaN(parsedDate)) {
        //   // Check if the parsed date is valid
        //   formattedDob = format(parsedDate, "dd.MM.yyyy");
        const therapiesData = (obj.therapies || []).map((t: any) => t.abbreviation).join(", ");

        return {
          ...obj,
          role: rolesData,
          therapiesList: therapiesData,
          createdAt: obj.createdAt ? format(parsedDate, "dd.MM.yyyy") : "",
          location: obj.location ? obj.location.id : "",
          therapistLocation: obj.therapistLocation ? obj.therapistLocation.id : "",
          // Add the "actions" property
          actions: (
            <>
              <IconButton onClick={(event) => handleMoreButtonClick(event, obj)}>
                <MoreVert />
              </IconButton>
            </>
          ),
        };
      });
      const filteredData = updatedData.filter((user) => includeDeleted || user.deletedAt === null);

      setUserData(filteredData);

      // setUserData(updatedData);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false); // Set loading to false when data fetching is done
    }
  };

  useEffect(() => {
    fetchData(selectedLocation);
  }, [user, includeDeleted, selectedLocation]);

  const handleLocationChange = (selectedLocationId: string) => {
    // Update the selected location and fetch data
    setSelectedLocation(selectedLocationId);
    fetchData(selectedLocationId);
  };

  const handleSearchUser = (searchKey: string) => {
    if (!searchKey.trim()) {
      fetchData(selectedLocation);
      return;
    }
    const filteredRows = userData.filter((row: any) => {
      const lastNameMatch =
        row.lastName && row.lastName.toLowerCase().includes(searchKey.toLowerCase());
      const firstNameMatch =
        row.firstName && row.firstName.toLowerCase().includes(searchKey.toLowerCase());
      const locationMatch =
        row.location && row.location.toLowerCase().includes(searchKey.toLowerCase());
      const emailMatch = row.email && row.email.toLowerCase().includes(searchKey.toLowerCase());
      // const rolesMatch = row.roles && row.roles.toLowerCase().includes(searchedVal.toLowerCase());

      // Check if the searched value matches any of the fields
      return lastNameMatch || firstNameMatch || locationMatch || emailMatch;
    });

    setUserData(filteredRows);
  };

  const columns = [
    { id: "firstName", label: "Vorname", minWidth: 50 },
    { id: "lastName", label: "Nachname", minWidth: 50 },
    { id: "email", label: "Email", minWidth: 80 },
    { id: "role", label: "Rolen", minWidth: 80 },
    { id: "therapiesList", label: "Heilmittel", minWidth: 50 },
    { id: "location", label: "Standort", minWidth: 50 },
    { id: "therapistLocation", label: "Behandlungsort", minWidth: 50 },
    { id: "createdAt", label: "Erstellet am", minWidth: 50 },
    // Add a new "Actions" column
    {
      id: "actions",
      label: "Actions",
      minWidth: 50,
    },
  ];

  return useObserver(() => {
    return (
      <>
        {loading ? (
          <Modal
            open={loading}
            aria-labelledby="loading-modal-title"
            aria-describedby="loading-modal-description"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div>
              <CircularProgress size={60} />
              Loading...
            </div>
          </Modal>
        ) : (
          <Grid container spacing={2}>
            <DataTable
              columns={columns}
              data={userData}
              onRowClick={(row) => {
                onSelectUser(row);
              }}
              tableHeaderTop={
                <UserDataTableHeaderTop
                  includeDeleted={includeDeleted}
                  onIncludeDeletedChange={setIncludeDeleted}
                  onAddUserClick={onSelectUser}
                  onSearchUser={handleSearchUser}
                  filters={[
                    {
                      options: locationsOptions[0]?.map((option: any) => ({
                        // Pass location options
                        label: option.id?.toString(),
                        value: option.id?.toString(),
                      })),
                      onOptionChange: handleLocationChange,
                      selectedOption: selectedLocation,
                    },
                  ]}
                />
              }
              //All the commented additionalProperty prop should be remove in the next iteration
              // additionalProperty={{
              //   additionalProperty: true,
              //   includeDeleted: includeDeleted,
              //   onIncludeDeletedChange: setIncludeDeleted,
              //   selectionTab: {
              //     user: selectedLocation, // Pass user details
              //     handleInputChange: fetchData, // Pass handleInputChange callback
              //     locationOptions: locationsOptions[0]?.map((option: any) => ({
              //       // Pass location options
              //       label: option.id?.toString(),
              //       value: option.id?.toString(),
              //     })),
              //     handleLocationChange: handleLocationChange, // Pass the function here
              //   },
              // }}
              // onAddClick={onSelectUser} // Pass onSelectUser callback to DataTable
            />
            <Menu id="actions-menu" anchorEl={menu} open={Boolean(menu)} onClose={handleMenuClose}>
              <MenuItem onClick={() => handleEditClick(selectedUser)}>Edit</MenuItem>
              {!selectedUser?.deletedAt && (
                <MenuItem onClick={() => handleActivateDeactivateClick(selectedUser)}>
                  Deactivate User
                </MenuItem>
              )}
              {selectedUser?.deletedAt && (
                <MenuItem onClick={() => handleActivateDeactivateClick(selectedUser)}>
                  Activate User
                </MenuItem>
              )}
            </Menu>
          </Grid>
        )}
      </>
    );
  });
});
export default Users;
