import { action, makeObservable, observable } from "mobx";
import { io, Socket } from 'socket.io-client';

export default class WebSocketStore {

  private basePath = process.env.REACT_APP_API_URL;

  @observable
  public socket: Socket;

  public constructor() {
    makeObservable(this);

    // Connect to the WebSocket server
    this.socket = io(this.basePath);
  }

  @action
  initializeWebSocket = async() => {
    console.log("WebSocket store initialized");

    // Listen for the connection confirmation
    this.socket.on("connection", (message) => {
      console.log(message); // This should print: "Welcome to the Appointments WebSocket server!"
    });
  }

  // TODD: Add more functions to interact with the WebSocket server here

}