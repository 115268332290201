import React, { useCallback, useEffect, useRef, useState } from "react";
import PatientHistoryTimelineRow from "./PatientHistoryTimelineRow";
import {
  GetHistoryDto,
  FindAllHistoryQueryFiltersDto,
  HistoryFiltersDto,
  GetHistoryDtoTypeEnum,
} from "../../../api-client";
import useStore from "../../../helpers/useStore";
import { HistoryContext } from "../../../stores/History/history.provider";
import { CircularProgress } from "@mui/material";
import { UserContext } from "../../../stores/User/User.provider";

interface PatientHistoryTimelineProps {
  patientId: number;
}
export default function PatientHistoryTimeline({ patientId }: PatientHistoryTimelineProps) {
  const UserStore = useStore(UserContext);
  const { user, getRoles, listPermissions, apiInfo, permissionMap } = UserStore;
  const HistoryStore = useStore(HistoryContext);
  const { getAllHistory } = HistoryStore;
  const [loading, setLoading] = useState<boolean>(true);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [histories, setHistories] = useState<GetHistoryDto[]>([]);
  const [fetchedApiFilters, setFetchedApiFilters] = useState<FindAllHistoryQueryFiltersDto>({
    limit: 10,
    page: 1,
    action: null,
    // type: GetHistoryDtoTypeEnum.Patient,
    type: null,
    fromDate: null,
    toDate: null,
    searchKey: null,
    patientId: patientId,
  });

  // Define the type of observer.current
  const observer = useRef<IntersectionObserver | null>(null);
  const lastElementRef = useCallback(
    (node: HTMLElement | null) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setFetchedApiFilters((prevFilters) => ({
            ...prevFilters,
            page: fetchedApiFilters.page! + 1,
          }));
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );
  useEffect(() => {
    if (patientId && user?.id) {
      fetchData();
    }
  }, [fetchedApiFilters, user?.id]);
  const fetchData = async () => {
    try {
      setLoading(true);

      const response = await getAllHistory(fetchedApiFilters);
      if (response?.length) {
        setHistories((prevHistories) => [...prevHistories, ...response]);
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching history data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {histories.map((history, index) => (
        <div ref={histories?.length == index + 1 ? lastElementRef : null}>
          <PatientHistoryTimelineRow key={history.id} history={history} />
        </div>
      ))}
      {loading && (
        <div>
          <CircularProgress size={60} />
          Loading...
        </div>
      )}
    </div>
  );
}
