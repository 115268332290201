import React, { useState, useEffect } from "react";
import axios from "axios"; // Axios is used for the API call, you can replace it with fetch or any other library
import notificationStore from "../../stores/Notification/notificationStore";
import useStore from "../../helpers/useStore";
import { AuthContext } from "../../stores/Auth/auth.provider";
import { Configuration, DocumentationApi, DocumentDto } from "../../api-client";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { Download, Folder, OpenInNew } from "@mui/icons-material";
import { useFetcher } from "react-router-dom";

// DocumentListViewer Component
export const DocumentListViewer = ({ patientId }: { patientId: number }) => {
  const AuthenticationStore = useStore(AuthContext);
  const { authToken } = AuthenticationStore;
  const config = new Configuration({
    basePath: process.env.REACT_APP_API_URL,
    accessToken: authToken || "",
  });

  console.log("User Provider has token:", authToken);
  const apiClient = new DocumentationApi(config);

  // State variables
  const [documents, setDocuments] = useState<DocumentDto[]>([]);
  const [loading, setLoading] = useState(false);
  const [accessUrl, setAccessUrl] = useState<string>();

  // useEffect to fetch documents when the component mounts or patientId changes
  useEffect(() => {
    if (!patientId) return;

    // Function to fetch documents
    const fetchDocuments = async () => {
      setLoading(true);
      try {
        const response = await apiClient.getDocuments(patientId);
        setDocuments(response.data); // Assuming response.data is an array of documents
      } catch (err) {
        notificationStore.showMessage("Fehler beim Abruf der Dokumente", "error", err);
      } finally {
        setLoading(false);
      }
    };

    // Call the fetch function
    fetchDocuments();
  }, [patientId]);

  useEffect(() => {
    if (accessUrl) {
      window.open(accessUrl, "_blank");
    }
  }, [accessUrl]);

  // Handle document click
  const handleDocumentClick = (document: DocumentDto) => {
    const fetchAccess = async () => {
      try {
        const response = await apiClient.requestAccessForDocument(document.id);
        setAccessUrl(response.data.accessUrl);
      } catch (err) {
        notificationStore.showMessage("Zugriff auf Dokument ist fehlgeschlagen", "error", err);
      }
    };

    fetchAccess();
  };

  return (
    <Grid container>
      <Grid item xs={4}>
        <Card>
          <CardHeader title="Documente" />
          <CardContent>
            {/* Loading state */}
            {loading && <p>Loading documents...</p>}

            {/* No documents state */}
            {!loading && documents.length === 0 && <p>No documents available.</p>}

            {/* Documents list */}
            <List>
              {documents.map((document: DocumentDto, index) => (
                <ListItem
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleDocumentClick(document)}
                    >
                      <OpenInNew />
                    </IconButton>
                  }
                >
                  <ListItemAvatar>
                    <Avatar>
                      <Folder />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={document.docuType} secondary={document.name} />
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
