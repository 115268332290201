import { useState, useEffect, useRef } from "react";
import { FindAllTicketsQueryFilters } from "../api-client";

interface TicketFilters {
  page: number;
  // Add other filter properties as needed
}

interface UseFetchTicketsResult {
  ticketsData: any[]; // Replace with actual ticket type
  isLoading: boolean;
  hasMore: boolean;
  fetchData: () => void; // Expose fetchData for manual triggers if needed
  resetData: () => void;
  
}

const useFetchTickets = (
  ticketFilters: FindAllTicketsQueryFilters,
  getTickets: (filters: FindAllTicketsQueryFilters) => Promise<any[]>,
  retryInterval: number = 3000,

): UseFetchTicketsResult => {
  const [ticketsData, setTicketsData] = useState<any[]>([]); // Replace with actual ticket type
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const fetchData = async () => {
    setIsLoading(true);
    let retryTimeout: NodeJS.Timeout | null = null;
    const loadTickets = async () => {
      try {
        const response = await getTickets(ticketFilters); // Your API call
        console.log("HANDLE_LOAD_MORE", ticketFilters.page);

        if (response?.length > 0) {
          setTicketsData((prevTickets) => [...prevTickets, ...response]);
          setHasMore(true);
        } else {
          setHasMore(false);
        }
        retryTimeout = null;
      } catch (error: any) {
        setHasMore(false); // Stop infinite loading

        // Check if the error is an HTTP error (assuming it has a status property)
        if (error?.response?.status || error?.code == "ERR_NETWORK") {
          console.warn(`HTTP error encountered: ${error?.response?.status}. Retrying...`);

          // Start retrying after specified interval
          retryTimeout = setTimeout(loadTickets, retryInterval);
        }
      } finally {
        if (!retryTimeout) setIsLoading(false);
      }
    };

    loadTickets();

    // Cleanup function to clear the timeout on unmount
    return () => {
      if (retryTimeout) {
        clearTimeout(retryTimeout);
      }
    };
  };

  useEffect(() => {
    fetchData();
  }, [ticketFilters]);

  const resetTicketsData = () => {
    setTicketsData([]);
  };

  return { ticketsData, isLoading, hasMore, fetchData, resetData: resetTicketsData };
};

export default useFetchTickets;
