import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  Input,
  InputAdornment,
  Link,
  Modal,
  TextField,
} from "@mui/material";
import { observer, useObserver } from "mobx-react";
import { Search } from "@mui/icons-material";
import debounce from "lodash.debounce";
import DataTable from "../../molecules/DataTable";
import useStore from "../../helpers/useStore";
import Controls from "../../atoms/Controls";
import { HistoryContext } from "../../stores/History/history.provider";
import { GetHistoryDto, FindAllHistoryQueryFiltersDto, HistoryFiltersDto } from "../../api-client";
import FlatCard from "../../atoms/FlatCard";
import HistoryFilters from "./HistoryFilters";
import { UserContext } from "../../stores/User/User.provider";

const HistoryTable: React.FC = observer(() => {
  const [loading, setLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [histories, setHistories] = useState<GetHistoryDto[]>([]);
  const [historyFilters, setHistoryFilters] = useState<HistoryFiltersDto | undefined>(undefined);
  const [fetchedApiFilters, setFetchedApiFilters] = useState<FindAllHistoryQueryFiltersDto>({
    limit: 20,
    page: 1,
    action: null,
    type: null,
    fromDate: null,
    toDate: null,
    searchKey: null,
  });

  const HistoryStore = useStore(HistoryContext);
  const { getAllHistory, getHistoryFilters } = HistoryStore;
  const [searchKey, setSearchKey] = useState("");
  const UserStore = useStore(UserContext);
  const { user, getRoles, listPermissions, apiInfo, permissionMap } = UserStore;
  const viewHistoryUserPermission = permissionMap.has("view-history-user");

  useEffect(() => {
    getHistoryFilters().then((filters) => {
      if (filters) setHistoryFilters(filters);
    });
  }, [getHistoryFilters]);

  useEffect(() => {
    if (user?.id) {
      fetchData();
    }
  }, [fetchedApiFilters, user?.id]);

  // Define the type of observer.current
  const observer = useRef<IntersectionObserver | null>(null);
  const lastElementRef = useCallback(
    (node: HTMLElement | null) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setFetchedApiFilters((prevFilters) => ({
            ...prevFilters,
            page: fetchedApiFilters.page! + 1,
          }));
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const fetchData = async () => {
    const retryInterval: number = 3000;
    let response: GetHistoryDto[] = [];
    let retryTimeout: NodeJS.Timeout | null = null;
    setLoading(true);
    const loadHistory = async () => {
      try {
        const data = await getAllHistory(fetchedApiFilters); // Your API call
        if (data?.length) {
          response = data;
          setHasMore(true);

          const updatedData = (response || []).map((obj: any) => {
            const parsedDate: Date = new Date(obj?.timestamp);

            return {
              ...obj,
              type: obj?.translations?.type || obj?.type,
              firstName:
                viewHistoryUserPermission || user?.id == obj.userId
                  ? obj.user?.firstName + " " + obj.user?.lastName
                  : "Mitarbeiter",
              // lastName: obj.user?.lastName,
              // email: obj.user?.email,
              createdAt: obj.timestamp ? parsedDate.toLocaleDateString("de-DE") : "",
              links: obj.patient ? (
                <Link href={`/patient?pId=${obj.patient?.id}`} color="inherit" target="_blank">
                  {obj.patient?.lastName + " " + obj.patient?.firstName}
                </Link>
              ) : null,
            };
          });

          setHistories((prevHistories) => [...prevHistories, ...updatedData]);
        } else {
          setHasMore(false);
        }

        retryTimeout = null;
      } catch (error: any) {
        setHasMore(false); // Stop infinite loading

        // Check if the error is an HTTP error (assuming it has a status property)
        if (error?.response?.status || error?.code == "ERR_NETWORK") {
          console.warn(`HTTP error encountered: ${error?.response?.status}. Retrying...`);

          // Start retrying after specified interval
          retryTimeout = setTimeout(loadHistory, retryInterval);
        }
      } finally {
        if (!retryTimeout) setLoading(false);
      }
    };

    loadHistory();
    // Cleanup function to clear the timeout on unmount
    return () => {
      if (retryTimeout) {
        clearTimeout(retryTimeout);
      }
    };
  };

  const handleSearchHistory = useCallback(
    debounce((query: string) => {
      setHistories([]); // Clear previous data
      setHasMore(true);
      setFetchedApiFilters((prevFilters) => ({
        ...prevFilters,
        searchKey: query?.trim() || "",
        page: 1,
      }));
    }, 500),
    []
  );

  const renderHistoryDataTableHeaderTop = (
    <>
      <Box sx={{ border: "3px solid #ccc", padding: "10px" }}>
        <HistoryFilters
          selectFilters={
            historyFilters
              ? [
                  {
                    name: "Type",
                    options: historyFilters.typeFilters.map((filter) => ({
                      label: filter.value,
                      value: filter.key,
                    })),
                    onChange: (optionValue: string) => {
                      setHistories([]); // Clear previous data
                      setHasMore(true);
                      setFetchedApiFilters((prevFilters) => ({
                        ...prevFilters,
                        type: optionValue,
                        action: null,
                        page: 1,
                      }));
                    },
                    value: fetchedApiFilters.type || "",
                  },
                  {
                    name: "Action",
                    options: historyFilters.actionFilters.map((filter) => ({
                      label: filter.value,
                      value: filter.key,
                    })),
                    onChange: (optionValue: string) => {
                      setHistories([]); // Clear previous data
                      setHasMore(true);
                      setFetchedApiFilters((prevFilters) => ({
                        ...prevFilters,
                        action: optionValue,
                        type: null,
                        page: 1,
                      }));
                    },
                    value: fetchedApiFilters.action || "",
                  },
                ]
              : []
          }
          dateFilters={[
            {
              name: "From",
              value: fetchedApiFilters.fromDate || "",
              onChange: (value: string) => {
                setHistories([]); // Clear previous data
                setHasMore(true);
                setFetchedApiFilters((prevFilters) => ({
                  ...prevFilters,
                  fromDate: value ? new Date(value).toISOString() : value,
                  page: 1,
                }));
              },
            },
            {
              name: "To",
              value: fetchedApiFilters.toDate || "",
              onChange: (value: string) => {
                setHistories([]); // Clear previous data
                setHasMore(true);
                setFetchedApiFilters((prevFilters) => ({
                  ...prevFilters,
                  toDate: value ? new Date(value).toISOString() : value,
                  page: 1,
                }));
              },
            },
          ]}
          searchFilter={{
            name: "Suchen",
            value: searchKey,
            onChange: (searchKey) => {
              setSearchKey(searchKey);
              handleSearchHistory(searchKey);
            },
          }}
        />
      </Box>
    </>
  );

  const columns = [
    { id: "firstName", label: "Benutzer", minWidth: 50 },
    // { id: "lastName", label: "Nachname", minWidth: 50 },
    // { id: "email", label: "Email", minWidth: 80 },
    { id: "type", label: "Type", minWidth: 80 },
    { id: "label", label: "Label", minWidth: 50 },
    { id: "message", label: "Message", minWidth: 50 },
    { id: "createdAt", label: "Erstellet am", minWidth: 50 },
    {
      id: "links",
      label: "Links",
      minWidth: 50,
    },
  ];

  return useObserver(() => (
    <>
      {/* {loading && (
        <div style={{ position: "absolute", top: "50%", left: "50%" }}>
          <CircularProgress size={60} />
          Loading...
        </div>
      )} */}
      <Grid container spacing={2} style={{ position: "relative", paddingBottom: 20 }}>
        <DataTable
          columns={columns}
          data={histories}
          onRowClick={(row) => {
            // console.log("History row clicked", row);
          }}
          tableHeaderTop={renderHistoryDataTableHeaderTop}
          lastRowRef={lastElementRef}
        />
        {loading && histories.length > 0 && (
          <Grid item xs={12} style={{ textAlign: "center", marginTop: "20px" }}>
            <CircularProgress size={40} />
          </Grid>
        )}
      </Grid>
    </>
  ));
});

export default HistoryTable;
