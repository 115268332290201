import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Tabs,
  Tab,
  Paper,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Collapse,
  Box,
  Button,
  Menu,
  MenuItem,
  Avatar,
  Autocomplete,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Chip,
  Tooltip,
  TableContainer,
  IconButton,
  CircularProgress,
  Modal,
  Card,
  CardHeader,
  CardContent,
  Grid,
} from "@mui/material";
import {
  KeyboardDoubleArrowUp,
  KeyboardArrowUp,
  DragHandle,
  KeyboardDoubleArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Add,
  Note,
} from "@mui/icons-material";
import { mobiliTheme, attentionColors } from "../../themes/mobiliTheme";
import { UserContext } from "../../stores/User/User.provider";
import useStore from "../../helpers/useStore";
import { FindAllTicketsQueryFilters, TicketDto } from "../../api-client";
import { TicketsStoreContext } from "../../stores/Ticketing/tickets.provider";
import { get } from "http";
import { commonTabStyle } from "../PatientOverview/tabStyles";
import TicketForm from "./TicketEditCreate/ticketForm";
import { format } from "date-fns";
import Person from "@mui/icons-material/Person";
import { set } from "mobx";
import Involvements from "./TicketEditCreate/Involvements";
import { observer } from "mobx-react";
import { MobiliAvatar } from "../../atoms/MobiliAvatar";
import useFetchTickets from "../../hooks/useFetchTickets";

const getPriorityColor = (priority: string) => {
  switch (priority) {
    case "High":
    case "HIGH":
      return attentionColors.immediateAttention; // Set background color to red for High priority
    case "Medium":
    case "MEDIUM":
      return attentionColors.mediumPriority; // Set background color to yellow for Medium priority
    case "Low":
    case "LOW":
      return attentionColors.lowPriority; // Set background color to green for Low priority
    default:
      return attentionColors.customInfoPrimary; // Default background color
  }
};

const TicketOverview: React.FC = observer(() => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const [selectedTicket, setSelectedTicket] = useState<any>(null);
  const TicketsStore = useStore(TicketsStoreContext);
  const { getTickets, getTicketById } = TicketsStore;
  const [refresh, setRefresh] = useState(false);

  const UserStore = useStore(UserContext);
  const { user } = UserStore;

  const [openDialog, setOpenDialog] = useState(false);
  const [openTicket, setOpenTicket] = useState(false);
  const commonFilter: FindAllTicketsQueryFilters = { limit: 10, page: 1 };
  const defaultFilter: FindAllTicketsQueryFilters = {
    ...commonFilter,
    ticketStatus: "NEW",
  };
  const [ticketFilters, setTicketFilters] = useState<FindAllTicketsQueryFilters>(defaultFilter);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    resetData();
    switch (newValue) {
      case 0:
        setTicketFilters(defaultFilter);
        break;
      case 1:
        if (user?.id) setTicketFilters({ ...commonFilter, createdByUserId: user?.id });
        break;
      case 2:
        if (user?.id) setTicketFilters({ ...commonFilter, assignedToUserId: user?.id });
        break;
      default:
        setTicketFilters(commonFilter);
    }
  };
  const [isTicketLoading, setIsTicketLoading] = useState(false);
  const handleRowClick = async (ticket: any) => {
    setIsTicketLoading(true);
    try {
      setOpenDialog(true);

      const response = await getTicketById(ticket?.id);
      setSelectedTicket(response);
    } catch (error) {
      console.error("Error fetching ticket:", error);
    } finally {
      setIsTicketLoading(false);
    }
  };

  const handleSubTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setOpenDropdown(newValue);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const { ticketsData, isLoading, hasMore, resetData } = useFetchTickets(ticketFilters, getTickets);
  useEffect(() => {  
      resetData()
      setTicketFilters({ ...ticketFilters, page: 1 });
    
  }, [refresh]);
  const prevLocationIdRef = useRef<string | undefined>();
  useEffect(() => {
    // setting loading data from page 1 when location is changed
    if (prevLocationIdRef.current && prevLocationIdRef.current !== user?.location?.id) {
      resetData();
      setTicketFilters({ ...ticketFilters, page: 1 });
    }

    prevLocationIdRef.current = user?.location?.id;
  }, [user?.location?.id]);

  // Define the type of observer.current
  const observer = useRef<IntersectionObserver | null>(null);
  const lastElementRef = useCallback(
    (node: HTMLElement | null) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setTicketFilters((prevFilters) => ({
            ...prevFilters,
            page: ticketFilters.page! + 1,
          }));
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore]
  );

  return (
    <>
      <Card sx={{ maxHeight: "400px" }}>
        <CardHeader
          avatar={<Note />}
          title={
            <Typography
              variant="h6"
              sx={{
                display: "flex",
                alignItems: "flex-end",
                paddingLeft: "8px",
                flexGrow: 1,
                borderRadius: 4,
                fontFamily: "inherit",
                justifyContent: "space-between",
              }}
            >
              Tickets
              <Tabs value={selectedTab} onChange={handleTabChange}>
                <Tab iconPosition="start" label="Neu" />
                <Tab iconPosition="start" label="Von mir" />
                <Tab iconPosition="start" label="Für mich" />
              </Tabs>
              <IconButton
                onClick={() => setOpenTicket(true)}
                sx={{ backgroundColor: mobiliTheme.palette.secondary.main }}
              >
                <Add sx={{ color: "white" }} />
              </IconButton>
            </Typography>
          }
        />
        <CardContent
          sx={{
            overflowY: "auto",
            height: "400px",
          }}
        >
          {/* {!isLoading && ticketsData.length > 0 && (
            <TicketsTable
              handleRowClick={handleRowClick}
              ticketsData={ticketsData}
              handleLoadMore={handleLoadMore}
            />
          )} */}
          <TicketsTable
            key={"tab-" + selectedTab}
            handleRowClick={handleRowClick}
            ticketsData={ticketsData}
            lastElementRef={lastElementRef}
          />
          {isLoading && <CircularProgress />}
          {!isLoading && ticketsData.length === 0 && (
            <Typography>Keine Tickets gefunden</Typography>
          )}
          {/* {selectedTab === 1 && !isLoading && ticketsData.length > 0 && (
            <TicketsTable
              handleRowClick={handleRowClick}
              ticketsData={ticketsData.filter(
                (ticket: any) =>
                  ticket.createdByUser?.id === user?.id && ticket.assignedToUser?.id !== user?.id
              )}
            />
          )}
          {selectedTab === 2 && !isLoading && ticketsData.length > 0 && (
            <TicketsTable
              handleRowClick={handleRowClick}
              ticketsData={ticketsData.filter(
                (ticket: any) =>
                  ticket.assignedToUser?.id === user?.id && ticket.createdByUser?.id !== user?.id
              )}
            />
          )} */}
        </CardContent>
      </Card>
      <TicketForm
        openTicket={openTicket}
        onCloseTicketForm={() => setOpenTicket(!openTicket)}
        refresh={refresh}
        setRefresh={setRefresh}
      />
      <TicketDialog
        open={openDialog}
        handleClose={handleCloseDialog}
        selectedTicket={selectedTicket}
        setSelectedTicket={setSelectedTicket}
        isTicketLoading={isTicketLoading}
        refresh={refresh}
        setRefresh={setRefresh}
      />
    </>
  );
});

export const TicketsTable: React.FC<{
  handleRowClick: (ticket: any) => void;
  handleLoadMore?: () => void;
  ticketsData: any;
  lastElementRef?: any;
}> = ({ handleRowClick, ticketsData, lastElementRef }) => {
  const UserStore = useStore(UserContext);
  const TicketsStore = useStore(TicketsStoreContext);
  const { user, allUsers, getUserBy, getAllUsers } = UserStore;
  const { getTickets } = TicketsStore;
  const [userOptions, setUserOptions] = useState<any>([]);
  const [initialRender, setInitialRender] = useState(true);
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenPopover = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       if (user) {
  //         // Fetch user data
  //         const userResponse = await getUserBy(user.location?.id as string);
  //         // Find index of logged-in user in userResponse
  //         const userIndex = userResponse.findIndex(
  //           (u: any) => u.id === user.id
  //         );

  //         // If logged-in user exists in userResponse, move it to the top
  //         if (userIndex !== -1) {
  //           userResponse.splice(userIndex, 1); // Remove logged-in user from its current position
  //           userResponse.unshift(user); // Add logged-in user at the beginning
  //         }
  //         setUserOptions(userResponse);
  //       } else {
  //         setUserOptions([]);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   // Fetch data when user changes or on initial render
  //   if (!initialRender || user) {
  //     fetchData();
  //   }

  //   // Set initialRender flag to false after initial render
  //   setInitialRender(false);
  // }, [user, initialRender]);
  // const [ticketData, setTicketData] = useState<TicketDto>({
  //   title: "",
  //   assignedToUser: { id: "" },
  //   description: "",
  //   activities: [],
  //   createdByUser: { id: "" },
  //   updatedByUser: { id: "" },
  //   ticketType: "",
  //   ticketStatus: "NEW",
  //   priority: "Low",
  // });

  // useEffect(() => {
  //   console.log("TICKET_DATA_LENGTH", ticketsData?.length);

  //   setTicketDataLength(ticketsData?.length);
  // }, [ticketsData]);

  // const handleChange = (e: any) => {
  //   const { name, value } = e.target;
  //   setTicketData({ ...ticketData, [name]: value });
  // };

  const [fetchingMoreUsers, setFetchingMoreUsers] = useState(false);
  // const handleMoreUsersClick = async () => {
  //   setFetchingMoreUsers(true);
  //   try {
  //     const moreUsers = await getAllUsers();
  //     // Filter out duplicate users by ID
  //     const filteredUsers = moreUsers.filter(
  //       (user) => !userOptions.some((existingUser: any) => existingUser.id === user.id)
  //     );
  //     setUserOptions((prevUsers: any) => [...prevUsers, ...filteredUsers]);
  //     // setFetchingMoreUsers(false);
  //   } catch (error) {
  //     console.error("Error fetching more users:", error);
  //     setFetchingMoreUsers(false);
  //   }
  // };

  const [anchorEl1, setAnchorEl1] = useState(null);
  const [selectedPriority, setSelectedPriority] = useState(
    <KeyboardDoubleArrowDown sx={{ color: attentionColors.lowPriority }} />
  ); // Default selected priority symbol

  const handleClick = (event: any) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl1(null);
  };

  // const handlePrioritySelect = (prioritySymbol: any, value: string) => {
  //   setSelectedPriority(prioritySymbol); // Update selected priority symbol
  //   setTicketData({ ...ticketData, priority: value }); // Update ticket data with selected priority
  //   handleClose();
  // };

  return (
    <TableContainer sx={{ maxHeight: "calc(100% - 48px)" }}>
    <Table >
      <TableHead
        sx={{
          position: "sticky",
          zIndex: "1",
          top: 0,
          backgroundColor: mobiliTheme.cardBackground,
        }}
      >
        <TableRow>
          <TableCell align="center" sx={{ padding: "8px" }}>ID#</TableCell>
          <TableCell align="center" sx={{ padding: "8px" }}>Typ</TableCell>
          <TableCell align="center" sx={{ padding: "8px" }}>Datum</TableCell>
          <TableCell align="center" sx={{ padding: "8px" }}>Status</TableCell>
          <TableCell align="center" sx={{ padding: "8px" }}>Erstellt</TableCell>
          <TableCell align="center" sx={{ padding: "8px" }}>Priorität</TableCell>
          <TableCell align="center" sx={{ padding: "8px" }}>Zugewiesen</TableCell>
          <TableCell align="center" sx={{ padding: "8px" }}>Betroffen</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {ticketsData.map((ticket: any, index: number) => (
          <TableRow
            onClick={() => handleRowClick(ticket)}
            ref={ticketsData.length === index + 1 ? lastElementRef : null}
            key={index}
            sx={{
              '&:nth-of-type(odd)': { backgroundColor: '#f9f9f9' },
              cursor: 'pointer',
            }}
          >
            <TableCell align="center" sx={{ padding: "8px" }}>
              <Tooltip title={`${ticket.id}`}>
                <Chip label={ticket.id} sx={{ whiteSpace: "normal", width: "100%" }} />
              </Tooltip>
            </TableCell>
            <TableCell align="center" sx={{ padding: "8px" }}>
              <Tooltip title={`${ticket.ticketType}`}>
                <Chip label={ticket.ticketType} sx={{ whiteSpace: "normal", width: "100%" }} />
              </Tooltip>
            </TableCell>
            <TableCell align="center" sx={{ padding: "8px" }}>
              <Tooltip title={`${format(new Date(ticket.createdAt), "dd.MM.yyyy HH:MM:SS")}`}>
                <Chip label={format(new Date(ticket.createdAt), "dd.MM")} sx={{ whiteSpace: "normal", width: "100%" }} />
              </Tooltip>
            </TableCell>
            <TableCell align="center" sx={{ padding: "8px" }}>
              <Tooltip title={`${ticket.ticketStatus}`}>
                <Chip label={ticket.ticketStatus} sx={{ whiteSpace: "normal", width: "100%" }} />
              </Tooltip>
            </TableCell>
            <TableCell align="center" sx={{ padding: "8px" }}>
              <MobiliAvatar user={ticket.createdByUser} sx={{ width: 32, height: 32 }} />
            </TableCell>
            <TableCell align="center" sx={{ padding: "8px" }}>
              <Tooltip title={`${ticket.priority}`}>
                <Avatar
                  sx={{
                    width: 30,
                    height: 30,
                    backgroundColor: getPriorityColor(ticket.priority),
                  }}
                />
              </Tooltip>
            </TableCell>
            <TableCell align="center" sx={{ padding: "8px" }}>
              <MobiliAvatar user={ticket.assignedToUser} sx={{ width: 32, height: 32 }} />
            </TableCell>
            <TableCell align="center" sx={{ padding: "8px" }}>
              <Tooltip
                title={
                  ticket.patient ? (
                    <Box sx={{ background: "white", borderRadius: "10px", padding: "5px" }}>
                      <Involvements ticketData={ticket} />
                    </Box>
                  ) : ""
                }
              >
                <Chip
                  label={ticket.patient ? `${ticket.patient.firstName} ${ticket.patient.lastName}` : "-"}
                  sx={{ whiteSpace: "normal", width: "100%" }}
                />
              </Tooltip>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
  
  );
};
export const TicketDialog: React.FC<{
  open: boolean;
  handleClose: () => void;
  selectedTicket: any;
  setSelectedTicket: any;
  isTicketLoading: boolean;
  refresh?: boolean;
setRefresh?:(value: boolean) => void;
}> = observer(({ open, handleClose, selectedTicket, setSelectedTicket, isTicketLoading,refresh,setRefresh }) => {
  return (
    <>
      <TicketForm
        openTicket={open}
        onCloseTicketForm={handleClose}
        data={selectedTicket}
        isTicketLoading={isTicketLoading}
        refresh={refresh}
        setRefresh={setRefresh}
      />
      <Tabs
        value={selectedTicket && selectedTicket.activities ? selectedTicket.activities.type : "All"}
        indicatorColor="primary"
        textColor="primary"
        centered
      />
    </>
  );
});

const TicketDropdown: React.FC<{
  openDropdown: string | null;
  subTabChange: (event: React.SyntheticEvent, newValue: string) => void;
  selectedTicket: any;
}> = ({ openDropdown, subTabChange, selectedTicket }) => {
  return (
    <Collapse in={openDropdown !== null} timeout="auto" unmountOnExit>
      <Box margin={1}>
        <Tabs
          value={openDropdown}
          onChange={subTabChange}
          indicatorColor="primary"
          textColor="primary"
          centered
          sx={{ minHeight: "" }}
        >
          <Tab iconPosition="start" label="All" value="All" sx={commonTabStyle} />
          <Tab iconPosition="start" label="Comments" value="Comments" sx={commonTabStyle} />
          <Tab iconPosition="start" label="History" value="History" sx={commonTabStyle} />
        </Tabs>
      </Box>
    </Collapse>
  );
};

export default TicketOverview;
