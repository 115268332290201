
  import React, { useEffect, useState } from "react";
  import { v4 as uuidv4 } from "uuid";

import { DoctorContext } from "../../../../stores/Doctor/doctor.provider";
import { UserContext } from "../../../../stores/User/User.provider";
import useStore from "../../../../helpers/useStore";

import { rxuseForm } from "../../../PatientDataEntryForm/Form";

import Private from "./Private/Private";
import { MasterTablesContext } from "../../../../stores/MasterTables/MasterTables.provider";
import { PatientDto } from "../../../../api-client";
import notificationStore from "../../../../stores/Notification/notificationStore";

import RxPage from "../../../RxPage";
import BG from "./BG/BG";
import Verordnung13 from "./Kasse/Verordnung13";
import { observer } from "mobx-react";
import Bundeswehr from "./Bundeswehr/Bundeswehr";
import Zahnarzt from "./Zahnarzt/Zahnarzt";

  
  interface RxFormsProps {
    isEditing?: boolean;

    // handleSubmit: () => void;
    selectedRow: any; // Adjust the type of selectedRow accordingly
    patientValues: any;
    masterData: any;
  handleData: (data: any) => void;
  setPatientData: (data: any) => void;
  readOnlyView?: boolean;
  openRx?: boolean;
  onCloseRx?: any;
  useDialog?: boolean;


  }
  interface DiagnoseGroup {
    a: string;
    b: string;
    c: string;
    d: string;
  }
  
  const RxFormsData: React.FC<RxFormsProps> = observer(({
    isEditing,

    selectedRow,
    patientValues,
    handleData,
    masterData,
    setPatientData,
    readOnlyView,
    openRx,
    onCloseRx,
    useDialog

 
  }) => {
    console.log(
      "SelectedrtteRow",
      selectedRow,
      patientValues
    );

    const DoctorStore = useStore(DoctorContext);
    const { doctorOptions } = DoctorStore;
    const UserStore = useStore(UserContext);
    const { user } = UserStore;
    const MasterTableStore=useStore(MasterTablesContext);
    const {masterTablesData}=MasterTableStore;
    const [dataRefreshFlag,setDataRefreshFlag]=useState<boolean>(false);
    const rxType = masterTablesData
    .filter((obj: any) => "rxType" in obj) // Filter objects that have the insuranceStatus property
    .map((obj: any) => obj.rxType);
  const diagnoseGroups = masterTablesData
    .filter((obj: any) => "diagnosisGroups" in obj)
    .map((obj: any) => obj.diagnosisGroups);
  const insurer = masterTablesData.filter((obj: any) => "insurer" in obj).map((obj: any) => obj.insurer);
  const account = masterTablesData.filter((obj: any) => "account" in obj).map((obj: any) => obj.account);

    const selectedInsurer = insurer[0].find(
      (insurer: any) => insurer.id === patientValues?.payer || insurer.id === patientValues?.payer?.id
    );
    const [therapytableData, settherapyTableData] = useState([]);
    // const rxStatusOptions = Object.values(RxTherapyDtoStatusEnum).map(
    //   (value) => ({
    //     label: value,
    //     value: value,
    //   })
    // );
    const initialFormValues = {
      // receiptId
      //  PatientId
      rxtype: "",
      firstName: patientValues.firstName,
      lastName: patientValues.lastName,
      dob: patientValues.dob,
      accountId: "",
      employee: "",
      chipcard: selectedInsurer?.ik ?? "",
      tarifGroupId: patientValues.tarifGroupId,
      // symptomCode: "",
      a: false,
      b: false,
      c: false,
      d: false,
      symptoms: "",
      Receiptpayer: patientValues.payer,
      insuranceStatus: patientValues.status,
      insuranceNo: patientValues.insuranceNo,
      doctorId: "",
      signatureStatus: "",
      isPerpetual: false,
      unvirtualize: false,
      isCopaymentExempted: false,
      isAccident: false,
      isBVG: false,
      status: "",
      hasIndividualSymptoms: false,
      hygiene: false,
      isAutoAddReport: false,
      urgent: false,
      // homeVisit: false,
      therapyReport: false,
      // isHomeVisitFree: false,
      date: patientValues.date,
      icdCodeEntries: [{ userText: "" }, { userText: "" }],
      practiceNumber: null,
      // number:"",
      doctorNumber: null,
      additionalNotes: "",
      diagnosegroup: "" || null,
      diagnosisText: "",
      isPerpetualApproved: false,
      therapyRxs: [],
      creationDate: "",
      visitType: "",
      problematic: false,
      changeRequested: false,
    };
  
    // Fetch the insurer property from the selected insurer object, or use an empty string if not found
    const insurerName = selectedInsurer?.name ?? "";
  
    const [formValues, setFormValues] = useState(initialFormValues);

    const rxvalidate = (fieldValues = formValues) => {
      let temp: any = { ...rxerrors };
      // if ("rxtype" in fieldValues)
      //   temp.rxtype = fieldValues.rxtype ? "" : "This field is required.";
      // if ("accountId" in fieldValues)
      //   temp.accountId = fieldValues.accountId ? "" : "This field is required.";
      // if ("chipcard" in fieldValues)
      //   temp.chipcard = fieldValues.chipcard ? "" : "Pflichtfeld";
      // if ("Receiptpayer" in fieldValues)
      //   temp.Receiptpayer = fieldValues.Receiptpayer ? "" : "Pflichtfeld";
      if ("tarifGroupId" in fieldValues)
        temp.tarifGroupId = fieldValues.tarifGroupId ? "" : "Pflichtfeld";
      // if ("practiceNumber" in fieldValues)
      //   temp.practiceNumber = fieldValues.practiceNumber
      //     ? ""
      //     : "This field is required.";
      // if ("doctorNumber" in fieldValues)
      //   temp.doctorNumber = fieldValues.doctorNumber
      //     ? ""
      //     : "This field is required.";
      // if ("doctorId" in fieldValues)
      //   temp.doctorId = fieldValues.doctorId ? "" : "This field is required.";
  
      // if ('email' in fieldValues)
      //     temp.email = (/$^|.+@.+..+/).test(fieldValues.email) ? "" : "Email is not valid."
  
      rxsetErrors({
        ...temp,
      });
  
      // Check if all values in the temp object are empty strings (valid)
      const isValid = Object.values(temp).every((x) => x === "");
  
      return isValid;
  
      // if (fieldValues == formValues)
      //   return Object.values(temp).every((x) => x == "");
    };
    const { rxerrors, rxsetErrors } = rxuseForm(initialFormValues, true, rxvalidate);
  
    // Initialize with two input boxes
    useEffect(() => {
      const fetchData = async () => {
        console.log("akkasksks",selectedRow)
        if (isEditing && selectedRow.rxNumber) {
          try {
            let rxs: [] = [];
            let updatedFormValues = { ...formValues };
            if (selectedRow.rxNumber) {
              const selectedRxNumber = selectedRow.rxNumber; // Assuming the property name is `number` for rxNumber in the selected row
              const filteredRxs = patientValues.rxs?.filter(
                (rx: any) => rx.rxNumber === selectedRxNumber
              );
  
              const formattedValues = { ...patientValues, rxs: filteredRxs };
  
              console.log("56878", patientValues, formattedValues.rxs, selectedRxNumber, filteredRxs);
              rxs = formattedValues.rxs
                ? formattedValues.rxs.flatMap((rx: any) => {
                    const therapyRxs = rx.therapyRxs || [];
                    console.log("THERRTZZ", therapyRxs);
                    return therapyRxs?.map((therapy: any) => ({
                      id: therapy.id,
                      date: rx.date,
                      // therapy: therapy.therapy.abbreviation || therapy.therapy,
                      frequency:therapy.frequency,
                      therapy: therapy.therapy,
                      amount: therapy.amount,
                      // frequency: therapy.frequency?.text || therapy.frequency,
                      isDoubled: therapy.isDoubled,
                      doubledTreatmentIds: therapy.doubledTreatmentIds,
                      rxSeries: therapy.rxSeries,
                    }));
                  })
                : [];
                settherapyTableData(rxs);
              formattedValues.rxs = formattedValues.rxs
                ? formattedValues.rxs.map((rx: any) => {
                    let icdCodeEntries;
  
                    if (rx.icdCodeEntries && rx.icdCodeEntries.length > 0) {
                      if (rx.icdCodeEntries.length === 1) {
                        icdCodeEntries = [...rx.icdCodeEntries, { userText: "" }];
                      } else {
                        icdCodeEntries = rx.icdCodeEntries;
                      }
                    } else {
                      icdCodeEntries = [{ userText: "" }, { userText: "" }];
                    }
                    return {
                      ...rx,
                      firstName: formattedValues.firstName,
                      lastName: formattedValues.lastName,
                      dob: formattedValues.dob,
                      Receiptpayer:
                        insurer[0].find((option: any) => option.id === rx.Receiptpayer)?.name ||
                        rx.payer?.name,
                      chipcard: rx.payer?.ik,
                      doctorId:
                        doctorOptions?.find((option: any) => option.id === rx.doctorId)?.contactInfo
                          .lastName || rx.doctor?.contactInfo?.lastName,
                      diagnosegroup:
                        diagnoseGroups[0].find((option: any) => option.id === rx.diagnosegroup)
                          ?.code || rx.diagnosisGroup?.code,
                      rxtype:
                        rxType[0].find((option: any) => option.id === rx.rxtype)?.text ||
                        rx.rxtype?.text,
                      accountId:
                        account[0].find((option: any) => option.id === rx.accountId)?.name ||
                        rx.account?.name,
                      icdCodeEntries: icdCodeEntries,
                    };
                  })
                : [];
              console.log("Formateed", formattedValues.rxs);
              updatedFormValues = {
                ...formValues,
                ...formattedValues.rxs[0], // Assuming only one item is filtered
                therapyRxs: rxs,
              };
              console.log("GZTUUU", formValues, updatedFormValues);
  
              // handleData({...patientValues,rxs:filteredRxs})
            }
            // else {
            //   console.log("ARB", formValues, selectedRow);
            //   const formattedValues = { ...patientValues, rxs: [selectedRow] };
  
            //   rxs = formattedValues.rxs
            //     ? formattedValues.rxs.map((rx: any) => ({
            //         id: rx.id,
            //         date: rx.date,
            //         number: rx.number,
            //         therapy: rx.therapy,
            //         frequency: rx.frequency,
            //         therapyId: rx.therapyId,
            //       }))
            //     : [];
            // }
            setFormValues(updatedFormValues);
            settherapyTableData(rxs);
         
          } catch (error) {
            console.log("error", error);
          }
          // };
  
          // fetchData();
          // dummyApiFetch().then((data) => {
          //   setValues(data);
          // });
        }
        // Make API request to fetch patient data based on patient ID or any other identifier
        else {
          console.log("Patientzzuu", patientValues);
          const filteredRxs = patientValues.rxs?.filter((rx: any) => rx.id === selectedRow.id);
          console.log("POOO", formValues, filteredRxs);
          const rxs = filteredRxs
            ? filteredRxs.flatMap((rx: any) => {
                const therapyRxs = rx.therapyRxs || [];
                console.log("THERAPPP", therapyRxs);
                return therapyRxs?.map((therapy: any) => ({
                  id: therapy.id || uuidv4(),
                  date: rx.date,
                  therapy: therapy.therapy,
                  amount: therapy.amount,
                  frequency: therapy.frequency || null,
                  isDoubled: therapy.isDoubled,
                  doubledTreatmentIds: therapy.doubledTreatmentIds,
                  rxSeries: therapy.rxSeries,
                }));
              })
            : [];
  
          console.log("Uiii", patientValues.rxs, filteredRxs, selectedRow, rxs, formValues);
          settherapyTableData(rxs);
          const updatedRx = {
            ...formValues,
            ...filteredRxs[0],
            accountId:
              account[0].find((option: any) => option.id === filteredRxs[0]?.accountId)?.name ||
              filteredRxs[0]?.accountId ||
              user?.location?.primaryAccount.id,
            diagnosegroup:
              diagnoseGroups[0].find((option: any) => option.id === filteredRxs[0]?.diagnosegroup)
                ?.code || filteredRxs[0]?.diagnosisGroup?.code,
            rxtype:
              rxType[0].find((option: any) => option.id === filteredRxs[0]?.rxtype)?.text ||
              filteredRxs[0]?.rxtype?.text,
            doctorId:
              doctorOptions?.find((option: any) => option.id === filteredRxs[0]?.doctorId)
                ?.contactInfo.lastName || filteredRxs[0]?.doctorId,
            Receiptpayer:
              insurer[0].find((option: any) => option.id === filteredRxs[0]?.Receiptpayer)?.name ||
              insurerName,
            // icdCodeEntries:filteredRxs[0].icdCodeEntries,
            therapyRxs: rxs,
          };
  
          // // // const updatedFormValues = { ...formValues, rxs: updatedRxs };
          setFormValues(updatedRx);
        
          console.log("OPOKK", formValues, updatedRx, doctorOptions);
        }
      };
      fetchData();
  
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditing, patientValues.rxs, selectedRow]);


  

  

  console.log("ajsajsjaksasa",therapytableData)

  
  const renderRxComponent = () => {
    switch (formValues?.rxtype) {
      case 'Privat/Selbstzahler':
        return (
          <Private
            selectedRow={formValues}
            patientValues={patientValues}
            // therapyTableData={therapytableData}
            handleData={handleData}
            openRx={openRx as boolean}
            onCloseRx={onCloseRx}
            newRx={false}
          />
        );
      case 'Berufsgenossenschaft':
        return (
          <BG
            selectedRow={formValues}
            patientValues={patientValues}
            handleData={handleData}
            // setUnsavedChanges={setUnsavedChanges}
            openRx={openRx as boolean}
            onCloseRx={onCloseRx}
            newRx={false}
          />
        );
        case 'Bundeswehr':
          return( <Bundeswehr
            rxFormData={
              formValues
            }
            patientValues={patientValues}
            handleData={handleData}
            masterTablesData={masterData}
            // setUnsavedChanges={setUnsavedChanges}
            newRx={false}
            openRx={openRx as boolean}
        onCloseRx={onCloseRx}
            // ref={saveGenericViewData}
          />)
        case 'Kasse':
        case'Entlassmanagement':
            return( <Verordnung13
                rxFormData={
                  formValues
                }
                patientValues={patientValues}
                handleData={handleData}
                masterTablesData={masterData}
                // setUnsavedChanges={setUnsavedChanges}
                newRx={false}
                openRx={openRx as boolean}
            onCloseRx={onCloseRx}
                // ref={saveGenericViewData}
              />)
              case 'Zahnarzt':
                return( <Zahnarzt
                    rxFormData={
                      formValues
                    }
                    patientValues={patientValues}
                    handleData={handleData}
                    masterTablesData={masterData}
                    // setUnsavedChanges={setUnsavedChanges}
                    newRx={false}
                    openRx={openRx as boolean}
                onCloseRx={onCloseRx}
                    // ref={saveGenericViewData}
                  />)
      // Add other cases for different rxtypes as needed
      default:
        return      (       <RxPage
        isEditing={true}
        selectedRow={selectedRow}
        patientValues={patientValues}
        masterData={masterData}
        handleData={handleData}
        setPatientData={(row: any) => setPatientData(row)}
        openRx={openRx}
        onCloseRx={onCloseRx}
        useDialog={true}
      />)
    }
  };

  return (
    <>
      {renderRxComponent()}
    </>
  );
  });
  
  export default RxFormsData;
  