import {
  Box,
  Button,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
} from "@mui/material";
import React, { FC, useCallback, useEffect, useState } from "react";
import { BaseTransactionDetailsDto } from "../../../api-client";
import useStore from "../../../helpers/useStore";
import { TransactionsContext } from "../../../stores/Transactions/Transactions.provider";
import { formatDateUI } from "../../../helpers/dateFormat";
import { TopLevelPaper } from "../../../themes/StyledComponents";
import { RxStoreContext } from "../../../stores/Rx/rx.provider";
import AlertDialogSlide from "../../Transactions/TransactionMethod";

interface PatientRechnungenProps {
  patientId: number;
  onTransactionComplete: () => void;
}

const PatientRechnungen: FC<PatientRechnungenProps> = ({ patientId, onTransactionComplete }) => {
  const TransactionsStore = useStore(TransactionsContext);
  const {
    getTransactionByPatientId,
    getPaidBillsByPatientId,
    getUnpaidBillsByPatientId,
    lastUpdate,
  } = TransactionsStore;
  const RxStore = useStore(RxStoreContext);
  const { getRxByNumber } = RxStore;

  const [allTransactions, setAllTransactions] = useState<BaseTransactionDetailsDto[]>([]);
  const [unpaidBills, setUnpaidBills] = useState<any[]>([]);
  const [paidBills, setPaidBills] = useState<any[]>([]);
  const [selectedTabs, setSelectedTabs] = useState(1);

  const fetchUnpaidBills = useCallback(async () => {
    const unpaidBills = await getUnpaidBillsByPatientId(patientId);
    // Sort based on the numeric part after the dash since the rxNumber is like "5016-1", "5016-2", "5016-3"
    const sortedUnpaidBills = unpaidBills.sort((a: any, b: any) => {
      const [prefixA, suffixA] = a.rxNumber.split("-");
      const [prefixB, suffixB] = b.rxNumber.split("-");

      if (prefixA !== prefixB) {
        return prefixA.localeCompare(prefixB);
      }

      return parseInt(suffixA) - parseInt(suffixB);
    });
    setUnpaidBills(sortedUnpaidBills);
  }, [getUnpaidBillsByPatientId, patientId]);

  const fetchPaidBills = useCallback(async () => {
    const paidBills = await getPaidBillsByPatientId(patientId);
    // Sort based on the numeric part after the dash since the rxNumber is like "5016-1", "5016-2", "5016-3"
    const sortedPaidBills = paidBills.sort((a: any, b: any) => {
      const [prefixA, suffixA] = a.rxNumber.split("-");
      const [prefixB, suffixB] = b.rxNumber.split("-");

      if (prefixA !== prefixB) {
        return prefixA.localeCompare(prefixB);
      }

      return parseInt(suffixA) - parseInt(suffixB);
    });
    setPaidBills(sortedPaidBills);
  }, [getPaidBillsByPatientId, patientId]);

  const fetchTransactions = useCallback(async () => {
    const transactions = await getTransactionByPatientId(patientId);

    const sortedTransactions = transactions.sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });
    setAllTransactions(sortedTransactions);
  }, [getTransactionByPatientId, patientId]);

  const [selectedRxData, setSelectedRxData] = useState<any>(null);
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);

  const handleZahlenClick = async (rxNumber: string) => {
    try {
      const rxData = await getRxByNumber(rxNumber);
      setSelectedRxData(rxData);
      setIsAlertDialogOpen(true);
      setOpenTransactionType(true);
    } catch (error) {
      console.error("Failed to fetch Rx data", error);
    }
  };

  useEffect(() => {
    fetchPaidBills();
  }, [fetchPaidBills, lastUpdate]);

  useEffect(() => {
    fetchUnpaidBills();
  }, [fetchUnpaidBills, lastUpdate]);

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions, lastUpdate]);

  const handleTabChange = (event: any, newValue: any) => {
    setSelectedTabs(newValue);
  };

  const [openTransactionType, setOpenTransactionType] = useState<boolean>(false);

  const handleCloseTransactionType = async () => {
    setOpenTransactionType(false);
    await fetchUnpaidBills();
    await fetchTransactions();
    onTransactionComplete();
  };

  return (
    <>
      <div>
        <Box sx={{ display: "flex", justifyContent: "center", marginBottom: "-20px" }}>
          <h2>Rezepte</h2>
        </Box>
        <Box>
          <Tabs
            orientation="horizontal"
            value={selectedTabs}
            onChange={handleTabChange}
            aria-label="sub-tabs"
          >
            <Tab
              iconPosition="start"
              label={
                <span
                  style={{
                    color: "black",
                    fontSize: "12px",
                  }}
                >
                  Fällig
                </span>
              }
              value={1}
              sx={{
                marginRight: "4px",
              }}
            />
            <Tab
              iconPosition="start"
              label={
                <span
                  style={{
                    color: "black",
                    fontSize: "12px",
                  }}
                >
                  Bezahlt
                </span>
              }
              value={2}
              sx={{
                marginRight: "4px",
              }}
            />
          </Tabs>
        </Box>
        {(() => {
          switch (selectedTabs) {
            case 1:
              return (
                <>
                  <TopLevelPaper sx={{ borderRadius: "0 0 12px 12px" }}>
                    <TableContainer component={Paper}>
                      <Table
                        sx={{ minWidth: 650 }}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>RX Nummer</TableCell>
                            <TableCell align="left">Gesamtpreis (€)</TableCell>
                            <TableCell align="left">Aktionen</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {unpaidBills.map((bills) => (
                            <TableRow key={bills.rxNumber}>
                              <TableCell
                                component="th"
                                scope="row"
                              >
                                {bills.rxNumber}
                              </TableCell>
                              <TableCell align="left">
                                {Number(bills.realPrice).toFixed(2).replace(".", ",") + " €"}
                              </TableCell>
                              <TableCell align="left">
                                <Button
                                  size="small"
                                  variant="outlined"
                                  color="secondary"
                                  onClick={() => handleZahlenClick(bills.rxNumber)}
                                  sx={{ fontSize: "0.6rem", padding: "0px 3px", minWidth: "auto" }}
                                >
                                  Zahlen
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </TopLevelPaper>
                  {isAlertDialogOpen && (
                    <AlertDialogSlide
                      open={openTransactionType}
                      handleClose={handleCloseTransactionType}
                      patientData={selectedRxData?.patient}
                      selectedRow={selectedRxData}
                    />
                  )}
                </>
              );
            case 2:
              return (
                <>
                  <TopLevelPaper sx={{ borderRadius: "0 0 12px 12px" }}>
                    <TableContainer component={Paper}>
                      <Table
                        sx={{ minWidth: 650 }}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>RX Nummer</TableCell>
                            <TableCell align="left">Gesamtpreis (€)</TableCell>
                            <TableCell align="left">Bezahlt (€)</TableCell>
                            <TableCell align="left">Fällig (€)</TableCell>
                            <TableCell align="left">Aktionen</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {paidBills.map((bills) => (
                            <TableRow key={bills.rxNumber}>
                              <TableCell
                                component="th"
                                scope="row"
                              >
                                {bills.rxNumber}
                              </TableCell>
                              <TableCell align="left">
                                {Number(bills.realPrice).toFixed(2).replace(".", ",") + " €"}
                              </TableCell>
                              <TableCell align="left">
                                {Number(bills.amountPaid).toFixed(2).replace(".", ",") + " €"}
                              </TableCell>
                              <TableCell align="left">
                                {Number(bills.dueAmount).toFixed(2).replace(".", ",") + " €"}
                              </TableCell>
                              <TableCell align="left">
                                {bills.dueAmount <= 0 ? (
                                  <span>Full Payment Done</span>
                                ) : (
                                  <Button
                                    size="small"
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => handleZahlenClick(bills.rxNumber)}
                                    sx={{
                                      fontSize: "0.6rem",
                                      padding: "0px 3px",
                                      minWidth: "auto",
                                    }}
                                  >
                                    Zahlen
                                  </Button>
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </TopLevelPaper>
                  {isAlertDialogOpen && (
                    <AlertDialogSlide
                      open={openTransactionType}
                      handleClose={handleCloseTransactionType}
                      patientData={selectedRxData?.patient}
                      selectedRow={selectedRxData}
                    />
                  )}
                </>
              );
            default:
              return null;
          }
        })()}
      </div>

      <div>
        <h2>Alle Bewegungen</h2>
        <TopLevelPaper sx={{ borderRadius: "0 0 12px 12px" }}>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Transaktion Nummer</TableCell>
                  <TableCell>RX Nummer</TableCell>
                  <TableCell align="left">Typ</TableCell>
                  <TableCell align="left">Gesamtpreis (€)</TableCell>
                  <TableCell align="left">Erstellungsdatum</TableCell>
                  <TableCell align="left">Erstellt von</TableCell>
                  <TableCell align="left">T-Konten</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allTransactions.map((transaction) => (
                  <TableRow key={transaction.transactionNumber}>
                    <TableCell
                      component="th"
                      scope="row"
                    >
                      {transaction.transactionNumber}
                    </TableCell>
                    <TableCell>{transaction.rx.rxNumber}</TableCell>
                    <TableCell align="left">{transaction.type}</TableCell>
                    <TableCell align="left">
                      {Number(transaction.amount).toFixed(2).replace(".", ",") + " €"}
                    </TableCell>
                    <TableCell align="left">{formatDateUI(transaction.createdAt)}</TableCell>
                    <TableCell align="left">
                      {transaction.user.firstName + " " + transaction.user.lastName}
                    </TableCell>
                    <TableCell align="left">
                      {transaction.tAccounts.id + " - " + transaction.tAccounts.name}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TopLevelPaper>
      </div>
    </>
  );
};

export default PatientRechnungen;
