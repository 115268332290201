/* eslint-disable no-template-curly-in-string */
import React, { useEffect, useState } from "react";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Timeline,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from "@mui/lab";
import { color } from "@mui/system";
import { AppointmentDto, ContinuousAppointmentDto, NoteDto, PatientAvailabilityDto, TimeSlotDto } from "../../api-client/api";
import { format } from "date-fns";
import useStore from "../../helpers/useStore";
import { AppointmentContext } from "../../stores/Appointment/appointment.provider";
import WarningDialog from "../../atoms/WarningDialog";
import {
  AccessTime,
  Cancel,
  CheckCircle,
  Error,
  InsertInvitation,
  NoteAlt,
  NoteAltOutlined,
  AutoAwesome,
  EditCalendar,
} from "@mui/icons-material";
import AppointmentSuggestion from "./Appointments/AppointmentSuggestion";
import Notes from "./Notes/Notes";
import { NotesContext } from "../../stores/Notes/notes.provider";
import { UserContext } from "../../stores/User/User.provider";
import {generateColor} from "../../helpers/generateColor";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import { formatDateUI, formatTime } from "../../helpers/dateFormat";
import { visitTypeMapping } from "../../helpers/visitTypeMapping";
import { useNavigate } from "react-router-dom";
import notificationStore from "../../stores/Notification/notificationStore";
import TerminView from "../PatientOverview/RxInfo/TerminView";
import { TerminViewConstants } from "../PatientOverview/TerminView";
import Controls from "../../atoms/Controls";
import { SmallChip, TherapyChip } from "../../atoms/Chip";
import { MobiliAvatar } from "../../atoms/MobiliAvatar";
import { mobiliTheme } from "../../themes/mobiliTheme";
import { observer } from "mobx-react";

// Define an interface for the appointment object
interface Appointment {
  id: string;
  start: string | undefined;
  // Add other properties of the appointment object here
}

const dialogStyle = {
  minWidth: "800px", // Adjust the width as needed
  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)", // Add a slight box shadow
};
interface UnscheduledAppointmentsProps {
  appointments: any[];
  continuousAppointments?: ContinuousAppointmentDto[];
  setDataRefreshFlag: any;
  dataRefreshFlag: any;
  patientId: any;
  blockedData: TimeSlotDto[];
  setblockedData: any;
  patientAvailabilityData: PatientAvailabilityDto[];
  setPatientAvailability: any;
  appointmentsLoading:boolean;
}
const date = (date: any) => {
  const dateString: string | undefined = format(date, "dd.MM.yyyy");
  const dateobj = dateString ? new Date(dateString) : null;
  return dateobj;
};

const rxNumberColorMap: { [key: string]: string } = {};

const getColorForRxNumber = (rxNumber: string) => {
  // Check if the color for this Rx number is already assigned
  if (rxNumberColorMap[rxNumber]) {
    return rxNumberColorMap[rxNumber];
  }

  // If not assigned, generate a new color using the generateColor function
  const newColor = generateColor(rxNumber);
  rxNumberColorMap[rxNumber] = newColor;
  console.log("NEWCOLO", newColor);
  return newColor;
};

const TherapyCard = ({ timeSlot }: { timeSlot: any }) => {
  // Get the color based on the Rx number
  const rxNumber = timeSlot?.therapyRx?.rx?.rxNumber || "";
  const visitType = visitTypeMapping[timeSlot?.therapyRx?.rx?.visitType];
  const rxNumberColor = getColorForRxNumber(rxNumber);
  return (
    <Tooltip
      title={
        <Box>
          {timeSlot?.user?.id && (
            <Typography variant="body1">
              Therapeut: {timeSlot?.user.firstName} {timeSlot?.user.lastName}
            </Typography>
          )}
          <Typography variant="body1">
            RxNo.:{timeSlot?.therapyRx?.rx?.rxNumber}
          </Typography>
          <Typography variant="body1">
            Therapy:{timeSlot?.therapyRx?.therapy?.abbreviation}
          </Typography>
        </Box>
      }
    >
      <Grid container alignItems="center" justifyContent="space-between">
      <Grid item xs={3} >
        {/* Therapy details */}
        <Tooltip title={rxNumber}>
        <Controls.Chip
          label={rxNumber}
          sx={{fontSize: "0.6em" }}
          variant="outlined"
        /></Tooltip>
      </Grid>
      <Grid item xs={3}>
        <TherapyChip
          therapyName={timeSlot?.therapyRx?.therapy?.abbreviation}
          sx={{ fontSize: "0.5em" }}
        />
      </Grid>
      <Grid item xs={3}>
        {/* Avatar */}
        {timeSlot?.user?.id && (
          <MobiliAvatar
            user={timeSlot?.user}
            isTherapist
            sx={{ width: 25, height: 25, fontSize: "0.8em" }}
          />
        )}
      </Grid>
      <Grid item xs={3}>
      {timeSlot.visitFee && (
        <Tooltip title={timeSlot.visitFee.abbreviation}>
       
            <SmallChip label={timeSlot.visitFee.abbreviation} />
        </Tooltip>
      )}</Grid>
      {/* We can Add visit type back in if requested:  */}
      {/* {visitType && (
        <Grid item>
          <Tooltip title={visitType}>
            <Controls.Chip label={visitType} color="primary" variant="outlined" />
          </Tooltip>
        </Grid>
      )} */}
    
    </Grid>
    </Tooltip>
  );
};
export const AppointmentCard = ({
  appointment,
  onAddNotes,
}: {
  appointment: any;
  onAddNotes?: () => void;
}) => {
  const { timeSlots } = appointment;
  // Calculate the height based on the number of therapyRx items
  const cardHeight = `${timeSlots.length * 30 + 20}px`; // Adjust the height calculation as needed

  // Determine the background color based on conditions
  const backgroundColor = appointment.start
    ? appointment.attended
      ? "success.main" // Attended and appointment.start exists
      : "primary.main" // Unattended and appointment.start exists
    : "info.main"; // No appointment.start

  return (
    <div>
      {/* Icon Button for adding notes as a tag */}
      <Tooltip title="Notizen">
        <IconButton
          sx={{
            position: "absolute !important",
            top: -12, // Position at the top of the card
            left: -25, // Position at the left of the card
            // backgroundColor: 'white', // Adjust the icon button background color
            borderRadius: "0 !important", // Remove border radius to make it a square tag
            zIndex: 1, // Place it above the card content
            visibility: onAddNotes ? "visible" : "hidden", // Show the icon button only if onAddNotes is defined
          }}
          onClick={onAddNotes}
        >
          <Badge color="secondary">
            <NoteAlt />
          </Badge>
        </IconButton>
      </Tooltip>

      <Box sx={{ padding: "0px", border: "0px dotted red",width:"300px" }}>
        <Box
          sx={{
            position: "relative",
            border:`1px solid ${mobiliTheme.mainHeader}`,
            p: 0,
            m: 0,
            padding: "0px",
            paddingBottom: "4px",
            margin: "0px",
            height: cardHeight,
              // display: "flex",
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            backgroundColor: backgroundColor,
            borderRadius: "0.4em",
          }}
        >
         {/* Second grid in another row */}
        <Grid container >
          {timeSlots
          .sort((a: any, b: any) => a.therapyRx.order - b.therapyRx.order)
          .map((timeSlot: any, index: any) => (
            <Grid item xs={12} key={index}>
              <TherapyCard timeSlot={timeSlot} />
            </Grid>
          ))}
        </Grid>
        </Box>
        </Box>
    </div>
  );
};

// const AppointmentCard = ({ appointment }: { appointment: any }) => {
//   const { timeSlots } = appointment;
//   // Calculate the height based on the number of therapyRx items
//   const cardHeight = `${timeSlots.length * 30 + 20}px`; // Adjust the height calculation as needed
//   return (
//     <>
//       <Card
//         sx={{
//           height: cardHeight,
//           background: appointment.start ? "blue" : "grey",
//         }}
//       >
//         <CardContent>
//           <Grid container spacing={1}>
//             {timeSlots.map((timeSlot: any, index: any) => (
//               <Grid item xs={12} key={index}>
//                 <TherapyCard timeSlot={timeSlot} />
//               </Grid>
//             ))}
//           </Grid>
//         </CardContent>

//       </Card>
//     </>
//   );
// };
export interface DeleteEvent {
  selectedAppointment: string;
  isContinuous:boolean;
  isDeleteDialogOpen: boolean;
}

const UnscheduledAppointments = observer(({
  appointments,
  continuousAppointments,
  setDataRefreshFlag,
  patientId,
  dataRefreshFlag,
  blockedData,
  setblockedData,
  patientAvailabilityData,
  setPatientAvailability,
  appointmentsLoading
}: UnscheduledAppointmentsProps) => {
  const AppointmentStore = useStore(AppointmentContext);
  const { setAttended, deleteAppointment, deleteContinuousAppointment } = AppointmentStore;
  const [showNotesDialog, setShowNotesDialog] = useState(false);
  const [isWarningDialogOpen, setWarningDialogOpen] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState<Appointment | null>(null); // Track the selected appointment for the dialog
  const [isSuggestionDialogOpen, setsuggestionDialogOpen] = useState(false);
  // const [suggestionData, setSuggestionData] = useState<any>(null); // Initialize with null or appropriate initial data
  const appointmentStore = useStore(AppointmentContext);
  const NotesStore = useStore(NotesContext);
  const { getAppointmentNotes, postNotes,fetchNotes,setFetchNotes,updateNoteById } = NotesStore;
  const [appoitmentNotes, setAppointmentNotes] = useState<NoteDto[]>([]);
  const UserStore = useStore(UserContext);
  const { user } = UserStore;
  const [selectedAppointmentId, setSelectedAppointmentId] = useState<string | null>(null);
  // const [fetchNotes, setFetchNotes] = useState(true); // Use a flag
  const [deleteEvent, setDeleteEvent] = useState<DeleteEvent|null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selectedAppointmentId) {
          console.log("selectedAppointmentId", selectedAppointmentId);
          const response = await getAppointmentNotes(selectedAppointmentId);
          setAppointmentNotes(response || []);
          // setFetchNotes(!fetchNotes);
        } else {
          console.log("No selectedAppointmentId available.");
        }
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchData();
  }, [selectedAppointmentId, fetchNotes]);

  const updateNote = async (index: number, updatedCard: NoteDto) => {
    if (selectedAppointmentId) {
      updatedCard.appointmentId = selectedAppointmentId;
      console.log("updatedCard", updatedCard);
      if(updatedCard.id){
        await updateNoteById(updatedCard.id,user?.id as string,updatedCard)
     }
     else{
      await postNotes(user?.id as string, updatedCard);
     }
      const updatedNotes = [...appoitmentNotes];
      updatedNotes[index] = updatedCard;
      setAppointmentNotes(updatedNotes);
      // setFetchNotes(true);
    } else {
      console.log("No selectedAppointmentId available.");
    }
  };

  const openSuggestionDialog = () => {
    setsuggestionDialogOpen(true);
  };

  const handlePlanning = (patientId: string) => {
    navigate("/termin?pid=" + patientId);
  };

  const handleContinuousPlanning = (patientId: string) => {
    navigate("/termin?pid=" + patientId + "&continuousView=true");
  };

  const handleAppointmentSuggestionClose = (wasSaved: boolean) => {
    setsuggestionDialogOpen(false);
    if (wasSaved) {
      setDataRefreshFlag(true);
    }
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       console.log("Get Solutions in use Effect in Terimin");
  //       const response = await getSolutions(patientId, 4);
  //       setSuggestionData(response);

  //       // setPatients(response);
  //     } catch (error) {
  //       console.log("error", error);
  //     }
  //   };

  //   fetchData();
  // }, [isSuggestionDialogOpen]);

  // Get the current date and time
  const currentDate: any = new Date();
  console.log("currentData", currentDate);

  // console.log("DataAA", suggestionData);

  const handleOpenWarningDialog = (appointmentId: any, appointmentStart: string | undefined) => {
    // Create an object with appointmentId and appointmentStart
    const appointmentInfo = { id: appointmentId, start: appointmentStart };
    setSelectedAppointment(appointmentInfo);
    setWarningDialogOpen(true);
  };

  const handleDelete = async () => {
    try {
      if (deleteEvent?.isContinuous)
        await deleteContinuousAppointment(deleteEvent?.selectedAppointment as string);
      else 
        await deleteAppointment(deleteEvent?.selectedAppointment as string);
      setDataRefreshFlag(true);      
      setDeleteEvent(null);
    } catch (error) {
      notificationStore.showMessage(
        "Fehler beim Löschen des Termins" + (error as Error).message,
        "error"
      );
      console.error("Error marking as attended/unattended:", error);
    }
  };
  
  const handleAttended = async (appointmentId: any, isAttended: boolean) => {
    try {
      // Call your API or setAttended function with the appointmentId and isAttended value
      await setAttended(appointmentId, isAttended);
      setDataRefreshFlag(isAttended);
    } catch (error) {
      console.error("Error marking as attended/unattended:", error);
    }
  };

  // Function to show the Notes component
  const handleAddNotes = (appointmentId: string) => {
    setSelectedAppointmentId(appointmentId);
    setShowNotesDialog(true);
  };

  const handleCloseNotesDialog = () => {
    setShowNotesDialog(false);
  };

  return (
    <Grid container direction="column">
      <Divider textAlign="left">
        <div style={{ textAlign: "left", fontWeight: "bold" }}>
          Standardtermine
        </div>
      </Divider>
      {appointments.length > 0 && (
        <Grid
          item
          xs={"auto"}
          // alignSelf="flex-end"
          sx={{border: "0px red solid", marginTop: 2}}
        >
          <Button
            variant="contained"
            color="secondary"
            sx={{ marginRight: 1 }}
            onClick={openSuggestionDialog}
            startIcon={<AutoAwesome />}
          >
            KI
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handlePlanning(patientId)}
            startIcon={<EditCalendar />}
          >
            Plannen
          </Button>
        </Grid>
      )}
      {/* {appointments && ( <Tooltip title="Open Appointment Suggestion">
        <Button
          sx={{
            width: 100,
            height: 40,
            padding: "3px",
            marginLeft: "4px",
            variant:"outlined" ,
            backgroundColor:"red"
          
          }}
          onClick={() => {
            openSuggestionDialog();
            // Additional actions you want to perform
          }}
        >
          Suggestion
        </Button>
      </Tooltip>)}  */}
      {/* <Grid item xs={12} sm={6} md={4} lg={3}> */}

    <TerminView  appointments={appointments}
                setDataRefreshFlag={setDataRefreshFlag}
                patientId={patientId}
                appointmentsLoading={appointmentsLoading}
                dataRefreshFlag={dataRefreshFlag}
                blockedData={blockedData}
                setblockedData={setblockedData}
                patientAvailabilityData={patientAvailabilityData}
                setPatientAvailability={setPatientAvailability} isView={TerminViewConstants.UNSCHEDULED_TERMIN}
                setUnScheduledSelectedAppointmentId={setSelectedAppointmentId}
                setDeleteEvent={setDeleteEvent}
                /> 
                
                 <Divider textAlign="left">
                  <div style={{ textAlign: "left", fontWeight: "bold" }}>
                  Dauertermine
                </div>
              </Divider>
      {continuousAppointments && continuousAppointments.length > 0 && (
        <>
        
          <Grid
            item
            xs={"auto"}
            // alignSelf="flex-end"
            sx={{border: "0px red solid", marginTop: 2}}
          >
          {/* <Button
            variant="contained"
            color="secondary"
            sx={{ marginRight: 1 }}
            onClick={openSuggestionDialog}
            startIcon={<AutoAwesome />}
          >
            KI
          </Button> */}
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleContinuousPlanning(patientId)}
            startIcon={<EditCalendar />}
          >
            Plannen
          </Button>
        </Grid>
        </>
        )}

<TerminView  appointments={continuousAppointments as unknown as ContinuousAppointmentDto[]}
                setDataRefreshFlag={setDataRefreshFlag}
                patientId={patientId}
                dataRefreshFlag={dataRefreshFlag}
                blockedData={blockedData}
                appointmentsLoading={appointmentsLoading}
                setblockedData={setblockedData}
                patientAvailabilityData={patientAvailabilityData}
                setPatientAvailability={setPatientAvailability} isView={TerminViewConstants.UNSCHEDULED_CONT_TERMIN}  setUnScheduledSelectedAppointmentId={setSelectedAppointmentId}
                setDeleteEvent={setDeleteEvent}/>
      {/* Render the WarningDialog conditionally */}
      {selectedAppointmentId && deleteEvent && (
        <WarningDialog
          open={deleteEvent.isDeleteDialogOpen}
          onClose={() => setDeleteEvent(null)}
          onContinue={handleDelete}
          title="Termin löschen"
          content="Möchten Sie diesen Termin wirklich löschen?"
        />
      )}
      <AppointmentSuggestion
        open={isSuggestionDialogOpen}
        onClose={handleAppointmentSuggestionClose}
        setDataRefreshFlag={setDataRefreshFlag}
        dataRefreshFlag={dataRefreshFlag}
        patientId={patientId}
        blockedData={blockedData}
        setblockedData={setblockedData}
        patientAvailabilityData={patientAvailabilityData}
        setPatientAvailabilityData={setPatientAvailability}
      />

      {/* </Grid> */}
    </Grid>
  );
});

export default UnscheduledAppointments;
