import { Box, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import LedgerTable from "../LedgerTable";


const InsuranceLayout = () => {

    const [selectedTabs,setSelectedTabs] = useState(1);

    const handleTabChange = (event:any, newValue: any) => {
        setSelectedTabs(newValue);
      };


    return (
        <div style={{ marginTop: '20px'}}> 
        <Box>
            <Tabs
            orientation="horizontal"
            value={selectedTabs}
            onChange={handleTabChange}
            aria-label="sub-tabs"
            >
                <Tab iconPosition="start" 
                    label={
                        <span
                        style={{
                            color: "black",
                            fontSize: "12px",
                        }}
                        >
                        STARTED BILLS
                        </span>
                    }
                    value={1}
                    sx={{
                        marginRight: "4px",
                    }}
                />
                <Tab
                    iconPosition="start"
                    label={
                    <span
                        style={{
                            color: "black",
                            fontSize: "12px",
                        }}
                    >
                        OPEN BILLS
                    </span>
                    }
                    value={2}
                    sx={{
                        marginRight: "4px",
                    }}
                />
                <Tab
                    iconPosition="start"
                    label={
                    <span
                        style={{
                            color: "black",
                            fontSize: "12px",
                        }}
                    >
                        PREVIOUS BILLS
                    </span>
                    }
                    value={3}
                    sx={{
                        marginRight: "4px",
                    }}
                />
            </Tabs>
        </Box>
        {(() => {
      switch (selectedTabs) {
        case 1:
          return <LedgerTable transactionType="cash"/>;
        case 2:
          return null
        case 3:
          return null
        default:
          return null;
      }
    })()}
    </div> 
    )
}

export default InsuranceLayout;