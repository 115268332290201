import { Draggable as DraggableEvent } from "@fullcalendar/interaction";
import React, { useEffect } from "react";
import { AppointmentDto, ContinuousAppointmentDto, PatientDto, TimeSlotDto } from "../../api-client";
import { TherapyExt, TherapyFrequencyExt } from "./Calendar.type";
import moment from "moment";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Badge, Box, Grid, Typography } from "@mui/material";
import { AppointmentCard } from "../../components/ScheduleOverview/UnscheduledAppointments";
import Draggable from "react-draggable";
import { TopLevelPaper } from "../../themes/StyledComponents";
import { observer } from "mobx-react";

interface EventShelfProps {
  appointments: (AppointmentDto | ContinuousAppointmentDto)[];
  title?: string;
  patient?: PatientDto;
  draggable?: boolean;
}

const EventShelf = observer((props: EventShelfProps) => {
  const { appointments, patient, title, draggable } = props;
  const heatTreaments = ["PA", "HL"];

  useEffect(() => {        
    const containerEl = document.getElementById('external-events');
    new DraggableEvent(containerEl!, {
      itemSelector: ".fc-event",
      eventData: (eventEl) => {
        const eventDataJson = JSON.parse(eventEl.getAttribute("data-event")!);
        const eventData = {
          title: eventEl.innerText,
          duration: eventDataJson['duration'],
          id: eventDataJson['id'],
          patient: eventDataJson['patient'],
          timeSlots: eventDataJson['timeSlots'],
          frequency: eventDataJson['frequency']
        };
        return eventData;
      },
    });
  }, []);

  const convertMinutesToTimeString = (minutes: number) => {
    const duration = moment.duration(minutes, "minutes");
    const formattedTime = moment.utc(duration.asMilliseconds()).format("HH:mm:ss");
    return formattedTime;
  };

  const groupAppointmentsByRx = (appointments: AppointmentDto[]) => {
    const groupedAppointments: Record<string, AppointmentDto[]> = {};

    appointments?.forEach(appointment => {
        const key = appointment.timeSlots?.[0].therapyRx?.rx?.rxNumber!;
        if (!groupedAppointments[key]) {
          groupedAppointments[key] = [];
        }
        groupedAppointments[key].push(appointment);
    });
    
    console.log('Grouped Appointments:', groupedAppointments);
    return groupedAppointments;
  };
  
  const groupAppointments = groupAppointmentsByRx(appointments);

  return (
    <TopLevelPaper>
      <Grid item direction="column" xs={12} sx={{ display: "flex" }}>
        <Grid item>
          <Typography>
            {title} {patient?.lastName} ({appointments.length})
          </Typography>
        </Grid>
        <Grid item sx={{ border: "0px solid black" }}>
          <Grid
            container
            direction="row"
            id="external-events"
            className="draggable-div"
            xs={12}
            sx={{ overflowX: "auto", flexWrap: "nowrap", paddingBottom: 1}}
          >
            {appointments.length === 0 && <>Kein Termin</>}
            {Object.entries(groupAppointments).map(([type, appointments]) => {
              const firstAppointment = appointments[0]; // Display one item from the group
              const heatTreatmentsDuration = firstAppointment.timeSlots!.reduce((acc, slot) => heatTreaments.includes((slot.therapyRx?.therapy as TherapyExt).abbreviation) ? acc + (slot.therapyRx?.therapy as TherapyExt).duration : acc, 0);
              const duration = firstAppointment.timeSlots?.reduce((acc, slot) => acc + (slot.therapyRx?.therapy as TherapyExt).duration, 0)!;
              const totalDuration = convertMinutesToTimeString(duration - heatTreatmentsDuration);
              return(
              <Draggable cancel=".non-draggable" disabled={!draggable}>
                <div
                  className="fc-event fc-h-event fc-daygrid-event fc-daygrid-block-event"
                  key={firstAppointment.id}
                  data-event={`{
                "duration": "${totalDuration}",
                "id": "${firstAppointment.id}",
                "patient": {
                  "id": "${patient?.id || (firstAppointment.timeSlots?.[0]?.therapyRx?.rx?.patient as PatientDto)?.id}"
                },
                "frequency": {
                  "id": "${(firstAppointment.frequency as TherapyFrequencyExt)?.id}",
                  "text": "${(firstAppointment.frequency as TherapyFrequencyExt)?.text}",
                  "prefferedValue": "${(firstAppointment.frequency as TherapyFrequencyExt)?.prefferedValue}"
                },
                "timeSlots": [${firstAppointment.timeSlots?.map((timeSlot: TimeSlotDto) => `{
                  "appointmentId": "${firstAppointment.id}",
                  "id": "${timeSlot.id}",
                  "type": "${timeSlot.type}",
                  "therapyRx": {
                    "id": "${timeSlot.therapyRx?.id}",
                    "rx": {
                      "rxNumber": "${timeSlot.therapyRx?.rx?.rxNumber}"
                    },
                    "amount": "${timeSlot.therapyRx?.amount}",
                    "order": "${timeSlot.therapyRx?.order}",
                    "therapy": {
                      "abbreviation": "${(timeSlot.therapyRx?.therapy as TherapyExt).abbreviation}",
                      "duration": "${(timeSlot.therapyRx?.therapy as TherapyExt).duration}"
                    }
                  }
                }`
                  )
                  .join(",")}]
              }`}
                >
                  {/* <div className="fc-event-main"> */}
                  {/* {appointment.timeSlots?.map((timeSlot: TimeSlotDto) => (
                  <div className='unscheduled-events' key={timeSlot.id}>
                    <span>RxNo. {timeSlot.therapyRx?.rx?.rxNumber}</span>
                    <span>{(timeSlot.therapyRx?.therapy as TherapyExt).abbreviation}</span>
                    <span>{(timeSlot.therapyRx?.therapy as TherapyExt).duration} min</span>
                  </div>
                ))} */}
                  <Badge badgeContent={appointments.length} color="primary">
                    <AppointmentCard appointment={firstAppointment} />
                  </Badge>
                  {/* </div> */}
                </div>
              </Draggable>)
            })}
          </Grid>
        </Grid>
      </Grid>
    </TopLevelPaper>
  );
});

export default EventShelf;
