import { action, makeObservable, observable } from "mobx";
import { IPromiseBasedObservable, fromPromise } from "mobx-utils";
import {
  HistoryApi,
  FindAllHistoryQueryFiltersDto,
  GetHistoryDto,
  HistoryFiltersDto,
} from "../../api-client";
import AuthStore from "../Auth/authStore";
import UserStore from "../User/UserStore";
import notificationStore from "../Notification/notificationStore";
import { initObservable } from "mobx/dist/internal";

export default class HistoryStore {
  public apiClient: HistoryApi;
  public userStore: UserStore; // Include UserStore as a property
  public authStore: AuthStore;

  public constructor(apiClient: HistoryApi, userStore: UserStore, authStore: AuthStore) {
    makeObservable(this);
    this.apiClient = apiClient;
    this.userStore = userStore; // Assign the passed UserStore
    this.authStore = authStore;
  }

  @observable
  public history: any = [];

  @observable
  public resourceRequest: IPromiseBasedObservable<any> | null = null;

  @action
  public getAllHistory = async ({
    limit,
    page,
    type,
    action,
    searchKey,
    fromDate,
    toDate,
    patientId,
    rxId,
    appointmentId,
    initialEvaluationId,
    treatmentNoteId,
  }: FindAllHistoryQueryFiltersDto) => {
    try {
      const response = await this.apiClient.getAllHistory(
        limit,
        page,
        type,
        action,
        searchKey,
        fromDate,
        toDate,
        patientId,
        rxId,
        appointmentId,
        initialEvaluationId,
        treatmentNoteId
      );

      const data = response.data as GetHistoryDto[];
      return data || [];
    } catch (error) {
      // console.log("Error fetching histories", error);
      // notificationStore.showMessage(JSON.stringify(error), "error");
      // this.resourceRequest = fromPromise.reject(error);
      return Promise.reject(error);
    }
  };

  @action
  public getHistoryFilters = async () => {
    try {
      const response = await this.apiClient.getHistoryFilters();
      const data = response.data as HistoryFiltersDto;
      return data;
    } catch (error) {
      console.log("Error fetching histories", error);
      this.resourceRequest = fromPromise.reject(error);
    }
  };
}
