import { action, makeObservable, observable } from "mobx";
import { IPromiseBasedObservable, fromPromise } from "mobx-utils";
import { PatientsApi,PatientDto, SearchDto, DoctorApi, CreateDoctorDto, SearchDoctorDto, NoteApi, NoteDto } from "../../api-client";
import AuthStore from "../Auth/authStore";
import PatientsStore from "../Patients/patientsStore";
import notificationStore from "../Notification/notificationStore";

export default class NotesStore {
  public apiClient: NoteApi;
  public authStore: AuthStore;
  public patientStore:PatientsStore

  public constructor(apiClient: NoteApi, authStore: AuthStore,patientStore:PatientsStore) {
    makeObservable(this);
    this.apiClient = apiClient;
    this.authStore=authStore;
    this.patientStore=patientStore;
  }

  @observable
  public fetchNotes:boolean=false;

  @observable
  public resourceRequest: IPromiseBasedObservable<any> | null = null;

  // @observable
  // public updatedDoctor: CreateDoctorDto | null = null;
  
  // @action
  // public setUpdatedDoctor = (doctor: CreateDoctorDto | null) => {
  //   this.updatedDoctor = doctor;
  //   console.log('UPDATAEDDDOCTOR',this.updatedDoctor?.number)
  // };

@action 
public  setFetchNotes= (value:boolean)=>{
  this.fetchNotes=value
}

  @action
  public postNotes = async (userId:string,body:NoteDto) => {
    try{
    const { data } = (await this.apiClient.postNote(userId,body)) as any;
    // await this.patientStore.updatePatientData(this.patientStore.filteredPatient as number);
    this.setFetchNotes(!this.fetchNotes)


    return data|| [];}
    catch (error:any) {
      this.resourceRequest = fromPromise.reject(error);
      const errorMessage = error.response.data.message || "Fehler beim Hinzufügen von Notizen!";
      notificationStore.showMessage(errorMessage, "error", error);
      
    }
  };

  @action
  public updateNoteById = async (noteId:string,userId:string,body:NoteDto) => {
    try{
    const { data } = (await this.apiClient.updateNote(noteId,userId,body)) as any;
    // await this.patientStore.updatePatientData(this.patientStore.filteredPatient as number);
    this.setFetchNotes(!this.fetchNotes)


    return data|| [];}
    catch (error:any) {
      this.resourceRequest = fromPromise.reject(error);
      const errorMessage = error.response.data.message || "Fehler beim Aktualisieren der Notizen!";
      notificationStore.showMessage(errorMessage, "error", error);
      
    }
  };
  @action
  public getPatientNotes = async (patientId:string) => {
    try{
    const { data } = (await this.apiClient.getPatientNotes(patientId)) as any;


    return data|| [];}
    catch (error) {
      this.resourceRequest = fromPromise.reject(error);
      
    }
  };

  @action
  public deleteNotebyId = async (noteId:string) => {
    try{
    const { data } = (await this.apiClient.deleteNote(noteId)) as any;
    this.setFetchNotes(!this.fetchNotes)
    


    return data|| [];}
    catch (error) {
      this.resourceRequest = fromPromise.reject(error);
      
    }
  };

  @action
  public getRxNotes = async (rxId:string) => {
    try{
    const { data } = (await this.apiClient.getRxNotes(rxId)) as any;


    return data|| [];}
    catch (error) {
      this.resourceRequest = fromPromise.reject(error);
      
    }
  };
  @action
  public getAppointmentNotes = async (apptId:string) => {
    try{
    const { data } = (await this.apiClient.getAppointmentNotes(apptId)) as any;


    return data|| [];}
    catch (error) {
      this.resourceRequest = fromPromise.reject(error);
      
    }
  };

  @action
  public getNotesForAppointments = async (apptIds:string[]) => {
    try{
    const { data } = (await this.apiClient.getNotesForAppointments(apptIds)) as any;


    return data|| [];}
    catch (error:any) {
      this.resourceRequest = fromPromise.reject(error);
      const errorMessage = error.response.data.message || "Notizen konnten nicht abgerufen werden!";
      notificationStore.showMessage(errorMessage, "error", error);
      
    }
  };


}
  

