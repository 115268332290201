/* tslint:disable */
/* eslint-disable */
/**
 * Physio Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AccountDto
 */
export interface AccountDto {
    /**
     * 
     * @type {string}
     * @memberof AccountDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AccountDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ActivityDto
 */
export interface ActivityDto {
    /**
     * 
     * @type {string}
     * @memberof ActivityDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityDto
     */
    'type': string;
    /**
     * 
     * @type {GetTicketChangeLogDTO}
     * @memberof ActivityDto
     */
    'text': GetTicketChangeLogDTO;
    /**
     * 
     * @type {string}
     * @memberof ActivityDto
     */
    'ticketId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityDto
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface AddressDto
 */
export interface AddressDto {
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AddressDto
     */
    'isDefaultVisit'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AddressDto
     */
    'isAlternativeBilling'?: boolean;
    /**
     * 
     * @type {ContactInfoDTO}
     * @memberof AddressDto
     */
    'contactInfo'?: ContactInfoDTO;
    /**
     * 
     * @type {boolean}
     * @memberof AddressDto
     */
    'isPrimary'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AddressDto
     */
    'isVisit'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AddressDto
     */
    'isInstitution'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @interface AdjustOutsideAppointmentsDto
 */
export interface AdjustOutsideAppointmentsDto {
    /**
     * 
     * @type {string}
     * @memberof AdjustOutsideAppointmentsDto
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdjustOutsideAppointmentsDto
     */
    'start'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdjustOutsideAppointmentsDto
     */
    'end'?: string;
}
/**
 * 
 * @export
 * @interface AppStatsDto
 */
export interface AppStatsDto {
    /**
     * 
     * @type {string}
     * @memberof AppStatsDto
     */
    'rxId': string;
    /**
     * 
     * @type {string}
     * @memberof AppStatsDto
     */
    'therapyRxId': string;
    /**
     * 
     * @type {number}
     * @memberof AppStatsDto
     */
    'amountPrescribed': number;
    /**
     * 
     * @type {number}
     * @memberof AppStatsDto
     */
    'amountDefined': number;
    /**
     * 
     * @type {number}
     * @memberof AppStatsDto
     */
    'amountScheduled': number;
}
/**
 * 
 * @export
 * @interface AppointmentDto
 */
export interface AppointmentDto {
    /**
     * 
     * @type {string}
     * @memberof AppointmentDto
     */
    'id'?: string;
    /**
     * 
     * @type {Array<TimeSlotDto>}
     * @memberof AppointmentDto
     */
    'timeSlots'?: Array<TimeSlotDto>;
    /**
     * 
     * @type {object}
     * @memberof AppointmentDto
     */
    'frequency': object;
    /**
     * 
     * @type {Array<NoteDto>}
     * @memberof AppointmentDto
     */
    'notes'?: Array<NoteDto>;
    /**
     * 
     * @type {string}
     * @memberof AppointmentDto
     */
    'start'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentDto
     */
    'end'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AppointmentDto
     */
    'attended'?: boolean;
    /**
     * 
     * @type {AddressDto}
     * @memberof AppointmentDto
     */
    'address'?: AddressDto;
}
/**
 * 
 * @export
 * @interface AppointmentTemplateDto
 */
export interface AppointmentTemplateDto {
    /**
     * 
     * @type {Array<TherapyRxDto>}
     * @memberof AppointmentTemplateDto
     */
    'therapyRx': Array<TherapyRxDto>;
    /**
     * 
     * @type {object}
     * @memberof AppointmentTemplateDto
     */
    'frequency': object;
    /**
     * 
     * @type {number}
     * @memberof AppointmentTemplateDto
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof AppointmentTemplateDto
     */
    'addressId'?: string;
}
/**
 * 
 * @export
 * @interface BackPlanResult
 */
export interface BackPlanResult {
    /**
     * 
     * @type {string}
     * @memberof BackPlanResult
     */
    'date': string;
    /**
     * 
     * @type {AppointmentDto}
     * @memberof BackPlanResult
     */
    'app': AppointmentDto;
    /**
     * 
     * @type {string}
     * @memberof BackPlanResult
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface BaseTransactionDetailsDto
 */
export interface BaseTransactionDetailsDto {
    /**
     * Transaction number
     * @type {string}
     * @memberof BaseTransactionDetailsDto
     */
    'transactionNumber': string;
    /**
     * Type of the transaction
     * @type {string}
     * @memberof BaseTransactionDetailsDto
     */
    'type': BaseTransactionDetailsDtoTypeEnum;
    /**
     * Description of the transaction
     * @type {string}
     * @memberof BaseTransactionDetailsDto
     */
    'text': string;
    /**
     * Amount of the transaction
     * @type {number}
     * @memberof BaseTransactionDetailsDto
     */
    'amount': number;
    /**
     * Flag indicating whether the transaction was exported
     * @type {boolean}
     * @memberof BaseTransactionDetailsDto
     */
    'wasExported': boolean;
    /**
     * 
     * @type {GetUserDto}
     * @memberof BaseTransactionDetailsDto
     */
    'user': GetUserDto;
    /**
     * 
     * @type {GetAccountDto}
     * @memberof BaseTransactionDetailsDto
     */
    'account': GetAccountDto;
    /**
     * 
     * @type {GetPatientDto}
     * @memberof BaseTransactionDetailsDto
     */
    'patient': GetPatientDto;
    /**
     * 
     * @type {GetRxDto}
     * @memberof BaseTransactionDetailsDto
     */
    'rx': GetRxDto;
    /**
     * Link to the cloud
     * @type {string}
     * @memberof BaseTransactionDetailsDto
     */
    'cloudLink': string;
    /**
     * Date when the transaction was created
     * @type {string}
     * @memberof BaseTransactionDetailsDto
     */
    'createdAt': string;
    /**
     * Date when the transaction was last updated
     * @type {string}
     * @memberof BaseTransactionDetailsDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {GetTAccountDto}
     * @memberof BaseTransactionDetailsDto
     */
    'tAccounts': GetTAccountDto;
    /**
     * 
     * @type {DocumentDto}
     * @memberof BaseTransactionDetailsDto
     */
    'docDto'?: DocumentDto;
}

export const BaseTransactionDetailsDtoTypeEnum = {
    Einnahme: 'Einnahme',
    Ausgabe: 'Ausgabe'
} as const;

export type BaseTransactionDetailsDtoTypeEnum = typeof BaseTransactionDetailsDtoTypeEnum[keyof typeof BaseTransactionDetailsDtoTypeEnum];

/**
 * 
 * @export
 * @interface CAResponse
 */
export interface CAResponse {
    /**
     * 
     * @type {ContinuousAppointmentDto}
     * @memberof CAResponse
     */
    'savedCA': ContinuousAppointmentDto;
    /**
     * 
     * @type {Array<BackPlanResult>}
     * @memberof CAResponse
     */
    'backPlanResults': Array<BackPlanResult>;
}
/**
 * 
 * @export
 * @interface CalculateRxDTO
 */
export interface CalculateRxDTO {
    /**
     * 
     * @type {boolean}
     * @memberof CalculateRxDTO
     */
    'matchNumberofAppointments': boolean;
    /**
     * 
     * @type {number}
     * @memberof CalculateRxDTO
     */
    'estimatedPrice': number;
    /**
     * 
     * @type {number}
     * @memberof CalculateRxDTO
     */
    'realPrice': number;
}
/**
 * 
 * @export
 * @interface ContactInfoDTO
 */
export interface ContactInfoDTO {
    /**
     * 
     * @type {string}
     * @memberof ContactInfoDTO
     */
    'salutation'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactInfoDTO
     */
    'firstNames'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactInfoDTO
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactInfoDTO
     */
    'phone1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactInfoDTO
     */
    'fax'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactInfoDTO
     */
    'mobile'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactInfoDTO
     */
    'addressLine1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactInfoDTO
     */
    'addressLine2'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactInfoDTO
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactInfoDTO
     */
    'zip'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactInfoDTO
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactInfoDTO
     */
    'businessName'?: string;
}
/**
 * 
 * @export
 * @interface ContextDto
 */
export interface ContextDto {
    /**
     * 
     * @type {object}
     * @memberof ContextDto
     */
    'pre': object;
    /**
     * 
     * @type {object}
     * @memberof ContextDto
     */
    'post': object;
}
/**
 * 
 * @export
 * @interface ContinuousAppointmentDto
 */
export interface ContinuousAppointmentDto {
    /**
     * 
     * @type {string}
     * @memberof ContinuousAppointmentDto
     */
    'id'?: string;
    /**
     * 
     * @type {Array<TimeSlotDto>}
     * @memberof ContinuousAppointmentDto
     */
    'timeSlots'?: Array<TimeSlotDto>;
    /**
     * 
     * @type {object}
     * @memberof ContinuousAppointmentDto
     */
    'frequency': object;
    /**
     * 
     * @type {Array<NoteDto>}
     * @memberof ContinuousAppointmentDto
     */
    'notes'?: Array<NoteDto>;
    /**
     * 
     * @type {string}
     * @memberof ContinuousAppointmentDto
     */
    'start'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContinuousAppointmentDto
     */
    'end'?: string;
    /**
     * 
     * @type {AddressDto}
     * @memberof ContinuousAppointmentDto
     */
    'address'?: AddressDto;
}
/**
 * 
 * @export
 * @interface ContinuousEventTimeSlotDto
 */
export interface ContinuousEventTimeSlotDto {
    /**
     * 
     * @type {string}
     * @memberof ContinuousEventTimeSlotDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContinuousEventTimeSlotDto
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContinuousEventTimeSlotDto
     */
    'title'?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof ContinuousEventTimeSlotDto
     */
    'user'?: UserDto;
    /**
     * 
     * @type {string}
     * @memberof ContinuousEventTimeSlotDto
     */
    'start'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContinuousEventTimeSlotDto
     */
    'end'?: string;
}
/**
 * 
 * @export
 * @interface CreateDoctorDto
 */
export interface CreateDoctorDto {
    /**
     * 
     * @type {number}
     * @memberof CreateDoctorDto
     */
    'number': number;
    /**
     * 
     * @type {number}
     * @memberof CreateDoctorDto
     */
    'clinicNumber': number;
    /**
     * 
     * @type {ContactInfoDTO}
     * @memberof CreateDoctorDto
     */
    'contactInfo': ContactInfoDTO;
}
/**
 * 
 * @export
 * @interface CreateDoctorReportDto
 */
export interface CreateDoctorReportDto {
    /**
     * 
     * @type {string}
     * @memberof CreateDoctorReportDto
     */
    'rxId': string;
    /**
     * 
     * @type {RxDto}
     * @memberof CreateDoctorReportDto
     */
    'rx'?: RxDto;
    /**
     * 
     * @type {boolean}
     * @memberof CreateDoctorReportDto
     */
    'isDifferentTreatmentPeriod'?: boolean | null;
    /**
     * 
     * @type {object}
     * @memberof CreateDoctorReportDto
     */
    'treatmentStart'?: object;
    /**
     * 
     * @type {object}
     * @memberof CreateDoctorReportDto
     */
    'treatmentEnd'?: object;
    /**
     * 
     * @type {string}
     * @memberof CreateDoctorReportDto
     */
    'therapyStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDoctorReportDto
     */
    'specialFeatures'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateDoctorReportDto
     */
    'isRegulatedTreatment'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateDoctorReportDto
     */
    'therapyFrequency'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateDoctorReportDto
     */
    'singleTherapy'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateDoctorReportDto
     */
    'groupTherapy'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof CreateDoctorReportDto
     */
    'treatmentModification'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateDoctorReportDto
     */
    'isTreatmentDiscontinued'?: boolean | null;
    /**
     * 
     * @type {object}
     * @memberof CreateDoctorReportDto
     */
    'treatmentTerminationDate'?: object;
    /**
     * 
     * @type {string}
     * @memberof CreateDoctorReportDto
     */
    'treatmentTerminationReason'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateDoctorReportDto
     */
    'treatmentTerminationAfter'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateDoctorReportDto
     */
    'continueTreatment'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof CreateDoctorReportDto
     */
    'therapyAssessment'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDoctorReportDto
     */
    'therapyChangeSuggestions'?: string;
    /**
     * 
     * @type {object}
     * @memberof CreateDoctorReportDto
     */
    'processingDate'?: object;
    /**
     * 
     * @type {object}
     * @memberof CreateDoctorReportDto
     */
    'printedAt'?: object;
    /**
     * 
     * @type {object}
     * @memberof CreateDoctorReportDto
     */
    'sentByEmailAt'?: object;
    /**
     * 
     * @type {object}
     * @memberof CreateDoctorReportDto
     */
    'sentByFaxAt'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof CreateDoctorReportDto
     */
    'completed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateDoctorReportDto
     */
    'createdByUserId'?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof CreateDoctorReportDto
     */
    'createdByUser'?: UserDto;
    /**
     * 
     * @type {string}
     * @memberof CreateDoctorReportDto
     */
    'updatedByUserId'?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof CreateDoctorReportDto
     */
    'updatedByUser'?: UserDto;
    /**
     * 
     * @type {object}
     * @memberof CreateDoctorReportDto
     */
    'createdAt'?: object;
    /**
     * 
     * @type {object}
     * @memberof CreateDoctorReportDto
     */
    'updatedAt'?: object;
}
/**
 * 
 * @export
 * @interface CreateInitialEvaluationDto
 */
export interface CreateInitialEvaluationDto {
    /**
     * 
     * @type {string}
     * @memberof CreateInitialEvaluationDto
     */
    'evaluation'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInitialEvaluationDto
     */
    'goals'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateInitialEvaluationDto
     */
    'patientId': number;
}
/**
 * 
 * @export
 * @interface CreateTransactionDto
 */
export interface CreateTransactionDto {
    /**
     * Description of the transaction
     * @type {string}
     * @memberof CreateTransactionDto
     */
    'text': string;
    /**
     * Type of the transaction
     * @type {string}
     * @memberof CreateTransactionDto
     */
    'type': CreateTransactionDtoTypeEnum;
    /**
     * Method of the transaction
     * @type {string}
     * @memberof CreateTransactionDto
     */
    'method': CreateTransactionDtoMethodEnum;
    /**
     * Amount of the transaction
     * @type {number}
     * @memberof CreateTransactionDto
     */
    'amount': number;
    /**
     * UUID of the associated account
     * @type {string}
     * @memberof CreateTransactionDto
     */
    'accountId': string;
    /**
     * UUID of the associated user
     * @type {string}
     * @memberof CreateTransactionDto
     */
    'userId': string;
    /**
     * Flag indicating whether the transaction was exported
     * @type {boolean}
     * @memberof CreateTransactionDto
     */
    'wasExported'?: boolean;
    /**
     * UUID of the associated rx
     * @type {object}
     * @memberof CreateTransactionDto
     */
    'rxId': object;
    /**
     * UUID of the associated rx
     * @type {object}
     * @memberof CreateTransactionDto
     */
    'patientId': object;
    /**
     * number of the associated Taccountant
     * @type {object}
     * @memberof CreateTransactionDto
     */
    'tAccountId'?: object;
    /**
     * Payment status of the transaction
     * @type {string}
     * @memberof CreateTransactionDto
     */
    'paymentStatus'?: string;
    /**
     * Link to the cloud
     * @type {string}
     * @memberof CreateTransactionDto
     */
    'cloudLink'?: string;
    /**
     * Description of the transaction
     * @type {string}
     * @memberof CreateTransactionDto
     */
    'textDescription'?: string;
}

export const CreateTransactionDtoTypeEnum = {
    Einnahme: 'Einnahme',
    Ausgabe: 'Ausgabe'
} as const;

export type CreateTransactionDtoTypeEnum = typeof CreateTransactionDtoTypeEnum[keyof typeof CreateTransactionDtoTypeEnum];
export const CreateTransactionDtoMethodEnum = {
    Cash: 'CASH',
    Ec: 'EC',
    Lastschrift: 'LASTSCHRIFT',
    Rechnung: 'RECHNUNG'
} as const;

export type CreateTransactionDtoMethodEnum = typeof CreateTransactionDtoMethodEnum[keyof typeof CreateTransactionDtoMethodEnum];

/**
 * 
 * @export
 * @interface CreateTreatmentNoteDto
 */
export interface CreateTreatmentNoteDto {
    /**
     * 
     * @type {string}
     * @memberof CreateTreatmentNoteDto
     */
    'note': string;
    /**
     * 
     * @type {number}
     * @memberof CreateTreatmentNoteDto
     */
    'patientId': number;
    /**
     * 
     * @type {string}
     * @memberof CreateTreatmentNoteDto
     */
    'treatmentId': string;
}
/**
 * 
 * @export
 * @interface DiagnosisGroupDto
 */
export interface DiagnosisGroupDto {
    /**
     * 
     * @type {string}
     * @memberof DiagnosisGroupDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DiagnosisGroupDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof DiagnosisGroupDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof DiagnosisGroupDto
     */
    'a'?: string;
    /**
     * 
     * @type {string}
     * @memberof DiagnosisGroupDto
     */
    'b'?: string;
    /**
     * 
     * @type {string}
     * @memberof DiagnosisGroupDto
     */
    'c'?: string;
    /**
     * 
     * @type {string}
     * @memberof DiagnosisGroupDto
     */
    'd'?: string;
}
/**
 * 
 * @export
 * @interface DoctorReportDto
 */
export interface DoctorReportDto {
    /**
     * 
     * @type {string}
     * @memberof DoctorReportDto
     */
    'rxId': string;
    /**
     * 
     * @type {RxDto}
     * @memberof DoctorReportDto
     */
    'rx'?: RxDto;
    /**
     * 
     * @type {boolean}
     * @memberof DoctorReportDto
     */
    'isDifferentTreatmentPeriod'?: boolean | null;
    /**
     * 
     * @type {object}
     * @memberof DoctorReportDto
     */
    'treatmentStart'?: object;
    /**
     * 
     * @type {object}
     * @memberof DoctorReportDto
     */
    'treatmentEnd'?: object;
    /**
     * 
     * @type {string}
     * @memberof DoctorReportDto
     */
    'therapyStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof DoctorReportDto
     */
    'specialFeatures'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DoctorReportDto
     */
    'isRegulatedTreatment'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DoctorReportDto
     */
    'therapyFrequency'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DoctorReportDto
     */
    'singleTherapy'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DoctorReportDto
     */
    'groupTherapy'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DoctorReportDto
     */
    'treatmentModification'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DoctorReportDto
     */
    'isTreatmentDiscontinued'?: boolean | null;
    /**
     * 
     * @type {object}
     * @memberof DoctorReportDto
     */
    'treatmentTerminationDate'?: object;
    /**
     * 
     * @type {string}
     * @memberof DoctorReportDto
     */
    'treatmentTerminationReason'?: string;
    /**
     * 
     * @type {number}
     * @memberof DoctorReportDto
     */
    'treatmentTerminationAfter'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DoctorReportDto
     */
    'continueTreatment'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DoctorReportDto
     */
    'therapyAssessment'?: string;
    /**
     * 
     * @type {string}
     * @memberof DoctorReportDto
     */
    'therapyChangeSuggestions'?: string;
    /**
     * 
     * @type {object}
     * @memberof DoctorReportDto
     */
    'processingDate'?: object;
    /**
     * 
     * @type {object}
     * @memberof DoctorReportDto
     */
    'printedAt'?: object;
    /**
     * 
     * @type {object}
     * @memberof DoctorReportDto
     */
    'sentByEmailAt'?: object;
    /**
     * 
     * @type {object}
     * @memberof DoctorReportDto
     */
    'sentByFaxAt'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof DoctorReportDto
     */
    'completed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DoctorReportDto
     */
    'createdByUserId'?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof DoctorReportDto
     */
    'createdByUser'?: UserDto;
    /**
     * 
     * @type {string}
     * @memberof DoctorReportDto
     */
    'updatedByUserId'?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof DoctorReportDto
     */
    'updatedByUser'?: UserDto;
    /**
     * 
     * @type {object}
     * @memberof DoctorReportDto
     */
    'createdAt'?: object;
    /**
     * 
     * @type {object}
     * @memberof DoctorReportDto
     */
    'updatedAt'?: object;
    /**
     * 
     * @type {string}
     * @memberof DoctorReportDto
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface DoctorReportStausDto
 */
export interface DoctorReportStausDto {
    /**
     * Doctor report status
     * @type {string}
     * @memberof DoctorReportStausDto
     */
    'status': DoctorReportStausDtoStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof DoctorReportStausDto
     */
    'doctorReportExists'?: boolean;
}

export const DoctorReportStausDtoStatusEnum = {
    Unknown: 'Unknown',
    NoneRequested: 'None - Requested',
    Requested: 'Requested',
    Complete: 'Complete',
    Sent: 'Sent'
} as const;

export type DoctorReportStausDtoStatusEnum = typeof DoctorReportStausDtoStatusEnum[keyof typeof DoctorReportStausDtoStatusEnum];

/**
 * 
 * @export
 * @interface DoctorReportTimeSlotDto
 */
export interface DoctorReportTimeSlotDto {
    /**
     * 
     * @type {TimeSlotDto}
     * @memberof DoctorReportTimeSlotDto
     */
    'earliestTimeSlot': TimeSlotDto;
    /**
     * 
     * @type {TimeSlotDto}
     * @memberof DoctorReportTimeSlotDto
     */
    'latestTimeSlot': TimeSlotDto;
}
/**
 * 
 * @export
 * @interface DocumentDto
 */
export interface DocumentDto {
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    'accessUrl': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    'docuType': DocumentDtoDocuTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    'createdAt': string;
    /**
     * 
     * @type {number}
     * @memberof DocumentDto
     */
    'patientId': number;
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    'userId': string;
}

export const DocumentDtoDocuTypeEnum = {
    Rezept: 'Rezept',
    Medizinisch: 'Medizinisch',
    Rechnung: 'Rechnung',
    Quittung: 'Quittung',
    Termine: 'Termine',
    Sonstiges: 'Sonstiges'
} as const;

export type DocumentDtoDocuTypeEnum = typeof DocumentDtoDocuTypeEnum[keyof typeof DocumentDtoDocuTypeEnum];

/**
 * 
 * @export
 * @interface DoubleTreatmentRxDto
 */
export interface DoubleTreatmentRxDto {
    /**
     * 
     * @type {boolean}
     * @memberof DoubleTreatmentRxDto
     */
    'isDoubled'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof DoubleTreatmentRxDto
     */
    'doubledTreatmentIds'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DoubleTreatmentRxDto
     */
    'id'?: string;
    /**
     * 
     * @type {object}
     * @memberof DoubleTreatmentRxDto
     */
    'therapy'?: object;
    /**
     * 
     * @type {number}
     * @memberof DoubleTreatmentRxDto
     */
    'amount'?: number;
    /**
     * 
     * @type {object}
     * @memberof DoubleTreatmentRxDto
     */
    'frequency'?: object;
    /**
     * 
     * @type {object}
     * @memberof DoubleTreatmentRxDto
     */
    'rxSeries'?: object;
    /**
     * 
     * @type {RxDto}
     * @memberof DoubleTreatmentRxDto
     */
    'rx'?: RxDto;
    /**
     * 
     * @type {string}
     * @memberof DoubleTreatmentRxDto
     */
    'visitFee'?: string;
    /**
     * 
     * @type {number}
     * @memberof DoubleTreatmentRxDto
     */
    'scheduledCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof DoubleTreatmentRxDto
     */
    'order'?: number;
}
/**
 * 
 * @export
 * @interface EntityTicketTypesDto
 */
export interface EntityTicketTypesDto {
    /**
     * 
     * @type {TicketingRelationEntities}
     * @memberof EntityTicketTypesDto
     */
    'entity': TicketingRelationEntities;
    /**
     * 
     * @type {Array<TicketFilterEntryDto>}
     * @memberof EntityTicketTypesDto
     */
    'ticketTypes': Array<TicketFilterEntryDto>;
}


/**
 * 
 * @export
 * @interface EntityTicketTypesQueryFilters
 */
export interface EntityTicketTypesQueryFilters {
    /**
     * 
     * @type {Array<string>}
     * @memberof EntityTicketTypesQueryFilters
     */
    'entities': Array<string>;
}
/**
 * 
 * @export
 * @interface EventTimeSlotDto
 */
export interface EventTimeSlotDto {
    /**
     * 
     * @type {string}
     * @memberof EventTimeSlotDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventTimeSlotDto
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventTimeSlotDto
     */
    'title'?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof EventTimeSlotDto
     */
    'user'?: UserDto;
    /**
     * 
     * @type {string}
     * @memberof EventTimeSlotDto
     */
    'start'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventTimeSlotDto
     */
    'end'?: string;
}
/**
 * 
 * @export
 * @interface FindAllHistoryQueryFiltersDto
 */
export interface FindAllHistoryQueryFiltersDto {
    /**
     * 
     * @type {number}
     * @memberof FindAllHistoryQueryFiltersDto
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof FindAllHistoryQueryFiltersDto
     */
    'page'?: number;
    /**
     * 
     * @type {string}
     * @memberof FindAllHistoryQueryFiltersDto
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FindAllHistoryQueryFiltersDto
     */
    'action'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FindAllHistoryQueryFiltersDto
     */
    'searchKey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FindAllHistoryQueryFiltersDto
     */
    'fromDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FindAllHistoryQueryFiltersDto
     */
    'toDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FindAllHistoryQueryFiltersDto
     */
    'patientId'?: number;
    /**
     * 
     * @type {string}
     * @memberof FindAllHistoryQueryFiltersDto
     */
    'rxId'?: string;
    /**
     * 
     * @type {string}
     * @memberof FindAllHistoryQueryFiltersDto
     */
    'appointmentId'?: string;
    /**
     * 
     * @type {number}
     * @memberof FindAllHistoryQueryFiltersDto
     */
    'initialEvaluationId'?: number;
    /**
     * 
     * @type {number}
     * @memberof FindAllHistoryQueryFiltersDto
     */
    'treatmentNoteId'?: number;
    /**
     * 
     * @type {string}
     * @memberof FindAllHistoryQueryFiltersDto
     */
    'addressId'?: string;
    /**
     * 
     * @type {string}
     * @memberof FindAllHistoryQueryFiltersDto
     */
    'therapyRxId'?: string;
    /**
     * 
     * @type {string}
     * @memberof FindAllHistoryQueryFiltersDto
     */
    'affectedUserId'?: string;
}
/**
 * 
 * @export
 * @interface FindAllTicketsQueryFilters
 */
export interface FindAllTicketsQueryFilters {
    /**
     * 
     * @type {number}
     * @memberof FindAllTicketsQueryFilters
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof FindAllTicketsQueryFilters
     */
    'page'?: number;
    /**
     * 
     * @type {string}
     * @memberof FindAllTicketsQueryFilters
     */
    'ticketStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof FindAllTicketsQueryFilters
     */
    'createdByUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof FindAllTicketsQueryFilters
     */
    'assignedToUserId'?: string;
}
/**
 * 
 * @export
 * @interface GetAccountDto
 */
export interface GetAccountDto {
    /**
     * The name of the account
     * @type {string}
     * @memberof GetAccountDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface GetCashDetailsDto
 */
export interface GetCashDetailsDto {
    /**
     * Transaction number
     * @type {string}
     * @memberof GetCashDetailsDto
     */
    'transactionNumber': string;
    /**
     * Type of the transaction
     * @type {string}
     * @memberof GetCashDetailsDto
     */
    'type': GetCashDetailsDtoTypeEnum;
    /**
     * Description of the transaction
     * @type {string}
     * @memberof GetCashDetailsDto
     */
    'text': string;
    /**
     * Amount of the transaction
     * @type {number}
     * @memberof GetCashDetailsDto
     */
    'amount': number;
    /**
     * Flag indicating whether the transaction was exported
     * @type {boolean}
     * @memberof GetCashDetailsDto
     */
    'wasExported': boolean;
    /**
     * 
     * @type {GetUserDto}
     * @memberof GetCashDetailsDto
     */
    'user': GetUserDto;
    /**
     * 
     * @type {GetAccountDto}
     * @memberof GetCashDetailsDto
     */
    'account': GetAccountDto;
    /**
     * 
     * @type {GetPatientDto}
     * @memberof GetCashDetailsDto
     */
    'patient': GetPatientDto;
    /**
     * 
     * @type {GetRxDto}
     * @memberof GetCashDetailsDto
     */
    'rx': GetRxDto;
    /**
     * Link to the cloud
     * @type {string}
     * @memberof GetCashDetailsDto
     */
    'cloudLink': string;
    /**
     * Date when the transaction was created
     * @type {string}
     * @memberof GetCashDetailsDto
     */
    'createdAt': string;
    /**
     * Date when the transaction was last updated
     * @type {string}
     * @memberof GetCashDetailsDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {GetTAccountDto}
     * @memberof GetCashDetailsDto
     */
    'tAccounts': GetTAccountDto;
    /**
     * 
     * @type {DocumentDto}
     * @memberof GetCashDetailsDto
     */
    'docDto'?: DocumentDto;
}

export const GetCashDetailsDtoTypeEnum = {
    Einnahme: 'Einnahme',
    Ausgabe: 'Ausgabe'
} as const;

export type GetCashDetailsDtoTypeEnum = typeof GetCashDetailsDtoTypeEnum[keyof typeof GetCashDetailsDtoTypeEnum];

/**
 * 
 * @export
 * @interface GetHistoryDto
 */
export interface GetHistoryDto {
    /**
     * 
     * @type {string}
     * @memberof GetHistoryDto
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof GetHistoryDto
     */
    'type': GetHistoryDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetHistoryDto
     */
    'action': GetHistoryDtoActionEnum;
    /**
     * 
     * @type {string}
     * @memberof GetHistoryDto
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof GetHistoryDto
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof GetHistoryDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetHistoryDto
     */
    'timestamp'?: string;
    /**
     * 
     * @type {object}
     * @memberof GetHistoryDto
     */
    'user': object;
    /**
     * 
     * @type {SingleHistoryTranslationFieldsDto}
     * @memberof GetHistoryDto
     */
    'translations': SingleHistoryTranslationFieldsDto;
}

export const GetHistoryDtoTypeEnum = {
    Patient: 'PATIENT',
    Appointment: 'APPOINTMENT',
    Rx: 'RX',
    Doctor: 'DOCTOR',
    UserProfile: 'USER_PROFILE',
    Documentation: 'DOCUMENTATION',
    RxSeries: 'RX_SERIES'
} as const;

export type GetHistoryDtoTypeEnum = typeof GetHistoryDtoTypeEnum[keyof typeof GetHistoryDtoTypeEnum];
export const GetHistoryDtoActionEnum = {
    CreateNewPatient: 'CREATE_NEW_PATIENT',
    UpdatePatient: 'UPDATE_PATIENT',
    CreateInitialEvaluation: 'CREATE_INITIAL_EVALUATION',
    CreateTreatmentNote: 'CREATE_TREATMENT_NOTE',
    UpdateInitialEvaluation: 'UPDATE_INITIAL_EVALUATION',
    UpdateTreatmentNote: 'UPDATE_TREATMENT_NOTE',
    SaveRx: 'SAVE_RX',
    UpdateRxstatus: 'UPDATE_RXSTATUS',
    SaveAppointment: 'SAVE_APPOINTMENT',
    DeletePatientAddress: 'DELETE_PATIENT_ADDRESS',
    CopyRx: 'COPY_RX',
    UpdateRxSeries: 'UPDATE_RX_SERIES',
    AddRxSeries: 'ADD_RX_SERIES',
    UpdateRxSeriesidForTherapyrx: 'UPDATE_RX_SERIESID_FOR_THERAPYRX',
    SaveSolutionAppointment: 'SAVE_SOLUTION_APPOINTMENT',
    SaveSolutionLunchBreak: 'SAVE_SOLUTION_LUNCH_BREAK',
    UpdateCancelledAppointment: 'UPDATE_CANCELLED_APPOINTMENT',
    CancelAppointment: 'CANCEL_APPOINTMENT',
    UpdateAppointment: 'UPDATE_APPOINTMENT',
    PrintRx: 'PRINT_RX'
} as const;

export type GetHistoryDtoActionEnum = typeof GetHistoryDtoActionEnum[keyof typeof GetHistoryDtoActionEnum];

/**
 * 
 * @export
 * @interface GetInitialEvaluationDto
 */
export interface GetInitialEvaluationDto {
    /**
     * 
     * @type {string}
     * @memberof GetInitialEvaluationDto
     */
    'evaluation'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetInitialEvaluationDto
     */
    'goals'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetInitialEvaluationDto
     */
    'patientId': number;
    /**
     * 
     * @type {number}
     * @memberof GetInitialEvaluationDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof GetInitialEvaluationDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof GetInitialEvaluationDto
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface GetPatientDto
 */
export interface GetPatientDto {
    /**
     * First name of the patient
     * @type {string}
     * @memberof GetPatientDto
     */
    'firstName': string;
    /**
     * Last name of the patient
     * @type {string}
     * @memberof GetPatientDto
     */
    'lastName': string;
}
/**
 * 
 * @export
 * @interface GetRechnungDetailsDto
 */
export interface GetRechnungDetailsDto {
    /**
     * Transaction number
     * @type {string}
     * @memberof GetRechnungDetailsDto
     */
    'transactionNumber': string;
    /**
     * Type of the transaction
     * @type {string}
     * @memberof GetRechnungDetailsDto
     */
    'type': GetRechnungDetailsDtoTypeEnum;
    /**
     * Description of the transaction
     * @type {string}
     * @memberof GetRechnungDetailsDto
     */
    'text': string;
    /**
     * Amount of the transaction
     * @type {number}
     * @memberof GetRechnungDetailsDto
     */
    'amount': number;
    /**
     * Flag indicating whether the transaction was exported
     * @type {boolean}
     * @memberof GetRechnungDetailsDto
     */
    'wasExported': boolean;
    /**
     * 
     * @type {GetUserDto}
     * @memberof GetRechnungDetailsDto
     */
    'user': GetUserDto;
    /**
     * 
     * @type {GetAccountDto}
     * @memberof GetRechnungDetailsDto
     */
    'account': GetAccountDto;
    /**
     * 
     * @type {GetPatientDto}
     * @memberof GetRechnungDetailsDto
     */
    'patient': GetPatientDto;
    /**
     * 
     * @type {GetRxDto}
     * @memberof GetRechnungDetailsDto
     */
    'rx': GetRxDto;
    /**
     * Link to the cloud
     * @type {string}
     * @memberof GetRechnungDetailsDto
     */
    'cloudLink': string;
    /**
     * Date when the transaction was created
     * @type {string}
     * @memberof GetRechnungDetailsDto
     */
    'createdAt': string;
    /**
     * Date when the transaction was last updated
     * @type {string}
     * @memberof GetRechnungDetailsDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {GetTAccountDto}
     * @memberof GetRechnungDetailsDto
     */
    'tAccounts': GetTAccountDto;
    /**
     * 
     * @type {DocumentDto}
     * @memberof GetRechnungDetailsDto
     */
    'docDto'?: DocumentDto;
    /**
     * Payment status of the transaction
     * @type {string}
     * @memberof GetRechnungDetailsDto
     */
    'paymentStatus': GetRechnungDetailsDtoPaymentStatusEnum;
    /**
     * Description of the transaction
     * @type {string}
     * @memberof GetRechnungDetailsDto
     */
    'textDescription': string;
}

export const GetRechnungDetailsDtoTypeEnum = {
    Einnahme: 'Einnahme',
    Ausgabe: 'Ausgabe'
} as const;

export type GetRechnungDetailsDtoTypeEnum = typeof GetRechnungDetailsDtoTypeEnum[keyof typeof GetRechnungDetailsDtoTypeEnum];
export const GetRechnungDetailsDtoPaymentStatusEnum = {
    Paid: 'PAID',
    Partial: 'PARTIAL',
    NotPaid: 'NOT PAID'
} as const;

export type GetRechnungDetailsDtoPaymentStatusEnum = typeof GetRechnungDetailsDtoPaymentStatusEnum[keyof typeof GetRechnungDetailsDtoPaymentStatusEnum];

/**
 * 
 * @export
 * @interface GetRxDto
 */
export interface GetRxDto {
    /**
     * Rx number
     * @type {string}
     * @memberof GetRxDto
     */
    'rxNumber': string;
    /**
     * 
     * @type {GetPatientDto}
     * @memberof GetRxDto
     */
    'patient': GetPatientDto;
}
/**
 * 
 * @export
 * @interface GetTAccountDto
 */
export interface GetTAccountDto {
    /**
     * TAccount ID
     * @type {number}
     * @memberof GetTAccountDto
     */
    'id': number;
    /**
     * TAccount name
     * @type {string}
     * @memberof GetTAccountDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface GetTicketChangeLogDTO
 */
export interface GetTicketChangeLogDTO {
    /**
     * 
     * @type {string}
     * @memberof GetTicketChangeLogDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GetTicketChangeLogDTO
     */
    'field': string;
    /**
     * 
     * @type {string}
     * @memberof GetTicketChangeLogDTO
     */
    'action': GetTicketChangeLogDTOActionEnum;
    /**
     * 
     * @type {string}
     * @memberof GetTicketChangeLogDTO
     */
    'previous': string;
    /**
     * 
     * @type {string}
     * @memberof GetTicketChangeLogDTO
     */
    'current': string;
    /**
     * 
     * @type {string}
     * @memberof GetTicketChangeLogDTO
     */
    'createdAt': string;
    /**
     * 
     * @type {UserDto}
     * @memberof GetTicketChangeLogDTO
     */
    'updatedByUser': UserDto;
    /**
     * 
     * @type {string}
     * @memberof GetTicketChangeLogDTO
     */
    'changeMessage': string;
}

export const GetTicketChangeLogDTOActionEnum = {
    Added: 'ADDED',
    Created: 'CREATED',
    Updated: 'UPDATED'
} as const;

export type GetTicketChangeLogDTOActionEnum = typeof GetTicketChangeLogDTOActionEnum[keyof typeof GetTicketChangeLogDTOActionEnum];

/**
 * 
 * @export
 * @interface GetUserDto
 */
export interface GetUserDto {
    /**
     * First name of the user
     * @type {string}
     * @memberof GetUserDto
     */
    'firstName': string;
    /**
     * Last name of the user
     * @type {string}
     * @memberof GetUserDto
     */
    'lastName': string;
    /**
     * Email of the user
     * @type {string}
     * @memberof GetUserDto
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface HistoryFilterEntryDto
 */
export interface HistoryFilterEntryDto {
    /**
     * 
     * @type {string}
     * @memberof HistoryFilterEntryDto
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof HistoryFilterEntryDto
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface HistoryFiltersDto
 */
export interface HistoryFiltersDto {
    /**
     * 
     * @type {Array<HistoryFilterEntryDto>}
     * @memberof HistoryFiltersDto
     */
    'actionFilters': Array<HistoryFilterEntryDto>;
    /**
     * 
     * @type {Array<HistoryFilterEntryDto>}
     * @memberof HistoryFiltersDto
     */
    'typeFilters': Array<HistoryFilterEntryDto>;
}
/**
 * 
 * @export
 * @interface ICDCodeDto
 */
export interface ICDCodeDto {
    /**
     * 
     * @type {string}
     * @memberof ICDCodeDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ICDCodeDto
     */
    'normCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ICDCodeDto
     */
    'text'?: string;
}
/**
 * 
 * @export
 * @interface ICDCodeEntryDto
 */
export interface ICDCodeEntryDto {
    /**
     * 
     * @type {string}
     * @memberof ICDCodeEntryDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ICDCodeEntryDto
     */
    'userText'?: string;
    /**
     * 
     * @type {ICDCodeDto}
     * @memberof ICDCodeEntryDto
     */
    'icdCode'?: ICDCodeDto;
}
/**
 * 
 * @export
 * @interface ItemDTO
 */
export interface ItemDTO {
    /**
     * 
     * @type {string}
     * @memberof ItemDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ItemDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ItemDTO
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface LeaveOfAbsenceDto
 */
export interface LeaveOfAbsenceDto {
    /**
     * 
     * @type {string}
     * @memberof LeaveOfAbsenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeaveOfAbsenceDto
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeaveOfAbsenceDto
     */
    'loAType'?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof LeaveOfAbsenceDto
     */
    'user'?: UserDto;
    /**
     * 
     * @type {string}
     * @memberof LeaveOfAbsenceDto
     */
    'start'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeaveOfAbsenceDto
     */
    'end'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeaveOfAbsenceDto
     */
    'comment'?: string;
}
/**
 * 
 * @export
 * @interface LocationDto
 */
export interface LocationDto {
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    'name': string;
    /**
     * 
     * @type {AccountDto}
     * @memberof LocationDto
     */
    'primaryAccount': AccountDto;
    /**
     * 
     * @type {Array<AccountDto>}
     * @memberof LocationDto
     */
    'accounts': Array<AccountDto>;
    /**
     * 
     * @type {AddressDto}
     * @memberof LocationDto
     */
    'address': AddressDto;
}
/**
 * 
 * @export
 * @interface NoteDto
 */
export interface NoteDto {
    /**
     * 
     * @type {string}
     * @memberof NoteDto
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof NoteDto
     */
    'patientId'?: number;
    /**
     * 
     * @type {string}
     * @memberof NoteDto
     */
    'appointmentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteDto
     */
    'rxId'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteDto
     */
    'text': string;
    /**
     * 
     * @type {UserDto}
     * @memberof NoteDto
     */
    'createdByUser'?: UserDto;
    /**
     * 
     * @type {UserDto}
     * @memberof NoteDto
     */
    'updatedByUser'?: UserDto;
}
/**
 * 
 * @export
 * @interface PatientAvailabilityDto
 */
export interface PatientAvailabilityDto {
    /**
     * 
     * @type {string}
     * @memberof PatientAvailabilityDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientAvailabilityDto
     */
    'groupId'?: string;
    /**
     * 
     * @type {PatientDto}
     * @memberof PatientAvailabilityDto
     */
    'patient'?: PatientDto;
    /**
     * 
     * @type {number}
     * @memberof PatientAvailabilityDto
     */
    'dayOfWeek'?: number;
    /**
     * 
     * @type {string}
     * @memberof PatientAvailabilityDto
     */
    'startTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientAvailabilityDto
     */
    'endTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientAvailabilityDto
     */
    'validityStart'?: string;
    /**
     * 
     * @type {object}
     * @memberof PatientAvailabilityDto
     */
    'validityEnd'?: object;
}
/**
 * 
 * @export
 * @interface PatientDto
 */
export interface PatientDto {
    /**
     * 
     * @type {number}
     * @memberof PatientDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    'salutation'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    'dob'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    'payer'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    'insuranceNo'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    'tarifGroupId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatientDto
     */
    'coPayment'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    'street'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    'zip'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    'letterOfAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    'phone1'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    'phone2'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    'mobile': string;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    'alternativeBillingFirstnames'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    'alternativeBillingLastname'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    'alternativeBillingTel'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    'alternativeBillingFax'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    'alternativeBillingMobile'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    'alternativeBillingAddressline1'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    'alternativeBillingAddressline2'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    'alternativeBillingCity'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    'alternativeBillingZip'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    'alternativeBillingEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    'treatmentBusinessName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    'treatmentTel'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    'treatmentFax'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    'treatmentMobile'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    'treatmentAddressline1'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    'treatmentAddressline2'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    'treatmentCity'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    'treatmentZip'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    'treatmentEmail'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatientDto
     */
    'smsDesired': boolean;
    /**
     * 
     * @type {Array<RxDto>}
     * @memberof PatientDto
     */
    'rxs'?: Array<RxDto>;
    /**
     * 
     * @type {Array<NoteDto>}
     * @memberof PatientDto
     */
    'notes'?: Array<NoteDto>;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    'primaryAddressId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    'alternativeBillingAddressId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    'treatmentAddressId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDto
     */
    'type'?: string;
    /**
     * 
     * @type {Array<AddressDto>}
     * @memberof PatientDto
     */
    'addresses'?: Array<AddressDto>;
}
/**
 * 
 * @export
 * @interface PermissionDto
 */
export interface PermissionDto {
    /**
     * 
     * @type {string}
     * @memberof PermissionDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PermissionDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PermissionDto
     */
    'description': string;
    /**
     * 
     * @type {boolean}
     * @memberof PermissionDto
     */
    'isUserPermission'?: boolean;
}
/**
 * 
 * @export
 * @interface RoleDto
 */
export interface RoleDto {
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'description': string;
    /**
     * 
     * @type {Array<PermissionDto>}
     * @memberof RoleDto
     */
    'permissions'?: Array<PermissionDto>;
}
/**
 * 
 * @export
 * @interface RxDto
 */
export interface RxDto {
    /**
     * 
     * @type {string}
     * @memberof RxDto
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof RxDto
     */
    'patientId'?: number;
    /**
     * 
     * @type {object}
     * @memberof RxDto
     */
    'patient'?: object;
    /**
     * 
     * @type {string}
     * @memberof RxDto
     */
    'rxNumber'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RxDto
     */
    'isPerpetual'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RxDto
     */
    'isPerpetualApproved'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RxDto
     */
    'isCopaymentExempted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RxDto
     */
    'isAccident'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RxDto
     */
    'isBVG'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RxDto
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof RxDto
     */
    'approvalStatus'?: RxDtoApprovalStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof RxDto
     */
    'validStartWindow'?: number;
    /**
     * 
     * @type {string}
     * @memberof RxDto
     */
    'rxExpireDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof RxDto
     */
    'insuranceStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof RxDto
     */
    'insureeNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RxDto
     */
    'rxtype'?: string;
    /**
     * 
     * @type {string}
     * @memberof RxDto
     */
    'symptoms'?: string;
    /**
     * 
     * @type {string}
     * @memberof RxDto
     */
    'additionalNotes'?: string;
    /**
     * 
     * @type {string}
     * @memberof RxDto
     */
    'additionalNotes2'?: string;
    /**
     * 
     * @type {string}
     * @memberof RxDto
     */
    'expectedTherapyLength'?: string;
    /**
     * 
     * @type {string}
     * @memberof RxDto
     */
    'signatureStatus'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RxDto
     */
    'isLongTermRx'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RxDto
     */
    'a'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RxDto
     */
    'b'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RxDto
     */
    'c'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RxDto
     */
    'd'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RxDto
     */
    'hasIndividualSymptoms'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RxDto
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof RxDto
     */
    'therapyStart'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RxDto
     */
    'therapyReport'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RxDto
     */
    'visitType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RxDto
     */
    'urgent'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RxDto
     */
    'hygiene'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RxDto
     */
    'doctorNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof RxDto
     */
    'practiceNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof RxDto
     */
    'diagnosisText'?: string;
    /**
     * 
     * @type {string}
     * @memberof RxDto
     */
    'diagnosisText2'?: string;
    /**
     * 
     * @type {string}
     * @memberof RxDto
     */
    'accountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RxDto
     */
    'Receiptpayer'?: string;
    /**
     * 
     * @type {object}
     * @memberof RxDto
     */
    'payer'?: object;
    /**
     * 
     * @type {string}
     * @memberof RxDto
     */
    'tarifGroupId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RxDto
     */
    'medicalPracticeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RxDto
     */
    'doctorId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RxDto
     */
    'accidentDay'?: string;
    /**
     * 
     * @type {DiagnosisGroupDto}
     * @memberof RxDto
     */
    'diagnosegroup'?: DiagnosisGroupDto;
    /**
     * 
     * @type {string}
     * @memberof RxDto
     */
    'therapy'?: string;
    /**
     * 
     * @type {number}
     * @memberof RxDto
     */
    'number'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RxDto
     */
    'problematic'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RxDto
     */
    'changeRequested'?: boolean;
    /**
     * 
     * @type {Array<ICDCodeEntryDto>}
     * @memberof RxDto
     */
    'icdCodeEntries'?: Array<ICDCodeEntryDto>;
    /**
     * 
     * @type {Array<DoubleTreatmentRxDto>}
     * @memberof RxDto
     */
    'therapyRxs'?: Array<DoubleTreatmentRxDto>;
    /**
     * 
     * @type {Array<NoteDto>}
     * @memberof RxDto
     */
    'notes'?: Array<NoteDto>;
    /**
     * 
     * @type {boolean}
     * @memberof RxDto
     */
    'unvirtualize'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RxDto
     */
    'processingStatus'?: RxDtoProcessingStatusEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof RxDto
     */
    'messages'?: Array<string>;
}

export const RxDtoApprovalStatusEnum = {
    Fehlt: 'fehlt',
    IstBestellt: 'ist Bestellt',
    LiegtVor: 'liegt vor'
} as const;

export type RxDtoApprovalStatusEnum = typeof RxDtoApprovalStatusEnum[keyof typeof RxDtoApprovalStatusEnum];
export const RxDtoProcessingStatusEnum = {
    InBehandlung: 'in Behandlung',
    Abgeschlossen: 'abgeschlossen',
    Abgerechnet: 'abgerechnet',
    Abgebrochen: 'abgebrochen',
    ZurKontrolle: 'zur Kontrolle'
} as const;

export type RxDtoProcessingStatusEnum = typeof RxDtoProcessingStatusEnum[keyof typeof RxDtoProcessingStatusEnum];

/**
 * 
 * @export
 * @interface RxSeriesDto
 */
export interface RxSeriesDto {
    /**
     * 
     * @type {string}
     * @memberof RxSeriesDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RxSeriesDto
     */
    'label'?: string;
    /**
     * 
     * @type {Array<TherapyRxDto>}
     * @memberof RxSeriesDto
     */
    'therapyRxs'?: Array<TherapyRxDto> | null;
}
/**
 * 
 * @export
 * @interface SearchDoctorDto
 */
export interface SearchDoctorDto {
    /**
     * 
     * @type {number}
     * @memberof SearchDoctorDto
     */
    'doctorNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchDoctorDto
     */
    'clinicNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchDoctorDto
     */
    'lastName'?: string;
}
/**
 * 
 * @export
 * @interface SearchDto
 */
export interface SearchDto {
    /**
     * 
     * @type {number}
     * @memberof SearchDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchDto
     */
    'dob'?: string;
}
/**
 * 
 * @export
 * @interface SingleHistoryTranslationFieldsDto
 */
export interface SingleHistoryTranslationFieldsDto {
    /**
     * 
     * @type {string}
     * @memberof SingleHistoryTranslationFieldsDto
     */
    'type': SingleHistoryTranslationFieldsDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SingleHistoryTranslationFieldsDto
     */
    'action': SingleHistoryTranslationFieldsDtoActionEnum;
}

export const SingleHistoryTranslationFieldsDtoTypeEnum = {
    Patient: 'Patient',
    Appointment: 'Appointment',
    Prescription: 'Prescription',
    Doctor: 'Doctor',
    User: 'User',
    Documentation: 'Documentation',
    RxSeries: 'Rx Series'
} as const;

export type SingleHistoryTranslationFieldsDtoTypeEnum = typeof SingleHistoryTranslationFieldsDtoTypeEnum[keyof typeof SingleHistoryTranslationFieldsDtoTypeEnum];
export const SingleHistoryTranslationFieldsDtoActionEnum = {
    CreateNewPatient: 'create new patient',
    UpdatePatient: 'update patient',
    CreateInitialEvaluation: 'create initial evaluation',
    UpdateInitialEvaluation: 'update initial evaluation',
    CreateTreatmentNote: 'create treatment note',
    UpdateTreatmentNote: 'update treatment note',
    SavePrescription: 'save prescription',
    UpdatePrescriptionProcessingStatus: 'update prescription processingStatus',
    ScheduleAppointment: 'schedule appointment',
    DeletePatientAddress: 'delete patient address',
    RxDuplication: 'rx duplication',
    RxSeriesUpdate: 'rx series update',
    CreateRxSeries: 'create rx series',
    ChangeRxseriesToTherapyRx: 'change rxseries to therapyRx',
    AutomatedSavedAppointments: 'automated saved appointments',
    AutomatedSavedLunchBreaks: 'automated saved lunch breaks',
    UpdateCancelledAppointment: 'update cancelled appointment',
    CancelAppointment: 'cancel appointment',
    UpdateAppointment: 'update appointment',
    PrintRx: 'print rx'
} as const;

export type SingleHistoryTranslationFieldsDtoActionEnum = typeof SingleHistoryTranslationFieldsDtoActionEnum[keyof typeof SingleHistoryTranslationFieldsDtoActionEnum];

/**
 * 
 * @export
 * @interface SolutionDto
 */
export interface SolutionDto {
    /**
     * 
     * @type {Array<AppointmentDto>}
     * @memberof SolutionDto
     */
    'appointments': Array<AppointmentDto>;
    /**
     * 
     * @type {Array<ContextDto>}
     * @memberof SolutionDto
     */
    'contexts'?: Array<ContextDto>;
    /**
     * 
     * @type {Array<SummaryMetricsDto>}
     * @memberof SolutionDto
     */
    'summary'?: Array<SummaryMetricsDto>;
    /**
     * 
     * @type {Array<TimeSlotDto>}
     * @memberof SolutionDto
     */
    'lunchBreaks'?: Array<TimeSlotDto>;
}
/**
 * 
 * @export
 * @interface StateForPatientDto
 */
export interface StateForPatientDto {
    /**
     * Mapping of therapist IDs to arrays of therapy IDs
     * @type {{ [key: string]: Array<string>; }}
     * @memberof StateForPatientDto
     */
    'therapistCredentials': { [key: string]: Array<string>; };
    /**
     * 
     * @type {Array<UnscheduledAppointmentDto>}
     * @memberof StateForPatientDto
     */
    'unscheduledAppointments': Array<UnscheduledAppointmentDto>;
    /**
     * 
     * @type {Array<TimeSlotDto>}
     * @memberof StateForPatientDto
     */
    'therapistsAvailabilities': Array<TimeSlotDto>;
    /**
     * 
     * @type {Array<TimeSlotDto>}
     * @memberof StateForPatientDto
     */
    'filteredAvailabilities': Array<TimeSlotDto>;
    /**
     * 
     * @type {Array<AppointmentDto>}
     * @memberof StateForPatientDto
     */
    'scheduledAppointments': Array<AppointmentDto>;
}
/**
 * 
 * @export
 * @interface SummaryMetricsDto
 */
export interface SummaryMetricsDto {
    /**
     * 
     * @type {string}
     * @memberof SummaryMetricsDto
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof SummaryMetricsDto
     */
    'value': string;
    /**
     * 
     * @type {object}
     * @memberof SummaryMetricsDto
     */
    'units': object;
}
/**
 * 
 * @export
 * @interface TherapyDto
 */
export interface TherapyDto {
    /**
     * 
     * @type {string}
     * @memberof TherapyDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TherapyDto
     */
    'abbreviation'?: string;
    /**
     * 
     * @type {object}
     * @memberof TherapyDto
     */
    'tarifGroup'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof TherapyDto
     */
    'isSupplementary'?: boolean;
}
/**
 * 
 * @export
 * @interface TherapyRxDto
 */
export interface TherapyRxDto {
    /**
     * 
     * @type {string}
     * @memberof TherapyRxDto
     */
    'id'?: string;
    /**
     * 
     * @type {object}
     * @memberof TherapyRxDto
     */
    'therapy'?: object;
    /**
     * 
     * @type {number}
     * @memberof TherapyRxDto
     */
    'amount'?: number;
    /**
     * 
     * @type {object}
     * @memberof TherapyRxDto
     */
    'frequency'?: object;
    /**
     * 
     * @type {object}
     * @memberof TherapyRxDto
     */
    'rxSeries'?: object;
    /**
     * 
     * @type {RxDto}
     * @memberof TherapyRxDto
     */
    'rx'?: RxDto;
    /**
     * 
     * @type {TherapyRxDto}
     * @memberof TherapyRxDto
     */
    'isDoubledBy'?: TherapyRxDto;
    /**
     * 
     * @type {TherapyRxDto}
     * @memberof TherapyRxDto
     */
    'isSupplementedBy'?: TherapyRxDto;
    /**
     * 
     * @type {string}
     * @memberof TherapyRxDto
     */
    'visitFee'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TherapyRxDto
     */
    'scheduledCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof TherapyRxDto
     */
    'order'?: number;
}
/**
 * 
 * @export
 * @interface TicketDto
 */
export interface TicketDto {
    /**
     * 
     * @type {number}
     * @memberof TicketDto
     */
    'id'?: number;
    /**
     * 
     * @type {UserDto}
     * @memberof TicketDto
     */
    'createdByUser'?: UserDto;
    /**
     * 
     * @type {string}
     * @memberof TicketDto
     */
    'createdByUserId'?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof TicketDto
     */
    'assignedToUser'?: UserDto;
    /**
     * 
     * @type {string}
     * @memberof TicketDto
     */
    'assignedToUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketDto
     */
    'ticketType'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketDto
     */
    'ticketStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketDto
     */
    'priority'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketDto
     */
    'description'?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof TicketDto
     */
    'updatedByUser'?: UserDto;
    /**
     * 
     * @type {string}
     * @memberof TicketDto
     */
    'updatedByUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketDto
     */
    'comments'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketDto
     */
    'appointmentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketDto
     */
    'rxId'?: string;
    /**
     * 
     * @type {number}
     * @memberof TicketDto
     */
    'patientId'?: number;
    /**
     * 
     * @type {Array<ActivityDto>}
     * @memberof TicketDto
     */
    'activities'?: Array<ActivityDto>;
    /**
     * 
     * @type {string}
     * @memberof TicketDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketDto
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface TicketFilterDto
 */
export interface TicketFilterDto {
    /**
     * 
     * @type {TicketFilterEntryDto}
     * @memberof TicketFilterDto
     */
    'ticketType': TicketFilterEntryDto;
    /**
     * 
     * @type {Array<TicketFilterEntryDto>}
     * @memberof TicketFilterDto
     */
    'statuses': Array<TicketFilterEntryDto>;
    /**
     * 
     * @type {Array<TicketFilterEntryDto>}
     * @memberof TicketFilterDto
     */
    'priorities': Array<TicketFilterEntryDto>;
}
/**
 * 
 * @export
 * @interface TicketFilterEntryDto
 */
export interface TicketFilterEntryDto {
    /**
     * 
     * @type {string}
     * @memberof TicketFilterEntryDto
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof TicketFilterEntryDto
     */
    'value': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TicketingRelationEntities = {
    Patient: 'PATIENT',
    Rx: 'RX',
    Appointment: 'APPOINTMENT',
    Other: 'OTHER'
} as const;

export type TicketingRelationEntities = typeof TicketingRelationEntities[keyof typeof TicketingRelationEntities];


/**
 * 
 * @export
 * @interface TimeSlotDto
 */
export interface TimeSlotDto {
    /**
     * 
     * @type {string}
     * @memberof TimeSlotDto
     */
    'id'?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof TimeSlotDto
     */
    'user'?: UserDto;
    /**
     * 
     * @type {number}
     * @memberof TimeSlotDto
     */
    'timeSlotPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSlotDto
     */
    'extraFees'?: number;
    /**
     * 
     * @type {TherapyDto}
     * @memberof TimeSlotDto
     */
    'visitFee'?: TherapyDto;
    /**
     * 
     * @type {PatientDto}
     * @memberof TimeSlotDto
     */
    'patient'?: PatientDto;
    /**
     * 
     * @type {TherapyRxDto}
     * @memberof TimeSlotDto
     */
    'therapyRx'?: TherapyRxDto;
    /**
     * 
     * @type {string}
     * @memberof TimeSlotDto
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof TimeSlotDto
     */
    'appointmentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeSlotDto
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof TimeSlotDto
     */
    'end': string;
    /**
     * 
     * @type {TimeSlotDto}
     * @memberof TimeSlotDto
     */
    'lunchBreak'?: TimeSlotDto;
    /**
     * 
     * @type {boolean}
     * @memberof TimeSlotDto
     */
    'attended'?: boolean;
    /**
     * 
     * @type {Array<TreatmentNoteDTO>}
     * @memberof TimeSlotDto
     */
    'treatmentNotes'?: Array<TreatmentNoteDTO>;
    /**
     * 
     * @type {string}
     * @memberof TimeSlotDto
     */
    'treatmentIndex'?: string;
}
/**
 * 
 * @export
 * @interface TokenDto
 */
export interface TokenDto {
    /**
     * 
     * @type {string}
     * @memberof TokenDto
     */
    'access_token': string;
}
/**
 * 
 * @export
 * @interface TreatmentNoteDTO
 */
export interface TreatmentNoteDTO {
    /**
     * 
     * @type {number}
     * @memberof TreatmentNoteDTO
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TreatmentNoteDTO
     */
    'note': string;
    /**
     * 
     * @type {string}
     * @memberof TreatmentNoteDTO
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof TreatmentNoteDTO
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof TreatmentNoteDTO
     */
    'user': UserDto;
}
/**
 * 
 * @export
 * @interface UnscheduledAppointmentDto
 */
export interface UnscheduledAppointmentDto {
    /**
     * 
     * @type {string}
     * @memberof UnscheduledAppointmentDto
     */
    'id'?: string;
    /**
     * 
     * @type {Array<UnscheduledTimeSlotDto>}
     * @memberof UnscheduledAppointmentDto
     */
    'timeSlots'?: Array<UnscheduledTimeSlotDto>;
    /**
     * 
     * @type {object}
     * @memberof UnscheduledAppointmentDto
     */
    'frequency': object;
    /**
     * 
     * @type {Array<NoteDto>}
     * @memberof UnscheduledAppointmentDto
     */
    'notes'?: Array<NoteDto>;
}
/**
 * 
 * @export
 * @interface UnscheduledTimeSlotDto
 */
export interface UnscheduledTimeSlotDto {
    /**
     * 
     * @type {string}
     * @memberof UnscheduledTimeSlotDto
     */
    'id'?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof UnscheduledTimeSlotDto
     */
    'user'?: UserDto;
    /**
     * 
     * @type {number}
     * @memberof UnscheduledTimeSlotDto
     */
    'timeSlotPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof UnscheduledTimeSlotDto
     */
    'extraFees'?: number;
    /**
     * 
     * @type {TherapyDto}
     * @memberof UnscheduledTimeSlotDto
     */
    'visitFee'?: TherapyDto;
    /**
     * 
     * @type {PatientDto}
     * @memberof UnscheduledTimeSlotDto
     */
    'patient'?: PatientDto;
    /**
     * 
     * @type {TherapyRxDto}
     * @memberof UnscheduledTimeSlotDto
     */
    'therapyRx'?: TherapyRxDto;
    /**
     * 
     * @type {string}
     * @memberof UnscheduledTimeSlotDto
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof UnscheduledTimeSlotDto
     */
    'appointmentId'?: string;
}
/**
 * 
 * @export
 * @interface UpdateAppointmentDto
 */
export interface UpdateAppointmentDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateAppointmentDto
     */
    'id'?: string;
    /**
     * 
     * @type {AddressDto}
     * @memberof UpdateAppointmentDto
     */
    'address'?: AddressDto;
    /**
     * 
     * @type {Array<TimeSlotDto>}
     * @memberof UpdateAppointmentDto
     */
    'timeSlots'?: Array<TimeSlotDto>;
}
/**
 * 
 * @export
 * @interface UpdateCancelledAppointmentDto
 */
export interface UpdateCancelledAppointmentDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateCancelledAppointmentDto
     */
    'callStatus'?: string;
}
/**
 * 
 * @export
 * @interface UpdateDoctorDto
 */
export interface UpdateDoctorDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateDoctorDto
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateDoctorDto
     */
    'clinicNumber'?: number;
    /**
     * 
     * @type {ContactInfoDTO}
     * @memberof UpdateDoctorDto
     */
    'contactInfo'?: ContactInfoDTO;
}
/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'nickName'?: string;
    /**
     * 
     * @type {LocationDto}
     * @memberof UserDto
     */
    'location'?: LocationDto;
    /**
     * 
     * @type {LocationDto}
     * @memberof UserDto
     */
    'therapistLocation'?: LocationDto;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'therapist'?: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof UserDto
     */
    'therapies'?: Array<object>;
    /**
     * 
     * @type {Array<RoleDto>}
     * @memberof UserDto
     */
    'roles'?: Array<RoleDto>;
    /**
     * 
     * @type {Array<PermissionDto>}
     * @memberof UserDto
     */
    'permissions'?: Array<PermissionDto>;
    /**
     * 
     * @type {Array<WeeklyWorkDayDto>}
     * @memberof UserDto
     */
    'weeklyWorkDays'?: Array<WeeklyWorkDayDto>;
}
/**
 * 
 * @export
 * @interface UserRegisterDto
 */
export interface UserRegisterDto {
    /**
     * 
     * @type {string}
     * @memberof UserRegisterDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserRegisterDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegisterDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegisterDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegisterDto
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface WeeklyWorkDayDto
 */
export interface WeeklyWorkDayDto {
    /**
     * 
     * @type {number}
     * @memberof WeeklyWorkDayDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyWorkDayDto
     */
    'dayOfWeek'?: number;
    /**
     * 
     * @type {string}
     * @memberof WeeklyWorkDayDto
     */
    'startTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof WeeklyWorkDayDto
     */
    'endTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof WeeklyWorkDayDto
     */
    'employeeId'?: string;
    /**
     * 
     * @type {WorkPlanDto}
     * @memberof WeeklyWorkDayDto
     */
    'workPlan'?: WorkPlanDto;
    /**
     * 
     * @type {boolean}
     * @memberof WeeklyWorkDayDto
     */
    'isBreak'?: boolean;
}
/**
 * 
 * @export
 * @interface WorkPlanDto
 */
export interface WorkPlanDto {
    /**
     * 
     * @type {number}
     * @memberof WorkPlanDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkPlanDto
     */
    'validityStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkPlanDto
     */
    'validityEnd'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkPlanDto
     */
    'employeeId'?: string;
}

/**
 * AppointmentApi - axios parameter creator
 * @export
 */
export const AppointmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This endpoint removes an appointment from the database. It is not intended to be used for canceling a scheduled appointment. Use the cancel endpoint for that. It will only work on unscheduled appointments whose timeslots have not been scheduled. The children timeslots will also be deleted
         * @summary Remove an appointment by its ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('_delete', 'id', id)
            const localVarPath = `/appointment/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {AdjustOutsideAppointmentsDto} adjustOutsideAppointmentsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adjustOutsideAppointments: async (adjustOutsideAppointmentsDto: AdjustOutsideAppointmentsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adjustOutsideAppointmentsDto' is not null or undefined
            assertParamExists('adjustOutsideAppointments', 'adjustOutsideAppointmentsDto', adjustOutsideAppointmentsDto)
            const localVarPath = `/appointment/adjustOutsideAppointments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adjustOutsideAppointmentsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AppointmentDto} appointmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentControllerCreate: async (appointmentDto: AppointmentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appointmentDto' is not null or undefined
            assertParamExists('appointmentControllerCreate', 'appointmentDto', appointmentDto)
            const localVarPath = `/appointment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appointmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backFillContinuous: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('backFillContinuous', 'id', id)
            const localVarPath = `/appointment/backFillContinuous/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canSwapAppointments: async (requestBody: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('canSwapAppointments', 'requestBody', requestBody)
            const localVarPath = `/appointment/canSwapAppointments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} apptId Appointment ID
         * @param {boolean} byTherapist 
         * @param {string} [reason] Reason for cancellation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancel: async (apptId: string, byTherapist: boolean, reason?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apptId' is not null or undefined
            assertParamExists('cancel', 'apptId', apptId)
            // verify required parameter 'byTherapist' is not null or undefined
            assertParamExists('cancel', 'byTherapist', byTherapist)
            const localVarPath = `/appointment/cancel/{apptId}`
                .replace(`{${"apptId"}}`, encodeURIComponent(String(apptId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (byTherapist !== undefined) {
                localVarQueryParameter['byTherapist'] = byTherapist;
            }

            if (reason !== undefined) {
                localVarQueryParameter['reason'] = reason;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} apptId Appointment ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelContinuous: async (apptId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apptId' is not null or undefined
            assertParamExists('cancelContinuous', 'apptId', apptId)
            const localVarPath = `/appointment/cancelContinuous/{apptId}`
                .replace(`{${"apptId"}}`, encodeURIComponent(String(apptId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} slotId TimeSlot ID
         * @param {boolean} byTherapist 
         * @param {string} [reason] Reason for cancellation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelTimeSlot: async (slotId: string, byTherapist: boolean, reason?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slotId' is not null or undefined
            assertParamExists('cancelTimeSlot', 'slotId', slotId)
            // verify required parameter 'byTherapist' is not null or undefined
            assertParamExists('cancelTimeSlot', 'byTherapist', byTherapist)
            const localVarPath = `/appointment/cancelTimeSlot/{slotId}`
                .replace(`{${"slotId"}}`, encodeURIComponent(String(slotId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (byTherapist !== undefined) {
                localVarQueryParameter['byTherapist'] = byTherapist;
            }

            if (reason !== undefined) {
                localVarQueryParameter['reason'] = reason;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} apptId Appointment ID
         * @param {boolean} [isContinuous] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyToNew: async (apptId: string, isContinuous?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apptId' is not null or undefined
            assertParamExists('copyToNew', 'apptId', apptId)
            const localVarPath = `/appointment/copyToNew/{apptId}`
                .replace(`{${"apptId"}}`, encodeURIComponent(String(apptId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isContinuous !== undefined) {
                localVarQueryParameter['isContinuous'] = isContinuous;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {AppointmentTemplateDto} appointmentTemplateDto 
         * @param {boolean} [isContinuous] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createForTreatments: async (appointmentTemplateDto: AppointmentTemplateDto, isContinuous?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appointmentTemplateDto' is not null or undefined
            assertParamExists('createForTreatments', 'appointmentTemplateDto', appointmentTemplateDto)
            const localVarPath = `/appointment/createForTreatments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isContinuous !== undefined) {
                localVarQueryParameter['isContinuous'] = isContinuous;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appointmentTemplateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This deletes a blocked slot for a patient based on the slot id
         * @summary Remove a blocked slot by its ID
         * @param {string} slotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBlockedSlot: async (slotId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slotId' is not null or undefined
            assertParamExists('deleteBlockedSlot', 'slotId', slotId)
            const localVarPath = `/appointment/availability/delete/{slotId}`
                .replace(`{${"slotId"}}`, encodeURIComponent(String(slotId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint removes a continuous appointment from the database. It will also remove all the appointments that are part of the continuous appointment
         * @summary Remove a continuous appointment and its timeslots by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContinuous: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteContinuous', 'id', id)
            const localVarPath = `/appointment/deleteContinuous/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This deletes a blocked slot for a patient based on the groupId id
         * @summary Remove Patient Unavailability by its GroupID
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePatientUnavailability: async (groupId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('deletePatientUnavailability', 'groupId', groupId)
            const localVarPath = `/appointment/availability/deletePatientUnavailability/{groupId}`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frequencyValidation: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('frequencyValidation', 'id', id)
            const localVarPath = `/appointment/frequencyValidation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} appointmentId Appointment ID
         * @param {string} date Date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frequencyValidationForAppointment: async (appointmentId: string, date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appointmentId' is not null or undefined
            assertParamExists('frequencyValidationForAppointment', 'appointmentId', appointmentId)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('frequencyValidationForAppointment', 'date', date)
            const localVarPath = `/appointment/validation/frequency/{appointmentId}/{date}`
                .replace(`{${"appointmentId"}}`, encodeURIComponent(String(appointmentId)))
                .replace(`{${"date"}}`, encodeURIComponent(String(date)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointment: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAppointment', 'id', id)
            const localVarPath = `/appointment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} date 
         * @param {boolean} weeklyworkday 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentsByDate: async (date: string, weeklyworkday: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getAppointmentsByDate', 'date', date)
            // verify required parameter 'weeklyworkday' is not null or undefined
            assertParamExists('getAppointmentsByDate', 'weeklyworkday', weeklyworkday)
            const localVarPath = `/appointment/date/{date}`
                .replace(`{${"date"}}`, encodeURIComponent(String(date)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (weeklyworkday !== undefined) {
                localVarQueryParameter['weeklyworkday'] = weeklyworkday;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} patientId 
         * @param {string} [addressId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentsForPatient: async (patientId: string, addressId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getAppointmentsForPatient', 'patientId', patientId)
            const localVarPath = `/appointment/forPatient/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (addressId !== undefined) {
                localVarQueryParameter['addressId'] = addressId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} rxNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentsForPatientWithRx: async (rxNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rxNumber' is not null or undefined
            assertParamExists('getAppointmentsForPatientWithRx', 'rxNumber', rxNumber)
            const localVarPath = `/appointment/printEachRx/{rxNumber}`
                .replace(`{${"rxNumber"}}`, encodeURIComponent(String(rxNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} rxNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentsForRx: async (rxNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rxNumber' is not null or undefined
            assertParamExists('getAppointmentsForRx', 'rxNumber', rxNumber)
            const localVarPath = `/appointment/forRx/{rxNumber}`
                .replace(`{${"rxNumber"}}`, encodeURIComponent(String(rxNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} seriesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentsForSeries: async (seriesId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'seriesId' is not null or undefined
            assertParamExists('getAppointmentsForSeries', 'seriesId', seriesId)
            const localVarPath = `/appointment/forSeries/{seriesId}`
                .replace(`{${"seriesId"}}`, encodeURIComponent(String(seriesId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentsForUser: async (userId: string, date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAppointmentsForUser', 'userId', userId)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getAppointmentsForUser', 'date', date)
            const localVarPath = `/appointment/forUser/{userId}/{date}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"date"}}`, encodeURIComponent(String(date)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBlockedSlotsForPatient: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getBlockedSlotsForPatient', 'patientId', patientId)
            const localVarPath = `/appointment/availability/getBlockedSlotsForPatient{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCancelledAppointments: async (locationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('getCancelledAppointments', 'locationId', locationId)
            const localVarPath = `/appointment/cancelled/{locationId}`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} patientId 
         * @param {string} [addressId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContinuousAppointmentsForPatient: async (patientId: string, addressId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getContinuousAppointmentsForPatient', 'patientId', patientId)
            const localVarPath = `/appointment/continuous/patient/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (addressId !== undefined) {
                localVarQueryParameter['addressId'] = addressId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientUnavailabilityForPatient: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getPatientUnavailabilityForPatient', 'patientId', patientId)
            const localVarPath = `/appointment/availability/getPatientUnavailability{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} patientId 
         * @param {string} locationId 
         * @param {string} [searchFunction] 
         * @param {number} [pruningTarget] 
         * @param {number} [beta1] 
         * @param {number} [beta2] 
         * @param {Array<any>} [wishTherapists] 
         * @param {Array<string>} [appointmentIds] 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSolution: async (patientId: string, locationId: string, searchFunction?: string, pruningTarget?: number, beta1?: number, beta2?: number, wishTherapists?: Array<any>, appointmentIds?: Array<string>, date?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getSolution', 'patientId', patientId)
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('getSolution', 'locationId', locationId)
            const localVarPath = `/appointment/solution/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (locationId !== undefined) {
                localVarQueryParameter['locationId'] = locationId;
            }

            if (searchFunction !== undefined) {
                localVarQueryParameter['searchFunction'] = searchFunction;
            }

            if (pruningTarget !== undefined) {
                localVarQueryParameter['pruningTarget'] = pruningTarget;
            }

            if (beta1 !== undefined) {
                localVarQueryParameter['beta1'] = beta1;
            }

            if (beta2 !== undefined) {
                localVarQueryParameter['beta2'] = beta2;
            }

            if (wishTherapists) {
                localVarQueryParameter['wishTherapists'] = wishTherapists;
            }

            if (appointmentIds) {
                localVarQueryParameter['appointmentIds'] = appointmentIds;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} patientId 
         * @param {number} max 
         * @param {string} locationId 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSolutions: async (patientId: string, max: number, locationId: string, date?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getSolutions', 'patientId', patientId)
            // verify required parameter 'max' is not null or undefined
            assertParamExists('getSolutions', 'max', max)
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('getSolutions', 'locationId', locationId)
            const localVarPath = `/appointment/solutions/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (max !== undefined) {
                localVarQueryParameter['max'] = max;
            }

            if (locationId !== undefined) {
                localVarQueryParameter['locationId'] = locationId;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} max 
         * @param {string} locationId 
         * @param {AppointmentTemplateDto} appointmentTemplateDto 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSolutionsForContinuous: async (max: number, locationId: string, appointmentTemplateDto: AppointmentTemplateDto, date?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'max' is not null or undefined
            assertParamExists('getSolutionsForContinuous', 'max', max)
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('getSolutionsForContinuous', 'locationId', locationId)
            // verify required parameter 'appointmentTemplateDto' is not null or undefined
            assertParamExists('getSolutionsForContinuous', 'appointmentTemplateDto', appointmentTemplateDto)
            const localVarPath = `/appointment/solutionsForContinuous`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (max !== undefined) {
                localVarQueryParameter['max'] = max;
            }

            if (locationId !== undefined) {
                localVarQueryParameter['locationId'] = locationId;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appointmentTemplateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} patientId 
         * @param {string} locationId 
         * @param {string} [startDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStateForPatient: async (patientId: string, locationId: string, startDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getStateForPatient', 'patientId', patientId)
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('getStateForPatient', 'locationId', locationId)
            const localVarPath = `/appointment/ai/stateForPatient/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (locationId !== undefined) {
                localVarQueryParameter['locationId'] = locationId;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {Array<AppointmentDto>} appointmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAppointments: async (appointmentDto: Array<AppointmentDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appointmentDto' is not null or undefined
            assertParamExists('saveAppointments', 'appointmentDto', appointmentDto)
            const localVarPath = `/appointment/saveAppointments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appointmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {Array<TimeSlotDto>} timeSlotDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveBlockedSlotsForPatient: async (patientId: number, timeSlotDto: Array<TimeSlotDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('saveBlockedSlotsForPatient', 'patientId', patientId)
            // verify required parameter 'timeSlotDto' is not null or undefined
            assertParamExists('saveBlockedSlotsForPatient', 'timeSlotDto', timeSlotDto)
            const localVarPath = `/appointment/availability/saveBlockedSlotsForPatient{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(timeSlotDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {Array<PatientAvailabilityDto>} patientAvailabilityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePatientUnavailibilityForPatient: async (patientId: number, patientAvailabilityDto: Array<PatientAvailabilityDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('savePatientUnavailibilityForPatient', 'patientId', patientId)
            // verify required parameter 'patientAvailabilityDto' is not null or undefined
            assertParamExists('savePatientUnavailibilityForPatient', 'patientAvailabilityDto', patientAvailabilityDto)
            const localVarPath = `/appointment/availability/savePatientUnavailibility{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientAvailabilityDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {SolutionDto} solutionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSolution: async (solutionDto: SolutionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'solutionDto' is not null or undefined
            assertParamExists('saveSolution', 'solutionDto', solutionDto)
            const localVarPath = `/appointment/saveSolution`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(solutionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} apptId 
         * @param {boolean} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAttended: async (apptId: string, value: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apptId' is not null or undefined
            assertParamExists('setAttended', 'apptId', apptId)
            // verify required parameter 'value' is not null or undefined
            assertParamExists('setAttended', 'value', value)
            const localVarPath = `/appointment/setAttended/{apptId}/{value}`
                .replace(`{${"apptId"}}`, encodeURIComponent(String(apptId)))
                .replace(`{${"value"}}`, encodeURIComponent(String(value)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} slotId 
         * @param {boolean} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTimeSlotAttended: async (slotId: string, value: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slotId' is not null or undefined
            assertParamExists('setTimeSlotAttended', 'slotId', slotId)
            // verify required parameter 'value' is not null or undefined
            assertParamExists('setTimeSlotAttended', 'value', value)
            const localVarPath = `/appointment/setTimeSlotAttended/{slotId}/{value}`
                .replace(`{${"slotId"}}`, encodeURIComponent(String(slotId)))
                .replace(`{${"value"}}`, encodeURIComponent(String(value)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} treatmentId 
         * @param {string} therapistId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTreatmentResources: async (treatmentId: string, therapistId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'treatmentId' is not null or undefined
            assertParamExists('setTreatmentResources', 'treatmentId', treatmentId)
            // verify required parameter 'therapistId' is not null or undefined
            assertParamExists('setTreatmentResources', 'therapistId', therapistId)
            const localVarPath = `/appointment/treatment/setResources`
                .replace(`{${"treatmentId"}}`, encodeURIComponent(String(treatmentId)))
                .replace(`{${"therapistId"}}`, encodeURIComponent(String(therapistId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {Array<string>} requestBody 
         * @param {boolean} [enforceAmounts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swapAppointments: async (requestBody: Array<string>, enforceAmounts?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('swapAppointments', 'requestBody', requestBody)
            const localVarPath = `/appointment/swapAppointments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (enforceAmounts !== undefined) {
                localVarQueryParameter['enforceAmounts'] = enforceAmounts;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {UpdateAppointmentDto} updateAppointmentDto 
         * @param {boolean} [isContinuous] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAppointmentDatatById: async (id: string, updateAppointmentDto: UpdateAppointmentDto, isContinuous?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAppointmentDatatById', 'id', id)
            // verify required parameter 'updateAppointmentDto' is not null or undefined
            assertParamExists('updateAppointmentDatatById', 'updateAppointmentDto', updateAppointmentDto)
            const localVarPath = `/appointment/updateAppointmentData/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isContinuous !== undefined) {
                localVarQueryParameter['isContinuous'] = isContinuous;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAppointmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {UpdateCancelledAppointmentDto} updateCancelledAppointmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCancelledAppointment: async (id: string, updateCancelledAppointmentDto: UpdateCancelledAppointmentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCancelledAppointment', 'id', id)
            // verify required parameter 'updateCancelledAppointmentDto' is not null or undefined
            assertParamExists('updateCancelledAppointment', 'updateCancelledAppointmentDto', updateCancelledAppointmentDto)
            const localVarPath = `/appointment/updateCancelledAppointment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCancelledAppointmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {ContinuousAppointmentDto} continuousAppointmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContinuousAppointment: async (id: string, continuousAppointmentDto: ContinuousAppointmentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateContinuousAppointment', 'id', id)
            // verify required parameter 'continuousAppointmentDto' is not null or undefined
            assertParamExists('updateContinuousAppointment', 'continuousAppointmentDto', continuousAppointmentDto)
            const localVarPath = `/appointment/updateContinuousAppointment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(continuousAppointmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppointmentApi - functional programming interface
 * @export
 */
export const AppointmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppointmentApiAxiosParamCreator(configuration)
    return {
        /**
         * This endpoint removes an appointment from the database. It is not intended to be used for canceling a scheduled appointment. Use the cancel endpoint for that. It will only work on unscheduled appointments whose timeslots have not been scheduled. The children timeslots will also be deleted
         * @summary Remove an appointment by its ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {AdjustOutsideAppointmentsDto} adjustOutsideAppointmentsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adjustOutsideAppointments(adjustOutsideAppointmentsDto: AdjustOutsideAppointmentsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adjustOutsideAppointments(adjustOutsideAppointmentsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AppointmentDto} appointmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appointmentControllerCreate(appointmentDto: AppointmentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appointmentControllerCreate(appointmentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async backFillContinuous(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.backFillContinuous(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async canSwapAppointments(requestBody: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.canSwapAppointments(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} apptId Appointment ID
         * @param {boolean} byTherapist 
         * @param {string} [reason] Reason for cancellation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancel(apptId: string, byTherapist: boolean, reason?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancel(apptId, byTherapist, reason, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} apptId Appointment ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelContinuous(apptId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelContinuous(apptId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} slotId TimeSlot ID
         * @param {boolean} byTherapist 
         * @param {string} [reason] Reason for cancellation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelTimeSlot(slotId: string, byTherapist: boolean, reason?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelTimeSlot(slotId, byTherapist, reason, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} apptId Appointment ID
         * @param {boolean} [isContinuous] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyToNew(apptId: string, isContinuous?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.copyToNew(apptId, isContinuous, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {AppointmentTemplateDto} appointmentTemplateDto 
         * @param {boolean} [isContinuous] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createForTreatments(appointmentTemplateDto: AppointmentTemplateDto, isContinuous?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createForTreatments(appointmentTemplateDto, isContinuous, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This deletes a blocked slot for a patient based on the slot id
         * @summary Remove a blocked slot by its ID
         * @param {string} slotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBlockedSlot(slotId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBlockedSlot(slotId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint removes a continuous appointment from the database. It will also remove all the appointments that are part of the continuous appointment
         * @summary Remove a continuous appointment and its timeslots by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteContinuous(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteContinuous(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This deletes a blocked slot for a patient based on the groupId id
         * @summary Remove Patient Unavailability by its GroupID
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePatientUnavailability(groupId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePatientUnavailability(groupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async frequencyValidation(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.frequencyValidation(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} appointmentId Appointment ID
         * @param {string} date Date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async frequencyValidationForAppointment(appointmentId: string, date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.frequencyValidationForAppointment(appointmentId, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppointment(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppointment(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} date 
         * @param {boolean} weeklyworkday 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppointmentsByDate(date: string, weeklyworkday: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppointmentsByDate(date, weeklyworkday, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} patientId 
         * @param {string} [addressId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppointmentsForPatient(patientId: string, addressId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AppointmentDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppointmentsForPatient(patientId, addressId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} rxNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppointmentsForPatientWithRx(rxNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppointmentsForPatientWithRx(rxNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} rxNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppointmentsForRx(rxNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppointmentsForRx(rxNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} seriesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppointmentsForSeries(seriesId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppointmentsForSeries(seriesId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppointmentsForUser(userId: string, date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppointmentsForUser(userId, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBlockedSlotsForPatient(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TimeSlotDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBlockedSlotsForPatient(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCancelledAppointments(locationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCancelledAppointments(locationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} patientId 
         * @param {string} [addressId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContinuousAppointmentsForPatient(patientId: string, addressId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContinuousAppointmentDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContinuousAppointmentsForPatient(patientId, addressId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientUnavailabilityForPatient(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientAvailabilityDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientUnavailabilityForPatient(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} patientId 
         * @param {string} locationId 
         * @param {string} [searchFunction] 
         * @param {number} [pruningTarget] 
         * @param {number} [beta1] 
         * @param {number} [beta2] 
         * @param {Array<any>} [wishTherapists] 
         * @param {Array<string>} [appointmentIds] 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSolution(patientId: string, locationId: string, searchFunction?: string, pruningTarget?: number, beta1?: number, beta2?: number, wishTherapists?: Array<any>, appointmentIds?: Array<string>, date?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SolutionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSolution(patientId, locationId, searchFunction, pruningTarget, beta1, beta2, wishTherapists, appointmentIds, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} patientId 
         * @param {number} max 
         * @param {string} locationId 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSolutions(patientId: string, max: number, locationId: string, date?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SolutionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSolutions(patientId, max, locationId, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} max 
         * @param {string} locationId 
         * @param {AppointmentTemplateDto} appointmentTemplateDto 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSolutionsForContinuous(max: number, locationId: string, appointmentTemplateDto: AppointmentTemplateDto, date?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SolutionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSolutionsForContinuous(max, locationId, appointmentTemplateDto, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} patientId 
         * @param {string} locationId 
         * @param {string} [startDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStateForPatient(patientId: string, locationId: string, startDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StateForPatientDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStateForPatient(patientId, locationId, startDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {Array<AppointmentDto>} appointmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveAppointments(appointmentDto: Array<AppointmentDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveAppointments(appointmentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {Array<TimeSlotDto>} timeSlotDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveBlockedSlotsForPatient(patientId: number, timeSlotDto: Array<TimeSlotDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveBlockedSlotsForPatient(patientId, timeSlotDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {Array<PatientAvailabilityDto>} patientAvailabilityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async savePatientUnavailibilityForPatient(patientId: number, patientAvailabilityDto: Array<PatientAvailabilityDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.savePatientUnavailibilityForPatient(patientId, patientAvailabilityDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {SolutionDto} solutionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveSolution(solutionDto: SolutionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveSolution(solutionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} apptId 
         * @param {boolean} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setAttended(apptId: string, value: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setAttended(apptId, value, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} slotId 
         * @param {boolean} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setTimeSlotAttended(slotId: string, value: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setTimeSlotAttended(slotId, value, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} treatmentId 
         * @param {string} therapistId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setTreatmentResources(treatmentId: string, therapistId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setTreatmentResources(treatmentId, therapistId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {Array<string>} requestBody 
         * @param {boolean} [enforceAmounts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async swapAppointments(requestBody: Array<string>, enforceAmounts?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.swapAppointments(requestBody, enforceAmounts, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {UpdateAppointmentDto} updateAppointmentDto 
         * @param {boolean} [isContinuous] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAppointmentDatatById(id: string, updateAppointmentDto: UpdateAppointmentDto, isContinuous?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAppointmentDatatById(id, updateAppointmentDto, isContinuous, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {UpdateCancelledAppointmentDto} updateCancelledAppointmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCancelledAppointment(id: string, updateCancelledAppointmentDto: UpdateCancelledAppointmentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCancelledAppointment(id, updateCancelledAppointmentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {ContinuousAppointmentDto} continuousAppointmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateContinuousAppointment(id: string, continuousAppointmentDto: ContinuousAppointmentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CAResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateContinuousAppointment(id, continuousAppointmentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AppointmentApi - factory interface
 * @export
 */
export const AppointmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppointmentApiFp(configuration)
    return {
        /**
         * This endpoint removes an appointment from the database. It is not intended to be used for canceling a scheduled appointment. Use the cancel endpoint for that. It will only work on unscheduled appointments whose timeslots have not been scheduled. The children timeslots will also be deleted
         * @summary Remove an appointment by its ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp._delete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {AdjustOutsideAppointmentsDto} adjustOutsideAppointmentsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adjustOutsideAppointments(adjustOutsideAppointmentsDto: AdjustOutsideAppointmentsDto, options?: any): AxiosPromise<void> {
            return localVarFp.adjustOutsideAppointments(adjustOutsideAppointmentsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AppointmentDto} appointmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentControllerCreate(appointmentDto: AppointmentDto, options?: any): AxiosPromise<void> {
            return localVarFp.appointmentControllerCreate(appointmentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        backFillContinuous(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.backFillContinuous(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canSwapAppointments(requestBody: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.canSwapAppointments(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} apptId Appointment ID
         * @param {boolean} byTherapist 
         * @param {string} [reason] Reason for cancellation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancel(apptId: string, byTherapist: boolean, reason?: string, options?: any): AxiosPromise<void> {
            return localVarFp.cancel(apptId, byTherapist, reason, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} apptId Appointment ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelContinuous(apptId: string, options?: any): AxiosPromise<void> {
            return localVarFp.cancelContinuous(apptId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} slotId TimeSlot ID
         * @param {boolean} byTherapist 
         * @param {string} [reason] Reason for cancellation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelTimeSlot(slotId: string, byTherapist: boolean, reason?: string, options?: any): AxiosPromise<void> {
            return localVarFp.cancelTimeSlot(slotId, byTherapist, reason, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} apptId Appointment ID
         * @param {boolean} [isContinuous] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyToNew(apptId: string, isContinuous?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.copyToNew(apptId, isContinuous, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {AppointmentTemplateDto} appointmentTemplateDto 
         * @param {boolean} [isContinuous] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createForTreatments(appointmentTemplateDto: AppointmentTemplateDto, isContinuous?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.createForTreatments(appointmentTemplateDto, isContinuous, options).then((request) => request(axios, basePath));
        },
        /**
         * This deletes a blocked slot for a patient based on the slot id
         * @summary Remove a blocked slot by its ID
         * @param {string} slotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBlockedSlot(slotId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteBlockedSlot(slotId, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint removes a continuous appointment from the database. It will also remove all the appointments that are part of the continuous appointment
         * @summary Remove a continuous appointment and its timeslots by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContinuous(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteContinuous(id, options).then((request) => request(axios, basePath));
        },
        /**
         * This deletes a blocked slot for a patient based on the groupId id
         * @summary Remove Patient Unavailability by its GroupID
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePatientUnavailability(groupId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deletePatientUnavailability(groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frequencyValidation(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.frequencyValidation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} appointmentId Appointment ID
         * @param {string} date Date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frequencyValidationForAppointment(appointmentId: string, date: string, options?: any): AxiosPromise<void> {
            return localVarFp.frequencyValidationForAppointment(appointmentId, date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointment(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.getAppointment(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} date 
         * @param {boolean} weeklyworkday 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentsByDate(date: string, weeklyworkday: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.getAppointmentsByDate(date, weeklyworkday, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} patientId 
         * @param {string} [addressId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentsForPatient(patientId: string, addressId?: string, options?: any): AxiosPromise<Array<AppointmentDto>> {
            return localVarFp.getAppointmentsForPatient(patientId, addressId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} rxNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentsForPatientWithRx(rxNumber: string, options?: any): AxiosPromise<void> {
            return localVarFp.getAppointmentsForPatientWithRx(rxNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} rxNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentsForRx(rxNumber: string, options?: any): AxiosPromise<void> {
            return localVarFp.getAppointmentsForRx(rxNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} seriesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentsForSeries(seriesId: string, options?: any): AxiosPromise<void> {
            return localVarFp.getAppointmentsForSeries(seriesId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentsForUser(userId: string, date: string, options?: any): AxiosPromise<void> {
            return localVarFp.getAppointmentsForUser(userId, date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBlockedSlotsForPatient(patientId: number, options?: any): AxiosPromise<Array<TimeSlotDto>> {
            return localVarFp.getBlockedSlotsForPatient(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCancelledAppointments(locationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.getCancelledAppointments(locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} patientId 
         * @param {string} [addressId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContinuousAppointmentsForPatient(patientId: string, addressId?: string, options?: any): AxiosPromise<Array<ContinuousAppointmentDto>> {
            return localVarFp.getContinuousAppointmentsForPatient(patientId, addressId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientUnavailabilityForPatient(patientId: number, options?: any): AxiosPromise<Array<PatientAvailabilityDto>> {
            return localVarFp.getPatientUnavailabilityForPatient(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} patientId 
         * @param {string} locationId 
         * @param {string} [searchFunction] 
         * @param {number} [pruningTarget] 
         * @param {number} [beta1] 
         * @param {number} [beta2] 
         * @param {Array<any>} [wishTherapists] 
         * @param {Array<string>} [appointmentIds] 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSolution(patientId: string, locationId: string, searchFunction?: string, pruningTarget?: number, beta1?: number, beta2?: number, wishTherapists?: Array<any>, appointmentIds?: Array<string>, date?: string, options?: any): AxiosPromise<SolutionDto> {
            return localVarFp.getSolution(patientId, locationId, searchFunction, pruningTarget, beta1, beta2, wishTherapists, appointmentIds, date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} patientId 
         * @param {number} max 
         * @param {string} locationId 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSolutions(patientId: string, max: number, locationId: string, date?: string, options?: any): AxiosPromise<Array<SolutionDto>> {
            return localVarFp.getSolutions(patientId, max, locationId, date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} max 
         * @param {string} locationId 
         * @param {AppointmentTemplateDto} appointmentTemplateDto 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSolutionsForContinuous(max: number, locationId: string, appointmentTemplateDto: AppointmentTemplateDto, date?: string, options?: any): AxiosPromise<Array<SolutionDto>> {
            return localVarFp.getSolutionsForContinuous(max, locationId, appointmentTemplateDto, date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} patientId 
         * @param {string} locationId 
         * @param {string} [startDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStateForPatient(patientId: string, locationId: string, startDate?: string, options?: any): AxiosPromise<StateForPatientDto> {
            return localVarFp.getStateForPatient(patientId, locationId, startDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {Array<AppointmentDto>} appointmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAppointments(appointmentDto: Array<AppointmentDto>, options?: any): AxiosPromise<void> {
            return localVarFp.saveAppointments(appointmentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {Array<TimeSlotDto>} timeSlotDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveBlockedSlotsForPatient(patientId: number, timeSlotDto: Array<TimeSlotDto>, options?: any): AxiosPromise<void> {
            return localVarFp.saveBlockedSlotsForPatient(patientId, timeSlotDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {Array<PatientAvailabilityDto>} patientAvailabilityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePatientUnavailibilityForPatient(patientId: number, patientAvailabilityDto: Array<PatientAvailabilityDto>, options?: any): AxiosPromise<void> {
            return localVarFp.savePatientUnavailibilityForPatient(patientId, patientAvailabilityDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {SolutionDto} solutionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSolution(solutionDto: SolutionDto, options?: any): AxiosPromise<void> {
            return localVarFp.saveSolution(solutionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} apptId 
         * @param {boolean} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAttended(apptId: string, value: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.setAttended(apptId, value, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} slotId 
         * @param {boolean} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTimeSlotAttended(slotId: string, value: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.setTimeSlotAttended(slotId, value, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} treatmentId 
         * @param {string} therapistId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTreatmentResources(treatmentId: string, therapistId: string, options?: any): AxiosPromise<void> {
            return localVarFp.setTreatmentResources(treatmentId, therapistId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {Array<string>} requestBody 
         * @param {boolean} [enforceAmounts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swapAppointments(requestBody: Array<string>, enforceAmounts?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.swapAppointments(requestBody, enforceAmounts, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {UpdateAppointmentDto} updateAppointmentDto 
         * @param {boolean} [isContinuous] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAppointmentDatatById(id: string, updateAppointmentDto: UpdateAppointmentDto, isContinuous?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.updateAppointmentDatatById(id, updateAppointmentDto, isContinuous, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {UpdateCancelledAppointmentDto} updateCancelledAppointmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCancelledAppointment(id: string, updateCancelledAppointmentDto: UpdateCancelledAppointmentDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateCancelledAppointment(id, updateCancelledAppointmentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {ContinuousAppointmentDto} continuousAppointmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContinuousAppointment(id: string, continuousAppointmentDto: ContinuousAppointmentDto, options?: any): AxiosPromise<CAResponse> {
            return localVarFp.updateContinuousAppointment(id, continuousAppointmentDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppointmentApi - object-oriented interface
 * @export
 * @class AppointmentApi
 * @extends {BaseAPI}
 */
export class AppointmentApi extends BaseAPI {
    /**
     * This endpoint removes an appointment from the database. It is not intended to be used for canceling a scheduled appointment. Use the cancel endpoint for that. It will only work on unscheduled appointments whose timeslots have not been scheduled. The children timeslots will also be deleted
     * @summary Remove an appointment by its ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public _delete(id: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration)._delete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {AdjustOutsideAppointmentsDto} adjustOutsideAppointmentsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public adjustOutsideAppointments(adjustOutsideAppointmentsDto: AdjustOutsideAppointmentsDto, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).adjustOutsideAppointments(adjustOutsideAppointmentsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AppointmentDto} appointmentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public appointmentControllerCreate(appointmentDto: AppointmentDto, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).appointmentControllerCreate(appointmentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public backFillContinuous(id: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).backFillContinuous(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public canSwapAppointments(requestBody: Array<string>, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).canSwapAppointments(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} apptId Appointment ID
     * @param {boolean} byTherapist 
     * @param {string} [reason] Reason for cancellation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public cancel(apptId: string, byTherapist: boolean, reason?: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).cancel(apptId, byTherapist, reason, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} apptId Appointment ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public cancelContinuous(apptId: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).cancelContinuous(apptId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} slotId TimeSlot ID
     * @param {boolean} byTherapist 
     * @param {string} [reason] Reason for cancellation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public cancelTimeSlot(slotId: string, byTherapist: boolean, reason?: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).cancelTimeSlot(slotId, byTherapist, reason, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} apptId Appointment ID
     * @param {boolean} [isContinuous] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public copyToNew(apptId: string, isContinuous?: boolean, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).copyToNew(apptId, isContinuous, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {AppointmentTemplateDto} appointmentTemplateDto 
     * @param {boolean} [isContinuous] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public createForTreatments(appointmentTemplateDto: AppointmentTemplateDto, isContinuous?: boolean, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).createForTreatments(appointmentTemplateDto, isContinuous, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This deletes a blocked slot for a patient based on the slot id
     * @summary Remove a blocked slot by its ID
     * @param {string} slotId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public deleteBlockedSlot(slotId: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).deleteBlockedSlot(slotId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint removes a continuous appointment from the database. It will also remove all the appointments that are part of the continuous appointment
     * @summary Remove a continuous appointment and its timeslots by ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public deleteContinuous(id: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).deleteContinuous(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This deletes a blocked slot for a patient based on the groupId id
     * @summary Remove Patient Unavailability by its GroupID
     * @param {string} groupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public deletePatientUnavailability(groupId: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).deletePatientUnavailability(groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public frequencyValidation(id: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).frequencyValidation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} appointmentId Appointment ID
     * @param {string} date Date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public frequencyValidationForAppointment(appointmentId: string, date: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).frequencyValidationForAppointment(appointmentId, date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public getAppointment(id: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).getAppointment(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} date 
     * @param {boolean} weeklyworkday 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public getAppointmentsByDate(date: string, weeklyworkday: boolean, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).getAppointmentsByDate(date, weeklyworkday, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} patientId 
     * @param {string} [addressId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public getAppointmentsForPatient(patientId: string, addressId?: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).getAppointmentsForPatient(patientId, addressId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} rxNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public getAppointmentsForPatientWithRx(rxNumber: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).getAppointmentsForPatientWithRx(rxNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} rxNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public getAppointmentsForRx(rxNumber: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).getAppointmentsForRx(rxNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} seriesId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public getAppointmentsForSeries(seriesId: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).getAppointmentsForSeries(seriesId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} userId 
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public getAppointmentsForUser(userId: string, date: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).getAppointmentsForUser(userId, date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public getBlockedSlotsForPatient(patientId: number, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).getBlockedSlotsForPatient(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} locationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public getCancelledAppointments(locationId: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).getCancelledAppointments(locationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} patientId 
     * @param {string} [addressId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public getContinuousAppointmentsForPatient(patientId: string, addressId?: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).getContinuousAppointmentsForPatient(patientId, addressId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public getPatientUnavailabilityForPatient(patientId: number, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).getPatientUnavailabilityForPatient(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} patientId 
     * @param {string} locationId 
     * @param {string} [searchFunction] 
     * @param {number} [pruningTarget] 
     * @param {number} [beta1] 
     * @param {number} [beta2] 
     * @param {Array<any>} [wishTherapists] 
     * @param {Array<string>} [appointmentIds] 
     * @param {string} [date] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public getSolution(patientId: string, locationId: string, searchFunction?: string, pruningTarget?: number, beta1?: number, beta2?: number, wishTherapists?: Array<any>, appointmentIds?: Array<string>, date?: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).getSolution(patientId, locationId, searchFunction, pruningTarget, beta1, beta2, wishTherapists, appointmentIds, date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} patientId 
     * @param {number} max 
     * @param {string} locationId 
     * @param {string} [date] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public getSolutions(patientId: string, max: number, locationId: string, date?: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).getSolutions(patientId, max, locationId, date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} max 
     * @param {string} locationId 
     * @param {AppointmentTemplateDto} appointmentTemplateDto 
     * @param {string} [date] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public getSolutionsForContinuous(max: number, locationId: string, appointmentTemplateDto: AppointmentTemplateDto, date?: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).getSolutionsForContinuous(max, locationId, appointmentTemplateDto, date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} patientId 
     * @param {string} locationId 
     * @param {string} [startDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public getStateForPatient(patientId: string, locationId: string, startDate?: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).getStateForPatient(patientId, locationId, startDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {Array<AppointmentDto>} appointmentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public saveAppointments(appointmentDto: Array<AppointmentDto>, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).saveAppointments(appointmentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} patientId 
     * @param {Array<TimeSlotDto>} timeSlotDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public saveBlockedSlotsForPatient(patientId: number, timeSlotDto: Array<TimeSlotDto>, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).saveBlockedSlotsForPatient(patientId, timeSlotDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} patientId 
     * @param {Array<PatientAvailabilityDto>} patientAvailabilityDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public savePatientUnavailibilityForPatient(patientId: number, patientAvailabilityDto: Array<PatientAvailabilityDto>, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).savePatientUnavailibilityForPatient(patientId, patientAvailabilityDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {SolutionDto} solutionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public saveSolution(solutionDto: SolutionDto, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).saveSolution(solutionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} apptId 
     * @param {boolean} value 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public setAttended(apptId: string, value: boolean, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).setAttended(apptId, value, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} slotId 
     * @param {boolean} value 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public setTimeSlotAttended(slotId: string, value: boolean, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).setTimeSlotAttended(slotId, value, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} treatmentId 
     * @param {string} therapistId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public setTreatmentResources(treatmentId: string, therapistId: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).setTreatmentResources(treatmentId, therapistId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {Array<string>} requestBody 
     * @param {boolean} [enforceAmounts] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public swapAppointments(requestBody: Array<string>, enforceAmounts?: boolean, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).swapAppointments(requestBody, enforceAmounts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {UpdateAppointmentDto} updateAppointmentDto 
     * @param {boolean} [isContinuous] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public updateAppointmentDatatById(id: string, updateAppointmentDto: UpdateAppointmentDto, isContinuous?: boolean, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).updateAppointmentDatatById(id, updateAppointmentDto, isContinuous, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {UpdateCancelledAppointmentDto} updateCancelledAppointmentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public updateCancelledAppointment(id: string, updateCancelledAppointmentDto: UpdateCancelledAppointmentDto, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).updateCancelledAppointment(id, updateCancelledAppointmentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {ContinuousAppointmentDto} continuousAppointmentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public updateContinuousAppointment(id: string, continuousAppointmentDto: ContinuousAppointmentDto, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).updateContinuousAppointment(id, continuousAppointmentDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {UserDto} userDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogin: async (userDto: UserDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userDto' is not null or undefined
            assertParamExists('authControllerLogin', 'userDto', userDto)
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {UserDto} userDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerLogin(userDto: UserDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerLogin(userDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @param {UserDto} userDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogin(userDto: UserDto, options?: any): AxiosPromise<TokenDto> {
            return localVarFp.authControllerLogin(userDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerUser(options?: any): AxiosPromise<void> {
            return localVarFp.authControllerUser(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @param {UserDto} userDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerLogin(userDto: UserDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerLogin(userDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerUser(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerUser(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BiApi - axios parameter creator
 * @export
 */
export const BiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doDaily: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bi/daily`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} [locationId] 
         * @param {number} [radius] 
         * @param {string} [centerDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGapReport: async (locationId?: string, radius?: number, centerDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bi/gapReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (locationId !== undefined) {
                localVarQueryParameter['locationId'] = locationId;
            }

            if (radius !== undefined) {
                localVarQueryParameter['radius'] = radius;
            }

            if (centerDate !== undefined) {
                localVarQueryParameter['centerDate'] = (centerDate as any instanceof Date) ?
                    (centerDate as any).toISOString() :
                    centerDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} startDate 
         * @param {string} [locationId] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalGaps: async (startDate: string, locationId?: string, endDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getTotalGaps', 'startDate', startDate)
            const localVarPath = `/bi/totalGaps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (locationId !== undefined) {
                localVarQueryParameter['locationId'] = locationId;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BiApi - functional programming interface
 * @export
 */
export const BiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async doDaily(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.doDaily(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} [locationId] 
         * @param {number} [radius] 
         * @param {string} [centerDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGapReport(locationId?: string, radius?: number, centerDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGapReport(locationId, radius, centerDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} startDate 
         * @param {string} [locationId] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTotalGaps(startDate: string, locationId?: string, endDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalGaps(startDate, locationId, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BiApi - factory interface
 * @export
 */
export const BiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BiApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doDaily(options?: any): AxiosPromise<void> {
            return localVarFp.doDaily(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} [locationId] 
         * @param {number} [radius] 
         * @param {string} [centerDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGapReport(locationId?: string, radius?: number, centerDate?: string, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.getGapReport(locationId, radius, centerDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} startDate 
         * @param {string} [locationId] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalGaps(startDate: string, locationId?: string, endDate?: string, options?: any): AxiosPromise<number> {
            return localVarFp.getTotalGaps(startDate, locationId, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BiApi - object-oriented interface
 * @export
 * @class BiApi
 * @extends {BaseAPI}
 */
export class BiApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BiApi
     */
    public doDaily(options?: AxiosRequestConfig) {
        return BiApiFp(this.configuration).doDaily(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} [locationId] 
     * @param {number} [radius] 
     * @param {string} [centerDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BiApi
     */
    public getGapReport(locationId?: string, radius?: number, centerDate?: string, options?: AxiosRequestConfig) {
        return BiApiFp(this.configuration).getGapReport(locationId, radius, centerDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} startDate 
     * @param {string} [locationId] 
     * @param {string} [endDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BiApi
     */
    public getTotalGaps(startDate: string, locationId?: string, endDate?: string, options?: AxiosRequestConfig) {
        return BiApiFp(this.configuration).getTotalGaps(startDate, locationId, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CalendarApi - axios parameter creator
 * @export
 */
export const CalendarApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This endpoint removes a continuous timeslot from the database
         * @summary Remove a continuous timeslot by its ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContinuousTimeSlot: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteContinuousTimeSlot', 'id', id)
            const localVarPath = `/calendar/deleteContinuousTimeSlot/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint removes a timeslot from the database
         * @summary Remove a timeslot by its ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTimeslot: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTimeslot', 'id', id)
            const localVarPath = `/calendar/deleteTimeslot/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentForCalendar: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAppointmentForCalendar', 'id', id)
            const localVarPath = `/calendar/appointment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} date 
         * @param {string} locationId 
         * @param {boolean} weeklyworkday 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentsByDateAndLocationForCalendar: async (date: string, locationId: string, weeklyworkday: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getAppointmentsByDateAndLocationForCalendar', 'date', date)
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('getAppointmentsByDateAndLocationForCalendar', 'locationId', locationId)
            // verify required parameter 'weeklyworkday' is not null or undefined
            assertParamExists('getAppointmentsByDateAndLocationForCalendar', 'weeklyworkday', weeklyworkday)
            const localVarPath = `/calendar/date/{date}/{locationId}`
                .replace(`{${"date"}}`, encodeURIComponent(String(date)))
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (weeklyworkday !== undefined) {
                localVarQueryParameter['weeklyworkday'] = weeklyworkday;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContinuousAppointment: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getContinuousAppointment', 'id', id)
            const localVarPath = `/calendar/continuous/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContinuousAppointmentsByLocation: async (locationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('getContinuousAppointmentsByLocation', 'locationId', locationId)
            const localVarPath = `/calendar/continuous/location/{locationId}`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContinuousAppointmentsForPatientCalendar: async (patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getContinuousAppointmentsForPatientCalendar', 'patientId', patientId)
            const localVarPath = `/calendar/continuous/patient/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContinuousAppointmentsForUserCalendar: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getContinuousAppointmentsForUserCalendar', 'userId', userId)
            const localVarPath = `/calendar/continuous/user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} seconds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestUpdatedAppointments: async (seconds: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'seconds' is not null or undefined
            assertParamExists('getLatestUpdatedAppointments', 'seconds', seconds)
            const localVarPath = `/calendar/getLatestUpdatedAppointments/{seconds}`
                .replace(`{${"seconds"}}`, encodeURIComponent(String(seconds)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNextAppointmentsByUserId: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getNextAppointmentsByUserId', 'userId', userId)
            const localVarPath = `/calendar/userNextAppointments/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientUnavailabilityForCalendar: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getPatientUnavailabilityForCalendar', 'patientId', patientId)
            const localVarPath = `/calendar/availability/getPatientUnavailability{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientUnavailabilityTypeByDateForCalendar: async (patientId: number, date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getPatientUnavailabilityTypeByDateForCalendar', 'patientId', patientId)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getPatientUnavailabilityTypeByDateForCalendar', 'date', date)
            const localVarPath = `/calendar/availability/getPatientUnavailabeTypeByDate/{patientId}/{date}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)))
                .replace(`{${"date"}}`, encodeURIComponent(String(date)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientsByAppointmentDateForCalendar: async (date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getPatientsByAppointmentDateForCalendar', 'date', date)
            const localVarPath = `/calendar/patient/{date}`
                .replace(`{${"date"}}`, encodeURIComponent(String(date)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} timeSlotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRxForTimeSlot: async (timeSlotId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'timeSlotId' is not null or undefined
            assertParamExists('getRxForTimeSlot', 'timeSlotId', timeSlotId)
            const localVarPath = `/calendar/timeslot/getRx/{timeSlotId}`
                .replace(`{${"timeSlotId"}}`, encodeURIComponent(String(timeSlotId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnscheduledAppointmentsForPatient: async (patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getUnscheduledAppointmentsForPatient', 'patientId', patientId)
            const localVarPath = `/calendar/patientAppointment/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnscheduledContinuousAppointmentsForPatient: async (patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getUnscheduledContinuousAppointmentsForPatient', 'patientId', patientId)
            const localVarPath = `/calendar/patientContinuousAppointment/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnscheduledTimeslotsForPatient: async (patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getUnscheduledTimeslotsForPatient', 'patientId', patientId)
            const localVarPath = `/calendar/patientTimeSlot/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAbsences: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserAbsences', 'userId', userId)
            const localVarPath = `/calendar/userAbsences/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersByLocationForCalendar: async (locationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('getUsersByLocationForCalendar', 'locationId', locationId)
            const localVarPath = `/calendar/user/{locationId}`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {ContinuousEventTimeSlotDto} continuousEventTimeSlotDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postContinuousEventTimeslot: async (continuousEventTimeSlotDto: ContinuousEventTimeSlotDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'continuousEventTimeSlotDto' is not null or undefined
            assertParamExists('postContinuousEventTimeslot', 'continuousEventTimeSlotDto', continuousEventTimeSlotDto)
            const localVarPath = `/calendar/createContinuousEventTimeSlot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(continuousEventTimeSlotDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {EventTimeSlotDto} eventTimeSlotDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEventTimeslot: async (eventTimeSlotDto: EventTimeSlotDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventTimeSlotDto' is not null or undefined
            assertParamExists('postEventTimeslot', 'eventTimeSlotDto', eventTimeSlotDto)
            const localVarPath = `/calendar/createEventTimeSlot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eventTimeSlotDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {LeaveOfAbsenceDto} leaveOfAbsenceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLeaveOfAbsence: async (leaveOfAbsenceDto: LeaveOfAbsenceDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'leaveOfAbsenceDto' is not null or undefined
            assertParamExists('postLeaveOfAbsence', 'leaveOfAbsenceDto', leaveOfAbsenceDto)
            const localVarPath = `/calendar/createLeaveOfAbsence`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(leaveOfAbsenceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {TimeSlotDto} timeSlotDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTimeslot: async (timeSlotDto: TimeSlotDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'timeSlotDto' is not null or undefined
            assertParamExists('postTimeslot', 'timeSlotDto', timeSlotDto)
            const localVarPath = `/calendar/createTimeslot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(timeSlotDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {AppointmentDto} appointmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAppointment: async (id: string, appointmentDto: AppointmentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAppointment', 'id', id)
            // verify required parameter 'appointmentDto' is not null or undefined
            assertParamExists('updateAppointment', 'appointmentDto', appointmentDto)
            const localVarPath = `/calendar/updateAppointment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appointmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {TimeSlotDto} timeSlotDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContinuousTimeSlot: async (id: string, timeSlotDto: TimeSlotDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateContinuousTimeSlot', 'id', id)
            // verify required parameter 'timeSlotDto' is not null or undefined
            assertParamExists('updateContinuousTimeSlot', 'timeSlotDto', timeSlotDto)
            const localVarPath = `/calendar/updateContinuousTimeSlot/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(timeSlotDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {TimeSlotDto} timeSlotDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTimeSlot: async (id: string, timeSlotDto: TimeSlotDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTimeSlot', 'id', id)
            // verify required parameter 'timeSlotDto' is not null or undefined
            assertParamExists('updateTimeSlot', 'timeSlotDto', timeSlotDto)
            const localVarPath = `/calendar/updateTimeSlot/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(timeSlotDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This will create an absence time slot for the user, as well as cancel all the affected appointments
         * @summary 
         * @param {string} userId 
         * @param {LeaveOfAbsenceDto} leaveOfAbsenceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAbsence: async (userId: string, leaveOfAbsenceDto: LeaveOfAbsenceDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userAbsence', 'userId', userId)
            // verify required parameter 'leaveOfAbsenceDto' is not null or undefined
            assertParamExists('userAbsence', 'leaveOfAbsenceDto', leaveOfAbsenceDto)
            const localVarPath = `/calendar/userAbsence/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(leaveOfAbsenceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CalendarApi - functional programming interface
 * @export
 */
export const CalendarApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CalendarApiAxiosParamCreator(configuration)
    return {
        /**
         * This endpoint removes a continuous timeslot from the database
         * @summary Remove a continuous timeslot by its ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteContinuousTimeSlot(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteContinuousTimeSlot(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint removes a timeslot from the database
         * @summary Remove a timeslot by its ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTimeslot(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTimeslot(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppointmentForCalendar(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppointmentForCalendar(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} date 
         * @param {string} locationId 
         * @param {boolean} weeklyworkday 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppointmentsByDateAndLocationForCalendar(date: string, locationId: string, weeklyworkday: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppointmentsByDateAndLocationForCalendar(date, locationId, weeklyworkday, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContinuousAppointment(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContinuousAppointmentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContinuousAppointment(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContinuousAppointmentsByLocation(locationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContinuousAppointmentDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContinuousAppointmentsByLocation(locationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContinuousAppointmentsForPatientCalendar(patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContinuousAppointmentDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContinuousAppointmentsForPatientCalendar(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContinuousAppointmentsForUserCalendar(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContinuousAppointmentDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContinuousAppointmentsForUserCalendar(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} seconds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLatestUpdatedAppointments(seconds: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLatestUpdatedAppointments(seconds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNextAppointmentsByUserId(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNextAppointmentsByUserId(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientUnavailabilityForCalendar(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientAvailabilityDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientUnavailabilityForCalendar(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientUnavailabilityTypeByDateForCalendar(patientId: number, date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientUnavailabilityTypeByDateForCalendar(patientId, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientsByAppointmentDateForCalendar(date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientsByAppointmentDateForCalendar(date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} timeSlotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRxForTimeSlot(timeSlotId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRxForTimeSlot(timeSlotId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnscheduledAppointmentsForPatient(patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnscheduledAppointmentsForPatient(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnscheduledContinuousAppointmentsForPatient(patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnscheduledContinuousAppointmentsForPatient(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnscheduledTimeslotsForPatient(patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnscheduledTimeslotsForPatient(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserAbsences(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserAbsences(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersByLocationForCalendar(locationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersByLocationForCalendar(locationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {ContinuousEventTimeSlotDto} continuousEventTimeSlotDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postContinuousEventTimeslot(continuousEventTimeSlotDto: ContinuousEventTimeSlotDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContinuousEventTimeSlotDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postContinuousEventTimeslot(continuousEventTimeSlotDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {EventTimeSlotDto} eventTimeSlotDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postEventTimeslot(eventTimeSlotDto: EventTimeSlotDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventTimeSlotDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postEventTimeslot(eventTimeSlotDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {LeaveOfAbsenceDto} leaveOfAbsenceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postLeaveOfAbsence(leaveOfAbsenceDto: LeaveOfAbsenceDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeaveOfAbsenceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postLeaveOfAbsence(leaveOfAbsenceDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {TimeSlotDto} timeSlotDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postTimeslot(timeSlotDto: TimeSlotDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeSlotDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postTimeslot(timeSlotDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {AppointmentDto} appointmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAppointment(id: string, appointmentDto: AppointmentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAppointment(id, appointmentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {TimeSlotDto} timeSlotDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateContinuousTimeSlot(id: string, timeSlotDto: TimeSlotDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateContinuousTimeSlot(id, timeSlotDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {TimeSlotDto} timeSlotDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTimeSlot(id: string, timeSlotDto: TimeSlotDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTimeSlot(id, timeSlotDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This will create an absence time slot for the user, as well as cancel all the affected appointments
         * @summary 
         * @param {string} userId 
         * @param {LeaveOfAbsenceDto} leaveOfAbsenceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userAbsence(userId: string, leaveOfAbsenceDto: LeaveOfAbsenceDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userAbsence(userId, leaveOfAbsenceDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CalendarApi - factory interface
 * @export
 */
export const CalendarApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CalendarApiFp(configuration)
    return {
        /**
         * This endpoint removes a continuous timeslot from the database
         * @summary Remove a continuous timeslot by its ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContinuousTimeSlot(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteContinuousTimeSlot(id, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint removes a timeslot from the database
         * @summary Remove a timeslot by its ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTimeslot(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTimeslot(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentForCalendar(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.getAppointmentForCalendar(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} date 
         * @param {string} locationId 
         * @param {boolean} weeklyworkday 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentsByDateAndLocationForCalendar(date: string, locationId: string, weeklyworkday: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.getAppointmentsByDateAndLocationForCalendar(date, locationId, weeklyworkday, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContinuousAppointment(id: string, options?: any): AxiosPromise<ContinuousAppointmentDto> {
            return localVarFp.getContinuousAppointment(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContinuousAppointmentsByLocation(locationId: string, options?: any): AxiosPromise<Array<ContinuousAppointmentDto>> {
            return localVarFp.getContinuousAppointmentsByLocation(locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContinuousAppointmentsForPatientCalendar(patientId: string, options?: any): AxiosPromise<Array<ContinuousAppointmentDto>> {
            return localVarFp.getContinuousAppointmentsForPatientCalendar(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContinuousAppointmentsForUserCalendar(userId: string, options?: any): AxiosPromise<Array<ContinuousAppointmentDto>> {
            return localVarFp.getContinuousAppointmentsForUserCalendar(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} seconds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestUpdatedAppointments(seconds: number, options?: any): AxiosPromise<void> {
            return localVarFp.getLatestUpdatedAppointments(seconds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNextAppointmentsByUserId(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.getNextAppointmentsByUserId(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientUnavailabilityForCalendar(patientId: number, options?: any): AxiosPromise<Array<PatientAvailabilityDto>> {
            return localVarFp.getPatientUnavailabilityForCalendar(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientUnavailabilityTypeByDateForCalendar(patientId: number, date: string, options?: any): AxiosPromise<void> {
            return localVarFp.getPatientUnavailabilityTypeByDateForCalendar(patientId, date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientsByAppointmentDateForCalendar(date: string, options?: any): AxiosPromise<void> {
            return localVarFp.getPatientsByAppointmentDateForCalendar(date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} timeSlotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRxForTimeSlot(timeSlotId: string, options?: any): AxiosPromise<void> {
            return localVarFp.getRxForTimeSlot(timeSlotId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnscheduledAppointmentsForPatient(patientId: string, options?: any): AxiosPromise<void> {
            return localVarFp.getUnscheduledAppointmentsForPatient(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnscheduledContinuousAppointmentsForPatient(patientId: string, options?: any): AxiosPromise<void> {
            return localVarFp.getUnscheduledContinuousAppointmentsForPatient(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnscheduledTimeslotsForPatient(patientId: string, options?: any): AxiosPromise<void> {
            return localVarFp.getUnscheduledTimeslotsForPatient(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAbsences(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.getUserAbsences(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersByLocationForCalendar(locationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.getUsersByLocationForCalendar(locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {ContinuousEventTimeSlotDto} continuousEventTimeSlotDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postContinuousEventTimeslot(continuousEventTimeSlotDto: ContinuousEventTimeSlotDto, options?: any): AxiosPromise<ContinuousEventTimeSlotDto> {
            return localVarFp.postContinuousEventTimeslot(continuousEventTimeSlotDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {EventTimeSlotDto} eventTimeSlotDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEventTimeslot(eventTimeSlotDto: EventTimeSlotDto, options?: any): AxiosPromise<EventTimeSlotDto> {
            return localVarFp.postEventTimeslot(eventTimeSlotDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {LeaveOfAbsenceDto} leaveOfAbsenceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLeaveOfAbsence(leaveOfAbsenceDto: LeaveOfAbsenceDto, options?: any): AxiosPromise<LeaveOfAbsenceDto> {
            return localVarFp.postLeaveOfAbsence(leaveOfAbsenceDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {TimeSlotDto} timeSlotDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTimeslot(timeSlotDto: TimeSlotDto, options?: any): AxiosPromise<TimeSlotDto> {
            return localVarFp.postTimeslot(timeSlotDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {AppointmentDto} appointmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAppointment(id: string, appointmentDto: AppointmentDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateAppointment(id, appointmentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {TimeSlotDto} timeSlotDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContinuousTimeSlot(id: string, timeSlotDto: TimeSlotDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateContinuousTimeSlot(id, timeSlotDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {TimeSlotDto} timeSlotDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTimeSlot(id: string, timeSlotDto: TimeSlotDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateTimeSlot(id, timeSlotDto, options).then((request) => request(axios, basePath));
        },
        /**
         * This will create an absence time slot for the user, as well as cancel all the affected appointments
         * @summary 
         * @param {string} userId 
         * @param {LeaveOfAbsenceDto} leaveOfAbsenceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAbsence(userId: string, leaveOfAbsenceDto: LeaveOfAbsenceDto, options?: any): AxiosPromise<void> {
            return localVarFp.userAbsence(userId, leaveOfAbsenceDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CalendarApi - object-oriented interface
 * @export
 * @class CalendarApi
 * @extends {BaseAPI}
 */
export class CalendarApi extends BaseAPI {
    /**
     * This endpoint removes a continuous timeslot from the database
     * @summary Remove a continuous timeslot by its ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public deleteContinuousTimeSlot(id: string, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).deleteContinuousTimeSlot(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint removes a timeslot from the database
     * @summary Remove a timeslot by its ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public deleteTimeslot(id: string, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).deleteTimeslot(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getAppointmentForCalendar(id: string, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).getAppointmentForCalendar(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} date 
     * @param {string} locationId 
     * @param {boolean} weeklyworkday 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getAppointmentsByDateAndLocationForCalendar(date: string, locationId: string, weeklyworkday: boolean, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).getAppointmentsByDateAndLocationForCalendar(date, locationId, weeklyworkday, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getContinuousAppointment(id: string, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).getContinuousAppointment(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} locationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getContinuousAppointmentsByLocation(locationId: string, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).getContinuousAppointmentsByLocation(locationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getContinuousAppointmentsForPatientCalendar(patientId: string, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).getContinuousAppointmentsForPatientCalendar(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getContinuousAppointmentsForUserCalendar(userId: string, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).getContinuousAppointmentsForUserCalendar(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} seconds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getLatestUpdatedAppointments(seconds: number, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).getLatestUpdatedAppointments(seconds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getNextAppointmentsByUserId(userId: string, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).getNextAppointmentsByUserId(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getPatientUnavailabilityForCalendar(patientId: number, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).getPatientUnavailabilityForCalendar(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} patientId 
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getPatientUnavailabilityTypeByDateForCalendar(patientId: number, date: string, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).getPatientUnavailabilityTypeByDateForCalendar(patientId, date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getPatientsByAppointmentDateForCalendar(date: string, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).getPatientsByAppointmentDateForCalendar(date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} timeSlotId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getRxForTimeSlot(timeSlotId: string, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).getRxForTimeSlot(timeSlotId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getUnscheduledAppointmentsForPatient(patientId: string, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).getUnscheduledAppointmentsForPatient(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getUnscheduledContinuousAppointmentsForPatient(patientId: string, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).getUnscheduledContinuousAppointmentsForPatient(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getUnscheduledTimeslotsForPatient(patientId: string, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).getUnscheduledTimeslotsForPatient(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getUserAbsences(userId: string, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).getUserAbsences(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} locationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getUsersByLocationForCalendar(locationId: string, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).getUsersByLocationForCalendar(locationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {ContinuousEventTimeSlotDto} continuousEventTimeSlotDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public postContinuousEventTimeslot(continuousEventTimeSlotDto: ContinuousEventTimeSlotDto, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).postContinuousEventTimeslot(continuousEventTimeSlotDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {EventTimeSlotDto} eventTimeSlotDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public postEventTimeslot(eventTimeSlotDto: EventTimeSlotDto, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).postEventTimeslot(eventTimeSlotDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {LeaveOfAbsenceDto} leaveOfAbsenceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public postLeaveOfAbsence(leaveOfAbsenceDto: LeaveOfAbsenceDto, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).postLeaveOfAbsence(leaveOfAbsenceDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {TimeSlotDto} timeSlotDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public postTimeslot(timeSlotDto: TimeSlotDto, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).postTimeslot(timeSlotDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {AppointmentDto} appointmentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public updateAppointment(id: string, appointmentDto: AppointmentDto, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).updateAppointment(id, appointmentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {TimeSlotDto} timeSlotDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public updateContinuousTimeSlot(id: string, timeSlotDto: TimeSlotDto, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).updateContinuousTimeSlot(id, timeSlotDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {TimeSlotDto} timeSlotDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public updateTimeSlot(id: string, timeSlotDto: TimeSlotDto, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).updateTimeSlot(id, timeSlotDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This will create an absence time slot for the user, as well as cancel all the affected appointments
     * @summary 
     * @param {string} userId 
     * @param {LeaveOfAbsenceDto} leaveOfAbsenceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public userAbsence(userId: string, leaveOfAbsenceDto: LeaveOfAbsenceDto, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).userAbsence(userId, leaveOfAbsenceDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DoctorApi - axios parameter creator
 * @export
 */
export const DoctorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {CreateDoctorDto} createDoctorDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUpdateDoctor: async (createDoctorDto: CreateDoctorDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDoctorDto' is not null or undefined
            assertParamExists('addUpdateDoctor', 'createDoctorDto', createDoctorDto)
            const localVarPath = `/doctor`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDoctorDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Provide either one parameter or none. If no parameters are provided, all doctors are returned
         * @summary fetch Doctors using various alternative methods
         * @param {string} [id] 
         * @param {string} [doctorNumber] 
         * @param {string} [clinicNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doctorControllerGetDoctors: async (id?: string, doctorNumber?: string, clinicNumber?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/doctor`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (doctorNumber !== undefined) {
                localVarQueryParameter['doctorNumber'] = doctorNumber;
            }

            if (clinicNumber !== undefined) {
                localVarQueryParameter['clinicNumber'] = clinicNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This operation is not yet implemented.
         * @summary delete by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doctorControllerRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('doctorControllerRemove', 'id', id)
            const localVarPath = `/doctor/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a doctor
         * @param {string} id 
         * @param {UpdateDoctorDto} updateDoctorDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doctorControllerUpdate: async (id: string, updateDoctorDto: UpdateDoctorDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('doctorControllerUpdate', 'id', id)
            // verify required parameter 'updateDoctorDto' is not null or undefined
            assertParamExists('doctorControllerUpdate', 'updateDoctorDto', updateDoctorDto)
            const localVarPath = `/doctor/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDoctorDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDOctorList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/doctor/getAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {SearchDoctorDto} searchDoctorDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchDoctor: async (searchDoctorDto: SearchDoctorDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchDoctorDto' is not null or undefined
            assertParamExists('searchDoctor', 'searchDoctorDto', searchDoctorDto)
            const localVarPath = `/doctor/searchDoctor`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchDoctorDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DoctorApi - functional programming interface
 * @export
 */
export const DoctorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DoctorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {CreateDoctorDto} createDoctorDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addUpdateDoctor(createDoctorDto: CreateDoctorDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addUpdateDoctor(createDoctorDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Provide either one parameter or none. If no parameters are provided, all doctors are returned
         * @summary fetch Doctors using various alternative methods
         * @param {string} [id] 
         * @param {string} [doctorNumber] 
         * @param {string} [clinicNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async doctorControllerGetDoctors(id?: string, doctorNumber?: string, clinicNumber?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.doctorControllerGetDoctors(id, doctorNumber, clinicNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This operation is not yet implemented.
         * @summary delete by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async doctorControllerRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.doctorControllerRemove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a doctor
         * @param {string} id 
         * @param {UpdateDoctorDto} updateDoctorDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async doctorControllerUpdate(id: string, updateDoctorDto: UpdateDoctorDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.doctorControllerUpdate(id, updateDoctorDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllDOctorList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllDOctorList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {SearchDoctorDto} searchDoctorDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchDoctor(searchDoctorDto: SearchDoctorDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchDoctor(searchDoctorDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DoctorApi - factory interface
 * @export
 */
export const DoctorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DoctorApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {CreateDoctorDto} createDoctorDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUpdateDoctor(createDoctorDto: CreateDoctorDto, options?: any): AxiosPromise<void> {
            return localVarFp.addUpdateDoctor(createDoctorDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Provide either one parameter or none. If no parameters are provided, all doctors are returned
         * @summary fetch Doctors using various alternative methods
         * @param {string} [id] 
         * @param {string} [doctorNumber] 
         * @param {string} [clinicNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doctorControllerGetDoctors(id?: string, doctorNumber?: string, clinicNumber?: string, options?: any): AxiosPromise<void> {
            return localVarFp.doctorControllerGetDoctors(id, doctorNumber, clinicNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * This operation is not yet implemented.
         * @summary delete by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doctorControllerRemove(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.doctorControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a doctor
         * @param {string} id 
         * @param {UpdateDoctorDto} updateDoctorDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doctorControllerUpdate(id: string, updateDoctorDto: UpdateDoctorDto, options?: any): AxiosPromise<void> {
            return localVarFp.doctorControllerUpdate(id, updateDoctorDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDOctorList(options?: any): AxiosPromise<void> {
            return localVarFp.getAllDOctorList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {SearchDoctorDto} searchDoctorDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchDoctor(searchDoctorDto: SearchDoctorDto, options?: any): AxiosPromise<void> {
            return localVarFp.searchDoctor(searchDoctorDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DoctorApi - object-oriented interface
 * @export
 * @class DoctorApi
 * @extends {BaseAPI}
 */
export class DoctorApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {CreateDoctorDto} createDoctorDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorApi
     */
    public addUpdateDoctor(createDoctorDto: CreateDoctorDto, options?: AxiosRequestConfig) {
        return DoctorApiFp(this.configuration).addUpdateDoctor(createDoctorDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Provide either one parameter or none. If no parameters are provided, all doctors are returned
     * @summary fetch Doctors using various alternative methods
     * @param {string} [id] 
     * @param {string} [doctorNumber] 
     * @param {string} [clinicNumber] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorApi
     */
    public doctorControllerGetDoctors(id?: string, doctorNumber?: string, clinicNumber?: string, options?: AxiosRequestConfig) {
        return DoctorApiFp(this.configuration).doctorControllerGetDoctors(id, doctorNumber, clinicNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This operation is not yet implemented.
     * @summary delete by ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorApi
     */
    public doctorControllerRemove(id: string, options?: AxiosRequestConfig) {
        return DoctorApiFp(this.configuration).doctorControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a doctor
     * @param {string} id 
     * @param {UpdateDoctorDto} updateDoctorDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorApi
     */
    public doctorControllerUpdate(id: string, updateDoctorDto: UpdateDoctorDto, options?: AxiosRequestConfig) {
        return DoctorApiFp(this.configuration).doctorControllerUpdate(id, updateDoctorDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorApi
     */
    public getAllDOctorList(options?: AxiosRequestConfig) {
        return DoctorApiFp(this.configuration).getAllDOctorList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {SearchDoctorDto} searchDoctorDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorApi
     */
    public searchDoctor(searchDoctorDto: SearchDoctorDto, options?: AxiosRequestConfig) {
        return DoctorApiFp(this.configuration).searchDoctor(searchDoctorDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DocumentationApi - axios parameter creator
 * @export
 */
export const DocumentationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {CreateInitialEvaluationDto} createInitialEvaluationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInitialEvaluation: async (createInitialEvaluationDto: CreateInitialEvaluationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createInitialEvaluationDto' is not null or undefined
            assertParamExists('createInitialEvaluation', 'createInitialEvaluationDto', createInitialEvaluationDto)
            const localVarPath = `/documentation/initialEvaluation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createInitialEvaluationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {CreateTreatmentNoteDto} createTreatmentNoteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTreatmentNote: async (createTreatmentNoteDto: CreateTreatmentNoteDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTreatmentNoteDto' is not null or undefined
            assertParamExists('createTreatmentNote', 'createTreatmentNoteDto', createTreatmentNoteDto)
            const localVarPath = `/documentation/treatmentNote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTreatmentNoteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorReport: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDoctorReport', 'id', id)
            const localVarPath = `/documentation/doctorReport/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} rxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorReportByRxId: async (rxId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rxId' is not null or undefined
            assertParamExists('getDoctorReportByRxId', 'rxId', rxId)
            const localVarPath = `/documentation/doctorReportByRxId/{rxId}`
                .replace(`{${"rxId"}}`, encodeURIComponent(String(rxId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} rxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorReportStatus: async (rxId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rxId' is not null or undefined
            assertParamExists('getDoctorReportStatus', 'rxId', rxId)
            const localVarPath = `/documentation/doctorReportStatus/{rxId}`
                .replace(`{${"rxId"}}`, encodeURIComponent(String(rxId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocuments: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getDocuments', 'patientId', patientId)
            const localVarPath = `/documentation/documents/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} rxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFirstAndLastTimeSlot: async (rxId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rxId' is not null or undefined
            assertParamExists('getFirstAndLastTimeSlot', 'rxId', rxId)
            const localVarPath = `/documentation/firstAndLastTimeSlot/{rxId}`
                .replace(`{${"rxId"}}`, encodeURIComponent(String(rxId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInitialEvaluation: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getInitialEvaluation', 'patientId', patientId)
            const localVarPath = `/documentation/initialEvaluation/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTreatmentNotes: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getTreatmentNotes', 'patientId', patientId)
            const localVarPath = `/documentation/treatmentNotes/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {CreateDoctorReportDto} createDoctorReportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDoctorReport: async (createDoctorReportDto: CreateDoctorReportDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDoctorReportDto' is not null or undefined
            assertParamExists('postDoctorReport', 'createDoctorReportDto', createDoctorReportDto)
            const localVarPath = `/documentation/doctorReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDoctorReportDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {DoctorReportDto} doctorReportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putNputDoctorReportote: async (doctorReportDto: DoctorReportDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'doctorReportDto' is not null or undefined
            assertParamExists('putNputDoctorReportote', 'doctorReportDto', doctorReportDto)
            const localVarPath = `/documentation/doctorReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(doctorReportDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestAccessForDocument: async (documentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('requestAccessForDocument', 'documentId', documentId)
            const localVarPath = `/documentation/requestAccess/{documentId}`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {CreateDoctorReportDto} createDoctorReportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDoctorReport: async (id: string, createDoctorReportDto: CreateDoctorReportDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateDoctorReport', 'id', id)
            // verify required parameter 'createDoctorReportDto' is not null or undefined
            assertParamExists('updateDoctorReport', 'createDoctorReportDto', createDoctorReportDto)
            const localVarPath = `/documentation/doctorReport/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDoctorReportDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {DoctorReportStausDto} doctorReportStausDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDoctorReportStatus: async (id: string, doctorReportStausDto: DoctorReportStausDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateDoctorReportStatus', 'id', id)
            // verify required parameter 'doctorReportStausDto' is not null or undefined
            assertParamExists('updateDoctorReportStatus', 'doctorReportStausDto', doctorReportStausDto)
            const localVarPath = `/documentation/doctorReportStatus`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(doctorReportStausDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {CreateInitialEvaluationDto} createInitialEvaluationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInitialEvaluation: async (id: number, createInitialEvaluationDto: CreateInitialEvaluationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateInitialEvaluation', 'id', id)
            // verify required parameter 'createInitialEvaluationDto' is not null or undefined
            assertParamExists('updateInitialEvaluation', 'createInitialEvaluationDto', createInitialEvaluationDto)
            const localVarPath = `/documentation/initialEvaluation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createInitialEvaluationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {CreateTreatmentNoteDto} createTreatmentNoteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTreatmentNote: async (id: number, createTreatmentNoteDto: CreateTreatmentNoteDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTreatmentNote', 'id', id)
            // verify required parameter 'createTreatmentNoteDto' is not null or undefined
            assertParamExists('updateTreatmentNote', 'createTreatmentNoteDto', createTreatmentNoteDto)
            const localVarPath = `/documentation/treatmentNote/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTreatmentNoteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentationApi - functional programming interface
 * @export
 */
export const DocumentationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {CreateInitialEvaluationDto} createInitialEvaluationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInitialEvaluation(createInitialEvaluationDto: CreateInitialEvaluationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetInitialEvaluationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInitialEvaluation(createInitialEvaluationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {CreateTreatmentNoteDto} createTreatmentNoteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTreatmentNote(createTreatmentNoteDto: CreateTreatmentNoteDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TreatmentNoteDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTreatmentNote(createTreatmentNoteDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDoctorReport(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDoctorReport(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} rxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDoctorReportByRxId(rxId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DoctorReportDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDoctorReportByRxId(rxId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} rxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDoctorReportStatus(rxId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DoctorReportStausDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDoctorReportStatus(rxId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocuments(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocuments(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} rxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFirstAndLastTimeSlot(rxId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DoctorReportTimeSlotDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFirstAndLastTimeSlot(rxId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInitialEvaluation(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetInitialEvaluationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInitialEvaluation(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTreatmentNotes(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TimeSlotDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTreatmentNotes(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {CreateDoctorReportDto} createDoctorReportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDoctorReport(createDoctorReportDto: CreateDoctorReportDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDoctorReport(createDoctorReportDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {DoctorReportDto} doctorReportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putNputDoctorReportote(doctorReportDto: DoctorReportDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putNputDoctorReportote(doctorReportDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestAccessForDocument(documentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestAccessForDocument(documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {CreateDoctorReportDto} createDoctorReportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDoctorReport(id: string, createDoctorReportDto: CreateDoctorReportDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DoctorReportDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDoctorReport(id, createDoctorReportDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {DoctorReportStausDto} doctorReportStausDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDoctorReportStatus(id: string, doctorReportStausDto: DoctorReportStausDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DoctorReportStausDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDoctorReportStatus(id, doctorReportStausDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {CreateInitialEvaluationDto} createInitialEvaluationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInitialEvaluation(id: number, createInitialEvaluationDto: CreateInitialEvaluationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetInitialEvaluationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInitialEvaluation(id, createInitialEvaluationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {CreateTreatmentNoteDto} createTreatmentNoteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTreatmentNote(id: number, createTreatmentNoteDto: CreateTreatmentNoteDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TreatmentNoteDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTreatmentNote(id, createTreatmentNoteDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentationApi - factory interface
 * @export
 */
export const DocumentationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentationApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {CreateInitialEvaluationDto} createInitialEvaluationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInitialEvaluation(createInitialEvaluationDto: CreateInitialEvaluationDto, options?: any): AxiosPromise<GetInitialEvaluationDto> {
            return localVarFp.createInitialEvaluation(createInitialEvaluationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {CreateTreatmentNoteDto} createTreatmentNoteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTreatmentNote(createTreatmentNoteDto: CreateTreatmentNoteDto, options?: any): AxiosPromise<TreatmentNoteDTO> {
            return localVarFp.createTreatmentNote(createTreatmentNoteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorReport(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.getDoctorReport(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} rxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorReportByRxId(rxId: string, options?: any): AxiosPromise<DoctorReportDto> {
            return localVarFp.getDoctorReportByRxId(rxId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} rxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorReportStatus(rxId: string, options?: any): AxiosPromise<DoctorReportStausDto> {
            return localVarFp.getDoctorReportStatus(rxId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocuments(patientId: number, options?: any): AxiosPromise<Array<DocumentDto>> {
            return localVarFp.getDocuments(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} rxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFirstAndLastTimeSlot(rxId: string, options?: any): AxiosPromise<DoctorReportTimeSlotDto> {
            return localVarFp.getFirstAndLastTimeSlot(rxId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInitialEvaluation(patientId: number, options?: any): AxiosPromise<Array<GetInitialEvaluationDto>> {
            return localVarFp.getInitialEvaluation(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTreatmentNotes(patientId: number, options?: any): AxiosPromise<Array<TimeSlotDto>> {
            return localVarFp.getTreatmentNotes(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {CreateDoctorReportDto} createDoctorReportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDoctorReport(createDoctorReportDto: CreateDoctorReportDto, options?: any): AxiosPromise<object> {
            return localVarFp.postDoctorReport(createDoctorReportDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {DoctorReportDto} doctorReportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putNputDoctorReportote(doctorReportDto: DoctorReportDto, options?: any): AxiosPromise<void> {
            return localVarFp.putNputDoctorReportote(doctorReportDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestAccessForDocument(documentId: string, options?: any): AxiosPromise<DocumentDto> {
            return localVarFp.requestAccessForDocument(documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {CreateDoctorReportDto} createDoctorReportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDoctorReport(id: string, createDoctorReportDto: CreateDoctorReportDto, options?: any): AxiosPromise<DoctorReportDto> {
            return localVarFp.updateDoctorReport(id, createDoctorReportDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {DoctorReportStausDto} doctorReportStausDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDoctorReportStatus(id: string, doctorReportStausDto: DoctorReportStausDto, options?: any): AxiosPromise<DoctorReportStausDto> {
            return localVarFp.updateDoctorReportStatus(id, doctorReportStausDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {CreateInitialEvaluationDto} createInitialEvaluationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInitialEvaluation(id: number, createInitialEvaluationDto: CreateInitialEvaluationDto, options?: any): AxiosPromise<GetInitialEvaluationDto> {
            return localVarFp.updateInitialEvaluation(id, createInitialEvaluationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {CreateTreatmentNoteDto} createTreatmentNoteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTreatmentNote(id: number, createTreatmentNoteDto: CreateTreatmentNoteDto, options?: any): AxiosPromise<TreatmentNoteDTO> {
            return localVarFp.updateTreatmentNote(id, createTreatmentNoteDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentationApi - object-oriented interface
 * @export
 * @class DocumentationApi
 * @extends {BaseAPI}
 */
export class DocumentationApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {CreateInitialEvaluationDto} createInitialEvaluationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentationApi
     */
    public createInitialEvaluation(createInitialEvaluationDto: CreateInitialEvaluationDto, options?: AxiosRequestConfig) {
        return DocumentationApiFp(this.configuration).createInitialEvaluation(createInitialEvaluationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {CreateTreatmentNoteDto} createTreatmentNoteDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentationApi
     */
    public createTreatmentNote(createTreatmentNoteDto: CreateTreatmentNoteDto, options?: AxiosRequestConfig) {
        return DocumentationApiFp(this.configuration).createTreatmentNote(createTreatmentNoteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentationApi
     */
    public getDoctorReport(id: string, options?: AxiosRequestConfig) {
        return DocumentationApiFp(this.configuration).getDoctorReport(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} rxId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentationApi
     */
    public getDoctorReportByRxId(rxId: string, options?: AxiosRequestConfig) {
        return DocumentationApiFp(this.configuration).getDoctorReportByRxId(rxId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} rxId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentationApi
     */
    public getDoctorReportStatus(rxId: string, options?: AxiosRequestConfig) {
        return DocumentationApiFp(this.configuration).getDoctorReportStatus(rxId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentationApi
     */
    public getDocuments(patientId: number, options?: AxiosRequestConfig) {
        return DocumentationApiFp(this.configuration).getDocuments(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} rxId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentationApi
     */
    public getFirstAndLastTimeSlot(rxId: string, options?: AxiosRequestConfig) {
        return DocumentationApiFp(this.configuration).getFirstAndLastTimeSlot(rxId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentationApi
     */
    public getInitialEvaluation(patientId: number, options?: AxiosRequestConfig) {
        return DocumentationApiFp(this.configuration).getInitialEvaluation(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentationApi
     */
    public getTreatmentNotes(patientId: number, options?: AxiosRequestConfig) {
        return DocumentationApiFp(this.configuration).getTreatmentNotes(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {CreateDoctorReportDto} createDoctorReportDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentationApi
     */
    public postDoctorReport(createDoctorReportDto: CreateDoctorReportDto, options?: AxiosRequestConfig) {
        return DocumentationApiFp(this.configuration).postDoctorReport(createDoctorReportDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {DoctorReportDto} doctorReportDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentationApi
     */
    public putNputDoctorReportote(doctorReportDto: DoctorReportDto, options?: AxiosRequestConfig) {
        return DocumentationApiFp(this.configuration).putNputDoctorReportote(doctorReportDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentationApi
     */
    public requestAccessForDocument(documentId: string, options?: AxiosRequestConfig) {
        return DocumentationApiFp(this.configuration).requestAccessForDocument(documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {CreateDoctorReportDto} createDoctorReportDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentationApi
     */
    public updateDoctorReport(id: string, createDoctorReportDto: CreateDoctorReportDto, options?: AxiosRequestConfig) {
        return DocumentationApiFp(this.configuration).updateDoctorReport(id, createDoctorReportDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {DoctorReportStausDto} doctorReportStausDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentationApi
     */
    public updateDoctorReportStatus(id: string, doctorReportStausDto: DoctorReportStausDto, options?: AxiosRequestConfig) {
        return DocumentationApiFp(this.configuration).updateDoctorReportStatus(id, doctorReportStausDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} id 
     * @param {CreateInitialEvaluationDto} createInitialEvaluationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentationApi
     */
    public updateInitialEvaluation(id: number, createInitialEvaluationDto: CreateInitialEvaluationDto, options?: AxiosRequestConfig) {
        return DocumentationApiFp(this.configuration).updateInitialEvaluation(id, createInitialEvaluationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} id 
     * @param {CreateTreatmentNoteDto} createTreatmentNoteDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentationApi
     */
    public updateTreatmentNote(id: number, createTreatmentNoteDto: CreateTreatmentNoteDto, options?: AxiosRequestConfig) {
        return DocumentationApiFp(this.configuration).updateTreatmentNote(id, createTreatmentNoteDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * HistoryApi - axios parameter creator
 * @export
 */
export const HistoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string | null} [type] 
         * @param {string | null} [action] 
         * @param {string | null} [searchKey] 
         * @param {string | null} [fromDate] 
         * @param {string | null} [toDate] 
         * @param {number} [patientId] 
         * @param {string} [rxId] 
         * @param {string} [appointmentId] 
         * @param {number} [initialEvaluationId] 
         * @param {number} [treatmentNoteId] 
         * @param {string} [addressId] 
         * @param {string} [therapyRxId] 
         * @param {string} [affectedUserId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllHistory: async (limit?: number, page?: number, type?: string | null, action?: string | null, searchKey?: string | null, fromDate?: string | null, toDate?: string | null, patientId?: number, rxId?: string, appointmentId?: string, initialEvaluationId?: number, treatmentNoteId?: number, addressId?: string, therapyRxId?: string, affectedUserId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (action !== undefined) {
                localVarQueryParameter['action'] = action;
            }

            if (searchKey !== undefined) {
                localVarQueryParameter['searchKey'] = searchKey;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = toDate;
            }

            if (patientId !== undefined) {
                localVarQueryParameter['patientId'] = patientId;
            }

            if (rxId !== undefined) {
                localVarQueryParameter['rxId'] = rxId;
            }

            if (appointmentId !== undefined) {
                localVarQueryParameter['appointmentId'] = appointmentId;
            }

            if (initialEvaluationId !== undefined) {
                localVarQueryParameter['initialEvaluationId'] = initialEvaluationId;
            }

            if (treatmentNoteId !== undefined) {
                localVarQueryParameter['treatmentNoteId'] = treatmentNoteId;
            }

            if (addressId !== undefined) {
                localVarQueryParameter['addressId'] = addressId;
            }

            if (therapyRxId !== undefined) {
                localVarQueryParameter['therapyRxId'] = therapyRxId;
            }

            if (affectedUserId !== undefined) {
                localVarQueryParameter['affectedUserId'] = affectedUserId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoryFilters: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/history/filters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HistoryApi - functional programming interface
 * @export
 */
export const HistoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HistoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string | null} [type] 
         * @param {string | null} [action] 
         * @param {string | null} [searchKey] 
         * @param {string | null} [fromDate] 
         * @param {string | null} [toDate] 
         * @param {number} [patientId] 
         * @param {string} [rxId] 
         * @param {string} [appointmentId] 
         * @param {number} [initialEvaluationId] 
         * @param {number} [treatmentNoteId] 
         * @param {string} [addressId] 
         * @param {string} [therapyRxId] 
         * @param {string} [affectedUserId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllHistory(limit?: number, page?: number, type?: string | null, action?: string | null, searchKey?: string | null, fromDate?: string | null, toDate?: string | null, patientId?: number, rxId?: string, appointmentId?: string, initialEvaluationId?: number, treatmentNoteId?: number, addressId?: string, therapyRxId?: string, affectedUserId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetHistoryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllHistory(limit, page, type, action, searchKey, fromDate, toDate, patientId, rxId, appointmentId, initialEvaluationId, treatmentNoteId, addressId, therapyRxId, affectedUserId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHistoryFilters(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HistoryFiltersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHistoryFilters(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HistoryApi - factory interface
 * @export
 */
export const HistoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HistoryApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string | null} [type] 
         * @param {string | null} [action] 
         * @param {string | null} [searchKey] 
         * @param {string | null} [fromDate] 
         * @param {string | null} [toDate] 
         * @param {number} [patientId] 
         * @param {string} [rxId] 
         * @param {string} [appointmentId] 
         * @param {number} [initialEvaluationId] 
         * @param {number} [treatmentNoteId] 
         * @param {string} [addressId] 
         * @param {string} [therapyRxId] 
         * @param {string} [affectedUserId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllHistory(limit?: number, page?: number, type?: string | null, action?: string | null, searchKey?: string | null, fromDate?: string | null, toDate?: string | null, patientId?: number, rxId?: string, appointmentId?: string, initialEvaluationId?: number, treatmentNoteId?: number, addressId?: string, therapyRxId?: string, affectedUserId?: string, options?: any): AxiosPromise<Array<GetHistoryDto>> {
            return localVarFp.getAllHistory(limit, page, type, action, searchKey, fromDate, toDate, patientId, rxId, appointmentId, initialEvaluationId, treatmentNoteId, addressId, therapyRxId, affectedUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoryFilters(options?: any): AxiosPromise<HistoryFiltersDto> {
            return localVarFp.getHistoryFilters(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HistoryApi - object-oriented interface
 * @export
 * @class HistoryApi
 * @extends {BaseAPI}
 */
export class HistoryApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string | null} [type] 
     * @param {string | null} [action] 
     * @param {string | null} [searchKey] 
     * @param {string | null} [fromDate] 
     * @param {string | null} [toDate] 
     * @param {number} [patientId] 
     * @param {string} [rxId] 
     * @param {string} [appointmentId] 
     * @param {number} [initialEvaluationId] 
     * @param {number} [treatmentNoteId] 
     * @param {string} [addressId] 
     * @param {string} [therapyRxId] 
     * @param {string} [affectedUserId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoryApi
     */
    public getAllHistory(limit?: number, page?: number, type?: string | null, action?: string | null, searchKey?: string | null, fromDate?: string | null, toDate?: string | null, patientId?: number, rxId?: string, appointmentId?: string, initialEvaluationId?: number, treatmentNoteId?: number, addressId?: string, therapyRxId?: string, affectedUserId?: string, options?: AxiosRequestConfig) {
        return HistoryApiFp(this.configuration).getAllHistory(limit, page, type, action, searchKey, fromDate, toDate, patientId, rxId, appointmentId, initialEvaluationId, treatmentNoteId, addressId, therapyRxId, affectedUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoryApi
     */
    public getHistoryFilters(options?: AxiosRequestConfig) {
        return HistoryApiFp(this.configuration).getHistoryFilters(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ItemsApi - axios parameter creator
 * @export
 */
export const ItemsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItems: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/item`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {ItemDTO} itemDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postItems: async (itemDTO: ItemDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemDTO' is not null or undefined
            assertParamExists('postItems', 'itemDTO', itemDTO)
            const localVarPath = `/item`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(itemDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ItemsApi - functional programming interface
 * @export
 */
export const ItemsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ItemsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getItems(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getItems(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {ItemDTO} itemDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postItems(itemDTO: ItemDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postItems(itemDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ItemsApi - factory interface
 * @export
 */
export const ItemsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ItemsApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItems(options?: any): AxiosPromise<void> {
            return localVarFp.getItems(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {ItemDTO} itemDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postItems(itemDTO: ItemDTO, options?: any): AxiosPromise<void> {
            return localVarFp.postItems(itemDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ItemsApi - object-oriented interface
 * @export
 * @class ItemsApi
 * @extends {BaseAPI}
 */
export class ItemsApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public getItems(options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).getItems(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {ItemDTO} itemDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public postItems(itemDTO: ItemDTO, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).postItems(itemDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MasterlistsApi - axios parameter creator
 * @export
 */
export const MasterlistsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMasterTables: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/masterlists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} [text] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getICDCCode: async (text?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/masterlists/icdCodes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (text !== undefined) {
                localVarQueryParameter['text'] = text;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTherapies: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/masterlists/therapies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MasterlistsApi - functional programming interface
 * @export
 */
export const MasterlistsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MasterlistsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllMasterTables(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllMasterTables(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} [text] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getICDCCode(text?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getICDCCode(text, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTherapies(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TherapyDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTherapies(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MasterlistsApi - factory interface
 * @export
 */
export const MasterlistsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MasterlistsApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMasterTables(options?: any): AxiosPromise<void> {
            return localVarFp.getAllMasterTables(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} [text] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getICDCCode(text?: string, options?: any): AxiosPromise<void> {
            return localVarFp.getICDCCode(text, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTherapies(options?: any): AxiosPromise<Array<TherapyDto>> {
            return localVarFp.getTherapies(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MasterlistsApi - object-oriented interface
 * @export
 * @class MasterlistsApi
 * @extends {BaseAPI}
 */
export class MasterlistsApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterlistsApi
     */
    public getAllMasterTables(options?: AxiosRequestConfig) {
        return MasterlistsApiFp(this.configuration).getAllMasterTables(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} [text] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterlistsApi
     */
    public getICDCCode(text?: string, options?: AxiosRequestConfig) {
        return MasterlistsApiFp(this.configuration).getICDCCode(text, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterlistsApi
     */
    public getTherapies(options?: AxiosRequestConfig) {
        return MasterlistsApiFp(this.configuration).getTherapies(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * NoteApi - axios parameter creator
 * @export
 */
export const NoteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {string} appointmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllAppointmentNotes: async (appointmentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appointmentId' is not null or undefined
            assertParamExists('deleteAllAppointmentNotes', 'appointmentId', appointmentId)
            const localVarPath = `/note/deleteAll/{appointmentId}`
                .replace(`{${"appointmentId"}}`, encodeURIComponent(String(appointmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllPatientNotes: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('deleteAllPatientNotes', 'patientId', patientId)
            const localVarPath = `/note/deleteAll/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} rxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllRxNotes: async (rxId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rxId' is not null or undefined
            assertParamExists('deleteAllRxNotes', 'rxId', rxId)
            const localVarPath = `/note/deleteAll/{rxId}`
                .replace(`{${"rxId"}}`, encodeURIComponent(String(rxId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNote: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteNote', 'id', id)
            const localVarPath = `/note/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} appointmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentNotes: async (appointmentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appointmentId' is not null or undefined
            assertParamExists('getAppointmentNotes', 'appointmentId', appointmentId)
            const localVarPath = `/note/getAppointmentNotes/{appointmentId}`
                .replace(`{${"appointmentId"}}`, encodeURIComponent(String(appointmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNote: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getNote', 'id', id)
            const localVarPath = `/note/getNote/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotesForAppointments: async (requestBody: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('getNotesForAppointments', 'requestBody', requestBody)
            const localVarPath = `/note/getNotesForAppointments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientNotes: async (patientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getPatientNotes', 'patientId', patientId)
            const localVarPath = `/note/getPatientNotes/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} rxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRxNotes: async (rxId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rxId' is not null or undefined
            assertParamExists('getRxNotes', 'rxId', rxId)
            const localVarPath = `/note/getRxNotes/{rxId}`
                .replace(`{${"rxId"}}`, encodeURIComponent(String(rxId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {NoteDto} noteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postNote: async (userId: string, noteDto: NoteDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('postNote', 'userId', userId)
            // verify required parameter 'noteDto' is not null or undefined
            assertParamExists('postNote', 'noteDto', noteDto)
            const localVarPath = `/note/postNote/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(noteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {NoteDto} noteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putNote: async (userId: string, noteDto: NoteDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('putNote', 'userId', userId)
            // verify required parameter 'noteDto' is not null or undefined
            assertParamExists('putNote', 'noteDto', noteDto)
            const localVarPath = `/note/updateNote/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(noteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {string} userId 
         * @param {NoteDto} noteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNote: async (id: string, userId: string, noteDto: NoteDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateNote', 'id', id)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateNote', 'userId', userId)
            // verify required parameter 'noteDto' is not null or undefined
            assertParamExists('updateNote', 'noteDto', noteDto)
            const localVarPath = `/note/updateNote/{id}/{userId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(noteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NoteApi - functional programming interface
 * @export
 */
export const NoteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NoteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} appointmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAllAppointmentNotes(appointmentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAllAppointmentNotes(appointmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAllPatientNotes(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAllPatientNotes(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} rxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAllRxNotes(rxId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAllRxNotes(rxId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNote(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNote(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} appointmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppointmentNotes(appointmentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppointmentNotes(appointmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNote(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNote(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotesForAppointments(requestBody: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotesForAppointments(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientNotes(patientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientNotes(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} rxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRxNotes(rxId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRxNotes(rxId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {NoteDto} noteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postNote(userId: string, noteDto: NoteDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postNote(userId, noteDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {NoteDto} noteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putNote(userId: string, noteDto: NoteDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putNote(userId, noteDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {string} userId 
         * @param {NoteDto} noteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNote(id: string, userId: string, noteDto: NoteDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNote(id, userId, noteDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NoteApi - factory interface
 * @export
 */
export const NoteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NoteApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} appointmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllAppointmentNotes(appointmentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAllAppointmentNotes(appointmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllPatientNotes(patientId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAllPatientNotes(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} rxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllRxNotes(rxId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAllRxNotes(rxId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNote(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteNote(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} appointmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentNotes(appointmentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.getAppointmentNotes(appointmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNote(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.getNote(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotesForAppointments(requestBody: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.getNotesForAppointments(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientNotes(patientId: string, options?: any): AxiosPromise<void> {
            return localVarFp.getPatientNotes(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} rxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRxNotes(rxId: string, options?: any): AxiosPromise<void> {
            return localVarFp.getRxNotes(rxId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {NoteDto} noteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postNote(userId: string, noteDto: NoteDto, options?: any): AxiosPromise<object> {
            return localVarFp.postNote(userId, noteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {NoteDto} noteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putNote(userId: string, noteDto: NoteDto, options?: any): AxiosPromise<void> {
            return localVarFp.putNote(userId, noteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {string} userId 
         * @param {NoteDto} noteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNote(id: string, userId: string, noteDto: NoteDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateNote(id, userId, noteDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NoteApi - object-oriented interface
 * @export
 * @class NoteApi
 * @extends {BaseAPI}
 */
export class NoteApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {string} appointmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    public deleteAllAppointmentNotes(appointmentId: string, options?: AxiosRequestConfig) {
        return NoteApiFp(this.configuration).deleteAllAppointmentNotes(appointmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    public deleteAllPatientNotes(patientId: number, options?: AxiosRequestConfig) {
        return NoteApiFp(this.configuration).deleteAllPatientNotes(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} rxId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    public deleteAllRxNotes(rxId: string, options?: AxiosRequestConfig) {
        return NoteApiFp(this.configuration).deleteAllRxNotes(rxId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    public deleteNote(id: string, options?: AxiosRequestConfig) {
        return NoteApiFp(this.configuration).deleteNote(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} appointmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    public getAppointmentNotes(appointmentId: string, options?: AxiosRequestConfig) {
        return NoteApiFp(this.configuration).getAppointmentNotes(appointmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    public getNote(id: string, options?: AxiosRequestConfig) {
        return NoteApiFp(this.configuration).getNote(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    public getNotesForAppointments(requestBody: Array<string>, options?: AxiosRequestConfig) {
        return NoteApiFp(this.configuration).getNotesForAppointments(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    public getPatientNotes(patientId: string, options?: AxiosRequestConfig) {
        return NoteApiFp(this.configuration).getPatientNotes(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} rxId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    public getRxNotes(rxId: string, options?: AxiosRequestConfig) {
        return NoteApiFp(this.configuration).getRxNotes(rxId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} userId 
     * @param {NoteDto} noteDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    public postNote(userId: string, noteDto: NoteDto, options?: AxiosRequestConfig) {
        return NoteApiFp(this.configuration).postNote(userId, noteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} userId 
     * @param {NoteDto} noteDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    public putNote(userId: string, noteDto: NoteDto, options?: AxiosRequestConfig) {
        return NoteApiFp(this.configuration).putNote(userId, noteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {string} userId 
     * @param {NoteDto} noteDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    public updateNote(id: string, userId: string, noteDto: NoteDto, options?: AxiosRequestConfig) {
        return NoteApiFp(this.configuration).updateNote(id, userId, noteDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PatientsApi - axios parameter creator
 * @export
 */
export const PatientsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This endpoint removes a address of a patient from the database.
         * @summary Remove a address
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAddress: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAddress', 'id', id)
            const localVarPath = `/patient/deleteAddress/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPatients: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/patient/getAllPatients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLiveValidation: async (body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getLiveValidation', 'body', body)
            const localVarPath = `/patient/getLiveValidation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatient: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPatient', 'id', id)
            const localVarPath = `/patient/getPatient/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStats: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/patient/stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {PatientDto} patientDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPatients: async (patientDto: PatientDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientDto' is not null or undefined
            assertParamExists('postPatients', 'patientDto', patientDto)
            const localVarPath = `/patient`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {SearchDto} searchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPatient: async (searchDto: SearchDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchDto' is not null or undefined
            assertParamExists('searchPatient', 'searchDto', searchDto)
            const localVarPath = `/patient/searchPatient`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatientsApi - functional programming interface
 * @export
 */
export const PatientsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatientsApiAxiosParamCreator(configuration)
    return {
        /**
         * This endpoint removes a address of a patient from the database.
         * @summary Remove a address
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAddress(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAddress(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllPatients(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SearchDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllPatients(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLiveValidation(body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLiveValidation(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatient(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatient(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStats(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStats(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {PatientDto} patientDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPatients(patientDto: PatientDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPatients(patientDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {SearchDto} searchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchPatient(searchDto: SearchDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchPatient(searchDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PatientsApi - factory interface
 * @export
 */
export const PatientsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatientsApiFp(configuration)
    return {
        /**
         * This endpoint removes a address of a patient from the database.
         * @summary Remove a address
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAddress(id: string, options?: any): AxiosPromise<AddressDto> {
            return localVarFp.deleteAddress(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPatients(options?: any): AxiosPromise<Array<SearchDto>> {
            return localVarFp.getAllPatients(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLiveValidation(body: object, options?: any): AxiosPromise<void> {
            return localVarFp.getLiveValidation(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatient(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.getPatient(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStats(options?: any): AxiosPromise<void> {
            return localVarFp.getStats(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {PatientDto} patientDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPatients(patientDto: PatientDto, options?: any): AxiosPromise<void> {
            return localVarFp.postPatients(patientDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {SearchDto} searchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPatient(searchDto: SearchDto, options?: any): AxiosPromise<void> {
            return localVarFp.searchPatient(searchDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PatientsApi - object-oriented interface
 * @export
 * @class PatientsApi
 * @extends {BaseAPI}
 */
export class PatientsApi extends BaseAPI {
    /**
     * This endpoint removes a address of a patient from the database.
     * @summary Remove a address
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public deleteAddress(id: string, options?: AxiosRequestConfig) {
        return PatientsApiFp(this.configuration).deleteAddress(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public getAllPatients(options?: AxiosRequestConfig) {
        return PatientsApiFp(this.configuration).getAllPatients(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public getLiveValidation(body: object, options?: AxiosRequestConfig) {
        return PatientsApiFp(this.configuration).getLiveValidation(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public getPatient(id: number, options?: AxiosRequestConfig) {
        return PatientsApiFp(this.configuration).getPatient(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public getStats(options?: AxiosRequestConfig) {
        return PatientsApiFp(this.configuration).getStats(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {PatientDto} patientDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public postPatients(patientDto: PatientDto, options?: AxiosRequestConfig) {
        return PatientsApiFp(this.configuration).postPatients(patientDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {SearchDto} searchDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public searchPatient(searchDto: SearchDto, options?: AxiosRequestConfig) {
        return PatientsApiFp(this.configuration).searchPatient(searchDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RxApi - axios parameter creator
 * @export
 */
export const RxApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {RxSeriesDto} rxSeriesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewSeriesToPatient: async (patientId: number, rxSeriesDto: RxSeriesDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('addNewSeriesToPatient', 'patientId', patientId)
            // verify required parameter 'rxSeriesDto' is not null or undefined
            assertParamExists('addNewSeriesToPatient', 'rxSeriesDto', rxSeriesDto)
            const localVarPath = `/rx/addSeriesForPatient/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rxSeriesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} rxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyToContinuous: async (rxId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rxId' is not null or undefined
            assertParamExists('copyToContinuous', 'rxId', rxId)
            const localVarPath = `/rx/copyToContinuous/{rxId}`
                .replace(`{${"rxId"}}`, encodeURIComponent(String(rxId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} rxId 
         * @param {boolean} softDelete 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRx: async (rxId: string, softDelete: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rxId' is not null or undefined
            assertParamExists('deleteRx', 'rxId', rxId)
            // verify required parameter 'softDelete' is not null or undefined
            assertParamExists('deleteRx', 'softDelete', softDelete)
            const localVarPath = `/rx/deleteRx/{rxId}/{softDelete}`
                .replace(`{${"rxId"}}`, encodeURIComponent(String(rxId)))
                .replace(`{${"softDelete"}}`, encodeURIComponent(String(softDelete)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} seriesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSeries: async (seriesId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'seriesId' is not null or undefined
            assertParamExists('deleteSeries', 'seriesId', seriesId)
            const localVarPath = `/rx/deleteSeries/{seriesId}`
                .replace(`{${"seriesId"}}`, encodeURIComponent(String(seriesId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRx: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getAllRx', 'patientId', patientId)
            const localVarPath = `/rx/getAll/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {Array<string>} requestBody Array of Rx IDs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppStats: async (requestBody: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('getAppStats', 'requestBody', requestBody)
            const localVarPath = `/rx/appstats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRx: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRx', 'id', id)
            const localVarPath = `/rx/getRx/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRxByNumber: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRxByNumber', 'id', id)
            const localVarPath = `/rx/getRxByNumber/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRxLackingAppointments: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getRxLackingAppointments', 'patientId', patientId)
            const localVarPath = `/rx/lackingAppointments/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} rxNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRxPrice: async (rxNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rxNumber' is not null or undefined
            assertParamExists('getRxPrice', 'rxNumber', rxNumber)
            const localVarPath = `/rx/getRxPrice/{rxNumber}`
                .replace(`{${"rxNumber"}}`, encodeURIComponent(String(rxNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRxSeriesForPatient: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getRxSeriesForPatient', 'patientId', patientId)
            const localVarPath = `/rx/series/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTherapyRxsSeriesForPatient: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getTherapyRxsSeriesForPatient', 'patientId', patientId)
            const localVarPath = `/rx/therapyRxsSeries/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {Array<RxDto>} rxDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientSaveRxs: async (patientId: number, rxDto: Array<RxDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('patientSaveRxs', 'patientId', patientId)
            // verify required parameter 'rxDto' is not null or undefined
            assertParamExists('patientSaveRxs', 'rxDto', rxDto)
            const localVarPath = `/rx/patientSaveRxs/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rxDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {RxDto} rxDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveRx: async (rxDto: RxDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rxDto' is not null or undefined
            assertParamExists('saveRx', 'rxDto', rxDto)
            const localVarPath = `/rx/saveRx`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rxDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} rxId 
         * @param {string} processingStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProcessingStatus: async (rxId: string, processingStatus: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rxId' is not null or undefined
            assertParamExists('updateProcessingStatus', 'rxId', rxId)
            // verify required parameter 'processingStatus' is not null or undefined
            assertParamExists('updateProcessingStatus', 'processingStatus', processingStatus)
            const localVarPath = `/rx/updateProcessingStatus/{rxId}/{processingStatus}`
                .replace(`{${"rxId"}}`, encodeURIComponent(String(rxId)))
                .replace(`{${"processingStatus"}}`, encodeURIComponent(String(processingStatus)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} seriesId 
         * @param {number} patientId 
         * @param {RxSeriesDto} rxSeriesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSeries: async (seriesId: string, patientId: number, rxSeriesDto: RxSeriesDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'seriesId' is not null or undefined
            assertParamExists('updateSeries', 'seriesId', seriesId)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('updateSeries', 'patientId', patientId)
            // verify required parameter 'rxSeriesDto' is not null or undefined
            assertParamExists('updateSeries', 'rxSeriesDto', rxSeriesDto)
            const localVarPath = `/rx/updateSeries/forPatient/{patientId}/{seriesId}`
                .replace(`{${"seriesId"}}`, encodeURIComponent(String(seriesId)))
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rxSeriesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} therapyRxId 
         * @param {string} newSeriesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSeriesForTherapyRx: async (therapyRxId: string, newSeriesId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'therapyRxId' is not null or undefined
            assertParamExists('updateSeriesForTherapyRx', 'therapyRxId', therapyRxId)
            // verify required parameter 'newSeriesId' is not null or undefined
            assertParamExists('updateSeriesForTherapyRx', 'newSeriesId', newSeriesId)
            const localVarPath = `/rx/updateSeriesId/forTherapyRx/{therapyRxId}/{newSeriesId}`
                .replace(`{${"therapyRxId"}}`, encodeURIComponent(String(therapyRxId)))
                .replace(`{${"newSeriesId"}}`, encodeURIComponent(String(newSeriesId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RxApi - functional programming interface
 * @export
 */
export const RxApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RxApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {RxSeriesDto} rxSeriesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addNewSeriesToPatient(patientId: number, rxSeriesDto: RxSeriesDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addNewSeriesToPatient(patientId, rxSeriesDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} rxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyToContinuous(rxId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.copyToContinuous(rxId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} rxId 
         * @param {boolean} softDelete 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRx(rxId: string, softDelete: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRx(rxId, softDelete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} seriesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSeries(seriesId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSeries(seriesId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllRx(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllRx(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {Array<string>} requestBody Array of Rx IDs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppStats(requestBody: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AppStatsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppStats(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRx(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRx(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRxByNumber(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRxByNumber(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRxLackingAppointments(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RxDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRxLackingAppointments(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} rxNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRxPrice(rxNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalculateRxDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRxPrice(rxNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRxSeriesForPatient(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RxSeriesDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRxSeriesForPatient(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTherapyRxsSeriesForPatient(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTherapyRxsSeriesForPatient(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {Array<RxDto>} rxDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patientSaveRxs(patientId: number, rxDto: Array<RxDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patientSaveRxs(patientId, rxDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {RxDto} rxDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveRx(rxDto: RxDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveRx(rxDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} rxId 
         * @param {string} processingStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProcessingStatus(rxId: string, processingStatus: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProcessingStatus(rxId, processingStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} seriesId 
         * @param {number} patientId 
         * @param {RxSeriesDto} rxSeriesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSeries(seriesId: string, patientId: number, rxSeriesDto: RxSeriesDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSeries(seriesId, patientId, rxSeriesDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} therapyRxId 
         * @param {string} newSeriesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSeriesForTherapyRx(therapyRxId: string, newSeriesId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSeriesForTherapyRx(therapyRxId, newSeriesId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RxApi - factory interface
 * @export
 */
export const RxApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RxApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {RxSeriesDto} rxSeriesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewSeriesToPatient(patientId: number, rxSeriesDto: RxSeriesDto, options?: any): AxiosPromise<void> {
            return localVarFp.addNewSeriesToPatient(patientId, rxSeriesDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} rxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyToContinuous(rxId: string, options?: any): AxiosPromise<void> {
            return localVarFp.copyToContinuous(rxId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} rxId 
         * @param {boolean} softDelete 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRx(rxId: string, softDelete: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.deleteRx(rxId, softDelete, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} seriesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSeries(seriesId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSeries(seriesId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRx(patientId: number, options?: any): AxiosPromise<void> {
            return localVarFp.getAllRx(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {Array<string>} requestBody Array of Rx IDs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppStats(requestBody: Array<string>, options?: any): AxiosPromise<Array<AppStatsDto>> {
            return localVarFp.getAppStats(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRx(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.getRx(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRxByNumber(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.getRxByNumber(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRxLackingAppointments(patientId: number, options?: any): AxiosPromise<Array<RxDto>> {
            return localVarFp.getRxLackingAppointments(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} rxNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRxPrice(rxNumber: string, options?: any): AxiosPromise<CalculateRxDTO> {
            return localVarFp.getRxPrice(rxNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRxSeriesForPatient(patientId: number, options?: any): AxiosPromise<Array<RxSeriesDto>> {
            return localVarFp.getRxSeriesForPatient(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTherapyRxsSeriesForPatient(patientId: number, options?: any): AxiosPromise<void> {
            return localVarFp.getTherapyRxsSeriesForPatient(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {Array<RxDto>} rxDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientSaveRxs(patientId: number, rxDto: Array<RxDto>, options?: any): AxiosPromise<void> {
            return localVarFp.patientSaveRxs(patientId, rxDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {RxDto} rxDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveRx(rxDto: RxDto, options?: any): AxiosPromise<void> {
            return localVarFp.saveRx(rxDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} rxId 
         * @param {string} processingStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProcessingStatus(rxId: string, processingStatus: string, options?: any): AxiosPromise<void> {
            return localVarFp.updateProcessingStatus(rxId, processingStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} seriesId 
         * @param {number} patientId 
         * @param {RxSeriesDto} rxSeriesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSeries(seriesId: string, patientId: number, rxSeriesDto: RxSeriesDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateSeries(seriesId, patientId, rxSeriesDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} therapyRxId 
         * @param {string} newSeriesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSeriesForTherapyRx(therapyRxId: string, newSeriesId: string, options?: any): AxiosPromise<void> {
            return localVarFp.updateSeriesForTherapyRx(therapyRxId, newSeriesId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RxApi - object-oriented interface
 * @export
 * @class RxApi
 * @extends {BaseAPI}
 */
export class RxApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {number} patientId 
     * @param {RxSeriesDto} rxSeriesDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RxApi
     */
    public addNewSeriesToPatient(patientId: number, rxSeriesDto: RxSeriesDto, options?: AxiosRequestConfig) {
        return RxApiFp(this.configuration).addNewSeriesToPatient(patientId, rxSeriesDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} rxId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RxApi
     */
    public copyToContinuous(rxId: string, options?: AxiosRequestConfig) {
        return RxApiFp(this.configuration).copyToContinuous(rxId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} rxId 
     * @param {boolean} softDelete 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RxApi
     */
    public deleteRx(rxId: string, softDelete: boolean, options?: AxiosRequestConfig) {
        return RxApiFp(this.configuration).deleteRx(rxId, softDelete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} seriesId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RxApi
     */
    public deleteSeries(seriesId: string, options?: AxiosRequestConfig) {
        return RxApiFp(this.configuration).deleteSeries(seriesId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RxApi
     */
    public getAllRx(patientId: number, options?: AxiosRequestConfig) {
        return RxApiFp(this.configuration).getAllRx(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {Array<string>} requestBody Array of Rx IDs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RxApi
     */
    public getAppStats(requestBody: Array<string>, options?: AxiosRequestConfig) {
        return RxApiFp(this.configuration).getAppStats(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RxApi
     */
    public getRx(id: string, options?: AxiosRequestConfig) {
        return RxApiFp(this.configuration).getRx(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RxApi
     */
    public getRxByNumber(id: string, options?: AxiosRequestConfig) {
        return RxApiFp(this.configuration).getRxByNumber(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RxApi
     */
    public getRxLackingAppointments(patientId: number, options?: AxiosRequestConfig) {
        return RxApiFp(this.configuration).getRxLackingAppointments(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} rxNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RxApi
     */
    public getRxPrice(rxNumber: string, options?: AxiosRequestConfig) {
        return RxApiFp(this.configuration).getRxPrice(rxNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RxApi
     */
    public getRxSeriesForPatient(patientId: number, options?: AxiosRequestConfig) {
        return RxApiFp(this.configuration).getRxSeriesForPatient(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RxApi
     */
    public getTherapyRxsSeriesForPatient(patientId: number, options?: AxiosRequestConfig) {
        return RxApiFp(this.configuration).getTherapyRxsSeriesForPatient(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} patientId 
     * @param {Array<RxDto>} rxDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RxApi
     */
    public patientSaveRxs(patientId: number, rxDto: Array<RxDto>, options?: AxiosRequestConfig) {
        return RxApiFp(this.configuration).patientSaveRxs(patientId, rxDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {RxDto} rxDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RxApi
     */
    public saveRx(rxDto: RxDto, options?: AxiosRequestConfig) {
        return RxApiFp(this.configuration).saveRx(rxDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} rxId 
     * @param {string} processingStatus 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RxApi
     */
    public updateProcessingStatus(rxId: string, processingStatus: string, options?: AxiosRequestConfig) {
        return RxApiFp(this.configuration).updateProcessingStatus(rxId, processingStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} seriesId 
     * @param {number} patientId 
     * @param {RxSeriesDto} rxSeriesDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RxApi
     */
    public updateSeries(seriesId: string, patientId: number, rxSeriesDto: RxSeriesDto, options?: AxiosRequestConfig) {
        return RxApiFp(this.configuration).updateSeries(seriesId, patientId, rxSeriesDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} therapyRxId 
     * @param {string} newSeriesId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RxApi
     */
    public updateSeriesForTherapyRx(therapyRxId: string, newSeriesId: string, options?: AxiosRequestConfig) {
        return RxApiFp(this.configuration).updateSeriesForTherapyRx(therapyRxId, newSeriesId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TicketsApi - axios parameter creator
 * @export
 */
export const TicketsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {TicketDto} ticketDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTicket: async (ticketDto: TicketDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketDto' is not null or undefined
            assertParamExists('createTicket', 'ticketDto', ticketDto)
            const localVarPath = `/tickets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ticketDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteComment: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteComment', 'id', id)
            const localVarPath = `/tickets/deleteComment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [ticketStatus] 
         * @param {string} [createdByUserId] 
         * @param {string} [assignedToUserId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTickets: async (limit?: number, page?: number, ticketStatus?: string, createdByUserId?: string, assignedToUserId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tickets/getAllTickets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (ticketStatus !== undefined) {
                localVarQueryParameter['ticketStatus'] = ticketStatus;
            }

            if (createdByUserId !== undefined) {
                localVarQueryParameter['createdByUserId'] = createdByUserId;
            }

            if (assignedToUserId !== undefined) {
                localVarQueryParameter['assignedToUserId'] = assignedToUserId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} appointmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentTickets: async (appointmentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appointmentId' is not null or undefined
            assertParamExists('getAppointmentTickets', 'appointmentId', appointmentId)
            const localVarPath = `/tickets/getAppointmentTickets/{appointmentId}`
                .replace(`{${"appointmentId"}}`, encodeURIComponent(String(appointmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {Array<string>} entities 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityTicketTypes: async (entities: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entities' is not null or undefined
            assertParamExists('getEntityTicketTypes', 'entities', entities)
            const localVarPath = `/tickets/entityTicketTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (entities) {
                localVarQueryParameter['entities'] = entities;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientTickets: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getPatientTickets', 'patientId', patientId)
            const localVarPath = `/tickets/getPatienttickets/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTicketById', 'id', id)
            const localVarPath = `/tickets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketFilters: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tickets/ticketFilters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {ActivityDto} activityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postorUpateComment: async (id: number, activityDto: ActivityDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postorUpateComment', 'id', id)
            // verify required parameter 'activityDto' is not null or undefined
            assertParamExists('postorUpateComment', 'activityDto', activityDto)
            const localVarPath = `/tickets/postorUpateComment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {TicketDto} ticketDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTickets: async (id: number, ticketDto: TicketDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTickets', 'id', id)
            // verify required parameter 'ticketDto' is not null or undefined
            assertParamExists('updateTickets', 'ticketDto', ticketDto)
            const localVarPath = `/tickets/update/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ticketDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TicketsApi - functional programming interface
 * @export
 */
export const TicketsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TicketsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {TicketDto} ticketDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTicket(ticketDto: TicketDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TicketDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTicket(ticketDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteComment(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteComment(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [ticketStatus] 
         * @param {string} [createdByUserId] 
         * @param {string} [assignedToUserId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllTickets(limit?: number, page?: number, ticketStatus?: string, createdByUserId?: string, assignedToUserId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TicketDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllTickets(limit, page, ticketStatus, createdByUserId, assignedToUserId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} appointmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppointmentTickets(appointmentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppointmentTickets(appointmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {Array<string>} entities 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntityTicketTypes(entities: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EntityTicketTypesDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEntityTicketTypes(entities, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientTickets(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientTickets(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTicketById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TicketDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTicketById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTicketFilters(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TicketFilterDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTicketFilters(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {ActivityDto} activityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postorUpateComment(id: number, activityDto: ActivityDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postorUpateComment(id, activityDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {TicketDto} ticketDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTickets(id: number, ticketDto: TicketDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TicketDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTickets(id, ticketDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TicketsApi - factory interface
 * @export
 */
export const TicketsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TicketsApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {TicketDto} ticketDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTicket(ticketDto: TicketDto, options?: any): AxiosPromise<TicketDto> {
            return localVarFp.createTicket(ticketDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteComment(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteComment(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [ticketStatus] 
         * @param {string} [createdByUserId] 
         * @param {string} [assignedToUserId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTickets(limit?: number, page?: number, ticketStatus?: string, createdByUserId?: string, assignedToUserId?: string, options?: any): AxiosPromise<Array<TicketDto>> {
            return localVarFp.getAllTickets(limit, page, ticketStatus, createdByUserId, assignedToUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} appointmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentTickets(appointmentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.getAppointmentTickets(appointmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {Array<string>} entities 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityTicketTypes(entities: Array<string>, options?: any): AxiosPromise<Array<EntityTicketTypesDto>> {
            return localVarFp.getEntityTicketTypes(entities, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientTickets(patientId: number, options?: any): AxiosPromise<void> {
            return localVarFp.getPatientTickets(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketById(id: number, options?: any): AxiosPromise<TicketDto> {
            return localVarFp.getTicketById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketFilters(options?: any): AxiosPromise<Array<TicketFilterDto>> {
            return localVarFp.getTicketFilters(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {ActivityDto} activityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postorUpateComment(id: number, activityDto: ActivityDto, options?: any): AxiosPromise<void> {
            return localVarFp.postorUpateComment(id, activityDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {TicketDto} ticketDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTickets(id: number, ticketDto: TicketDto, options?: any): AxiosPromise<TicketDto> {
            return localVarFp.updateTickets(id, ticketDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TicketsApi - object-oriented interface
 * @export
 * @class TicketsApi
 * @extends {BaseAPI}
 */
export class TicketsApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {TicketDto} ticketDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public createTicket(ticketDto: TicketDto, options?: AxiosRequestConfig) {
        return TicketsApiFp(this.configuration).createTicket(ticketDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public deleteComment(id: string, options?: AxiosRequestConfig) {
        return TicketsApiFp(this.configuration).deleteComment(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [ticketStatus] 
     * @param {string} [createdByUserId] 
     * @param {string} [assignedToUserId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public getAllTickets(limit?: number, page?: number, ticketStatus?: string, createdByUserId?: string, assignedToUserId?: string, options?: AxiosRequestConfig) {
        return TicketsApiFp(this.configuration).getAllTickets(limit, page, ticketStatus, createdByUserId, assignedToUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} appointmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public getAppointmentTickets(appointmentId: string, options?: AxiosRequestConfig) {
        return TicketsApiFp(this.configuration).getAppointmentTickets(appointmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {Array<string>} entities 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public getEntityTicketTypes(entities: Array<string>, options?: AxiosRequestConfig) {
        return TicketsApiFp(this.configuration).getEntityTicketTypes(entities, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public getPatientTickets(patientId: number, options?: AxiosRequestConfig) {
        return TicketsApiFp(this.configuration).getPatientTickets(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public getTicketById(id: number, options?: AxiosRequestConfig) {
        return TicketsApiFp(this.configuration).getTicketById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public getTicketFilters(options?: AxiosRequestConfig) {
        return TicketsApiFp(this.configuration).getTicketFilters(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} id 
     * @param {ActivityDto} activityDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public postorUpateComment(id: number, activityDto: ActivityDto, options?: AxiosRequestConfig) {
        return TicketsApiFp(this.configuration).postorUpateComment(id, activityDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} id 
     * @param {TicketDto} ticketDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public updateTickets(id: number, ticketDto: TicketDto, options?: AxiosRequestConfig) {
        return TicketsApiFp(this.configuration).updateTickets(id, ticketDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TransactionsApi - axios parameter creator
 * @export
 */
export const TransactionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {CreateTransactionDto} createTransactionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTransaction: async (createTransactionDto: CreateTransactionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTransactionDto' is not null or undefined
            assertParamExists('createTransaction', 'createTransactionDto', createTransactionDto)
            const localVarPath = `/transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTransactionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTransactions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBadgeUnpaidBillsByPatientId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBadgeUnpaidBillsByPatientId', 'id', id)
            const localVarPath = `/transactions/bill/badgeUnpaid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCashTransactionsBetweenDates: async (startDate: string, endDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getCashTransactionsBetweenDates', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getCashTransactionsBetweenDates', 'endDate', endDate)
            const localVarPath = `/transactions/cash/between-dates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} searchString 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCashTransactionsWithSearch: async (startDate: string, endDate: string, searchString: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getCashTransactionsWithSearch', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getCashTransactionsWithSearch', 'endDate', endDate)
            // verify required parameter 'searchString' is not null or undefined
            assertParamExists('getCashTransactionsWithSearch', 'searchString', searchString)
            const localVarPath = `/transactions/cash/search-between-dates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (searchString !== undefined) {
                localVarQueryParameter['searchString'] = searchString;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDuePaymentByRxNumber: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDuePaymentByRxNumber', 'id', id)
            const localVarPath = `/transactions/bill/due/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEcCashTransactionsBetweenDates: async (startDate: string, endDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getEcCashTransactionsBetweenDates', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getEcCashTransactionsBetweenDates', 'endDate', endDate)
            const localVarPath = `/transactions/ec-cash/between-dates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} searchString 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEcCashTransactionsWithSearch: async (startDate: string, endDate: string, searchString: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getEcCashTransactionsWithSearch', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getEcCashTransactionsWithSearch', 'endDate', endDate)
            // verify required parameter 'searchString' is not null or undefined
            assertParamExists('getEcCashTransactionsWithSearch', 'searchString', searchString)
            const localVarPath = `/transactions/ec-cash/search-between-dates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (searchString !== undefined) {
                localVarQueryParameter['searchString'] = searchString;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastSchriftTransactionsBetweenDates: async (startDate: string, endDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getLastSchriftTransactionsBetweenDates', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getLastSchriftTransactionsBetweenDates', 'endDate', endDate)
            const localVarPath = `/transactions/lastschrift/between-dates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} searchString 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastSchriftTransactionsWithSearch: async (startDate: string, endDate: string, searchString: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getLastSchriftTransactionsWithSearch', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getLastSchriftTransactionsWithSearch', 'endDate', endDate)
            // verify required parameter 'searchString' is not null or undefined
            assertParamExists('getLastSchriftTransactionsWithSearch', 'searchString', searchString)
            const localVarPath = `/transactions/lastschrift/search-between-dates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (searchString !== undefined) {
                localVarQueryParameter['searchString'] = searchString;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaidBillsByPatientId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPaidBillsByPatientId', 'id', id)
            const localVarPath = `/transactions/bill/paid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRechnungTransactionById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRechnungTransactionById', 'id', id)
            const localVarPath = `/transactions/rechnung/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRechnungTransactionsBetweenDates: async (startDate: string, endDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getRechnungTransactionsBetweenDates', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getRechnungTransactionsBetweenDates', 'endDate', endDate)
            const localVarPath = `/transactions/rechnung/between-dates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} searchString 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRechnungTransactionsWithSearch: async (startDate: string, endDate: string, searchString: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getRechnungTransactionsWithSearch', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getRechnungTransactionsWithSearch', 'endDate', endDate)
            // verify required parameter 'searchString' is not null or undefined
            assertParamExists('getRechnungTransactionsWithSearch', 'searchString', searchString)
            const localVarPath = `/transactions/rechnung/search-between-dates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (searchString !== undefined) {
                localVarQueryParameter['searchString'] = searchString;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionByPatientId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTransactionByPatientId', 'id', id)
            const localVarPath = `/transactions/patient/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnpaidBillsByPatientId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUnpaidBillsByPatientId', 'id', id)
            const localVarPath = `/transactions/bill/unpaid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransactionsApi - functional programming interface
 * @export
 */
export const TransactionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TransactionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {CreateTransactionDto} createTransactionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTransaction(createTransactionDto: CreateTransactionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseTransactionDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTransaction(createTransactionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllTransactions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseTransactionDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllTransactions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBadgeUnpaidBillsByPatientId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBadgeUnpaidBillsByPatientId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCashTransactionsBetweenDates(startDate: string, endDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCashDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCashTransactionsBetweenDates(startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} searchString 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCashTransactionsWithSearch(startDate: string, endDate: string, searchString: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCashDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCashTransactionsWithSearch(startDate, endDate, searchString, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDuePaymentByRxNumber(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDuePaymentByRxNumber(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEcCashTransactionsBetweenDates(startDate: string, endDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEcCashTransactionsBetweenDates(startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} searchString 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEcCashTransactionsWithSearch(startDate: string, endDate: string, searchString: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEcCashTransactionsWithSearch(startDate, endDate, searchString, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLastSchriftTransactionsBetweenDates(startDate: string, endDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLastSchriftTransactionsBetweenDates(startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} searchString 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLastSchriftTransactionsWithSearch(startDate: string, endDate: string, searchString: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLastSchriftTransactionsWithSearch(startDate, endDate, searchString, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaidBillsByPatientId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaidBillsByPatientId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRechnungTransactionById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRechnungDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRechnungTransactionById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRechnungTransactionsBetweenDates(startDate: string, endDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRechnungTransactionsBetweenDates(startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} searchString 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRechnungTransactionsWithSearch(startDate: string, endDate: string, searchString: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRechnungTransactionsWithSearch(startDate, endDate, searchString, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransactionByPatientId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseTransactionDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTransactionByPatientId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnpaidBillsByPatientId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnpaidBillsByPatientId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TransactionsApi - factory interface
 * @export
 */
export const TransactionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TransactionsApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {CreateTransactionDto} createTransactionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTransaction(createTransactionDto: CreateTransactionDto, options?: any): AxiosPromise<BaseTransactionDetailsDto> {
            return localVarFp.createTransaction(createTransactionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTransactions(options?: any): AxiosPromise<BaseTransactionDetailsDto> {
            return localVarFp.getAllTransactions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBadgeUnpaidBillsByPatientId(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.getBadgeUnpaidBillsByPatientId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCashTransactionsBetweenDates(startDate: string, endDate: string, options?: any): AxiosPromise<GetCashDetailsDto> {
            return localVarFp.getCashTransactionsBetweenDates(startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} searchString 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCashTransactionsWithSearch(startDate: string, endDate: string, searchString: string, options?: any): AxiosPromise<GetCashDetailsDto> {
            return localVarFp.getCashTransactionsWithSearch(startDate, endDate, searchString, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDuePaymentByRxNumber(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.getDuePaymentByRxNumber(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEcCashTransactionsBetweenDates(startDate: string, endDate: string, options?: any): AxiosPromise<void> {
            return localVarFp.getEcCashTransactionsBetweenDates(startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} searchString 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEcCashTransactionsWithSearch(startDate: string, endDate: string, searchString: string, options?: any): AxiosPromise<void> {
            return localVarFp.getEcCashTransactionsWithSearch(startDate, endDate, searchString, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastSchriftTransactionsBetweenDates(startDate: string, endDate: string, options?: any): AxiosPromise<void> {
            return localVarFp.getLastSchriftTransactionsBetweenDates(startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} searchString 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastSchriftTransactionsWithSearch(startDate: string, endDate: string, searchString: string, options?: any): AxiosPromise<void> {
            return localVarFp.getLastSchriftTransactionsWithSearch(startDate, endDate, searchString, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaidBillsByPatientId(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.getPaidBillsByPatientId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRechnungTransactionById(id: string, options?: any): AxiosPromise<GetRechnungDetailsDto> {
            return localVarFp.getRechnungTransactionById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRechnungTransactionsBetweenDates(startDate: string, endDate: string, options?: any): AxiosPromise<void> {
            return localVarFp.getRechnungTransactionsBetweenDates(startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {string} searchString 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRechnungTransactionsWithSearch(startDate: string, endDate: string, searchString: string, options?: any): AxiosPromise<void> {
            return localVarFp.getRechnungTransactionsWithSearch(startDate, endDate, searchString, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionByPatientId(id: number, options?: any): AxiosPromise<BaseTransactionDetailsDto> {
            return localVarFp.getTransactionByPatientId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnpaidBillsByPatientId(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.getUnpaidBillsByPatientId(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TransactionsApi - object-oriented interface
 * @export
 * @class TransactionsApi
 * @extends {BaseAPI}
 */
export class TransactionsApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {CreateTransactionDto} createTransactionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public createTransaction(createTransactionDto: CreateTransactionDto, options?: AxiosRequestConfig) {
        return TransactionsApiFp(this.configuration).createTransaction(createTransactionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public getAllTransactions(options?: AxiosRequestConfig) {
        return TransactionsApiFp(this.configuration).getAllTransactions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public getBadgeUnpaidBillsByPatientId(id: number, options?: AxiosRequestConfig) {
        return TransactionsApiFp(this.configuration).getBadgeUnpaidBillsByPatientId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public getCashTransactionsBetweenDates(startDate: string, endDate: string, options?: AxiosRequestConfig) {
        return TransactionsApiFp(this.configuration).getCashTransactionsBetweenDates(startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {string} searchString 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public getCashTransactionsWithSearch(startDate: string, endDate: string, searchString: string, options?: AxiosRequestConfig) {
        return TransactionsApiFp(this.configuration).getCashTransactionsWithSearch(startDate, endDate, searchString, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public getDuePaymentByRxNumber(id: string, options?: AxiosRequestConfig) {
        return TransactionsApiFp(this.configuration).getDuePaymentByRxNumber(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public getEcCashTransactionsBetweenDates(startDate: string, endDate: string, options?: AxiosRequestConfig) {
        return TransactionsApiFp(this.configuration).getEcCashTransactionsBetweenDates(startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {string} searchString 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public getEcCashTransactionsWithSearch(startDate: string, endDate: string, searchString: string, options?: AxiosRequestConfig) {
        return TransactionsApiFp(this.configuration).getEcCashTransactionsWithSearch(startDate, endDate, searchString, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public getLastSchriftTransactionsBetweenDates(startDate: string, endDate: string, options?: AxiosRequestConfig) {
        return TransactionsApiFp(this.configuration).getLastSchriftTransactionsBetweenDates(startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {string} searchString 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public getLastSchriftTransactionsWithSearch(startDate: string, endDate: string, searchString: string, options?: AxiosRequestConfig) {
        return TransactionsApiFp(this.configuration).getLastSchriftTransactionsWithSearch(startDate, endDate, searchString, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public getPaidBillsByPatientId(id: number, options?: AxiosRequestConfig) {
        return TransactionsApiFp(this.configuration).getPaidBillsByPatientId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public getRechnungTransactionById(id: string, options?: AxiosRequestConfig) {
        return TransactionsApiFp(this.configuration).getRechnungTransactionById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public getRechnungTransactionsBetweenDates(startDate: string, endDate: string, options?: AxiosRequestConfig) {
        return TransactionsApiFp(this.configuration).getRechnungTransactionsBetweenDates(startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {string} searchString 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public getRechnungTransactionsWithSearch(startDate: string, endDate: string, searchString: string, options?: AxiosRequestConfig) {
        return TransactionsApiFp(this.configuration).getRechnungTransactionsWithSearch(startDate, endDate, searchString, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public getTransactionByPatientId(id: number, options?: AxiosRequestConfig) {
        return TransactionsApiFp(this.configuration).getTransactionByPatientId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public getUnpaidBillsByPatientId(id: number, options?: AxiosRequestConfig) {
        return TransactionsApiFp(this.configuration).getUnpaidBillsByPatientId(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserProfileApi - axios parameter creator
 * @export
 */
export const UserProfileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {boolean} softDelete 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateOrDeactivateUser: async (userId: string, softDelete: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('activateOrDeactivateUser', 'userId', userId)
            // verify required parameter 'softDelete' is not null or undefined
            assertParamExists('activateOrDeactivateUser', 'softDelete', softDelete)
            const localVarPath = `/user-profile/activateOrDeactivateUser/{userId}/{softDelete}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"softDelete"}}`, encodeURIComponent(String(softDelete)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user-profile/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {string} newLocationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserLocation: async (userId: string, newLocationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('changeUserLocation', 'userId', userId)
            // verify required parameter 'newLocationId' is not null or undefined
            assertParamExists('changeUserLocation', 'newLocationId', newLocationId)
            const localVarPath = `/user-profile/changeUserLocation/{userId}/{newLocationId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"newLocationId"}}`, encodeURIComponent(String(newLocationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {UserRegisterDto} userRegisterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserLoginDetails: async (userRegisterDto: UserRegisterDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userRegisterDto' is not null or undefined
            assertParamExists('changeUserLoginDetails', 'userRegisterDto', userRegisterDto)
            const localVarPath = `/user-profile/changeUserLoginDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userRegisterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {WeeklyWorkDayDto} weeklyWorkDayDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWeeklyWorkDay: async (weeklyWorkDayDto: WeeklyWorkDayDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'weeklyWorkDayDto' is not null or undefined
            assertParamExists('createWeeklyWorkDay', 'weeklyWorkDayDto', weeklyWorkDayDto)
            const localVarPath = `/user-profile/createWeeklyWorkDay`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(weeklyWorkDayDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {WorkPlanDto} workPlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkPlan: async (workPlanDto: WorkPlanDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workPlanDto' is not null or undefined
            assertParamExists('createWorkPlan', 'workPlanDto', workPlanDto)
            const localVarPath = `/user-profile/workPlan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workPlanDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletUser: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deletUser', 'userId', userId)
            const localVarPath = `/user-profile/delete/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWeeklyWorkDay: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteWeeklyWorkDay', 'id', id)
            const localVarPath = `/user-profile/deleteWeeklyWorkDay/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkPlan: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteWorkPlan', 'id', id)
            const localVarPath = `/user-profile/deleteWorkPlan/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} workPlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fillSlotsForWorkPlan: async (workPlanId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workPlanId' is not null or undefined
            assertParamExists('fillSlotsForWorkPlan', 'workPlanId', workPlanId)
            const localVarPath = `/user-profile/workPlans/fillSlots/{workPlanId}`
                .replace(`{${"workPlanId"}}`, encodeURIComponent(String(workPlanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthenticatedUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user-profile/getAuthUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTherapistCredentials: async (locationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('getTherapistCredentials', 'locationId', locationId)
            const localVarPath = `/user-profile/therapistCredentials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (locationId !== undefined) {
                localVarQueryParameter['locationId'] = locationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserById', 'userId', userId)
            const localVarPath = `/user-profile/getUser/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user-profile/getAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} [locationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersBy: async (locationId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user-profile/getUsersBy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (locationId !== undefined) {
                localVarQueryParameter['locationId'] = locationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWeeklyWorkDays: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getWeeklyWorkDays', 'userId', userId)
            const localVarPath = `/user-profile/weeklyWorkDays/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {number} workPlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWeeklyWorkDaysByWorkPlan: async (userId: string, workPlanId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getWeeklyWorkDaysByWorkPlan', 'userId', userId)
            // verify required parameter 'workPlanId' is not null or undefined
            assertParamExists('getWeeklyWorkDaysByWorkPlan', 'workPlanId', workPlanId)
            const localVarPath = `/user-profile/weeklyWorkDays/{userId}/{workPlanId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"workPlanId"}}`, encodeURIComponent(String(workPlanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkPlans: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getWorkPlans', 'userId', userId)
            const localVarPath = `/user-profile/workPlans/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} workPlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isWorkPlanOutdated: async (workPlanId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workPlanId' is not null or undefined
            assertParamExists('isWorkPlanOutdated', 'workPlanId', workPlanId)
            const localVarPath = `/user-profile/workPlans/isOutdated/{workPlanId}`
                .replace(`{${"workPlanId"}}`, encodeURIComponent(String(workPlanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {WeeklyWorkDayDto} weeklyWorkDayDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putWeeklyWorkDay: async (weeklyWorkDayDto: WeeklyWorkDayDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'weeklyWorkDayDto' is not null or undefined
            assertParamExists('putWeeklyWorkDay', 'weeklyWorkDayDto', weeklyWorkDayDto)
            const localVarPath = `/user-profile/putWeeklyWorkDay`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(weeklyWorkDayDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {UserDto} userDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserInfo: async (userDto: UserDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userDto' is not null or undefined
            assertParamExists('saveUserInfo', 'userDto', userDto)
            const localVarPath = `/user-profile/saveUserInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendOtp: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('sendOtp', 'userId', userId)
            const localVarPath = `/user-profile/sendOtp/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} email 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userProfile: async (email: string, username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('userProfile', 'email', email)
            // verify required parameter 'username' is not null or undefined
            assertParamExists('userProfile', 'username', username)
            const localVarPath = `/user-profile/getUserByLogin/{email}/{username}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)))
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WorkPlanDto} workPlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userProfileControllerPutWorkPlan: async (workPlanDto: WorkPlanDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workPlanDto' is not null or undefined
            assertParamExists('userProfileControllerPutWorkPlan', 'workPlanDto', workPlanDto)
            const localVarPath = `/user-profile/putWorkPlan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workPlanDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {UserRegisterDto} userRegisterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRegister: async (userRegisterDto: UserRegisterDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userRegisterDto' is not null or undefined
            assertParamExists('userRegister', 'userRegisterDto', userRegisterDto)
            const localVarPath = `/user-profile/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userRegisterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserProfileApi - functional programming interface
 * @export
 */
export const UserProfileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserProfileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {boolean} softDelete 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateOrDeactivateUser(userId: string, softDelete: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activateOrDeactivateUser(userId, softDelete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {string} newLocationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeUserLocation(userId: string, newLocationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeUserLocation(userId, newLocationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {UserRegisterDto} userRegisterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeUserLoginDetails(userRegisterDto: UserRegisterDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeUserLoginDetails(userRegisterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {WeeklyWorkDayDto} weeklyWorkDayDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWeeklyWorkDay(weeklyWorkDayDto: WeeklyWorkDayDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWeeklyWorkDay(weeklyWorkDayDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {WorkPlanDto} workPlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWorkPlan(workPlanDto: WorkPlanDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWorkPlan(workPlanDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletUser(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWeeklyWorkDay(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWeeklyWorkDay(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWorkPlan(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWorkPlan(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} workPlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fillSlotsForWorkPlan(workPlanId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fillSlotsForWorkPlan(workPlanId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthenticatedUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuthenticatedUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTherapistCredentials(locationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTherapistCredentials(locationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserById(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserById(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} [locationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersBy(locationId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersBy(locationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWeeklyWorkDays(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWeeklyWorkDays(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {number} workPlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWeeklyWorkDaysByWorkPlan(userId: string, workPlanId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWeeklyWorkDaysByWorkPlan(userId, workPlanId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkPlans(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkPlans(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} workPlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isWorkPlanOutdated(workPlanId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isWorkPlanOutdated(workPlanId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {WeeklyWorkDayDto} weeklyWorkDayDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putWeeklyWorkDay(weeklyWorkDayDto: WeeklyWorkDayDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putWeeklyWorkDay(weeklyWorkDayDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {UserDto} userDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveUserInfo(userDto: UserDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveUserInfo(userDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendOtp(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendOtp(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} email 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userProfile(email: string, username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userProfile(email, username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {WorkPlanDto} workPlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userProfileControllerPutWorkPlan(workPlanDto: WorkPlanDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userProfileControllerPutWorkPlan(workPlanDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {UserRegisterDto} userRegisterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userRegister(userRegisterDto: UserRegisterDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userRegister(userRegisterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserProfileApi - factory interface
 * @export
 */
export const UserProfileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserProfileApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {boolean} softDelete 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateOrDeactivateUser(userId: string, softDelete: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.activateOrDeactivateUser(userId, softDelete, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInfo(options?: any): AxiosPromise<void> {
            return localVarFp.apiInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {string} newLocationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserLocation(userId: string, newLocationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.changeUserLocation(userId, newLocationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {UserRegisterDto} userRegisterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserLoginDetails(userRegisterDto: UserRegisterDto, options?: any): AxiosPromise<void> {
            return localVarFp.changeUserLoginDetails(userRegisterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {WeeklyWorkDayDto} weeklyWorkDayDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWeeklyWorkDay(weeklyWorkDayDto: WeeklyWorkDayDto, options?: any): AxiosPromise<object> {
            return localVarFp.createWeeklyWorkDay(weeklyWorkDayDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {WorkPlanDto} workPlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkPlan(workPlanDto: WorkPlanDto, options?: any): AxiosPromise<void> {
            return localVarFp.createWorkPlan(workPlanDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletUser(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deletUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWeeklyWorkDay(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteWeeklyWorkDay(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkPlan(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteWorkPlan(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} workPlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fillSlotsForWorkPlan(workPlanId: number, options?: any): AxiosPromise<string> {
            return localVarFp.fillSlotsForWorkPlan(workPlanId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthenticatedUser(options?: any): AxiosPromise<void> {
            return localVarFp.getAuthenticatedUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTherapistCredentials(locationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.getTherapistCredentials(locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.getUserById(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(options?: any): AxiosPromise<void> {
            return localVarFp.getUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} [locationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersBy(locationId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.getUsersBy(locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWeeklyWorkDays(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.getWeeklyWorkDays(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {number} workPlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWeeklyWorkDaysByWorkPlan(userId: string, workPlanId: number, options?: any): AxiosPromise<void> {
            return localVarFp.getWeeklyWorkDaysByWorkPlan(userId, workPlanId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkPlans(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.getWorkPlans(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} workPlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isWorkPlanOutdated(workPlanId: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.isWorkPlanOutdated(workPlanId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {WeeklyWorkDayDto} weeklyWorkDayDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putWeeklyWorkDay(weeklyWorkDayDto: WeeklyWorkDayDto, options?: any): AxiosPromise<object> {
            return localVarFp.putWeeklyWorkDay(weeklyWorkDayDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {UserDto} userDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserInfo(userDto: UserDto, options?: any): AxiosPromise<void> {
            return localVarFp.saveUserInfo(userDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendOtp(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.sendOtp(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} email 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userProfile(email: string, username: string, options?: any): AxiosPromise<void> {
            return localVarFp.userProfile(email, username, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WorkPlanDto} workPlanDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userProfileControllerPutWorkPlan(workPlanDto: WorkPlanDto, options?: any): AxiosPromise<void> {
            return localVarFp.userProfileControllerPutWorkPlan(workPlanDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {UserRegisterDto} userRegisterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRegister(userRegisterDto: UserRegisterDto, options?: any): AxiosPromise<void> {
            return localVarFp.userRegister(userRegisterDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserProfileApi - object-oriented interface
 * @export
 * @class UserProfileApi
 * @extends {BaseAPI}
 */
export class UserProfileApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {string} userId 
     * @param {boolean} softDelete 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public activateOrDeactivateUser(userId: string, softDelete: boolean, options?: AxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).activateOrDeactivateUser(userId, softDelete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public apiInfo(options?: AxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).apiInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} userId 
     * @param {string} newLocationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public changeUserLocation(userId: string, newLocationId: string, options?: AxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).changeUserLocation(userId, newLocationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {UserRegisterDto} userRegisterDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public changeUserLoginDetails(userRegisterDto: UserRegisterDto, options?: AxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).changeUserLoginDetails(userRegisterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {WeeklyWorkDayDto} weeklyWorkDayDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public createWeeklyWorkDay(weeklyWorkDayDto: WeeklyWorkDayDto, options?: AxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).createWeeklyWorkDay(weeklyWorkDayDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {WorkPlanDto} workPlanDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public createWorkPlan(workPlanDto: WorkPlanDto, options?: AxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).createWorkPlan(workPlanDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public deletUser(userId: string, options?: AxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).deletUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public deleteWeeklyWorkDay(id: number, options?: AxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).deleteWeeklyWorkDay(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public deleteWorkPlan(id: number, options?: AxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).deleteWorkPlan(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} workPlanId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public fillSlotsForWorkPlan(workPlanId: number, options?: AxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).fillSlotsForWorkPlan(workPlanId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public getAuthenticatedUser(options?: AxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).getAuthenticatedUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} locationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public getTherapistCredentials(locationId: string, options?: AxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).getTherapistCredentials(locationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public getUserById(userId: string, options?: AxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).getUserById(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public getUsers(options?: AxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).getUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} [locationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public getUsersBy(locationId?: string, options?: AxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).getUsersBy(locationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public getWeeklyWorkDays(userId: string, options?: AxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).getWeeklyWorkDays(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} userId 
     * @param {number} workPlanId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public getWeeklyWorkDaysByWorkPlan(userId: string, workPlanId: number, options?: AxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).getWeeklyWorkDaysByWorkPlan(userId, workPlanId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public getWorkPlans(userId: string, options?: AxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).getWorkPlans(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} workPlanId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public isWorkPlanOutdated(workPlanId: number, options?: AxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).isWorkPlanOutdated(workPlanId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {WeeklyWorkDayDto} weeklyWorkDayDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public putWeeklyWorkDay(weeklyWorkDayDto: WeeklyWorkDayDto, options?: AxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).putWeeklyWorkDay(weeklyWorkDayDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {UserDto} userDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public saveUserInfo(userDto: UserDto, options?: AxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).saveUserInfo(userDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public sendOtp(userId: string, options?: AxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).sendOtp(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} email 
     * @param {string} username 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public userProfile(email: string, username: string, options?: AxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).userProfile(email, username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkPlanDto} workPlanDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public userProfileControllerPutWorkPlan(workPlanDto: WorkPlanDto, options?: AxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).userProfileControllerPutWorkPlan(workPlanDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {UserRegisterDto} userRegisterDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public userRegister(userRegisterDto: UserRegisterDto, options?: AxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).userRegister(userRegisterDto, options).then((request) => request(this.axios, this.basePath));
    }
}



