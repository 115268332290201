import moment from "moment";
import {
  AppointmentDto,
  BackPlanResult,
  CAResponse,
  ContinuousAppointmentDto,
  ContinuousEventTimeSlotDto,
  EventTimeSlotDto,
  LeaveOfAbsenceDto,
  PatientAvailabilityDto,
  PatientDto,
  TimeSlotDto,
  UserDto,
} from "../../api-client";
import useStore from "../../helpers/useStore";
import { UserContext } from "../../stores/User/User.provider";
import Calendar from "../../molecules/Calendar/Calendar";
import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { CalendarContext } from "../../stores/Calendar/calendar.provider";
import { Event, TherapyExt, TimeSlotExt } from "../../molecules/Calendar/Calendar.type";
import { useParams, useSearchParams } from "react-router-dom";
import { PatientsContext } from "../../stores/Patients/patients.provider";
import { Draggable, EventReceiveArg } from "@fullcalendar/interaction";
import UnscheduledEvents from "../../molecules/Calendar/UnscheduledEvents";
import { observer } from "mobx-react";
import notificationStore from "../../stores/Notification/notificationStore";
import { AppointmentContext } from "../../stores/Appointment/appointment.provider";
import EventShelf from "../../molecules/Calendar/EventShelf";
import { Directions } from "@mui/icons-material";
import CalendarDrawer from "../../molecules/Calendar/CalendarDrawer";
import { AppointmentCardView } from "../PatientOverview/RxInfo/TerminView";
import { AppointmentCard } from "../ScheduleOverview/Appointments/AppointmentSuggestion";
import FlatCard from "../../atoms/FlatCard";
import { WebSocketContext } from "../../stores/WebSocket/WebSocket.provider";

const Scheduler = observer(() => {
  const DATE_FORMAT = "YYYY-MM-DD";
  const UserStore = useStore(UserContext);
  const { user } = UserStore;
  const location = user?.location?.id;
  if (!location) {
    console.warn("No location found for user");
  } else {
    console.log("No worries, location is right here:", location);
  }
  const [users, setUsers] = useState<UserDto[]>([]);
  const [appointments, setAppointments] = useState<AppointmentDto[]>([]);
  const [goneFishings, setGoneFishings] = useState<TimeSlotDto[]>([]);
  const [leaveOfAbsences, setLeaveOfAbsences] = useState<LeaveOfAbsenceDto[]>([]);
  const [EventTimeslots, setEventTimeslots] = useState<EventTimeSlotDto[]>([]);
  const [lunchBreaks, setLunchBreaks] = useState<TimeSlotDto[]>([]);
  const [travelTimes, setTravelTimes] = useState<TimeSlotDto[]>([]);

  const [continuousEventTimeslots, setContinuousEventTimeslots] = useState<
    ContinuousEventTimeSlotDto[]
  >([]);
  const [unscheduleds, setUnscheduleds] = useState<AppointmentDto[]>([]);
  const [showLoading, setShowLoading] = useState(false);
  const PatientsStore = useStore(PatientsContext);
  const { getPatient, getAllPatients } = PatientsStore;
  const CalendarStore = useStore(CalendarContext);
  const {
    getAppointment,
    getContinuousAppointment,
    getUsersByLocation,
    getPatientsByAppointmentDate,
    getAppointmentsByDateAndLocation,
    createTimeslot,
    createEventTimeSlot,
    createContinuousEventTimeSlot,
    createLeaveOfAbsence,
    saveAppointment,
    getUnscheduledPatientAppointments,
    deleteTimeslot,
    deleteContinuousTimeslot,
    getContinuousAppointmentsByLocation,
    getContinuousAppointmentsForPatient,
    getUnscheduledContinuousAppointments,
    updateTimeSlot,
    updateContinuousTimeSlot,
    getPatientUnavailabilityForCalendar,
    getPatientUnavailabeTypeByDateForCalendar,
    getLatestUpdatedAppointments
  } = CalendarStore;
  const AppointmentStore = useStore(AppointmentContext);
  const { updateContinuousAppointment, getAppointmentsForPatient } = AppointmentStore;
  const WebSocketStore = useStore(WebSocketContext);
  const { socket } = WebSocketStore;
  const [selectedDate, setSelectedDate] = useState<Date>(() => {
    return new Date(
      localStorage.getItem("lastViewedDate") || moment(new Date()).format("YYYY-MM-DD")
    );
  });
  const weeklyWorkDay = true;
  const [queryParameters] = useSearchParams();
  const [patient, setPatient] = useState<PatientDto>();
  const [continuousEvents, setContinuousEvents] = useState<ContinuousAppointmentDto[]>([]);
  const isContinuousParam = queryParameters.get("continuousView");
  const [continuousView, setContinuousView] = useState<boolean>(isContinuousParam === "true");
  const [shelfAppointments, setShelfAppointments] = useState<AppointmentDto[]>([]);
  const [shelfContinuousAppointments, setShelfContinuousAppointments] = useState<
    ContinuousAppointmentDto[]
  >([]);
  const [reFetch, setRefetch] = useState<Date>(new Date());
  const [patientAppointments, setPatientAppointments] = useState<AppointmentDto[]>();
  const [drawerOpen, setDrawerOpen] = useState(!!queryParameters.get("pid"));
  const [selectedEventRxId, setSelectedEventRxId] = useState<string | undefined>();
  const [targetPatient, setTargetPatient] = useState<PatientDto>();
  const [showOpenDrawerButton, setShowOpenDrawerButton] = useState<boolean>(
    !!queryParameters.get("pid")
  );
  const [showResponseDialog, setShowResponseDialog] = useState(false);
  const [responseDialogData, setResponseDialogData] = useState<CAResponse | undefined>();
  const [patientAvailability, setPatientAvailability] = useState<PatientAvailabilityDto[]>();
  const [patientUnavailabeTypes, setPatientUnavailabeTypes] = useState<TimeSlotDto[] | undefined>();

  const handleContinuousViewChange = async (continuous: boolean) => {
    const patientId = patient?.id?.toString();
    if (patientId) {
      await fetchUnscheduledAppointments(patientId, continuous);
    }
    setContinuousView(continuous);
    setShelfAppointments([]);
    setShelfContinuousAppointments([]);
  };

  useEffect(() => {
    const isContinuousParam = queryParameters.get("continuousView");
    if (isContinuousParam !== null) {
      handleContinuousViewChange(isContinuousParam === "true");
    }
  }, [queryParameters]);

  useEffect(() => {
     // Handle incoming appointment update
     socket.on("appointmentsUpdate", (updatedAppointments) => {
      console.log("Received appointments update:", updatedAppointments);
      // Here, you can update your UI with the received appointments
      // renderCalendar();
      
      // Update appointments with only the modified appointment
      const appointmentType = updatedAppointments.type || updatedAppointments.timeSlots?.[0]?.type;
      switch (appointmentType) {
        case "TimeSlot":
        case "Treatment":
          const updatedAppointment = updatedAppointments as AppointmentDto;
          setAppointments((prev) => {
            if (prev) {
              return prev.map((appointment) =>
                appointment.id === updatedAppointment.id ? updatedAppointment : appointment
              );
            }
            return prev;
          });
          notificationStore.showMessage("Termin erfolgreich geändert", "success");
          break;
        case "EventTimeSlot":
          const updatedEventTimeSlot = updatedAppointments as EventTimeSlotDto;
          // Find the index of the event time slot to update
          const eventIndex = EventTimeslots.findIndex(
            (eventTimeSlot) => eventTimeSlot.id === updatedEventTimeSlot.id
          );
          setEventTimeslots((prev) => {
            // Update the existing event if found, or add it if not found
            if (eventIndex !== -1) {
              return prev.map((eventTimeSlot) =>
                eventTimeSlot.id === updatedEventTimeSlot.id ? updatedEventTimeSlot : eventTimeSlot
              );
            }
            return [...prev, updatedEventTimeSlot];
          });
          notificationStore.showMessage("Event erfolgreich geändert", "success");
          break;
        // case "ContinuousEventTimeSlot":
        //   const updatedContinuousEventTimeSlot = updatedAppointments as ContinuousEventTimeSlotDto;
        //   // Find the index of the continuous event time slot to update
        //   const continuousEventIndex = continuousEvents.findIndex(
        //     (continuousEventTimeSlot) =>
        //       continuousEventTimeSlot.id === updatedContinuousEventTimeSlot.id
        //   );
        //   setContinuousEvents((prev) => {
        //     // Update the existing continuous event if found, or add it if not found
        //     if (continuousEventIndex !== -1) {
        //       return prev.map((continuousEventTimeSlot) =>
        //         continuousEventTimeSlot.id === updatedContinuousEventTimeSlot.id
        //           ? updatedContinuousEventTimeSlot
        //           : continuousEventTimeSlot
        //       );
        //     }
        //     return [...prev, updatedContinuousEventTimeSlot];
        //   });
        //   notificationStore.showMessage("Dauertermin erfolgreich geändert", "success");
        //   break;
        default:
          renderCalendar();
          break;
      }
    });
  }, [socket]);

  useEffect(() => {
    const handler = setTimeout(() => {
    const fetchData = async () => {
      if (!location) {
        // cant do anythign yet
        return;
      }
      try {
        Loading(true);
        console.log("Loading started");
        const patientId = queryParameters.get("pid");
        let date = moment(selectedDate).format(DATE_FORMAT);

        const fetchPromises = [
          fetchUsers(),
          continuousView
            ? fetchContiniuousAppointmentsByLocation(location)
            : fetchAppointments(date, location),
        ];

        if (patientId) {
          fetchPromises.push(fetchPatient(patientId));
          fetchPromises.push(fetchTargetPatient(patientId));
          fetchPromises.push(fetchUnscheduledAppointments(patientId, continuousView));
          fetchPromises.push(fetchPatientAppointments(patientId));
          fetchPromises.push(fetchPatientAvailability(patientId));
          fetchPromises.push(fetchPatientUnavailabeTypes(patientId));
        }

          await Promise.all(fetchPromises);

        } catch (error) {
          Loading(false);
          notificationStore.showMessage("Fehler beim Laden der Termine", "error", error);
          console.log("Loading error", error);
        } finally {
          Loading(false);
          console.log("Loading finished");
        }
      };
      
    fetchData();

    // // Start auto-refresh logic only after the debounced fetch
    // const interval = setInterval(checkLatestAppointmentChanges, 5 * 1000); // Auto-refresh every 5s

    // // Cleanup interval when dependencies change or component unmounts
    // return () => {
    //   clearInterval(interval);
    // };

    }, 500);

    // Cleanup the timeout if the user navigates again before the delay is finished
    return () => {
      clearTimeout(handler);
    };
  }, [selectedDate, location, continuousView, reFetch]);

  const Loading = (isLoading: boolean) => {
    setShowLoading(isLoading);
  };

  const fetchPatient = async (patientId: string) => {
    const pid = parseInt(patientId);
    try {
      const patient = await getPatient(pid);
      setPatient(patient);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTargetPatient = async (patientId: string) => {
    const pid = parseInt(patientId);
    try {
      const patient = await getPatient(pid);
      setTargetPatient(patient);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPatientAvailability = async(patientId: string) => {
    const pid = parseInt(patientId);
    try {
      const patientAvailablilties = await getPatientUnavailabilityForCalendar(pid);     
      console.log("patientAvailablilties:", patientAvailablilties);
      setPatientAvailability(patientAvailablilties);
    } catch (error) {
      console.log(error);
    }
  }

  const fetchPatientUnavailabeTypes = async(patientId: string) => {
    const pid = parseInt(patientId);
    try {
      const patientUnavailabeTypes = await getPatientUnavailabeTypeByDateForCalendar(pid, moment(selectedDate).format(DATE_FORMAT));
      console.log("patientUnavailabeTypes:", patientUnavailabeTypes);
      setPatientUnavailabeTypes(patientUnavailabeTypes);
    } catch (error) {
      console.log(error);
    }
  }

  const checkLatestAppointmentChanges = async () => {
    try {
      const latestUpdatedAppointments: AppointmentDto[] = await getLatestUpdatedAppointments(5);
      if (latestUpdatedAppointments.length > 0) {
        console.log("latestUpdatedAppointments:", latestUpdatedAppointments);
        renderCalendar();
      }
    } catch (error) {
      console.error(error);
      notificationStore.showMessage("Fehler beim Laden der Termine", "error", error);
    } finally {
      console.log("checkLatestAppointmentChanges Loading finished");
    }
  }

  const fetchUsers = async () => {
    try {
      const users = await getUsersByLocation(location!);
      setUsers(users);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAppointments = async (date: string, locationId: string) => {
    try {
      const data = await getAppointmentsByDateAndLocation(date, locationId, weeklyWorkDay);
      if (data) {
        setAppointments(data.appointments);
        setGoneFishings(data.goneFishings);
        setLeaveOfAbsences(data.leaveOfAbsences);
        setEventTimeslots(data.eventTimeSlots);
        setLunchBreaks(data.lunchBreaks);
        setTravelTimes(data.travelTimes);
        console.log("appointments:", data.appointments);
        console.log("goneFishings:", data.goneFishings);
        console.log("leaveOfAbsences:", data.leaveOfAbsences);
        console.log("eventTimeSlots:", data.eventTimeSlots);
        console.log("lunchBreaks:", data.lunchBreaks);
      }
    } catch (error) {
      console.error(error);
      notificationStore.showMessage("Fehler beim Laden der Termine", "error", error);
    } finally {
      console.log("fetchAppointments Loading finished");
    }
  };

  const fetchContiniuousAppointmentsByLocation = async (locationId: string) => {
    try {
      const continuousApps = await getContinuousAppointmentsByLocation(locationId);
      setContinuousEvents(continuousApps.appointments);
      setContinuousEventTimeslots(continuousApps.eventTimeSlots);
      console.log("Continiuous Appointments:", continuousApps.appointments);
      console.log("Continiuous EventTimeSlots:", continuousApps.eventTimeSlots);
    } catch (error) {
      console.error(error);
      notificationStore.showMessage("Fehler beim Laden der Dauertermine", "error", error);
    } finally {
      console.log("fetch Continiuous Appointments Loading finished");
    }
  };

  const fetchUnscheduledAppointments = async (patientId: string, continuousView: boolean) => {
    try {
      const unscheduleds = continuousView
        ? await getUnscheduledContinuousAppointments(patientId)
        : await getUnscheduledPatientAppointments(patientId);
      console.log("unscheduleds:", unscheduleds);
      setUnscheduleds(unscheduleds);
    } catch (error) {
      console.log(error);
    } finally {
      console.log("Unscheduled Loading finished");
    }
  };

  const fetchPatientAppointments = async (patientId: string) => {
    try {
      const appointments = await getAppointmentsForPatient(patientId);
      console.log("appointments:", appointments);
      setPatientAppointments(appointments);
    } catch (error) {
      console.log(error);
    } finally {
      console.log("fetchPatientAppointments Loading finished");
    }
  };

  const onCreateEvent = async (event: Event) => {
    try {
      console.log("Event", event);
      const userId: string = event.resourceId || "";
      const user = users.find((u) => u.id === userId);
      let result: any;

      switch (event.type) {
        case "GoneFishing":
          const timeslot: TimeSlotDto = {
            user,
            type: "GoneFishing",
            start: moment(event.start!).toISOString(),
            end: moment(event.end!).toISOString(),
          };
          console.log("Timeslot", timeslot);
          result = await createTimeslot(timeslot);
          break;
        case "LeaveOfAbsence":
          const leaveOfAbsence: LeaveOfAbsenceDto = {
            user,
            type: "LeaveOfAbsence",
            loAType: event.loaType,
            start: moment(event.start!).toISOString(),
            end: moment(event.end!).toISOString(),
          };
          console.log("LeaveOfAbsence", leaveOfAbsence);
          result = await createLeaveOfAbsence(leaveOfAbsence);
          break;
        case "EventTimeSlot":
          const eventTimeSlot: EventTimeSlotDto = {
            user,
            type: "EventTimeSlot",
            title: event.title,
            start: moment(event.start!).toISOString(),
            end: moment(event.end!).toISOString(),
          };
          console.log("EventTimeSlot", eventTimeSlot);
          result = await createEventTimeSlot(eventTimeSlot);
          break;
        case "TravelTime":
          const travelTime: ContinuousEventTimeSlotDto = {
            user,
            type: "ContinuousEventTimeSlot",
            title: event.title,
            start: moment(event.start!).toISOString(),
            end: moment(event.end!).toISOString(),
          };
          console.log("travelTime", travelTime);
          throw new Error("TravelTime creation not implemented");
          // result = await createContinuousEventTimeSlot(continuousEventTimeSlot);
          break;
        case "ContinuousEventTimeSlot":
          const continuousEventTimeSlot: ContinuousEventTimeSlotDto = {
            user,
            type: "ContinuousEventTimeSlot",
            title: event.title,
            start: moment(event.start!).toISOString(),
            end: moment(event.end!).toISOString(),
          };
          console.log("ContinuousEventTimeSlot", continuousEventTimeSlot);
          result = await createContinuousEventTimeSlot(continuousEventTimeSlot);
          break;
        default:
          break;
      }
      notificationStore.showMessage("Event erfolgreich hinzugefügt", "success");
      console.log(result);
    } catch (error) {
      console.log(error);
      notificationStore.showMessage("Fehler beim Hinzufügen des Events", "error", error);
    } finally {
      console.log("Calling renderCalendar");
      renderCalendar();
    }
  };

  const renderCalendar = async () => {
    let date = moment(selectedDate).format(DATE_FORMAT);
    console.log("renderCalendar date:", date);
    setRefetch(new Date());
    // continuousView ?
    // fetchContiniuousAppointmentsByLocation(location!) :
    // fetchAppointments(date, location!);
  };

  const updateContinuousWithResponse = async (
    id: string,
    appointment: ContinuousAppointmentDto
  ) => {
    const result = await updateContinuousAppointment(id, appointment);
    renderCalendar();
    setResponseDialogData(result);
    if (result && result.backPlanResults.length > 0) setShowResponseDialog(true);
  };

  const onChangeEvent = async (event: Event, relatedEvents?: Event[]) => {
    const userId = event.resourceId || "";
    const user = users.find((u) => u.id === userId);

    const relatedEventUserId = relatedEvents?.map((event) => event.resourceId)[0];
    const relatedEventUser = users.find((u) => u.id === relatedEventUserId);

    console.log("onChangeEvent Event", event);

    if (!event.appointmentId) {
      console.log("Event has no appointmentId, there is a timeslot of a different type");
      const timeslot: TimeSlotDto = {
        id: event.id!,
        user,
        start: event.start!,
        end: event.end!,
        type: event.type!,
      };
      const result = continuousView
        ? await updateContinuousTimeSlot(event.id!, timeslot)
        : await updateTimeSlot(event.id!, timeslot);
      console.log(result);
    } else {
      const appointment = continuousView
        ? await getContinuousAppointment(event.appointmentId!)
        : await getAppointment(event.appointmentId!);
      if (appointment) {
        const appDto = appointment as AppointmentDto;
        console.log("Appointment in onChangeEvent in Scheduler", appDto);

        // for main event
        setTimeSlotChanges(appDto, event, user!);

        // for related events
        relatedEvents?.forEach((relatedEvent) => {
          setTimeSlotChanges(appDto, relatedEvent, relatedEventUser!);
        });

        const result = continuousView
          ? updateContinuousWithResponse(event.appointmentId!, appDto)
          : await saveAppointment(event.appointmentId!, appDto);
        
        // if (result) {
        //   notificationStore.showMessage("Termin erfolgreich geändert", "success");
        // }
      }
    }
  };

  const setTimeSlotChanges = (appDto: AppointmentDto, event: Event, user: UserDto) => {
    const updatedTimeSlot = appDto.timeSlots?.find((slot) => slot.id?.toString() === event.id);
    if (updatedTimeSlot) {
      updatedTimeSlot.user = user;
      updatedTimeSlot.start = event.start!;
      updatedTimeSlot.end = event.end!;
    }
    const indexOfUpdatedTimeSlot = appDto.timeSlots?.findIndex(
      (slot) => slot.id === event.timeSlot?.id
    );
    if (indexOfUpdatedTimeSlot !== undefined && indexOfUpdatedTimeSlot !== -1) {
      if (appDto.timeSlots) {
        appDto.timeSlots[indexOfUpdatedTimeSlot] = updatedTimeSlot!;
      }
    }
  };

  const onEventReceive = async (eventReceive: EventReceiveArg) => {
    // const draggedEl = eventReceive.draggedEl;
    // draggedEl.style.display = "none"; // Detach the dragged element from the DOM
    // eventReceive.draggedEl.parentNode?.removeChild(eventReceive.draggedEl);
    const event = eventReceive.event;
    console.log("Event", event.toPlainObject());
    const userId = eventReceive.event.getResources()[0]?.id;
    const user = users.find((u) => u.id === userId);
    let current = new Date(event.start?.getTime()!);

    // calculate the shift for heat treatments
    let shift = 0;
    const heatTreatments = ["PA", "HL"];
    let firstNonHeatTreatmentIndex = Infinity;

    // sort timeSlots by therapyRx order
    const timeSlots = event.extendedProps.timeSlots?.slice().sort(
      (a: TimeSlotDto, b: TimeSlotDto) => a.therapyRx?.order! - b.therapyRx?.order!
    ) as TimeSlotDto[];
  
    // Find the first non-heat treatment index
    timeSlots.forEach((timeSlot: TimeSlotDto, index: number) => {
      if (!heatTreatments.includes((timeSlot.therapyRx?.therapy as TherapyExt).abbreviation)) {
        firstNonHeatTreatmentIndex = Math.min(firstNonHeatTreatmentIndex, index);
      }
    });

    // Calculate the shift for heat treatments that are before the first non-heat treatment index
    timeSlots.forEach((timeSlot: TimeSlotDto, index: number) => {
      if (
        heatTreatments.includes((timeSlot.therapyRx?.therapy as TherapyExt).abbreviation) &&
        index < firstNonHeatTreatmentIndex
      ) {
        shift -= (timeSlot.therapyRx?.therapy as TherapyExt).duration;
      }
    });

    const appointment: AppointmentDto = {
      start: event.start?.toString()!,
      end: event.end?.toString()!,
      frequency: {
        id: event.extendedProps.frequency.id,
        text: event.extendedProps.frequency.text,
        prefferedValue: event.extendedProps.frequency.prefferedValue,
      },
      timeSlots: timeSlots
        // ?.sort(
        //   (a: TimeSlotDto, b: TimeSlotDto) =>
        //     new Date(a.start).getTime() - new Date(b.start).getTime()
        // )
        .map((slot: any) => {
          const duration = parseInt((slot.therapyRx.therapy as TherapyExt).duration.toString());
          console.log("duration:", duration);
          const start = new Date(current.getTime());
          start.setMinutes(start.getMinutes());
          current.setMinutes(current.getMinutes() + duration);
          const end = new Date(current.getTime());
          start.setMinutes(start.getMinutes() + shift);
          end.setMinutes(end.getMinutes() + shift);
          console.log("start", start, "end", end);
          const timeSlot: TimeSlotDto = {
            id: slot.id,
            user,
            start: start.toISOString(),
            end: end.toISOString(),
            type: slot.type,
            therapyRx: {
              id: slot.therapyRx.id,
              therapy: {
                abbreviation: (slot.therapyRx.therapy as TherapyExt).abbreviation,
                duration: (slot.therapyRx.therapy as TherapyExt).duration,
              },
              amount: slot.therapyRx.amount,
              rx: {
                rxNumber: slot.therapyRx.rx.rxNumber,
              },
            },
          };
          return timeSlot;
        }),
    };
    console.log("Appointment", appointment);

    const result = continuousView
      ? // await saveContinuousAppointment(event.id!, appointment) :
        await updateContinuousWithResponse(event.id!, appointment)
      : await saveAppointment(event.id!, appointment);
    console.log(result);
    if (result) {
      setShelfAppointments(shelfAppointments.filter((app) => app.id !== event.id));
      setShelfContinuousAppointments(
        shelfContinuousAppointments.filter((app) => app.id !== event.id)
      );
      setUnscheduleds(unscheduleds.filter((app) => app.id !== event.id));
      notificationStore.showMessage("Termin erfolgreich umgezogen", "success");
      renderCalendar();
    }
  };

  const onRemoveEvent = async (timeslotId: string) => {
    const result = await deleteTimeslot(timeslotId);
    console.log(result);
  };

  const onRemoveContinuousEvent = async (timeslotId: string) => {
    const result = await deleteContinuousTimeslot(timeslotId);
    console.log(result);
  };

  const onMoveEventDate = async (event: Event) => {
    if (continuousView) {
      const continuousAppDto: ContinuousAppointmentDto = await getContinuousAppointment(
        event.appointmentId!
      );
      console.log("Event moved to shelf box:", continuousAppDto);
      continuousAppDto.timeSlots?.sort(
        (a: TimeSlotDto, b: TimeSlotDto) =>
          new Date(a.start).getTime() - new Date(b.start).getTime()
      );
      notificationStore.showMessage("Dauertermin ins Regal verschoben", "info");
      setShelfContinuousAppointments([...shelfContinuousAppointments, continuousAppDto]);
    } else {
      const appDto: AppointmentDto = await getAppointment(event.appointmentId!);
      appDto.timeSlots?.sort(
        (a: TimeSlotDto, b: TimeSlotDto) =>
          new Date(a.start).getTime() - new Date(b.start).getTime()
      );
      console.log("Event moved to shelf box:", appDto);
      notificationStore.showMessage("Termin ins Regal verschoben", "info");
      setShelfAppointments([...shelfAppointments, appDto]);
    }
  };

  const handleAddShelfEvent = async (event: Event) => {
    console.log("Event moved from shelf box:", event);
    const lastShelfEvent = continuousView
      ? shelfContinuousAppointments[shelfContinuousAppointments.length - 1]
      : shelfAppointments[shelfAppointments.length - 1];
    const userId = event.resourceId || "";
    const user = users.find((u) => u.id === userId);
    let current = new Date(event.start);

    if (lastShelfEvent) {
      try {
        //get the last shelf event duration in minutes
        const lastShelfEventDuration = lastShelfEvent.timeSlots!.reduce(
          (acc, slot) => acc + (slot.therapyRx?.therapy as TherapyExt).duration,
          0
        );

        lastShelfEvent.start = event.start;
        lastShelfEvent.end = moment(event.start)
          .add(lastShelfEventDuration, "minutes")
          .toISOString();

        lastShelfEvent.timeSlots = lastShelfEvent
          .timeSlots!.sort(
            (a: TimeSlotDto, b: TimeSlotDto) =>
              new Date(a.start).getTime() - new Date(b.start).getTime()
          )
          .map((slot: any) => {
            const duration = parseInt((slot.therapyRx.therapy as TherapyExt).duration.toString());
            const start = new Date(current.getTime());
            start.setMinutes(start.getMinutes());
            current.setMinutes(current.getMinutes() + duration);
            const end = new Date(current.getTime());
            console.log("start", start, "end", end);
            const timeSlot: TimeSlotDto = {
              id: slot.id,
              user,
              start: start.toISOString(),
              end: end.toISOString(),
              type: slot.type,
              therapyRx: {
                id: slot.therapyRx.id,
                therapy: {
                  abbreviation: (slot.therapyRx.therapy as TherapyExt).abbreviation,
                  duration: (slot.therapyRx.therapy as TherapyExt).duration,
                },
                amount: slot.therapyRx.amount,
                rx: {
                  rxNumber: slot.therapyRx.rx?.rxNumber,
                },
              },
            };
            return timeSlot;
          });

        const result = continuousView
          ? await updateContinuousWithResponse(
              lastShelfEvent.id!,
              lastShelfEvent as ContinuousAppointmentDto
            )
          : await saveAppointment(lastShelfEvent.id!, lastShelfEvent as AppointmentDto);
        console.log(result);
        setShelfAppointments(shelfAppointments.filter((app) => app.id !== lastShelfEvent.id));
        setShelfContinuousAppointments(
          shelfContinuousAppointments.filter((app) => app.id !== lastShelfEvent.id)
        );
        notificationStore.showMessage("Termin erfolgreich hinzugefügt", "success");
      } catch (error) {
        console.log(error);
        notificationStore.showMessage("Fehler beim Hinzufügen des Events", "error", error);
      } finally {
        renderCalendar();
      }
    }
  };

  const onEventContentClick = async (event: TimeSlotExt) => {
    console.log("Event content clicked", event);
    const patientId = event?.patient?.id?.toString();
    const rxId = event?.therapyRx?.rx?.id;
    if (patientId) {
      setDrawerOpen(true);
      setSelectedEventRxId(rxId);
      setShowOpenDrawerButton(true);
      await fetchPatient(patientId);
      await fetchUnscheduledAppointments(patientId, continuousView);
      await fetchPatientAppointments(patientId);
      await fetchPatientAvailability(patientId);
      await fetchPatientUnavailabeTypes(patientId);
    }
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  const handleOpenDrawer = () => {
    setDrawerOpen(true);
    setShowOpenDrawerButton(true);
  };

  const handlePatientTabChange = async (newPatientId: string) => {
    fetchPatient(newPatientId);
    fetchPatientAppointments(newPatientId);
  };

  const handleTargetPatientChange = async (newTarget: PatientDto) => {
    // replace target with temporary patient
    setTargetPatient(newTarget);
    const patientId = newTarget.id?.toString()!;
    fetchPatient(patientId);
  };

  const onCloseNotesDialog = () => {
    renderCalendar();
  };

  const drawerWidth = 320;
  return (
    <Grid container height={"100%"}>
      <Grid item width={drawerOpen ? drawerWidth : 0}>
        <CalendarDrawer
          open={drawerOpen}
          patient={patient!}
          targetPatient={targetPatient!}
          appointments={patientAppointments || []}
          drawerWidth={drawerWidth}
          selectedTab={selectedEventRxId}
          showOpenButton={showOpenDrawerButton}
          onClose={handleCloseDrawer}
          onOpen={handleOpenDrawer}
          onPatientTabChange={handlePatientTabChange}
          onTargetPatientChange={handleTargetPatientChange}
        />{" "}
      </Grid>
      <Grid item width={drawerOpen ? `calc(100% - ${drawerWidth}px)` : "100%"}>
        <Grid
          container
          direction="column"
          sx={{
            border: "0px dotted blue",
            height: "100%",
            alignItems: "stretch",
            flexWrap: "nowrap",
          }}
        >
          <Grid item sx={{ border: "0px solid green" }}>
            {/* unscheduled events */}
            {patient &&
              (continuousView
                ? shelfContinuousAppointments.length === 0
                : shelfAppointments.length === 0) && (
                <EventShelf
                  appointments={unscheduleds}
                  title={`Offene ${continuousView ? "Dauertermin" : "Termine"}`}
                  patient={patient}
                />
              )}
            {/* shelf events */}
            {(shelfAppointments.length > 0 || shelfContinuousAppointments.length > 0) && (
              <EventShelf
                appointments={continuousView ? shelfContinuousAppointments : shelfAppointments}
                title={"Ablage"}
              />
            )}
          </Grid>

          <Grid item sx={{ border: "0px solid red", flexGrow: 1 }}>
            <Calendar
              isContinuous={continuousView}
              users={users}
              appointments={appointments.filter(
                (app) => shelfAppointments.findIndex((shelfApp) => shelfApp.id === app.id) === -1
              )}
              goneFishings={goneFishings}
              leaveOfAbsences={leaveOfAbsences}
              eventTimeslots={EventTimeslots}
              lunchBreaks={lunchBreaks}
              travelTimes={travelTimes}
              patientAvailabilities={patientAvailability}
              patientUnavailableTypes={patientUnavailabeTypes}
              continuousEventTimeSlots={continuousEventTimeslots}
              continuousAppointments={continuousEvents.filter(
                (app) =>
                  shelfContinuousAppointments.findIndex((shelfApp) => shelfApp.id === app.id) === -1
              )}
              onDatesSet={setSelectedDate}
              onCreateEvent={onCreateEvent}
              onChangeEvent={onChangeEvent}
              onEventReceive={onEventReceive}
              onRemoveEvent={onRemoveEvent}
              onRemoveContinuousEvent={onRemoveContinuousEvent}
              onOutdated={renderCalendar}
              onContinuousViewChange={handleContinuousViewChange}
              isLoading={showLoading}
              onMoveEventDate={onMoveEventDate}
              isShelfEvents={shelfAppointments.length > 0 || shelfContinuousAppointments.length > 0}
              onAddShelfEvent={handleAddShelfEvent}
              onEventContentClick={onEventContentClick}
              onCloseNotesDialog={onCloseNotesDialog}
            />
          </Grid>
        </Grid>
      </Grid>
      <CAResponseDialog
        open={showResponseDialog}
        handleClose={() => {
          setShowResponseDialog(false);
        }}
        responseDialogData={responseDialogData}
      />
    </Grid>
  );
});

const CAResponseDialog = (props: any) => {
  const response = props.responseDialogData as CAResponse;
  let caAppDto: ContinuousAppointmentDto | undefined;
  let backPlanResults: BackPlanResult[] | undefined;

  if (props.responseDialogData) {
    caAppDto = response.savedCA;
    backPlanResults = response.backPlanResults;
  }
  return (
    <Dialog open={props.open} onClose={props.handleClose} style={{ zIndex: 2000 }} fullWidth>
      <DialogTitle id="alert-dialog-title">{"Zurückplanen"}</DialogTitle>
      <DialogContent>
        {caAppDto && (
          <>
            <FlatCard title="Dauertermin">
              <Grid container spacing={3}>
                <Grid item xs={3} />
                <Grid item xs={6}>
                  <AppointmentCard appointment={caAppDto} continous={true} />
                </Grid>
                <Grid item xs={3} />
              </Grid>
            </FlatCard>
            <FlatCard title="Ergebnisse der Zurückplanung">
              <List>
                {backPlanResults?.map((result: BackPlanResult, index) => (
                  <ListItem>
                    <Grid container spacing={3} alignItems={"center"}>
                      <Grid item xs={3}>
                        <Typography>{new Date(result.date).toLocaleDateString()}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        {result.app && (
                          <AppointmentCard appointment={result.app} continous={false} />
                        )}
                      </Grid>
                      <Grid item xs={3}>
                        <Typography>{result.message}</Typography>
                      </Grid>
                    </Grid>
                  </ListItem>
                ))}
              </List>
            </FlatCard>
          </>
        )}
        <></>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} autoFocus>
          Schließen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Scheduler;
