import { useLocalStore } from "mobx-react";
import React, { createContext, ReactNode } from "react";
import { HistoryApi, Configuration } from "../../api-client";
import { AuthContext } from "../Auth/auth.provider";
import useStore from "../../helpers/useStore";
import HistoryStore from "./historyStore";
import { UserContext } from "../User/User.provider";

interface HistoryProviderProps {
  children: ReactNode;
}

export const HistoryContext = createContext<HistoryStore | null>(null);
HistoryContext.displayName = "HistoryContext";

export const HistoryProvider = ({ children }: HistoryProviderProps) => {
  const basePath = process.env.REACT_APP_API_URL;
  const AuthenticationStore = useStore(AuthContext);
  const { authToken } = AuthenticationStore;
  const userStore = useStore(UserContext); // Use UserContext

  const config = new Configuration({
    basePath: basePath,
    accessToken: authToken || "",
  });

  // console.log('authiii',authToken,config)
  const apiClient = new HistoryApi(config);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const store = useLocalStore(() => new HistoryStore(apiClient, userStore, AuthenticationStore));

  store.apiClient = apiClient;
  // store.getCancelledAppointments().then((res) => { console.log("CancelledAppointmentsdfsf:"); });

  return <HistoryContext.Provider value={store}>{children}</HistoryContext.Provider>;
};
