import { Box, Grid } from "@mui/material";
import { observer } from "mobx-react";
import { TopLevelPaper } from "../../themes/StyledComponents";
import HistoryTable from "./HistoryTable";

const History = observer(() => {
  return (
    <Grid spacing={2} sx={{ overflowY: "auto", height: "calc(100vh - 80px)" }}>
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            height: "70px",
          }}
        ></Box>
      </Grid>
      <TopLevelPaper>
        <Grid container spacing={2} sx={{ marginTop: "20px !important" }}>
          {/* Add left margin to the Users component */}
          <Grid
            item
            xs={12}
            sx={{ marginLeft: "10px", width: "100%", border: "0px solid red", display: "flex" }}
          >
            <HistoryTable />
          </Grid>
        </Grid>
      </TopLevelPaper>
    </Grid>
  );
});

export default History;
