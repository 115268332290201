import { Box, Card, CircularProgress, Tab, Tabs, Typography } from "@mui/material";
import Notes from "../ScheduleOverview/Notes/Notes";
import { TicketDialog, TicketsTable } from "../Ticketing/TicketOverview";
import { useEffect, useState } from "react";
import { NoteDto } from "../../api-client";
import useStore from "../../helpers/useStore";
import { TicketsStoreContext } from "../../stores/Ticketing/tickets.provider";
import React from "react";
import { NotesContext } from "../../stores/Notes/notes.provider";
import { UserContext } from "../../stores/User/User.provider";
import { observer } from "mobx-react";
import { mobiliTheme } from "../../themes/mobiliTheme";
import PatientHistoryTimeline from "./PatientHistory/PatientHistoryTimeline";

// import { Tabs} from '@mui/base/Tabs';

export const PatientNotesAndRecords = observer(({ patientId }: { patientId: any }) => {
  const UserStore = useStore(UserContext);
  const { user } = UserStore;
  const [selectedTabs, setSelectedTabs] = useState(1);
  const [patientNotes, setPatientNotes] = useState<NoteDto[]>([]);
  const TicketsStore = useStore(TicketsStoreContext);
  const { getTicketsByPatientId, getTicketById, lastTicketsUpdate } = TicketsStore;
  const [ticketData, setTicketData] = useState([]);
  const [isTicketLoading, setIsTicketLoading] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [value, setValue] = React.useState("1");
  const NotesStore = useStore(NotesContext);

  const { getPatientNotes, postNotes,updateNoteById } = NotesStore;

  const [fetchNotes, setFetchNotes] = useState(true); // Use a flag

  // Data for the table
  useEffect(() => {
    const fetchData = async () => {
      if (patientId) {
        setIsLoading(true); // Set loading state to true before fetching data
        try {
          const response = await getTicketsByPatientId(patientId as number);
          setTicketData(response);
        } catch (error) {
          console.error("Error fetching tickets:", error);
          // Handle error (e.g., show error message to user)
        } finally {
          setIsLoading(false); // Set loading state to false regardless of success or failure
        }
      }
    };

    fetchData();
  }, [lastTicketsUpdate]);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const handleRowClick = async (ticket: any) => {
    setIsTicketLoading(true);
    try {
      setOpenDialog(true);

      const response = await getTicketById(ticket?.id);
      setSelectedTicket(response);
    } catch (error) {
      console.error("Error fetching ticket:", error);
    } finally {
      setIsTicketLoading(false);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  // Function to handle tab change
  const handleTabChange = (event: any, newValue: any) => {
    setSelectedTabs(newValue); // Update selectedTabs state variable with the new value
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getPatientNotes(patientId as unknown as string);

        setPatientNotes(response);
        setFetchNotes(false); // Set the flag to false to prevent further fetching
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchData();
  }, [patientId, fetchNotes]);

  useEffect(() => {
    if (selectedTabs === 2) {
      const fetchData = async () => {
        try {
          const tickets = await getTicketsByPatientId(patientId as number);
          setTicketData(tickets);
        } catch (error) {
          console.log("error", error);
        }
      };

      fetchData();
    }
  }, [selectedTabs]);
  const updateNote = async (index: number, updatedCard: NoteDto) => {
    updatedCard.patientId = patientId;
    if(updatedCard.id){
      await updateNoteById(updatedCard.id,user?.id as string,updatedCard)
   }
   else{
    await postNotes(user?.id as string, updatedCard);
   }
    const updatedNotes = [...patientNotes];
    updatedNotes[index] = updatedCard;
    setPatientNotes(updatedNotes);
    setFetchNotes(true);
  };

  return (
    <Box>
      <Box sx={{ border: "0px solid green", display: "flex", justifyContent: "flex-start" }}>
        <Tabs
          TabScrollButtonProps={{ style: { height: "0px" } }} // This hides the scroll buttons
          orientation="horizontal"
          value={selectedTabs}
          onChange={handleTabChange}
          aria-label="sub-tabs"
          sx={{ minHeight: "", paddingLeft: mobiliTheme.topLevelPadding }}
        >
          <Tab iconPosition="start" label="Notes" value={1} />
          <Tab
            iconPosition="start"
            label="Tickets"
            value={2}
            sx={{
              marginRight: "4px",
            }}
          />
          <Tab iconPosition="start" label="History" value={3} />
        </Tabs>
      </Box>
      <Card sx={{ padding: "0px", backgroundColor: mobiliTheme.cardBackground }}>
        {/* <Notes notes={patientNotes} setNotes={updateNote} /> */}
        {/* <Box sx={{ border: "0px solid green", flexGrow: "1" }}> */}

        {(() => {
          switch (selectedTabs) {
            case 1:
              return <Notes notes={patientNotes} setNotes={updateNote} maxVisibleNotes={patientNotes.length}/>;
            case 2:
              return (
                <>
                  {isLoading && <CircularProgress />}
                  {!isLoading && ticketData && ticketData.length === 0 && (
                    <Typography>Keine Tickets gefunden</Typography>
                  )}
                  {!isLoading && ticketData && ticketData.length > 0 && (
                    <TicketsTable handleRowClick={handleRowClick} ticketsData={ticketData} />
                  )}
                </>
              );

            case 3:
              // Render the History component or content here
              return <PatientHistoryTimeline patientId={patientId} />;

            default:
              return null; // Handle unexpected tab value
          }
        })()}

        <TicketDialog
          open={openDialog}
          handleClose={handleCloseDialog}
          selectedTicket={selectedTicket}
          setSelectedTicket={setSelectedTicket}
          isTicketLoading={isTicketLoading}
        />
      </Card>
    </Box>
  );
});
