import { Grid, List, ListItem, ListItemIcon, ListItemText, Paper, Tooltip } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import { observer } from "mobx-react";
import debounce from "lodash/debounce";
import useStore from "../helpers/useStore";
import { PatientsContext } from "../stores/Patients/patients.provider";
import InfoIcon from "@mui/icons-material/Info";

export enum formType {
  SHORT = "Short",
  LONG = "Long",
}

interface ValidationCardProps {
  patientData: any;
  selectedRx: any;
  form: formType;
}

interface ValidationResult {
  label: string;
  validity: string;
  message: string;
}

const ValidationCard: FC<ValidationCardProps> = observer(({ patientData, selectedRx, form }) => {
  const patientStore = useStore(PatientsContext);
  const { getLiveValidation } = patientStore;

  const [liveValidationData, setLiveValidationData] = useState<any>({});

  const debounceValidation = useCallback(
    debounce(async (currentFormData) => {
      const data = await getLiveValidation(currentFormData);
      setLiveValidationData(data);
    }, 100),
    []
  );

  useEffect(() => {
    if (selectedRx && patientData) {
      debounceValidation(selectedRx);
    }
  }, [selectedRx, patientData, debounceValidation]);

  const getPersonalValidationStatus = (result: ValidationResult) => {
    if (result.validity === "Valid") {
      return { icon: <CheckCircleIcon sx={{ color: "success.main" }} />, message: result.message };
    } else if (result.validity === "Not Valid") {
      return { icon: <ErrorIcon sx={{ color: "error.main" }} />, message: result.message };
    } else if (result.validity === "Partially Valid") {
      return { icon: <WarningIcon sx={{ color: "warning.main" }} />, message: result.message };
    } else if (result.validity === "Info") {
      return { icon: <InfoIcon sx={{ color: "info.main" }} />, message: result.message };
    } else {
      return { icon: <ErrorIcon sx={{ color: "error.main" }} />, message: result.message };
    }
  };

  // if (!selectedRx.type?.id || selectedRx?.type.id !== "Kasse") {
  //   return (

  <List>
    <ListItem>
      <ListItemIcon>
        <WarningIcon style={{ color: "warning.main" }} />
      </ListItemIcon>
      <ListItemText
        sx={{ fontWeight: "bold" }}
        primary="Diese Validierungskarte ist nur für den Rezept-typ Kasse verfügbar."
      />
    </ListItem>
  </List>;

  //   );
  // }

  // const extractValidationResults = (validationObject: any) => {
  //   return Object.keys(validationObject).map((key) => ({
  //     label: validationObject[key].label,
  //     result: validationObject[key],
  //   }));
  // };

  const extractValidationResults = (validationObject: any) => {
    return Object.keys(validationObject).flatMap((key) => {
      const validation = validationObject[key];
      const results = [];
      if (validation) {
        // Push the main validation result
        results.push({
          label: validation.label,
          result: validation,
        });

        // Check for additional validation result
        if (validation.additionalInfo) {
          results.push({
            label: validation.additionalInfo.label,
            result: {
              validity: validation.additionalInfo.validity,
              message: validation.additionalInfo.message,
            },
          });
        }
      }
      return results;
    });
  };

  const patientValidationResults = patientData?.validationResults
    ? extractValidationResults(patientData.validationResults)
    : [];
  const liveValidationResults = liveValidationData
    ? extractValidationResults(liveValidationData)
    : [];
  const validationResultsAll = [...patientValidationResults, ...liveValidationResults];
  // const rxValidationResults = selectedRx?.rxValidation ? extractValidationResults(selectedRx.rxValidation) : [];
  // const validationResultsAll = [...patientValidationResults, ...rxValidationResults];

  const validationResults = validationResultsAll.filter(({ result }) => result);

  validationResults.sort((a, b) => {
    if (a.result.validity === "Not Valid") return -1;
    if (b.result.validity === "Not Valid") return 1;
    if (a.result.validity === "Partially Valid") return -1;
    if (b.result.validity === "Partially Valid") return 1;
    if (a.result.validity === "Info") return -1;
    if (b.result.validity === "Info") return 1;
    return 0;
  });

  return (
    <List>
      {validationResults.map(({ label, result }, index) => (
        <Tooltip
          title={result.message}
          placement="right"
          arrow
        >
          <ListItem
            dense
            sx={{ padding: "2px 1px" }}
            key={index}
          >
            <ListItemIcon sx={{ minWidth: "1px", marginRight: "1px" }}>
              {getPersonalValidationStatus(result).icon}
            </ListItemIcon>
            <ListItemText
              primary={label}
              secondary={
                form === formType.LONG ? getPersonalValidationStatus(result).message : null
              }
              //{getPersonalValidationStatus(result).message}
              primaryTypographyProps={{ variant: "body2" }}
            />
          </ListItem>
        </Tooltip>
      ))}
    </List>
  );
});

export default ValidationCard;
