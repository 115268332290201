import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, styled } from "@mui/material";
import { ReactNode } from "react";
import { mainAppGridStyle, mobiliTheme } from "../themes/mobiliTheme";

const FullScreenDialogStyled = styled(Dialog)(({ theme }) => ({
  // Custom styles here
  "& .MuiDialogTitle-root": {
    backgroundColor: theme.mainHeader,
    color: theme.palette.common.white,
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    background: `linear-gradient(to top, ${mobiliTheme.mainHeader}, ${mobiliTheme.topLevelBackground})`,
  },
  "& .MuiDialogActions-root": {
    backgroundColor: theme.topLevelBackground,
    padding: theme.spacing(1),
  },
}));

// Define the props for FullScreenDialog
interface FullScreenDialogProps extends DialogProps {
    titleComp: ReactNode;
    children: ReactNode;
    actions?: ReactNode;
  }
  
  // FullScreenDialog component
  export const FullScreenDialog = (props: FullScreenDialogProps) => {
    const {  titleComp, children, actions, fullScreen = true, ...rest } = props;
  
    return (
      <FullScreenDialogStyled fullScreen={fullScreen} {...rest}>
        <DialogTitle>{titleComp}</DialogTitle>
        <DialogContent
         sx={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
            overflowY: "hidden",
            pt: 40,
          }}
          >{children}</DialogContent>
        {actions && <DialogActions>{actions}</DialogActions>}
      </FullScreenDialogStyled>
    );
  };