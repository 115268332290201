import moment from "moment";
import { AppointmentDto, ContinuousAppointmentDto, EventTimeSlotDto, LeaveOfAbsenceDto, PatientDto, TimeSlotDto, UserDto } from "../../api-client";
import useStore from "../../helpers/useStore";
import { UserContext } from "../../stores/User/User.provider";
import { FC, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { CalendarContext } from "../../stores/Calendar/calendar.provider";
import { Event } from "../../molecules/Calendar/Calendar.type";
import { useSearchParams } from "react-router-dom";
import { PatientsContext } from "../../stores/Patients/patients.provider";
import { Draggable, EventReceiveArg } from "@fullcalendar/interaction";
import { EventDropArg } from "@fullcalendar/core";
import UnscheduledEvents from "../../molecules/Calendar/UnscheduledEvents";
import { observer } from "mobx-react";
import notificationStore from "../../stores/Notification/notificationStore";
import WeeklyCalendar from "../../molecules/Calendar/WeeklyCalendar";
import { AppointmentContext } from "../../stores/Appointment/appointment.provider";

interface ContinuousSchedulerProps {
  userId?: string;
}

const ContinuousScheduler: React.FC<ContinuousSchedulerProps> = observer(({ userId }) => {
  
  const DATE_FORMAT = 'YYYY-MM-DD';
  const UserStore = useStore(UserContext)
  const { user, getUserById } = UserStore;
  const location = user?.location?.id;

  const [therpist, setTherapist] = useState<UserDto>();
  const [continiuousEvents, setContiniuousEvents] = useState<Event[]>([]);
  const [unscheduleds, setUnscheduleds] = useState<AppointmentDto[]>([]);
  const [showLoading, setShowLoading] = useState(false);
  const [users, setUsers] = useState<UserDto[]>([]);
  const AppointmentStore = useStore(AppointmentContext)
  const {  updateContinuousAppointment } = AppointmentStore;
  const CalendarStore = useStore(CalendarContext);
  const { 
    getContinuousAppointment, 
    getContinuousAppointmentsForUser
  } = CalendarStore;

  const [queryParameters] = useSearchParams();

  useEffect(() => {
    fetchData();
  }, []);
  
  const fetchData = async () => {
    if (userId) {
      const user = await getUserById(userId);
      setTherapist(user);
      fetchContiniuousAppointments(userId);
    }
  };

  const Loading = (isLoading: boolean) => {
    setShowLoading(isLoading);
  }

  // const fetchUsers = async () => {
  //   try {
  //     Loading(true);
  //     const users = await getUsersByLocation(location!);
  //     setUsers(users);
  //   }
  //   catch (error) {
  //     console.log(error);
  //   }
  //   finally {
  //     Loading(false);
  //   }
  // };

  const fetchContiniuousAppointments = async (userId: string) => {
    try {
      Loading(true);
      const data = await getContinuousAppointmentsForUser(userId);
      if (data) {
        const events = data?.map((appointment: ContinuousAppointmentDto) => {
          let evts = appointment.timeSlots?.map((slot: TimeSlotDto) => {
            let evnt: Event = {
              id: slot.id?.toString()!,
              title: 'Continuous Appointment',
              start: appointment.start!,
              end: appointment.end!,
              appointmentId: appointment.id,
              // resourceId: eventTimeslot.user?.id,
              // type: "",
              // editable: false,
            };
            return evnt;
          });
          return evts;
        })
        .flat();
        setContiniuousEvents(events);
        console.log('Continiuous Appointments:', events);
      }
    }
    catch (error) {
      console.error(error);
      notificationStore.showMessage("Fehler beim Laden der Termine", "error", error);
    }
    finally {
      Loading(false);
      console.log('fetch Continiuous Appointments Loading finished');
    }
  }

  const onCreateEvent = async (event: Event) => {
    try {
      console.log('Event', event);
      let result : any;

      const timeslot: TimeSlotDto = {
        type: "",
        start: moment(event.start!).toISOString(),
        end: moment(event.end!).toISOString()
      }
      console.log('Timeslot', timeslot);
      // result = await createTimeslot(timeslot);
       
      notificationStore.showMessage("Event erfolgreich hinzugefügt", "success");
      // console.log(result);
    }
    catch (error) {
      console.log(error);
      notificationStore.showMessage("Fehler beim Hinzufügen des Events", "error", error);
    }
    finally {
      console.log("Calling renderCalendar");
      renderCalendar();
    }
  }

  const renderCalendar = async () => {
  }

  const onChangeEvent = async (event: Event, relatedEvents?: Event[]) => {

    // const userId = event.resourceId || '';
    // const user = users.find(u => u.id === userId);

    // const relatedEventUserId = relatedEvents?.map(event => event.resourceId)[0];
    // const relatedEventUser = users.find(u => u.id === relatedEventUserId);

    console.log('Event', event);
    
    const appointment = await getContinuousAppointment(event.appointmentId!);
    if (appointment) {
      const appDto = appointment as ContinuousAppointmentDto;
      console.log('Continuous Appointment', appDto);

      // for main event
      setTimeSlotChanges(appDto, event, user!);
      
      // // for related events
      // relatedEvents?.forEach(relatedEvent => {
      //   setTimeSlotChanges(appDto, relatedEvent, relatedEventUser!);
      // });

      const result = await updateContinuousAppointment(event.appointmentId!, appDto);
      console.log(result);
    }
  }

  const setTimeSlotChanges = (appDto: AppointmentDto, event: Event, user: UserDto) => {
    const updatedTimeSlot = appDto.timeSlots?.find(slot => slot.id?.toString() ===  event.id);
      if (updatedTimeSlot) {
        updatedTimeSlot.user = user;
        updatedTimeSlot.start = event.start!;
        updatedTimeSlot.end = event.end!;
      }
      const indexOfUpdatedTimeSlot = appDto.timeSlots?.findIndex(slot => slot.id === event.timeSlot?.id);
      if (indexOfUpdatedTimeSlot !== undefined && indexOfUpdatedTimeSlot !== -1) {
        if (appDto.timeSlots) {
          appDto.timeSlots[indexOfUpdatedTimeSlot] = updatedTimeSlot!;
        }
      }
    }

  const onEventReceive = async (eventReceive: EventReceiveArg) => {
    eventReceive.draggedEl.parentNode?.removeChild(eventReceive.draggedEl);
    const event = eventReceive.event;
    console.log('Event', event.toPlainObject());
    let accumulatedDuration: number = 0;

    const appointment: ContinuousAppointmentDto = {
      start: event.start?.toString()!,
      end: event.end?.toString()!,
      frequency: {
        id: event.extendedProps.frequency.id, 
        text: event.extendedProps.frequency.text, 
        prefferedValue: event.extendedProps.frequency.prefferedValue
      },
      timeSlots: event.extendedProps.timeSlots?.sort((a: TimeSlotDto, b: TimeSlotDto) => parseInt(a.id!) - parseInt(b.id!))
        .map((slot: any) => {
          console.log('duration:', slot.duration);
          const start = new Date(event.start?.getTime()!);
          const end = new Date(event.start?.getTime()!);
          start.setMinutes(start.getMinutes() + accumulatedDuration);
          end.setMinutes(start.getMinutes() + parseInt(slot.duration));
          const timeSlot: TimeSlotDto = {
            start: start.toISOString(),
            end: end.toISOString(),
            type: slot.type,
          };
          accumulatedDuration += parseInt(slot.duration);
          return timeSlot;
        }),
    }
    console.log('Appointment in onEventReceive', appointment);
    
    const result = await updateContinuousAppointment(event.id!, appointment);
    console.log(result);

    renderCalendar();
  }

  // // const onRemoveEvent = async (timeslotId: number) => {
  //   const result = await deleteTimeslot(timeslotId);
  //   console.log(result);
  // }

  return (
      <Box padding={1} paddingTop={3}>
        Dauert Termin für {therpist?.firstName && therpist?.lastName && <h3>{therpist?.firstName} {therpist?.lastName}</h3>}
        <WeeklyCalendar
          events={continiuousEvents}
          // onDatesSet={setSelectedDate}
          onCreateEvent={onCreateEvent}
          onChangeEvent={onChangeEvent}
          onEventReceive={onEventReceive}
          // onRemoveEvent={onRemoveEvent}
          onOutdated ={renderCalendar}
        />
      </Box>
  );
});

export default ContinuousScheduler